import useMedia from 'modules/Shared/hooks/useMedia';
import Footer, { FooterColorEnum } from 'modules/UI/components/Footer';
import Logo from 'modules/UI/components/Logo';
import SideNav from 'modules/UI/components/SideNav';
import AppHeader from 'modules/UI/components/AppHeader';
import ErrorBoundary from 'modules/Shared/components/ErrorBoundary';
import type { ReactNode } from 'react';
import {
  GlobalStyles,
  LayoutContent,
  LayoutContentWrapper,
  LayoutNavigation,
  LayoutWrapper,
  LogoWrapper,
  StyledHeaderRow,
  StyledRow,
} from './DefaultLayout.styled';

type DefaultLayoutProps = {
  children?: ReactNode;
};

const DefaultLayout = ({ children }: DefaultLayoutProps): JSX.Element => {
  const { isTablet } = useMedia();

  return (
    <LayoutWrapper>
      <GlobalStyles />

      <LayoutContentWrapper>
        <LayoutNavigation>
          <LogoWrapper>
            <Logo small={isTablet} />
          </LogoWrapper>

          <ErrorBoundary>
            <SideNav />
          </ErrorBoundary>
        </LayoutNavigation>

        <LayoutContent>
          <StyledHeaderRow>
            <AppHeader />
          </StyledHeaderRow>

          <StyledRow>
            <ErrorBoundary>{children}</ErrorBoundary>
          </StyledRow>
        </LayoutContent>
      </LayoutContentWrapper>

      <ErrorBoundary>
        <Footer color={FooterColorEnum.primary} />
      </ErrorBoundary>
    </LayoutWrapper>
  );
};

export default DefaultLayout;
