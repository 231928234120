import { Component } from 'react';
import type { PropsWithChildren, ReactNode } from 'react';
import ErrorComponent from './Error';

type ErrorBoundaryProps = PropsWithChildren<Record<string, unknown>>;

type ErrorBoundaryState = {
  error: Error | null;
  isNothingRendered?: boolean;
  isRetryDisabled?: boolean;
};

const TIME_BUTTON_DISABLED = 800;
const TIME_RENDER_NOTHING = 400;

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error };
  }

  state: ErrorBoundaryState = { error: null };

  private _timeoutEnableRetry: number | undefined;
  private _timeoutRenderNothing: number | undefined;

  componentWillUnmount(): void {
    window.clearTimeout(this._timeoutEnableRetry);
    window.clearTimeout(this._timeoutRenderNothing);
  }

  componentDidCatch(error: Error): void {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  scheduleResetRenderNothing(): void {
    window.clearTimeout(this._timeoutRenderNothing);

    this._timeoutRenderNothing = window.setTimeout(
      this.handleResetRenderNothing,
      TIME_RENDER_NOTHING
    );
  }

  scheduleResetRetryDisabled(): void {
    window.clearTimeout(this._timeoutEnableRetry);

    this._timeoutEnableRetry = window.setTimeout(
      this.handleResetRetryDisabled,
      TIME_BUTTON_DISABLED
    );
  }

  handleResetRenderNothing = (): void => {
    this.setState({ isNothingRendered: false });
  };

  handleResetRetryDisabled = (): void => {
    this.setState({ isRetryDisabled: false });
  };

  handleClickRetry = (): void => {
    if (this.state.isRetryDisabled) return;

    this.setState(
      { isNothingRendered: true, isRetryDisabled: true, error: null },
      () => {
        this.scheduleResetRenderNothing();
        this.scheduleResetRetryDisabled();
      }
    );
  };

  render(): ReactNode {
    const { children } = this.props;
    const { error, isRetryDisabled, isNothingRendered } = this.state;

    if (error || isNothingRendered) {
      return (
        <ErrorComponent
          isRetryDisabled={isRetryDisabled}
          isNotShown={isNothingRendered}
          onRetry={this.handleClickRetry}
        />
      );
    }

    return children;
  }
}
