import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import PublishPackageButton from 'modules/Documents/components/PublishPackageButton';
import type { PropsWithChildren, ReactNode } from 'react';
import { StyledDiv, StyledText } from '../../PackageWizard.styled';
import { ButtonsWrapper } from './PublishPackage.styled';

type PublishPackageProps = PropsWithChildren<{
  onPublish: () => Promise<void>;
  slotBeforeButtons?: ReactNode;
  handleSavePackage: () => Promise<void>;
  isPublishDisabled?: boolean;
  isSaveDisabled?: boolean;
}>;

const PublishPackage = ({
  children,
  slotBeforeButtons,
  onPublish,
  isPublishDisabled,
  isSaveDisabled,
  handleSavePackage,
}: PublishPackageProps): JSX.Element => {
  return (
    <>
      <StyledText>
        Проверьте корректность заполнения данных перед отправкой документов.
        <br />
        Внимание, документы станут доступны выбранным участникам, их нельзя
        <br />
        будет изменить
      </StyledText>

      {slotBeforeButtons}

      <ButtonsWrapper>
        <PublishPackageButton
          disabled={isPublishDisabled}
          onClick={onPublish}
          size={ButtonSizesEnum.Medium}
          type='button'
        />

        <StyledDiv />

        <Button
          color={ButtonColorsEnum.Secondary}
          disabled={isSaveDisabled}
          onClick={handleSavePackage}
          size={ButtonSizesEnum.Medium}
          type='button'
        >
          Сохранить
        </Button>
      </ButtonsWrapper>

      {children}
    </>
  );
};

export default PublishPackage;
