import styled, { css } from 'styled-components';
import Button from '@signplatform-ui/button';
import Header from '@signplatform-ui/header';
import flex from 'modules/StyledUtility/Flex';
import Text from '@signplatform-ui/text';
import { media } from 'modules/StyledUtility';

export const ButtonWithIcon = styled(Button)`
  & + & {
    margin-left: 12px;
  }
`;

export const DocumentHeader = styled(Header)`
  margin: 0 0 4px;
  min-height: 40px;
  ${flex.startCenter};
  word-break: break-word;

  ${media.mobile(css`
    display: block;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `)}
`;

export const StyledUniqueNumber = styled(Text)`
  margin: 0 0 20px;
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  top: 27px;
  right: 0;
  z-index: 75;
`;
