import { media } from 'modules/StyledUtility';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Card from '@signplatform-ui/card';
import Button from '@signplatform-ui/button';
import { hexToRgba } from '@signplatform-ui/colors';
import Profile from './components/Profile';

const SIDEBAR_Z_INDEX = 9000;

export const StyledCard = styled(Card)<{ isVisible: boolean }>`
  position: static;
  display: block;
  height: auto;
  width: 100%;
  border-radius: 16px;
  background-color: ${({ theme }) =>
    hexToRgba(theme.color.background.primary, 0.6)!};
  transform: none;
  transition: none;

  ${media.tablet(css<{ isVisible: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 12px 24px 20px;
    background: ${({ theme }) => theme.color.background.primary};
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 232px;
    z-index: ${SIDEBAR_Z_INDEX};
    transition: transform 0.2s ease-in;
    transform: translateX(${({ isVisible }) => (isVisible ? '0' : '300px')});
    border-radius: 16px 0 0 16px;
  `)}
`;

export const StyledSideNavList = styled.ul`
  padding: 0;
  margin: 0;
`;

export const StyledMenuControl = styled(Button)`
  display: none;

  ${media.tablet(
    css`
      display: block;
    `
  )}

  &[data-dimension='s'] {
    width: 40px;
    height: 40px;
    padding: 4px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const StyledCloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  ${StyledMenuControl} {
    &[data-dimension='s'] {
      margin-right: -8px;
    }

    svg path {
      fill: ${({ theme }) => theme.color.text.secondary};
    }
  }
`;

export const StyledOverlay = styled.div`
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  content: '';
  width: 100%;
  height: 100%;
  z-index: ${SIDEBAR_Z_INDEX - 1};
  background: #000;
  opacity: 0.4;

  ${media.tablet(
    css`
      display: block;
    `
  )}
`;

export const StyledUserProfile = styled(Profile)`
  display: none;

  ${media.tablet(
    css`
      display: flex;
    `
  )}
`;

export const StyledContainer = styled.div`
  flex-grow: 1;
`;

export const StyledControlsLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px 9px;
  border-radius: 8px;
  color: ${({ theme }) => theme.color.text.primary};

  &:hover {
    color: ${({ theme }) => theme.color.basic.primary};

    svg path {
      fill: ${({ theme }) => theme.color.basic.primary};
    }
  }

  svg {
    margin-right: 8px;
  }
`;

export const StyledControls = styled.div`
  display: none;

  ${media.tablet(
    css`
      display: block;
    `
  )}
`;
