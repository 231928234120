import AppRoutesPaths from 'modules/Router/routes/paths';
import { lazy } from 'react';
import type { AppRoute } from '../types';
import { RouteTypeEnum } from '../types';

const DetailsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProfileDetails" */ 'modules/Profile/pages/Details'
    )
);

const ExitPage = lazy(
  () =>
    import(/* webpackChunkName: "ProfileExit" */ 'modules/Profile/pages/Exit')
);

const routes: AppRoute[] = [
  {
    id: 'profile:details',
    path: AppRoutesPaths.ProfileDetails,
    type: RouteTypeEnum.Private,
    exact: true,
    component: DetailsPage,
  },
  {
    id: 'profile:exit',
    path: AppRoutesPaths.ProfileExit,
    type: RouteTypeEnum.Private,
    exact: true,
    component: ExitPage,
  },
];

export default routes;
