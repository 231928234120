import ApiProvider from 'modules/Api/components/ApiProvider';
import NotificationsContainer from 'modules/Notifications/components/NotificationsContainer';
import AppPayloadProvider from 'modules/Shared/components/AppPayloadProvider';
import MediaProvider from 'modules/Shared/components/MediaProvider';
import TokenProvider from 'modules/Shared/components/TokenProvider';
import UserProvider from 'modules/Shared/components/UserProvider';
import { memo } from 'react';
import type { PropsWithChildren } from 'react';

function AppStarter({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element {
  return (
    <MediaProvider>
      <TokenProvider>
        <ApiProvider>
          <UserProvider>
            <AppPayloadProvider>{children}</AppPayloadProvider>
          </UserProvider>
        </ApiProvider>
      </TokenProvider>

      <NotificationsContainer />
    </MediaProvider>
  );
}

export default memo(AppStarter);
