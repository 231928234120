import { pluralize } from 'services/commonUtils';

const secondsVariants: [string, string, string] = [
  'секунду',
  'секунды',
  'секунд',
];

export default function getThresholdMessage(threshold: number): string {
  return `Повторная отправка будет доступна через ${threshold} ${pluralize(
    threshold,
    secondsVariants
  )}`;
}
