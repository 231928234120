import { isEmpty } from 'lodash';
import { ParticipantsLabel, ParticipantsWrapper } from './Participants.styled';

type ParticipantsProps = {
  participants: string[];
  className?: string;
};

const Participants = ({
  participants,
  className = '',
}: ParticipantsProps): JSX.Element => {
  return (
    <ParticipantsWrapper className={className}>
      <ParticipantsLabel>
        Участники{!isEmpty(participants) && `(${participants.length})`}:{' '}
      </ParticipantsLabel>
      {participants.map((participant, idx) => (
        <span>
          {participant}
          {idx < participants.length - 1 && participants.length > 1 ? ',' : '.'}
        </span>
      ))}
    </ParticipantsWrapper>
  );
};

export default Participants;
