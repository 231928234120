import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import { TextTypesEnum } from '@signplatform-ui/text';
import { useSignPackage } from 'modules/Documents/hooks';
import notifySigningSuccess from 'modules/Documents/utils/notifySigningSuccess';
import Modal, {
  ModalButtonPanel,
  ModalContent,
  ModalTitle,
} from 'modules/UI/components/Modal';
import PackageSigningErrorModal from '../PackageSigningErrorModal';
import qrCodeImgNormal from './images/qr-code.png';
import qrCodeImg2x from './images/qr-code_2x.png';
import qrCodeImgMin from './images/qr-code_min.png';
import {
  StyledDescription,
  StyledDescriptionContainer,
  StyledMainText,
  StyledQR,
  StyledText,
} from './PackagePublishedModal.styled';

type DocumentCreatedModalProps = {
  hasSignature?: boolean;
  onClose: () => void;
  onSignSuccess?: () => Promise<void> | void;
  packageId: EntityId;
  ownerClientId: EntityId;
};

const PackagePublishedModal = ({
  hasSignature,
  onClose,
  onSignSuccess,
  packageId,
  ownerClientId,
}: DocumentCreatedModalProps): JSX.Element | null => {
  const { isLoading, isError, reset, mutateAsync } = useSignPackage();

  const onSignPackage = async () => {
    await mutateAsync({
      packageId,
      clientIdOwner: ownerClientId,
    });
    await onSignSuccess?.();
    onClose();
    notifySigningSuccess();
  };

  const onCloseModal = () => {
    reset();
    onClose();
  };

  if (isError) {
    return <PackageSigningErrorModal onClose={onCloseModal} />;
  }

  return (
    <Modal onClose={onClose}>
      <ModalTitle>
        Комплект документов отправлен выбранным участникам
      </ModalTitle>

      <ModalContent>
        {!hasSignature && (
          <>
            <StyledMainText>
              Документы отправлены выбранным участникам. Вы можете перейти к
              подписанию сейчас или сделать это позднее через форму просмотра
              документов.
            </StyledMainText>

            <StyledDescriptionContainer>
              <StyledQR
                src={qrCodeImgNormal}
                srcX2={qrCodeImg2x}
                srcPlaceholder={qrCodeImgMin}
                alt='Наведите камеру телефона на QR-код или перейдите в приложение'
              />

              <StyledDescription>
                <StyledText type={TextTypesEnum.SBold}>
                  Подписание документов происходит в мобильном приложении
                  Госключ
                </StyledText>
                Чтобы установить приложение, наведите камеру на QR-код и
                скачайте мобильное приложение или перейдите на{' '}
                <a href='https://goskey.ru' rel='noopener'>
                  goskey.ru
                </a>
              </StyledDescription>
            </StyledDescriptionContainer>
          </>
        )}
        {hasSignature && (
          <>Откройте мобильное приложение Госключ и завершите подписание</>
        )}
      </ModalContent>

      <ModalButtonPanel>
        <Button
          onClick={onSignPackage}
          size={ButtonSizesEnum.Medium}
          disabled={isLoading}
        >
          Подписать сейчас
        </Button>

        <Button
          onClick={onClose}
          color={ButtonColorsEnum.Secondary}
          size={ButtonSizesEnum.Medium}
          disabled={isLoading}
        >
          Подписать позже
        </Button>
      </ModalButtonPanel>
    </Modal>
  );
};

export default PackagePublishedModal;
