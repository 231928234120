import MaskedInput from 'modules/UI/components/form/MaskedInput';
import type { ChangeEventHandler, FocusEventHandler } from 'react';

type SnilsInputProps = {
  name: string;
  label?: string;
  placeholder?: string;
  status?: 'error' | 'success';
  extraText?: string;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  value?: string;
  unmasked?: boolean;
  disabled?: boolean;
};

const SNILS_FORMAT_MASK = '000-000-000 00';
const SNILS_LABEL = 'СНИЛС';
const SNILS_PLACEHOLDER = 'Введите СНИЛС';

const maskOptions = {
  mask: SNILS_FORMAT_MASK,
};

const SnilsInput = ({
  name,
  label = SNILS_LABEL,
  placeholder = SNILS_PLACEHOLDER,
  status,
  extraText,
  onChange,
  className,
  value = '',
  ...rest
}: SnilsInputProps): JSX.Element => {
  return (
    <MaskedInput
      className={className}
      displayClearIcon={!rest.disabled && !!value?.length}
      extraText={extraText}
      label={label}
      maskOptions={maskOptions}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      status={status}
      value={value}
      {...rest}
    />
  );
};

export default SnilsInput;
