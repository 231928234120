import flex from 'modules/StyledUtility/Flex';
import { media } from 'modules/StyledUtility';
import { memo } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import bodyBg from './images/body.png';

const backgroundBase = `fixed left top / 100% 720px no-repeat url('${bodyBg}')`;
const bgTabletSize = '1280px 720px';
const backgroundTablet = `left top / ${bgTabletSize} no-repeat url('${bodyBg}')`;
const background = `${backgroundBase}, linear-gradient(90deg, #FBFCFF 0%, #EDF1FF 101.21%), #EDF1FF`;

export const stickyStyles = css`
  position: sticky;

  ${media.tablet(css`
    background: ${backgroundTablet};
  `)};

  ${media.lowHeight(css`
    position: static;
  `)}
`;

export const GlobalStyles = memo(createGlobalStyle`
  body {
    background: ${background};
    --headerHeight: 80px;

    ${media.tablet(css`
      background-size: ${bgTabletSize};
      --headerHeight: 64px;
    `)}
  }
`);

export const LayoutWrapper = styled.div`
  ${flex.flowCol};
  min-height: 100vh;
`;

export const LayoutContentWrapper = styled.div`
  ${flex.centerStart};
  flex-grow: 1;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

export const LayoutNavigation = styled.div`
  flex: 0 0 auto;
  padding: 0 0 0 56px;
  ${stickyStyles};
  top: 0;
  width: 288px;
  z-index: 90;

  ${media.tablet(css`
    width: 100%;
    ${flex.betweenCenter};
    padding: 12px 48px;
  `)}

  ${media.mobile(css`
    padding: 12px 16px;
  `)}
`;

export const LayoutContent = styled.div`
  ${flex.flowCol};
  flex: 1 1 auto;
  max-width: ${({ theme }) => theme.gridConfig.container.maxWidth.xxl}px;
  padding: 0 40px 40px;
  width: 100%;

  ${media.tablet(css`
    padding: 0 48px 40px;
  `)};

  ${media.mobile(css`
    padding: 0 16px 40px;
  `)}
`;

export const LogoWrapper = styled.div`
  padding: 24px;

  ${media.tablet(css`
    padding: 0;
  `)}
`;

export const StyledHeaderRow = styled.div`
  background: ${background};
  position: sticky;
  top: 0;
  z-index: 80;

  ${media.tablet(css`
    display: none;
  `)}
`;

export const StyledRow = styled.div`
  flex: 1 1 auto;
  position: relative;
`;

type StyledStickyContainerProps = {
  $top?: number;
  $hasOffset?: boolean;
};

function getOffsetStyles({
  $top,
  $hasOffset = true,
}: StyledStickyContainerProps) {
  const topValue =
    $top !== undefined
      ? `${$top}px`
      : $hasOffset
      ? 'calc(var(--headerHeight) - 1px)'
      : 'var(--headerHeight)';

  return css`
    top: ${topValue};
    // ${$hasOffset ? 'margin: -1px -1px 0' : undefined}
  `;
}

export const StyledStickyContainer = styled.div<StyledStickyContainerProps>`
  background: ${background};
  z-index: 70;
  ${flex.flowCol};
  ${stickyStyles};
  ${getOffsetStyles};

  ${media.tablet(css`
    background-attachment: initial;
    background-position-y: calc(-1 * var(--headerHeight));
    padding-right: 48px;
    padding-left: 48px;
    margin-left: -48px;
    margin-right: -48px;
  `)}

  ${media.mobile(css`
    padding-right: 56px;
    padding-left: 16px;
    margin-left: -16px;
    margin-right: -16px;
  `)}
`;
