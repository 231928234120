import {
  ClientServerFile,
  ClientServerFileCreator,
} from 'modules/UI/components/DocumentsPackEditor';
import DocumentValidator from 'modules/Documents/classes/DocumentValidator';
import type { IFileInstance } from 'modules/UI/types';
import type { DocumentFullData } from 'modules/Documents/types';
import type {
  IPackageDocsAdapter,
  IPackageDocsAdapterBasic,
  SigningsAdaptedInput,
} from './types';

type PackageDocsAdapterInput = DocumentFullData[];

type MapToServerData = {
  clientId: EntityId;
  doMapSigningsForDocument: (doc: IFileInstance) => SigningsAdaptedInput;
  docsUINameById: Readonly<{ [clientServerId: string]: string }>;
  instance: IPackageDocsAdapterBasic;
  packageId: EntityId;
};

export default class PackageDocsAdapter implements IPackageDocsAdapter {
  static mapToServer({
    clientId,
    doMapSigningsForDocument,
    docsUINameById,
    instance,
    packageId,
  }: MapToServerData): Map<DocumentFullData, IFileInstance> {
    const result = new Map<DocumentFullData, IFileInstance>();

    instance.docs.forEach((fileData) => {
      const mappedFileData = ClientServerFile.mapServer({
        clientId,
        docsUINameById,
        instance: fileData,
        packageId,
        signingsData: doMapSigningsForDocument(fileData),
      });

      result.set(
        mappedFileData as DeepMutable<typeof mappedFileData>,
        fileData
      );
    });

    return result;
  }

  private readonly _files: IFileInstance[];

  constructor(private _data: PackageDocsAdapterInput) {
    this._files = DocumentValidator.getSortedFilesList(
      ClientServerFileCreator.getClientServerFilesFromServerData(this._data),
      []
    );
  }

  get docsWithClientOrServerFile(): IPackageDocsAdapter['docsWithClientOrServerFile'] {
    return this.docs.filter((x) => x.hasClientFile || x.hasServerFile);
  }

  get docs(): IPackageDocsAdapter['docs'] {
    return this._files;
  }

  onAddFiles: IPackageDocsAdapter['onAddFiles'] = (files) => {
    this._files.push(...files);
  };
}
