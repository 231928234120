import Accordion from '@signplatform-ui/accordion';
import Button from '@signplatform-ui/button';
import Card from '@signplatform-ui/card';
import { ChevronDownOutline } from '@signplatform-ui/icons';
import Link from '@signplatform-ui/link';
import Text from '@signplatform-ui/text';
import { typography } from '@signplatform-ui/typographics';
import { Badge } from '@vtb/ui-kit3';
import Participants from 'modules/Documents/components/Participants';
import { media } from 'modules/StyledUtility';
import styled, { css } from 'styled-components';
import ChangeStatusDate from './components/ChangeStatusDate';

// Пеореопредление библиотечных стилей https://www.npmjs.com/package/react-collapsible
export const AccordionStyled = styled(Accordion)`
  .Collapsible {
    padding: 0;
  }

  .Collapsible__contentInner {
    padding: 16px 0 0 0;
    display: flex;

    ${media.mobile(css`
      flex-direction: column;
    `)}
  }
`;

export const ContentWrap = styled.div`
  padding: 0 24px 24px 24px;
`;

export const CardTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 24px 0 24px;
`;

export const CardTitleRow = styled.div`
  display: flex;
`;

export const CardActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const StyledCard = styled(Card)`
  position: relative;
  padding: 0;

  & + & {
    margin-top: 1rem;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;

  ${media.mobile(css`
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

export const StatusLeftGroup = styled.div`
  display: flex;
  align-items: center;

  & > div + div {
    margin-left: 16px;
  }

  ${media.mobile(css`
    flex-direction: column;
    align-items: flex-start;

    & > div + div {
      margin-left: 0;
      margin-top: 12px;
    }
  `)}
`;

export const StyledParticipants = styled(Participants)`
  margin-top: 28px;
`;

export const StyledLink = styled(Link)`
  display: block;
  overflow: hidden;
  margin-top: 3px;
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const AccordionTriggerButton = styled(Button)`
  position: relative;
  margin: 0 6px 0 12px;
`;

export const ChevronDownOutlineStyled = styled(ChevronDownOutline)<{
  $up: boolean;
}>`
  position: absolute;
  transition: 200ms ease-out transform;
  transform: ${({ $up }) => $up && 'rotateZ(-180deg)'};
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const ListItem = styled(Text)`
  display: flex;
  align-items: center;
  word-break: break-word;
  overflow-wrap: break-word;

  ${media.mobile(css`
     {
      margin-top: 8px;
    }
  `)}
`;

export const ListItemSuffix = styled.span`
  ${typography['Caption/XS']}
  color: ${({ theme }) => theme.color.text.secondary};
  margin-left: 8px;
`;

export const BadgeStyled = styled(Badge)`
  margin-left: 8px;
`;

export const StyledChangeStatusDate = styled(ChangeStatusDate)`
  ${media.mobile(css`
    margin-top: 12px;
  `)}
`;

export const StyledPackageDates = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${media.mobile(css`
    width: 100%;
    align-items: flex-start;
  `)}
`;

export const StyledTimer = styled.div`
  display: flex;
`;

export const StyledTooltipContent = styled.div`
  word-break: break-word;
  overflow-wrap: break-word;
`;
