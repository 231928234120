import { useTheme } from 'styled-components';
import { ButtonColorsEnum, ButtonSizesEnum } from '@signplatform-ui/button';
import { DownloadOutline, MoreHorizontalOutline } from '@signplatform-ui/icons';
import { MenuPositionsEnum } from '@signplatform-ui/dropdownbutton';
import { StyledDropdownButton } from 'modules/Shared/components/SharedStyled';
import type { MenuItemProp } from '@signplatform-ui/menu';
import type { DownloadPackageSingleDocInnerProps } from './types';
import { ButtonStyled } from './DownloadPackageSingleDoc.styled';

export default function DownloadPackageSingleDocVC({
  isDownloadDisabled,
  onDownload,
  onDownloadWithSignature,
  hasDownloadWithSignature,
  isDownloadWithSignatureDisabled,
}: DownloadPackageSingleDocInnerProps): JSX.Element {
  const { color } = useTheme();

  if (hasDownloadWithSignature) {
    const menuItems: MenuItemProp[] = [
      {
        id: 'download-doc',
        'data-testid': 'download-doc',
        title: 'Скачать оригинал',
        onClick(e) {
          if (isDownloadDisabled) return;
          e.preventDefault();
          onDownload();
        },
      },
      {
        id: 'download-doc-with-es',
        'data-testid': 'download-doc-with-es',
        title: 'Скачать со штампами ЭП',
        onClick(e) {
          if (isDownloadWithSignatureDisabled) return;
          e.preventDefault();
          onDownloadWithSignature();
        },
      },
    ];

    return (
      <StyledDropdownButton
        color={ButtonColorsEnum.Ghost}
        data-testid='package-card-document-multibutton'
        displayAsSquare
        items={menuItems}
        position={MenuPositionsEnum.Right}
        size={ButtonSizesEnum.Medium}
      >
        <MoreHorizontalOutline />
      </StyledDropdownButton>
    );
  }

  return (
    <ButtonStyled
      color={ButtonColorsEnum.Ghost}
      size={ButtonSizesEnum.Medium}
      displayAsSquare
      disabled={isDownloadDisabled}
      onClick={onDownload}
      style={{ color: color.text.secondary }}
    >
      <DownloadOutline />
    </ButtonStyled>
  );
}
