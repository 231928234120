import AppStarter from 'modules/App/components/AppStarter';
import { ScrollToTop } from 'modules/Layouts/DefaultLayout/components';
import AppRoutesHistoryProvider from 'modules/Shared/components/AppRoutesHistoryProvider';
import { PageLoading } from 'modules/UI';
import { memo, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import type { ComponentType } from 'react';
import type { AppRoute } from './types';
import PrivateRoute from './components/PrivateRoute';
import appRoutes from './routes';
import { RouteTypeEnum } from './types';

const ComponentByRouteType = {
  [RouteTypeEnum.Public]: Route,
  [RouteTypeEnum.Private]: PrivateRoute,
};

const getRouteComponent = ({
  type,
  id,
  ...routeProps
}: AppRoute): JSX.Element => {
  const RouteComponent: ComponentType = type
    ? ComponentByRouteType[type]
    : Route;

  return <RouteComponent key={id} {...routeProps} />;
};

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <AppStarter>
        <AppRoutesHistoryProvider>
          <Suspense fallback={<PageLoading />}>
            <Switch>{appRoutes.map(getRouteComponent)}</Switch>
          </Suspense>

          <ScrollToTop />
        </AppRoutesHistoryProvider>
      </AppStarter>
    </BrowserRouter>
  );
};

export default memo(Router);
