import { useState } from 'react';
import type { Dispatch } from 'react';
import type { AppConfig } from '../types';

export const appConfig: AppConfig = {
  authContextUrl: process.env.REACT_APP_AUTH_CTX || '',
  userContextUrl: process.env.REACT_APP_USER_CTX || '',
  documentsContextUrl: process.env.REACT_APP_DOCUMENTS_CTX || '',
  loadDocumentsContextUrl: process.env.REACT_APP_LOAD_DOCUMENTS_CTX || '',
  signGateContextUrl: process.env.REACT_APP_SIGN_GATE_CTX || '',
  counterpartiesContextUrl: process.env.REACT_APP_COUNTERPARTY_CTX || '',
  esiaAuthUrl: process.env.REACT_APP_ESIA_AUTH_URL || '',
  attorneyContextUrl: process.env.REACT_APP_CRUDL_DOCUMENT || '',
  genDocContextUrl: process.env.REACT_APP_GENDOC_CTX || '',
  attorneyLoadDocumentContextUrl: process.env.REACT_APP_LOAD_DOCUMENT_CTX || '',
  authClientId: process.env.REACT_APP_AUTH_CLIENT_ID || '',
  authPrefix: process.env.REACT_APP_AVANPOST_PREFIX || '',
  referencesContextUrl: process.env.REACT_APP_REFERENCES_CTX || '',
  logicReferencesContextUrl: process.env.REACT_APP_LOGIC_REFERENCES_CTX || '',
  excGateContextUrl: process.env.REACT_APP_EXC_GATE_CTX || '',
  checkSignContextUrl: process.env.REACT_APP_CHECK_SIGN_CTX || '',
};

export default function useConfiguration(): [AppConfig, Dispatch<AppConfig>] {
  const [config, setConfig] = useState<AppConfig>(appConfig);

  return [config, setConfig];
}
