import { makeEnumerablePrototype } from 'modules/Common/services';
import type {
  InnerCounterpartySigningData,
  CounterpartyClientOrg,
  CounterpartyOrgBase,
  SignerSignStatusUni,
} from 'modules/Documents/types';

import SignMixin from './SignMixin';

type ISigningAdapterOrgInner = CounterpartyClientOrg &
  PartialToUndefined<CounterpartyOrgBase>;

@makeEnumerablePrototype
export default class SigningAdapterOrgInner
  extends SignMixin<SignerSignStatusUni>
  implements ISigningAdapterOrgInner
{
  readonly inviteId: ISigningAdapterOrgInner['inviteId'];
  readonly email: ISigningAdapterOrgInner['email'];
  readonly organizationKio: ISigningAdapterOrgInner['organizationKio'];

  readonly __feOrg;
  readonly __source;
  readonly name;
  readonly ogrn;
  readonly organizationInn;
  readonly organizationKpp;
  readonly orgnip;
  readonly type;

  readonly clientId;

  constructor(private _data: InnerCounterpartySigningData) {
    super(_data);

    const { innerCounterpartyData } = _data;

    this.__source = _data;

    this.__feOrg = true;
    this.name = innerCounterpartyData.name;
    this.ogrn = innerCounterpartyData.ogrn;
    this.organizationInn = innerCounterpartyData.organizationInn;
    this.organizationKpp = innerCounterpartyData.organizationKpp;
    this.orgnip = innerCounterpartyData.ogrnIp;
    this.type = innerCounterpartyData.type;

    this.clientId = innerCounterpartyData.clientId;
  }
}
