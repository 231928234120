import styled from 'styled-components';
import Text from '@signplatform-ui/text';
import { typography } from '@signplatform-ui/typographics';

export const Title = styled.div`
  ${typography['Main/S']}
`;

export const Placeholder = styled(Text)`
  margin-bottom: 24px;
`;

export const Content = styled.div`
  display: inline-block;
`;
