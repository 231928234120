import { useMemo, useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import {
  PackageDocsContext,
  usePackageContext,
} from 'modules/Documents/contexts';
import type { ComponentType, FC } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import type { IPackageDocsContext } from 'modules/Documents/contexts';
import type { DocsFormValues, PackageWizardFormValues } from '../ownTypes';
import useNotLoadedDocIds from '../hooks/useNotLoadedDocIds';
import usePackageWizardForm from '../hooks/usePackageWizardForm';
import useDocsErrorMap from '../hooks/useDocsErrorMap';
import useDocsStatusPolling from '../hooks/useDocsStatusPolling';
import useAppPayloadFiles from '../hooks/useAppPayloadFiles';
import useSettledPromise from '../hooks/useSettledPromise';
import useDocsUINameWithForm from '../hooks/useDocsUINameWithForm';

type SafeFormAssertion = DocsFormValues extends PackageWizardFormValues
  ? UseFormReturn<DocsFormValues>
  : never;

const packageWizardFormHOC = <T,>(Component: ComponentType<T>): FC<T> => {
  return (props: T) => {
    const packageCardContext = usePackageContext();
    const { packageAdapted } = packageCardContext;
    const { packageId } = packageAdapted;

    const {
      isSettled: isFilesPromiseSettled,
      promise: filesUploadPromise,
      handleSetPromise: setFilesUploadPromise,
    } = useSettledPromise();

    const { notLoadedDocIds, onSetNotLoadedDocIds } = useNotLoadedDocIds({
      packageId,
    });

    const { formData } = usePackageWizardForm({ packageAdapted });
    const { setValue, getValues } = formData;

    const {
      docsUIName,
      onGetDocName: handleGetDocName,
      onSetDocName: handleSetDocName,
    } = useDocsUINameWithForm({
      formData: formData as SafeFormAssertion,
    });

    const { docsErrorMap } = useDocsErrorMap({
      clientDocs: getValues('docs'),
      docsUIName,
      notLoadedDocIds,
    });

    const handleGetDocs = useCallback(() => {
      return getValues('docs');
    }, [getValues]);

    const { filesPromise } = useAppPayloadFiles({
      onGetDocs: handleGetDocs,
      packageAdapted,
      setClientDocs: (value) => {
        setValue('docs', value, { shouldDirty: true });
      },
    });

    if (filesPromise) setFilesUploadPromise(filesPromise);

    useDocsStatusPolling({
      notLoadedDocIds,
      onGetDocs: handleGetDocs,
      packageAdapted,
      onSetNotLoadedDocIds,
      async onBeforeStartPolling() {
        try {
          await filesUploadPromise;
          // eslint-disable-next-line no-empty
        } catch (e) {}
      },
    });

    const docsContextData = useMemo((): IPackageDocsContext => {
      return {
        docsErrorMap,
        docsUINameById: docsUIName,
        isDisabled: !isFilesPromiseSettled,
        notLoadedDocIds,
        getDocName: handleGetDocName,
        setDocName: handleSetDocName,
      };
    }, [
      docsErrorMap,
      docsUIName,
      handleGetDocName,
      handleSetDocName,
      isFilesPromiseSettled,
      notLoadedDocIds,
    ]);

    return (
      <FormProvider {...formData}>
        <PackageDocsContext.Provider value={docsContextData}>
          <Component {...props} />
        </PackageDocsContext.Provider>
      </FormProvider>
    );
  };
};

export default packageWizardFormHOC;
