import styled, { css } from 'styled-components';
import Card from '@signplatform-ui/card';
import type { CardProps } from '@signplatform-ui/card';

const hasErrorClassName = css`
  outline: 1px solid ${({ theme }) => theme.color.status.danger};
  outline-offset: -1px;
`;

// eslint-disable-next-line import/prefer-default-export
export const StyledCard = styled(Card)<CardProps & { hasError?: boolean }>`
  margin: 8px 0;
  ${({ hasError }) => hasError && hasErrorClassName}
`;

export const StyledContainer = styled.div<{ $isDisabled?: boolean }>`
  ${({ $isDisabled }) => ($isDisabled ? 'opacity: 0.75' : undefined)};
`;
