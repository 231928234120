import { makeEnumerablePrototype } from 'modules/Common/services';
import type {
  OuterPersonSigningData,
  CounterpartyPerson,
  CounterpartyPersonBase,
  InviteStatusEnumUni,
} from 'modules/Documents/types';
import SignMixin from './SignMixin';

type ISigningAdapterPersonOuter = CounterpartyPerson &
  PartialToUndefined<CounterpartyPersonBase>;

@makeEnumerablePrototype
export default class SigningAdapterPersonOuter
  extends SignMixin<InviteStatusEnumUni>
  implements ISigningAdapterPersonOuter
{
  readonly firstName: ISigningAdapterPersonOuter['firstName'];
  readonly inn: ISigningAdapterPersonOuter['inn'];
  readonly lastName: ISigningAdapterPersonOuter['lastName'];
  readonly middleName: ISigningAdapterPersonOuter['middleName'];
  readonly phoneNumber: ISigningAdapterPersonOuter['phoneNumber'];

  readonly __fePerson;
  readonly __source;
  readonly email;
  readonly idNum;
  readonly inviteId;
  readonly snils;
  readonly participantId;

  constructor(private _data: OuterPersonSigningData) {
    super(_data);

    const { outerPersonsData, participantId } = _data;

    this.__source = _data;

    this.__fePerson = true;
    this.email = outerPersonsData.email;
    this.idNum = outerPersonsData.fullDocNumber;
    this.inviteId = _data.inviteId;
    this.snils = outerPersonsData.snils;
    this.participantId = participantId;
  }
}
