import { typography } from '@signplatform-ui/typographics';
import styled from 'styled-components';

const StyledErrorMessageContent = styled.div`
  ${typography['Additional/XS']}
  color: ${({ theme }) => theme.color.status.danger};
  margin: 8px 0;
`;

export default StyledErrorMessageContent;
