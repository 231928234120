import { ButtonSizesEnum, ButtonColorsEnum } from '@signplatform-ui/button';
import { HeaderSizesEnum } from '@signplatform-ui/header';
import {
  PublicLayoutWithLogo,
  StyledHeader,
  StyledTextInfo,
} from 'modules/Layouts/PublicLayout';
import { useConfiguration, useStorage } from 'modules/Shared/hooks';
import Footer, { FooterColorEnum } from 'modules/UI/components/Footer';
import { memo, useCallback } from 'react';
import type { ReactNode, MouseEventHandler } from 'react';
import goToAuth from './goToAuth';
import { ButtonLogin, LinkStyled } from './Login.styled';

type LoginContentProps = {
  onAuthClick: MouseEventHandler<HTMLButtonElement>;
};

const LoginContent = memo(({ onAuthClick }: LoginContentProps): JSX.Element => {
  return (
    <div>
      <StyledHeader type={HeaderSizesEnum.H1}>
        Платформа
        <br />
        подписания
      </StyledHeader>

      <ButtonLogin
        color={ButtonColorsEnum.White}
        onClick={onAuthClick}
        size={ButtonSizesEnum.Large}
      >
        Войти через Госуслуги
      </ButtonLogin>

      <StyledTextInfo>
        Чтобы войти, пожалуйста, убедитесь, что ваша учетная запись подтверждена
        на портале Госуслуг
      </StyledTextInfo>

      <LinkStyled href='https://esia.gosuslugi.ru/registration/'>
        Нет подтвержденной учетной записи Госуслуг
      </LinkStyled>
    </div>
  );
});

const Login = ({ children }: { children?: ReactNode }): JSX.Element => {
  const [{ authClientId, authPrefix }] = useConfiguration();

  const { storageWriteData } = useStorage(false);

  const handleWriteStorage = useCallback(() => {
    storageWriteData({
      loggedInRedirect: window.location.pathname,
    });
  }, [storageWriteData]);

  const handleGoToAuth: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      handleWriteStorage();
      goToAuth(e, authClientId, authPrefix);
    },
    [authClientId, authPrefix, handleWriteStorage]
  );

  const content = <LoginContent onAuthClick={handleGoToAuth} />;
  const footer = <Footer color={FooterColorEnum.secondary} />;

  return (
    <PublicLayoutWithLogo slotContent={content} footer={footer}>
      {children}
    </PublicLayoutWithLogo>
  );
};

export default Login;
