import styled, { css } from 'styled-components';
import { FontSizesEnum, LineHeightsEnum } from '@signplatform-ui/typographics';
import { media } from 'modules/StyledUtility';

export const StyledTileHeader = styled.div`
  color: ${({ theme }) => theme.color.text.secondary};
  font-size: ${FontSizesEnum.AdditionalL};
  line-height: ${LineHeightsEnum.AdditionalL};
`;

export const StyledTileDescription = styled.div`
  color: ${({ theme }) => theme.color.text.secondary};
`;

export const StyledImageWrapper = styled.div`
  margin-bottom: 16px;

  img {
    height: 48px;
    width: auto;
  }
`;

export const StyledTile = styled.div<{ isActive?: boolean }>`
  background: ${({ theme }) => theme.color.background.secondary};
  border-radius: 8px;
  cursor: pointer;
  flex: 1 1 auto;
  height: 176px;
  max-width: 400px;
  min-height: 176px;
  min-width: 192px;
  overflow-wrap: break-word;
  overflow: hidden;
  padding: 20px 20px 10px 20px;
  width: calc(30% - 16px);

  ${media.tablet(css`
    height: 152px;
  `)}

  ${media.mobile(css`
    min-width: 242px;
  `)}

  &:not(:first-of-type) {
    margin-left: 16px;
  }

  ${({ isActive }) => isActive && 'width: 50%;'}
  ${({ isActive }) => isActive && 'flex: 0 0 auto;'}

  ${StyledTileHeader} {
    font-size: ${FontSizesEnum.H6};
    line-height: ${LineHeightsEnum.H6};
    color: ${({ theme }) => theme.color.text.primary};
    margin-bottom: 16px;

    ${media.tablet(css`
      font-size: 16px;
      line-height: 1.25;
      margin-bottom: 12px;
    `)}
  }

  ${StyledTileDescription} {
    ${media.tablet(css`
      font-size: 14px;
      line-height: 20px;
    `)}
  }

  ${StyledImageWrapper} {
    ${({ isActive }) => isActive && 'display: none;'}
  }
`;
