enum PowAttorneyEnum {
  List = 'powAttorneyList',
  Draft = 'powAttorneyDraft',
  GetPreview = 'getPreview',
  GetPdf = 'powAttorneyGetPdf',
  Organizations = 'Organizations',
  DulType = 'DulType',
  AttorneyXml = 'powAttorneyXML',
  AttorneyPermissions = 'attorneyPermissions',
  UserOrganizations = 'userOrganizations',
}

export const DYL_TYPES = Object.freeze({
  PASSPORT_FR: '21',
  FOREIGN_CITIZEN_DOC: '10',
  MILITARY_ID: '07',
  PETITIONS_REFUGEE_RF: '11',
  RESIDENCE_PERMIT_RF: '12',
  REFUGEE_CERTIFICATE: '13',
  TEMP_RESIDENCE_PERMIT_RF: '15',
  TEMP_ASYLUM_CERTIFICATE_RF: '18',
  IDENTIFICATION_SOLDIER: '24',
});

export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_FORMAT_BE = 'yyyy-MM-dd';

export default PowAttorneyEnum;
