import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import { useModal } from 'modules/Common/hooks';
import PackageSigningErrorModal from 'modules/Documents/components/PackageSigningErrorModal';
import { useSignPackage } from 'modules/Documents/hooks';
import notifySigningSuccess from 'modules/Documents/utils/notifySigningSuccess';
import { TypeSignEnum } from '@sp-api/documents-api';
import { EmptyDocsModal } from './components';
import EmptyDocsModalQes from './components/EmptyDocsModal/EmptyDocsModalQES';

type GoskeyEmptyDocsNotificationProps = {
  ownerClientId: EntityId;
  packageId: EntityId;
  invalidateQuery: () => Promise<void>;
  signType?: TypeSignEnum;
};

const GoskeyEmptyDocsNotification = ({
  ownerClientId,
  packageId,
  invalidateQuery,
  signType,
}: GoskeyEmptyDocsNotificationProps): JSX.Element => {
  const { onClose, onOpen, isOpen } = useModal();

  const { isLoading, isError, reset, mutateAsync, error } = useSignPackage();

  const onCloseModal = () => {
    reset();
    onClose();
  };

  const onSignPackage = async () => {
    await mutateAsync({
      packageId,
      clientIdOwner: ownerClientId,
    });
    await invalidateQuery();
    onCloseModal();
    notifySigningSuccess();
  };

  if (isError) {
    return (
      <PackageSigningErrorModal
        onClose={onCloseModal}
        displayCode={error?.data?.displayCode}
      />
    );
  }

  return (
    <>
      <Button
        size={ButtonSizesEnum.Small}
        color={ButtonColorsEnum.Ghost}
        onClick={onOpen}
      >
        Я не получил документы в Госключе
      </Button>

      {isOpen &&
        (signType !== TypeSignEnum.Qes ? (
          <EmptyDocsModal
            onClose={onCloseModal}
            isLoading={isLoading}
            onSignPackage={onSignPackage}
          />
        ) : (
          <EmptyDocsModalQes
            onClose={onCloseModal}
            isLoading={isLoading}
            onSignPackage={onSignPackage}
          />
        ))}
    </>
  );
};

export default GoskeyEmptyDocsNotification;
