import { useLoadDocumentApiClient } from 'modules/Api/hooks';
import { useMutation } from 'react-query';
import type { UseMutationResult } from 'react-query';
import type { ErrorResponse } from 'modules/App/types';
import type {
  PackageFileRequest,
  PackageFileResponse,
} from '@sp-api/load-document-api';

export default function useUploadDocument(): UseMutationResult<
  PackageFileResponse,
  ErrorResponse,
  PackageFileRequest
> {
  const loadDocumentApi = useLoadDocumentApiClient();

  return useMutation<PackageFileResponse, ErrorResponse, PackageFileRequest>(
    async (params) => {
      const { data } = await loadDocumentApi.uploadPackageFiles(params);
      return data;
    }
  );
}
