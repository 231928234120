import InputField from '@signplatform-ui/input-field';
import { memo, useEffect } from 'react';
import { useIMask } from 'react-imask';
import { useFormContext } from 'react-hook-form';
import type { IMask } from 'react-imask';
import type {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  Ref,
  InputHTMLAttributes,
} from 'react';

export type MaskedInputProps = InputHTMLAttributes<HTMLInputElement> & {
  name?: string;
  label?: string;
  placeholder?: string;
  status?: 'error' | 'success';
  extraText?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  maskOptions: IMask.AnyMaskedOptions;
  value?: string;
  unmasked?: boolean;
  displayClearIcon?: boolean;
};

const MaskedInput = ({
  maskOptions,
  name,
  label,
  placeholder,
  status,
  extraText,
  onChange,
  className,
  value = '',
  unmasked,
  ...rest
}: MaskedInputProps): JSX.Element => {
  const { setValue } = useFormContext();

  const { ref, maskRef } = useIMask(maskOptions, {
    onAccept: (maskedValue, { unmaskedValue }) => {
      onChange({
        target: {
          value: unmasked ? String(unmaskedValue) : String(maskedValue),
        },
      } as ChangeEvent<HTMLInputElement>);
    },
  });

  useEffect(() => {
    if (maskRef.current) {
      if (unmasked) {
        maskRef.current.unmaskedValue = String(value);
      } else {
        ref.current.value = String(value);
        maskRef.current.updateValue();
        setValue(String(name), value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <InputField
      label={label}
      placeholder={placeholder}
      status={status}
      extraText={extraText}
      name={name}
      ref={ref as Ref<HTMLInputElement>}
      className={className}
      data-testid={`${name}-input`}
      {...rest}
    />
  );
};

export default memo(MaskedInput);
