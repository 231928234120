import type { RouteProps } from 'react-router-dom';

export enum RouteTypeEnum {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export type AppRoute = RouteProps & {
  id: string;
  type: RouteTypeEnum;
};
