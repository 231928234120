import { useMemo, useRef, useState, useCallback } from 'react';
import type { PropsWithChildren } from 'react';
import type { PackageCreateLocalStateFiles } from './types';
import type { IAppPayloadContext } from './AppPayloadContext';
import AppPayloadContext from './AppPayloadContext';

const AppPayloadProvider = ({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element => {
  const [updated, setUpdated] = useState(Date.now());

  const errorFilesDataPackageCreate = useRef<
    IAppPayloadContext['packageDetailsFiles']['value']
  >(new Map());

  const handleSetErrorFiles = useCallback(
    (packageId: EntityId, data: PackageCreateLocalStateFiles | null) => {
      if (!data) errorFilesDataPackageCreate.current.delete(packageId);
      else errorFilesDataPackageCreate.current.set(packageId, data);

      setUpdated(Date.now());
    },
    []
  );

  const contextValue = useMemo((): IAppPayloadContext => {
    return {
      packageDetailsFiles: {
        value: errorFilesDataPackageCreate.current,
        onChange: handleSetErrorFiles,
      },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated]);

  return (
    <AppPayloadContext.Provider value={contextValue}>
      {children}
    </AppPayloadContext.Provider>
  );
};

export default AppPayloadProvider;
