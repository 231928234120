import type { AppRoute } from '../types';
import commonRoutes from './common';
import documentsRoutes from './documents';
import notFoundRoute from './notFound';
import profileRoutes from './profile';
import powAttorneyRoutes from './powersAttorney';
import agreementsRoutes from './agreements';

const appRoutes: AppRoute[] = [
  ...commonRoutes,
  ...profileRoutes,
  ...documentsRoutes,
  ...powAttorneyRoutes,
  ...agreementsRoutes,
  notFoundRoute,
];

export default appRoutes;
