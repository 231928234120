import { ClientServerFile } from 'modules/UI/components/DocumentsPackEditor';
import { useMutation, useQueryClient } from 'react-query';
import type { UseMutationResult } from 'react-query';
import type { PackageFullData } from '@sp-api/documents-api';
import type { FileRequest } from '@sp-api/load-document-api';
import type { ErrorResponse } from 'modules/App/types';
import type { IFileInstance } from 'modules/UI/types';
import type { MinimalGetFilesContentData } from './useUploadFilesByPackageData';
import useUploadFilesByPackageData, {
  getPreparedFilesToUploadByMetadata,
} from './useUploadFilesByPackageData';
import useUpdatePackageMeta from './useUpdatePackageMeta';
import { getPackageCardQueryKey } from './usePackageCard';

type GetFilesDataForRequest = MinimalGetFilesContentData & {
  filesDict: { [fileUUID: string]: IFileInstance };
};

function getFilesDataForRequest({
  filesDict,
  packageData,
}: GetFilesDataForRequest): Promise<FileRequest[]> {
  return getPreparedFilesToUploadByMetadata(packageData, (docData) => {
    if (!docData.fileUUID) return 'dontUpload';

    const foundClientFile = filesDict[docData.fileUUID];

    if (!foundClientFile) return 'notFound';
    if (foundClientFile.hasServerFile) return 'dontUpload';

    return {
      content: ClientServerFile.getContent(foundClientFile),
      extensionWithDot: foundClientFile.fileExtWithDot,
    };
  });
}

type PackageUpdateParams = {
  filesDict: { [fileClientServerId: string]: IFileInstance };
  packageFullData: PackageFullData;
  packageId: EntityId;
};

export default function useUpdatePackage(): UseMutationResult<
  PackageFullData,
  ErrorResponse,
  PackageUpdateParams
> {
  const queryClient = useQueryClient();

  const { mutateAsync: updatePackageMeta } = useUpdatePackageMeta();
  const { mutateAsync: uploadFilesByMetadata } = useUploadFilesByPackageData(
    getFilesDataForRequest
  );

  return useMutation<PackageFullData, ErrorResponse, PackageUpdateParams>(
    async ({ packageId, packageFullData, filesDict }) => {
      const packageData = await updatePackageMeta({
        packageId,
        packageFullData,
      });

      queryClient.setQueryData(
        getPackageCardQueryKey({
          packageId,
          ownerClientId: packageFullData.clientIdOwner,
        }),
        packageData
      );

      await uploadFilesByMetadata({
        filesDict,
        packageData,
      });

      return packageData;
    }
  );
}
