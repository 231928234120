import { media } from 'modules/StyledUtility';
import styled, { css } from 'styled-components';

export const WrapDocuments = styled.div`
  ${media.mobile(css`
    margin-top: 14px;
  `)}
`;

export const StyledTooltipContent = styled.div`
  word-break: break-word;
  overflow-wrap: break-word;
`;
