import { useFormContext } from 'react-hook-form';
import type { FieldError } from 'react-hook-form';
import type { PackageWizardFormValues } from 'modules/Documents/components/PackageWizard/ownTypes';
import type { DateTimeObj } from 'modules/Documents/components/PackageSigningDueDate/types';

type UseSignDueDateReturnType = {
  signDueDate: DateTimeObj;
  onSetDueDate: (dueDate: DateTimeObj) => void;
  errorMessage: string | undefined;
};

export default function useSignDueDate(): UseSignDueDateReturnType {
  const {
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<PackageWizardFormValues>();

  const onSetDueDate = (dueDate: DateTimeObj) => {
    setValue('signDueDate', dueDate, {
      shouldDirty: true,
      shouldValidate: !!errors.signDueDate,
    });
  };

  const signDueDate = watch('signDueDate');

  return {
    signDueDate,
    onSetDueDate,
    errorMessage: (errors.signDueDate as FieldError)?.message,
  };
}
