import { createContext } from 'react';

export type TokenModel = {
  accessToken: string;
  refreshToken: string;
} | null;

export type TokenContextProps = {
  token: TokenModel;
  setToken: (token: TokenModel) => void;
};

const TokenContext = createContext<TokenContextProps>({
  token: null,
} as TokenContextProps);

export default TokenContext;
