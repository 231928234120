import type { PersonNameData } from 'modules/Documents/utils/types';
import delExtraSpaces from './delExtraSpaces';
import formatNamePart from './formatNamePart';

export default function getPersonFio(data: PersonNameData): string {
  const firstName = formatNamePart(data.firstName);
  const lastName = formatNamePart(data.lastName);
  const middleName = formatNamePart(data.middleName);

  return delExtraSpaces(`${lastName} ${firstName} ${middleName}`);
}
