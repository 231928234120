import {
  DocsOutline,
  DocSignOutline,
  PlusCircleSolid,
} from '@signplatform-ui/icons';
import AppRoutesPaths from 'modules/Router/routes/paths';
import { CreatePackage } from 'modules/Documents';
import type { NavigationItem } from 'modules/UI/components/SideNav/types/NavigationItem';

let items: NavigationItem[] = [];
if (process.env.REACT_APP_ADD_NEW_DOC === 'true') {
  items = [
    {
      id: 'package:new',
      title: 'Новый документ',
      icon: PlusCircleSolid,
      component: CreatePackage,
    },
  ];
}
items = items.concat([
  {
    id: 'package:list',
    title: 'Все документы',
    to: AppRoutesPaths.DocumentsList,
    icon: DocsOutline,
  },
  {
    id: 'document:powers-attorney:list',
    title: 'Доверенности',
    to: AppRoutesPaths.PowAttorneyList,
    icon: DocSignOutline,
  },
]);

export default items;
