import { yupResolver } from '@hookform/resolvers/yup';
import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import Text, { TextTypesEnum } from '@signplatform-ui/text';
import TextAreaField from '@signplatform-ui/textarea-field';
import useRejectSignPackage from 'modules/Documents/hooks/useRejectSignPackage';
import Modal, {
  ModalButtonPanel,
  ModalContent,
  ModalTitle,
} from 'modules/UI/components/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

type RejectSigningFormData = {
  comment: string;
};

type RejectSigningModalProps = {
  onClose: () => void;
  ownerClientId: EntityId;
  packageId: EntityId;
  signerClientId: EntityId;
  onSuccess?: () => Promise<void>;
};

const RejectSigningModal = ({
  onClose,
  ownerClientId,
  packageId,
  signerClientId,
  onSuccess,
}: RejectSigningModalProps): JSX.Element => {
  const { mutateAsync, isLoading } = useRejectSignPackage();

  const formData = useForm<RejectSigningFormData>({
    defaultValues: {
      comment: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        comment: yup.string(),
      })
    ),
  });

  const { getValues, register } = formData;

  const handleSubmit = async (): Promise<void> => {
    await mutateAsync({
      ownerClientId,
      packageId,
      signerClientId,
      comment: getValues().comment,
    });

    await onSuccess?.();

    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <FormProvider {...formData}>
        <ModalTitle data-testid='reject-signing-modal-title'>
          Отклонение комплекта документов
        </ModalTitle>

        <>
          <ModalContent>
            <Text
              type={TextTypesEnum.L}
              style={{ marginBottom: 16 }}
              data-testid='reject-signing-modal-caption'
            >
              Вы уверены, что хотите отклонить комплект документов? Процесс
              подписания будет прекращен
            </Text>

            <TextAreaField
              {...register('comment')}
              disabled={isLoading}
              label='Вы можете указать причину отклонения:'
              placeholder='Введите текст'
              data-testid='reject-signing-modal-input'
              id='reject-signing-comment-input'
            />
          </ModalContent>

          <ModalButtonPanel>
            <Button
              color={ButtonColorsEnum.Primary}
              size={ButtonSizesEnum.Medium}
              type='submit'
              onClick={handleSubmit}
              disabled={isLoading}
              data-testid='reject-signing-modal-confirm-button'
            >
              Подтвердить
            </Button>

            <Button
              color={ButtonColorsEnum.Secondary}
              size={ButtonSizesEnum.Medium}
              onClick={onClose}
              data-testid='reject-signing-modal-cancel-button'
            >
              Отмена
            </Button>
          </ModalButtonPanel>
        </>
      </FormProvider>
    </Modal>
  );
};

export default RejectSigningModal;
