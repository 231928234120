export const ALLOWED_FILES_EXTENSIONS = [
  '.pdf',
  '.tif',
  '.tiff',
  '.txt',
  '.xml',
];

export const mimeToExtWithDot: { readonly [key: string]: string } = {
  'application/pdf': '.pdf',
  'image/tiff': '.tiff',
  'application/xml': '.xml',
  'text/xml': '.xml',
  'text/plain': '.txt',
};

export const UPLOAD_ACCEPT = ALLOWED_FILES_EXTENSIONS.join(',');

export const MAX_FILE_SIZE_BYTES = 20 * (1024 * 1024);
export const MAX_PACK_SIZE_BYTES = 80 * (1024 * 1024);
