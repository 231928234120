export const MIN_LENGTH = 1;
export const MAX_LENGTH = 195;
export const DATE_TIME_FORMAT = 'dd.MM.yyyy, HH:mm';
export const TIME_FORMAT_WITH_SECS = 'HH:mm:ss';
export const DATE_FORMAT = 'dd.MM.yyyy';

export const FILE_NAME_COMMON_ERROR =
  'Наименование документа содержит некорректные символы';

export const enum DocumentRejectReason {
  // must be falsy
  FileIsOk = 0,
  // must de truthy
  FileIsEmpty = 1,
  FileIsTooLarge,
  FileNameIsEmpty,
  FileExtIsForbidden,
  FileNameHasForbiddenSymbols,
  FileNameIsForbidden,
  FileNameAlreadyUsed,
  PackageIsTooLarge,
  FileWasNotLoaded,
}
