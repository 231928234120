import { yupResolver } from '@hookform/resolvers/yup';
import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import Text, { TextTypesEnum } from '@signplatform-ui/text';
import TextAreaField from '@signplatform-ui/textarea-field';
import useRevokeSignPackage from 'modules/Documents/hooks/useRevokeSignPackage';
import Modal, {
  ModalButtonPanel,
  ModalContent,
  ModalTitle,
} from 'modules/UI/components/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { notificationsService } from 'modules/Notifications';
import { NotificationsTypesEnum } from '@signplatform-ui/notification';

const MAX_LENGTH = 500;

type RevokeSigningFormData = {
  comment: string;
};

type RevokeSigningModalProps = {
  onClose: () => void;
  onSuccess?: () => Promise<void>;
  packageId: EntityId;
};

const RevokeSigningModal = ({
  onClose,
  packageId,
  onSuccess,
}: RevokeSigningModalProps): JSX.Element => {
  const { mutateAsync, isLoading } = useRevokeSignPackage();

  const formData = useForm<RevokeSigningFormData>({
    defaultValues: {
      comment: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        comment: yup
          .string()
          .test(
            'maxLength',
            `Длина комментария должна быть не более ${MAX_LENGTH} символов`,
            (val = '') => val.length < MAX_LENGTH
          ),
      })
    ),
  });

  const {
    getValues,
    register,
    formState: {
      errors: { comment: error },
    },
    handleSubmit,
  } = formData;

  const onSubmit = async (): Promise<void> => {
    try {
      await mutateAsync({
        packageId,
        revokeReason: getValues().comment,
      });

      await onSuccess?.();
    } catch (e) {
      notificationsService.notify(
        'Ошибка при отзыве документов',
        'Невозможно отозвать комплект документов',
        NotificationsTypesEnum.Error
      );
    }
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <FormProvider {...formData}>
        <ModalTitle data-testid='revoke-signing-modal-title'>
          Отзыв комплекта документов
        </ModalTitle>

        <>
          <ModalContent>
            <Text
              type={TextTypesEnum.L}
              style={{ marginBottom: 16 }}
              data-testid='revoke-signing-modal-caption'
            >
              После отзыва комплект документов станет недоступен для других
              пользователей. Вы уверены, что хотите отозвать комплект
              документов?
            </Text>

            <TextAreaField
              {...register('comment')}
              disabled={isLoading}
              label='Вы можете указать причину отзыва:'
              placeholder='Введите текст'
              data-testid='revoke-signing-modal-input'
              id='revoke-signing-comment-input'
              status={error?.message ? 'error' : undefined}
              extraText={error?.message}
            />
          </ModalContent>

          <ModalButtonPanel>
            <Button
              color={ButtonColorsEnum.Primary}
              size={ButtonSizesEnum.Medium}
              type='submit'
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
              data-testid='revoke-signing-modal-confirm-button'
            >
              Подтвердить
            </Button>

            <Button
              color={ButtonColorsEnum.Secondary}
              size={ButtonSizesEnum.Medium}
              onClick={onClose}
              data-testid='revoke-signing-modal-cancel-button'
            >
              Отмена
            </Button>
          </ModalButtonPanel>
        </>
      </FormProvider>
    </Modal>
  );
};

export default RevokeSigningModal;
