import useSignPackage from 'modules/Documents/hooks/useSignPackage';
import PackageSigningErrorModal from 'modules/Documents/components/PackageSigningErrorModal';
import React from 'react';
// import CertificateNotFoundErrorModal from 'modules/Documents/components/ResignButton/components/CertificateNotFoundErrorModal';
// import NotGetCertErrorModal from 'modules/Documents/components/ResignButton/components/NotGetCertErrorModal';
import notifySigningSuccess from 'modules/Documents/utils/notifySigningSuccess';
import type { ButtonProps } from '@signplatform-ui/button';
import { StyledButton, StyledTooltipContent } from './ResignButton.styled';

type ResignButtonProps = ButtonProps & {
  onSignSuccess?: () => Promise<void> | void;
  ownerClientId: EntityId;
  packageId: EntityId;
  hasTooltip?: boolean;
  tooltip?: React.ReactNode;
};

const ResignButton = ({
  ownerClientId,
  packageId,
  onSignSuccess,
  tooltip,
  disabled,
  ...buttonProps
}: ResignButtonProps): JSX.Element => {
  const { isLoading, isError, reset, mutateAsync, error } = useSignPackage();

  const onSign = async () => {
    await mutateAsync({ packageId, clientIdOwner: ownerClientId });
    await onSignSuccess?.();
    notifySigningSuccess();
  };

  return (
    <>
      <StyledButton
        onClick={onSign}
        data-testid='document-resign-button'
        disabled={disabled || isLoading}
        {...buttonProps}
      >
        Подписать еще раз
        {tooltip && <StyledTooltipContent>{tooltip}</StyledTooltipContent>}
      </StyledButton>
      {isError && (
        <PackageSigningErrorModal
          onClose={reset}
          displayCode={error?.data?.displayCode}
        />
      )}
    </>
  );
};

export default ResignButton;
