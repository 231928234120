import { DateTime } from 'luxon';
import { DATE_FORMAT } from 'modules/Documents/constants';
import type { DateTimeObj } from 'modules/Documents/components/PackageSigningDueDate/types';

export default function transformDate(
  value: Date,
  originalValue: DateTime | DateTimeObj
): Date | null {
  if (!value) return null;

  if (DateTime.isDateTime(originalValue)) {
    return originalValue.toJSDate();
  }

  const [hour, minute, second] = originalValue.time.split(':');
  const dateTimeString = DateTime.fromFormat(
    originalValue.date,
    DATE_FORMAT
  ).set({
    hour: Number(hour),
    minute: Number(minute),
    second: Number(second),
  });

  if (dateTimeString.isValid) {
    return dateTimeString.toJSDate();
  }

  return null;
}
