import {
  Configuration,
  SpLogicCounterpartiesApi,
} from '@sp-api/counterparties-api';
import { apiService } from 'modules/Api/components/ApiProvider/services';
import { useConfiguration } from 'modules/Shared/hooks';

const useApi = apiService.createUseApi(SpLogicCounterpartiesApi, Configuration);

const useCounterpartiesApi = (): SpLogicCounterpartiesApi => {
  const [{ counterpartiesContextUrl }] = useConfiguration();
  return useApi(counterpartiesContextUrl);
};

export default useCounterpartiesApi;
