import type { PersonNameData } from 'modules/Documents/utils/types';
import formatNamePart from './formatNamePart';
import delExtraSpaces from './delExtraSpaces';

export default function getPersonShortName(data: PersonNameData): string {
  const firstName = formatNamePart(data.firstName);
  const lastName = formatNamePart(data.lastName);
  const middleName = formatNamePart(data.middleName);

  const endSymbol = data.lastName ? '.' : '';
  const dataStr = `${firstName} ${middleName} ${lastName[0] || ''}`;

  return delExtraSpaces(dataStr) + endSymbol;
}
