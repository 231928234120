import { useFormContext, Controller } from 'react-hook-form';
import type { InputHTMLAttributes } from 'react';
import type { FieldError } from 'react-hook-form';
import PhoneInput from './PhoneInput';

export type FormPhoneInputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: FieldError;
  name: string;
  label?: string;
  unmasked?: boolean;
};

function FormPhoneInput({
  error,
  name,
  ...restFieldProps
}: FormPhoneInputProps): JSX.Element {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange, value } }) => (
        <PhoneInput
          status={error && 'error'}
          extraText={error?.message}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          {...restFieldProps}
        />
      )}
    />
  );
}

export default FormPhoneInput;
