import Modal from '@signplatform-ui/modal';
import useMedia from 'modules/Shared/hooks/useMedia';
import type { ModalProps } from '@signplatform-ui/modal';
import type { PropsWithChildren } from 'react';

type ModalWrapperProps = PropsWithChildren<ModalProps>;

const modalContainer = document.getElementById('root') || undefined;

const ModalWrapper = ({
  children,
  ...rest
}: ModalWrapperProps): JSX.Element => {
  const { isMobile } = useMedia();
  return (
    <Modal container={modalContainer} mobile={isMobile} {...rest}>
      {children}
    </Modal>
  );
};

export default ModalWrapper;
