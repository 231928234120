import { useDownloadPackage } from 'modules/Documents/hooks';
import { useCallback } from 'react';

export type UseDownloadPackProps = {
  ownerClientId: EntityId;
  packageId: EntityId;
};

export type UseDownloadPackOutput = {
  onDownload: () => Promise<void>;
  downloadRequest: ReturnType<typeof useDownloadPackage>;
};

export default function useDownloadPackAction({
  ownerClientId,
  packageId,
}: UseDownloadPackProps): UseDownloadPackOutput {
  const downloadRequest = useDownloadPackage({
    ownerClientId,
    packageId,
  });

  const onDownload = useCallback(async () => {
    await downloadRequest.refetch();
  }, [downloadRequest]);

  return {
    downloadRequest,
    onDownload,
  };
}
