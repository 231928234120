import { ErrorCode } from 'react-dropzone';
import { generateGuid } from 'modules/Common/services';
import type { FileRejection } from 'react-dropzone';
import type { DocumentFullData } from '@sp-api/documents-api';
import ClientServerFile from './ClientServerFile';

const enum RejectReasonException {
  IgnoreFile,
  AcceptFile,
}

type FileRejectReason = ErrorCode | RejectReasonException;
type GetRejectReason = (rejection: FileRejection) => FileRejectReason;
type ErrorCodeDict = { [K in ErrorCode]?: boolean };

export default class ClientServerFileCreator {
  private static getDropzoneRejectReason: GetRejectReason = ({ errors }) => {
    const errorCodes = errors.reduce<ErrorCodeDict>((acc, error) => {
      acc[error.code as ErrorCode] = true;
      return acc;
    }, {});

    if (errorCodes[ErrorCode.TooManyFiles]) {
      return RejectReasonException.IgnoreFile;
    }

    if (errorCodes[ErrorCode.FileTooLarge]) return ErrorCode.FileTooLarge;

    if (errorCodes[ErrorCode.FileInvalidType]) {
      // will handle this later
      return RejectReasonException.AcceptFile;
    }

    return RejectReasonException.AcceptFile;
  };

  static getClientFileFromRejection(
    file: File,
    rejectReason: ErrorCode
  ): ClientServerFile {
    return new ClientServerFile({
      upload: {
        file,
        fileUUID: generateGuid(),
        rejectReason,
      },
    });
  }

  static getClientFile(file: File): ClientServerFile {
    return new ClientServerFile({
      upload: {
        file,
        fileUUID: generateGuid(),
      },
    });
  }

  static getClientServerFilesFromServerData(
    docs: DeepRO<DocumentFullData[]>
  ): ClientServerFile[] {
    return docs.map((x) => {
      return new ClientServerFile({
        document: x,
      });
    });
  }

  static getClientFiles(
    acceptedFiles: File[],
    rejectedFiles: FileRejection[] = [],
    getRejectReason: GetRejectReason = ClientServerFileCreator.getDropzoneRejectReason
  ): ClientServerFile[] {
    const result: ClientServerFile[] = [];
    const resultRejected: ClientServerFile[] = [];

    acceptedFiles.forEach((file) => {
      result.push(ClientServerFileCreator.getClientFile(file));
    });

    rejectedFiles.forEach((rejection) => {
      const rejectReason = getRejectReason(rejection);

      if (rejectReason === RejectReasonException.IgnoreFile) return;

      if (rejectReason === RejectReasonException.AcceptFile) {
        result.push(ClientServerFileCreator.getClientFile(rejection.file));
        return;
      }

      resultRejected.push(
        ClientServerFileCreator.getClientFileFromRejection(
          rejection.file,
          rejectReason
        )
      );
    });

    return [...result, ...resultRejected];
  }
}
