import { DateTime } from 'luxon';
import type { Duration } from 'luxon';

const getDiff = (dueDate: string): Duration => {
  const startTime = DateTime.fromISO(dueDate);
  const endTime = DateTime.now();
  return startTime.diff(endTime, [
    'days',
    'hours',
    'minutes',
    'seconds',
    'milliseconds',
  ]);
};

export default getDiff;
