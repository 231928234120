import styled, { css } from 'styled-components';
import flex from 'modules/StyledUtility/Flex';
import { FontSizesEnum, LineHeightsEnum } from '@signplatform-ui/typographics';
import { media } from 'modules/StyledUtility';
import { Col } from 'styled-bootstrap-grid';

export const StyledCol = styled(Col)`
  margin-top: 20px;

  ${media.mobile(css`
    margin-top: 16px;
  `)}
`;

export const StyledFileProperty = styled.div`
  word-break: break-word;
  overflow-wrap: break-word;
  ${flex.flowCol};

  [data-name='label'],
  [data-name='value'] {
    font-size: ${FontSizesEnum.AdditionalL};
    line-height: ${LineHeightsEnum.AdditionalL};
  }

  [data-name='label'] {
    margin-bottom: 4px;
  }
`;
