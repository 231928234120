import type { LinkProps } from 'react-router-dom';
import type { NavigationItem } from '../../types/NavigationItem';
import {
  StyledLink,
  StyledNavItemText,
  StyledSideNavItem,
} from './SideNavItem.styled';

export type SideNavItemProps = {
  item: NavigationItem;
};

function SideNavItem({ item }: SideNavItemProps): JSX.Element {
  const {
    to = '#',
    title,
    icon: Icon,
    component: LinkComponent = StyledLink,
  } = item;

  const linkProps: Partial<LinkProps> = {};

  if (to) {
    linkProps.to = to;
  }

  return (
    <StyledSideNavItem>
      <LinkComponent to={to} {...linkProps}>
        {Icon && <Icon />}
        <StyledNavItemText>{title}</StyledNavItemText>
      </LinkComponent>
    </StyledSideNavItem>
  );
}

export default SideNavItem;
