import type { ReactNode } from 'react';
import downloadImgMin from './images/download_min.png';
import downloadImgNormal from './images/download.png';
import downloadImg2x from './images/download_2x.png';
import {
  StyledQR,
  DesktopContent,
  NonDesktopContent,
} from './CertificateInstructions.styled';
import qrCodeImgNormal from './images/qr-code.png';
import qrCodeImg2x from './images/qr-code_2x.png';
import qrCodeImgMin from './images/qr-code_min.png';
import registerImgMin from './images/register_min.png';
import registerImgNormal from './images/register.png';
import registerImg2x from './images/register_2x.png';
import issueqesImgMin from './images/issueqes_min.png';
import issueqesImgNormal from './images/issueqes.png';
import issueqesImg2x from './images/issueqes_2x.png';
import certImgNormal from './images/cert.png';
import certImg2x from './images/cert_2x.png';

export type CertificateInstruction = {
  id: string;
  image: {
    min: string;
    normal: string;
    x2: string;
  };
  header: string | undefined;
  activeHeader: string;
  description: string;
  activeDescription: ReactNode;
};

// eslint-disable-next-line import/prefer-default-export
export const instructionItems: CertificateInstruction[] = [
  {
    id: 'download',
    image: {
      min: downloadImgMin,
      normal: downloadImgNormal,
      x2: downloadImg2x,
    },
    header: undefined,
    description: 'Скачайте мобильное приложение Госключ на своё устройство',
    activeHeader: 'Скачайте мобильное приложение Госключ на своё устройство',
    activeDescription: (
      <>
        <DesktopContent>
          <StyledQR
            src={qrCodeImgNormal}
            srcX2={qrCodeImg2x}
            srcPlaceholder={qrCodeImgMin}
            alt='Наведите камеру телефона на QR-код или перейдите в приложение'
          />
          Наведите камеру телефона на QR-код или перейдите в приложение{' '}
          <a href='https://goskey.ru' rel='noopener'>
            goskey.ru
          </a>
        </DesktopContent>
        <NonDesktopContent>
          Для получения электронной подписи воспользуйтесь приложением{' '}
          <a href='https://goskey.ru' rel='noopener'>
            goskey.ru
          </a>
        </NonDesktopContent>
      </>
    ),
  },
  {
    id: 'register',
    image: {
      min: registerImgMin,
      normal: registerImgNormal,
      x2: registerImg2x,
    },
    header: undefined,
    description: 'Зарегистрируйтесь в приложении для выпуска ЭП',
    activeHeader: 'Зарегистрируйтесь в приложении для выпуска подписи',
    activeDescription: (
      <>
        Ключ электронной подписи создаётся, хранится и применяется в приложении{' '}
        <a href='https://goskey.ru' rel='noopener'>
          goskey.ru
        </a>
      </>
    ),
  },
  {
    id: 'sign',
    image: {
      min: certImgNormal,
      normal: certImgNormal,
      x2: certImg2x,
    },
    header: undefined,
    description: 'Получите подпись и подписывайте с её помощью документы',
    activeHeader: 'Подписывайте документы с помощью электронной подписи',
    activeDescription:
      'Получите сертификат электронной подписи и подписывайте документы без USB-токенов, СМС-паролей и личной явки',
  },
];
export const instructionQESItems: CertificateInstruction[] = [
  {
    id: 'download',
    image: {
      min: downloadImgMin,
      normal: downloadImgNormal,
      x2: downloadImg2x,
    },
    header: undefined,
    description: 'Скачайте мобильное приложение Госключ на своё устройство',
    activeHeader: 'Скачайте мобильное приложение Госключ на своё устройство',
    activeDescription: (
      <>
        <DesktopContent>
          <StyledQR
            src={qrCodeImgNormal}
            srcX2={qrCodeImg2x}
            srcPlaceholder={qrCodeImgMin}
            alt='Наведите камеру телефона на QR-код или перейдите в приложение'
          />
          Наведите камеру телефона на QR-код или перейдите в приложение{' '}
          <a href='https://goskey.ru' rel='noopener'>
            goskey.ru
          </a>
        </DesktopContent>
        <NonDesktopContent>
          Для получения электронной подписи воспользуйтесь приложением{' '}
          <a href='https://goskey.ru' rel='noopener'>
            goskey.ru
          </a>
        </NonDesktopContent>
      </>
    ),
  },
  {
    id: 'register',
    image: {
      min: registerImgMin,
      normal: registerImgNormal,
      x2: registerImg2x,
    },
    header: undefined,
    description: 'Зарегистрируйтесь в Госключе и выпустите УНЭП',
    activeHeader: 'Зарегистрируйтесь в приложении для выпуска подписи',
    activeDescription: (
      <>
        Ключ электронной подписи создаётся, хранится и применяется в приложении{' '}
        <a href='https://goskey.ru' rel='noopener'>
          goskey.ru
        </a>
      </>
    ),
  },
  {
    id: 'sign',
    image: {
      min: issueqesImgMin,
      normal: issueqesImgNormal,
      x2: issueqesImg2x,
    },
    header: undefined,
    description: 'Выпустите УКЭП по биометрическому загранпаспорту',
    activeHeader: 'Выпустите УКЭП',
    activeDescription:
      'Выпустите УКЭП в приложении Госключ используя биометрический загранпаспорт и телефон с NFC-модулем',
  },
];
