import { ButtonColorsEnum, ButtonSizesEnum } from '@signplatform-ui/button';
import DropdownButton, {
  MenuPositionsEnum,
} from '@signplatform-ui/dropdownbutton';
import { MoreHorizontalOutline } from '@signplatform-ui/icons';
import { useModal } from 'modules/Common/hooks';
import { useMedia } from 'modules/Shared/hooks';
import { useDownloadPackage } from 'modules/Documents/hooks';
import PublishPackageButton from 'modules/Documents/components/PublishPackageButton';
import RenamePackageModalUI from 'modules/Documents/components/RenamePackageModal/RenamePackageModalUI';
import RemovePackageModal from 'modules/Documents/components/RemovePackageModal';
import type { MenuItemProp } from '@signplatform-ui/menu';
import {
  ButtonsWrapper,
  CardButtonsWrapper,
} from './PackageWizardActions.styled';

type PackageWizardActionsProps = {
  packageId: EntityId;
  title?: string;
  ownerClientId: EntityId;
  onPublish: () => Promise<void>;
  isPublishDisabled: boolean;
  buttons: {
    sendButton?: boolean;
    signButton?: boolean;
    rejectButton?: boolean;
    downloadButton?: boolean;
    renameButton?: boolean;
    deleteButton?: boolean;
  };
};

const PackageWizardActions = ({
  packageId,
  ownerClientId,
  onPublish,
  isPublishDisabled,
  buttons: { downloadButton, renameButton, deleteButton },
}: PackageWizardActionsProps): JSX.Element => {
  const renameModalRef = useModal();
  const removeModalRef = useModal();

  const { refetch: downloadPackage, isLoading } = useDownloadPackage({
    ownerClientId,
    packageId,
  });

  const { isMobile } = useMedia();

  const items = ((): MenuItemProp[] => {
    const buttonsResult: MenuItemProp[] = [];

    if (isMobile) {
      buttonsResult.push({
        id: 'send',
        title: 'Отправить',
        isDisabled: isPublishDisabled,
        onClick: (e) => {
          e.preventDefault();
          void onPublish();
        },
      });
    }

    if (downloadButton) {
      buttonsResult.push({
        id: 'download',
        title: 'Скачать пакет',
        isDisabled: isLoading,
        onClick: (e) => {
          e.preventDefault();
          void downloadPackage();
        },
      });
    }

    if (renameButton) {
      buttonsResult.push({
        id: 'rename',
        title: 'Переименовать',
        onClick: (e) => {
          e.preventDefault();
          renameModalRef.onOpen();
        },
      });
    }

    if (deleteButton) {
      buttonsResult.push({
        id: 'remove',
        title: 'Удалить',
        onClick: (e) => {
          e.preventDefault();
          removeModalRef.onOpen();
        },
      });
    }

    return buttonsResult;
  })();

  return (
    <>
      <ButtonsWrapper data-testid='package-actions-wrapper'>
        {!!items.length && (
          <DropdownButton
            color={ButtonColorsEnum.Ghost}
            size={ButtonSizesEnum.Medium}
            items={items}
            displayAsSquare
            position={MenuPositionsEnum.Right}
            data-testid='document-actions-dropdown'
          >
            <MoreHorizontalOutline />
          </DropdownButton>
        )}

        <CardButtonsWrapper>
          {!isMobile && (
            <PublishPackageButton
              size={ButtonSizesEnum.Medium}
              color={ButtonColorsEnum.Secondary}
              onPublish={onPublish}
              disabled={isPublishDisabled}
            >
              Отправить
            </PublishPackageButton>
          )}
        </CardButtonsWrapper>
      </ButtonsWrapper>

      {renameModalRef.isOpen && (
        <RenamePackageModalUI onClose={renameModalRef.onClose} />
      )}

      {removeModalRef.isOpen && (
        <RemovePackageModal
          packageId={packageId}
          onClose={removeModalRef.onClose}
        />
      )}
    </>
  );
};

export default PackageWizardActions;
