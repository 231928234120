import styled from 'styled-components';

export const StyledErrorContainer = styled.div<{
  $isNotShown: boolean | undefined;
}>`
  transition: opacity 0.2s linear;
  opacity: ${({ $isNotShown }) => ($isNotShown ? 0 : 1)};
`;

export const StyledErrorContent = styled.p`
  margin: 0 0 24px;
`;
