import { ToCustomerSolid, UserRoleSolid1 } from '@signplatform-ui/icons';
import { useUser } from 'modules/Shared/hooks';
import Status from 'modules/Documents/components/Status/Status';
import { useTheme } from 'styled-components';

type DirectionStatusProps = {
  clientIdOwner: EntityId;
};

const DirectionStatus = ({
  clientIdOwner,
}: DirectionStatusProps): JSX.Element => {
  const theme = useTheme();
  const { user } = useUser();
  const Icon =
    user?.clientId === clientIdOwner ? UserRoleSolid1 : ToCustomerSolid;

  return (
    <Status color={theme.color.text.tertiary} data-testid='document-direction'>
      <Icon data-testid='document-direction-icon' />
      <span data-testid='document-direction-title'>
        {user?.clientId === clientIdOwner ? 'Исходящие' : 'Входящие'}
      </span>
    </Status>
  );
};

export default DirectionStatus;
