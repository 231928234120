import { getCounterpartyCaption } from 'modules/Documents/utils';
import type { FoundOrInvitedCounterparty } from 'modules/Documents/components/PackageWizard/types';
import type { CpTypeTrap } from './types';
import getCounterpartyIdentifier from './getCounterpartyIdentifier';

export type CounterpartyAdaptedData<
  T extends DeepROStrict<FoundOrInvitedCounterparty>
> = {
  // подпись
  caption: string;
  // уникальный идентификатор среди сиблингов
  perSiblingId: string;
  source: T;
};

export default function getCounterpartyData<
  T extends DeepROStrict<FoundOrInvitedCounterparty>
>(data: CpTypeTrap<T>): CounterpartyAdaptedData<T> {
  return {
    caption: getCounterpartyCaption(data),
    perSiblingId: getCounterpartyIdentifier(data),
    source: data,
  };
}
