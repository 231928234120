import { HeaderSizesEnum } from '@signplatform-ui/header';
import { LinkTypesEnum } from '@signplatform-ui/link';
import { TextTypesEnum } from '@signplatform-ui/text';
import { usePackageContext } from 'modules/Documents/contexts';
import useWatchStickyHeader from 'modules/Documents/hooks/useWatchStickyHeader';
import {
  Breadcrumb,
  StyledStickyContainer,
} from 'modules/Layouts/DefaultLayout';
import { useTheme } from 'styled-components';
import AppRoutesPaths from 'modules/Router/routes/paths';
import {
  ButtonsWrapper,
  DocumentHeader,
  StyledUniqueNumber,
} from './CardHeader.styled';
import { PackageCardHeaderActions } from './components';

const PackageCardHeader = ({
  resignThreshold,
  resignTooltip,
  onSignSuccess,
}: {
  resignThreshold?: number;
  resignTooltip?: React.ReactNode;
  onSignSuccess?: () => Promise<void>;
}): JSX.Element | null => {
  const {
    packageAdapted: { name, source },
  } = usePackageContext();

  const { color } = useTheme();
  const { stickyRef, buttonsRef } = useWatchStickyHeader();

  return (
    <>
      <StyledStickyContainer ref={stickyRef}>
        <Breadcrumb
          type={LinkTypesEnum.Secondary}
          toFallback={AppRoutesPaths.AgreementsList}
          toOverride={AppRoutesPaths.DocumentsList}
        />

        <DocumentHeader type={HeaderSizesEnum.H5} data-testid='document-header'>
          {name}
        </DocumentHeader>
      </StyledStickyContainer>

      {source.uniqueNumber && (
        <StyledUniqueNumber type={TextTypesEnum.S} color={color.text.secondary}>
          {'ID: '}
          {source.uniqueNumber}
        </StyledUniqueNumber>
      )}

      <ButtonsWrapper ref={buttonsRef}>
        <PackageCardHeaderActions
          resignThreshold={resignThreshold}
          resignTooltip={resignTooltip}
          onSignSuccess={onSignSuccess}
        />
      </ButtonsWrapper>
    </>
  );
};

export default PackageCardHeader;
