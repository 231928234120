import styled from 'styled-components';
import ProfileAvatarSizesEnum from 'modules/UI/components/ProfileAvatar/ProfileAvatarSizesEnum';

const avatarSizes = {
  [ProfileAvatarSizesEnum.XS]: 24,
  [ProfileAvatarSizesEnum.SM]: 32,
  [ProfileAvatarSizesEnum.MD]: 40,
  [ProfileAvatarSizesEnum.LG]: 48,
  [ProfileAvatarSizesEnum.XL]: 56,
};

export const StyledProfileAvatar = styled.div<{ size: ProfileAvatarSizesEnum }>`
  width: ${({ size }) => avatarSizes[size]}px;
  height: ${({ size }) => avatarSizes[size]}px;
  background: ${({ theme }) => theme.color.background.tertiary};
  flex-shrink: 0;
  border-radius: 50%;
  position: relative;
`;

export const StyledLabel = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.color.text.primary};
`;
