import { Configuration, RegistrationApi } from '@signplatform/registration-api';
import { useConfiguration } from 'modules/Shared/hooks';
import { apiService } from '../services';

const useApi = apiService.createUseApi(RegistrationApi, Configuration);

const useAuthApi = (): RegistrationApi => {
  const [{ authContextUrl }] = useConfiguration();
  return useApi(authContextUrl);
};

export default useAuthApi;
