import type { FoundOrInvitedCounterparty } from 'modules/Documents/components/PackageWizard/types';
import type { CounterpartyOrg } from '../types';

export default function isCounterpartyOrg(
  counterpartyData: DeepROStrict<FoundOrInvitedCounterparty>
): counterpartyData is CounterpartyOrg {
  const asserted = counterpartyData as DeepRO<CounterpartyOrg>;

  return !!(
    asserted.ogrn ||
    asserted.organizationInn ||
    asserted.organizationKpp ||
    asserted.name
  );
}
