import { media } from 'modules/StyledUtility';
import styled, { css } from 'styled-components';
import LazyImg from 'modules/Common/components/LazyImg';

export const StyledQR = styled(LazyImg)`
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const StyledRow = styled.div`
  display: flex;
  margin-top: 24px;
  overflow: auto;
  padding-bottom: 10px;
`;

export const DesktopContent = styled.div`
  ${media.tablet(css`
    flex-direction: column;
    display: none;
  `)}
`;

export const NonDesktopContent = styled.div`
  display: none;
  ${media.tablet(css`
    display: block;
  `)}
`;
