import LazyImg from 'modules/Common/components/LazyImg/LazyImg';
import styled from 'styled-components';
import { typography } from '@signplatform-ui/typographics';
import flex from 'modules/StyledUtility/Flex';
import Text from '@signplatform-ui/text';

export const StyledQR = styled(LazyImg)`
  margin-right: 36px;
  flex-shrink: 0;

  img {
    height: 114px;
    border-radius: 7.93043px;
  }
`;

export const StyledMainText = styled.div`
  margin-bottom: 20px;
`;

export const StyledText = styled(Text)`
  margin-bottom: 8px;
`;

export const StyledDescription = styled.div`
  ${typography['Additional/S']}
`;

export const StyledDescriptionContainer = styled.div`
  ${flex.start}
`;
