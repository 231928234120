import { lazy } from 'react';
import AppRoutesPaths from 'modules/Router/routes/paths';
import type { AppRoute } from '../types';
import { RouteTypeEnum } from '../types';

const LoginPage = lazy(
  () => import(/* webpackChunkName: "LoginPage" */ 'modules/Auth/pages/SignUp')
);

const TokenPage = lazy(
  () => import(/* webpackChunkName: "TokenPage" */ 'modules/Auth/pages/Token')
);

const HomePage = lazy(
  () =>
    import(/* webpackChunkName: "HomePage" */ 'modules/Common/pages/Home/Home')
);

const routes: AppRoute[] = [
  {
    id: 'common:home',
    path: AppRoutesPaths.Home,
    exact: true,
    type: RouteTypeEnum.Private,
    component: HomePage,
  },
  {
    id: 'common:login',
    path: AppRoutesPaths.SignUp,
    type: RouteTypeEnum.Public,
    component: LoginPage,
  },
  {
    id: 'common:token',
    path: AppRoutesPaths.Token,
    type: RouteTypeEnum.Public,
    component: TokenPage,
  },
];

export default routes;
