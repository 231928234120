import useSignGateApiClient from 'modules/Api/hooks/useSignGateApiClient';
import { ID_POINT_CHANNEL_ID } from 'modules/Documents/constants';
import { useMutation, useQueryClient } from 'react-query';
import type { ErrorResponse } from 'modules/App/types';
import type { QueryKey, UseMutationResult } from 'react-query';

type PackagePublishParams = {
  packageId: EntityId;
  clientIdOwner: EntityId;
};

export default function useSignPackage(
  invalidateQuery?: QueryKey
): UseMutationResult<void, ErrorResponse, PackagePublishParams> {
  const signGateApi = useSignGateApiClient();
  const queryClient = useQueryClient();

  return useMutation<void, ErrorResponse, PackagePublishParams>(
    async ({ packageId, clientIdOwner }) => {
      await signGateApi.signPackage(
        clientIdOwner,
        packageId,
        ID_POINT_CHANNEL_ID
      );
    },
    {
      onSuccess: () =>
        invalidateQuery
          ? queryClient.invalidateQueries(invalidateQuery)
          : undefined,
    }
  );
}
