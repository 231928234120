import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import {
  StyledModalContent,
  StyledModalTitle,
  StyledOl,
  StyledP,
} from 'modules/Documents/components/PackageCard/PackageCard.styled';
import Modal, { ModalButtonPanel } from 'modules/UI/components/Modal';
import { Col, Row } from 'styled-bootstrap-grid';
import QrCodeGoskey from 'modules/Documents/components/QrCodeGoskey';

type EmptyDocsModalProps = {
  onClose: (isConfirmed?: boolean) => Promise<void> | void;
  onSignPackage: () => Promise<void>;
  isLoading: boolean;
};

const EmptyDocsModal = ({
  onClose,
  onSignPackage,
  isLoading,
}: EmptyDocsModalProps): JSX.Element => {
  return (
    <Modal onClose={() => onClose()}>
      <StyledModalTitle>
        Если вы не получили комплект документов в Госключе
      </StyledModalTitle>

      <StyledP>
        Убедитесь, что у вас установлено мобильное приложение
        <br />и повторно отправьте комплект документов на подписание
      </StyledP>

      <StyledModalContent>
        <Row>
          <Col col={3}>
            <QrCodeGoskey />
          </Col>

          <Col col={9}>
            <StyledOl>
              <li>
                Установите мобильное приложение. Для этого наведите камеру на
                QR-код или перейдите по адресу{' '}
                <a
                  href='https://goskey.ru/'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  goskey.ru
                </a>
              </li>

              <li>
                Повторно отправьте комплект документов на подписание из карточки
                комплекта документов
              </li>

              <li>Подпишите комплект документов в мобильном приложении</li>
            </StyledOl>
          </Col>
        </Row>
      </StyledModalContent>

      <ModalButtonPanel>
        <Button
          onClick={onSignPackage}
          color={ButtonColorsEnum.Primary}
          size={ButtonSizesEnum.Medium}
          disabled={isLoading}
          data-testid='empty-goskey-modal-sign-button'
        >
          Подписать
        </Button>

        <Button
          size={ButtonSizesEnum.Medium}
          color={ButtonColorsEnum.Secondary}
          disabled={isLoading}
          onClick={() => onClose()}
        >
          Отменить
        </Button>
      </ModalButtonPanel>
    </Modal>
  );
};

export default EmptyDocsModal;
