import { notificationsService } from 'modules/Notifications';
import { NotificationsTypesEnum } from '@signplatform-ui/notification';
import type { ErrorResponse } from 'modules/App/types';

const errorCodesFileProtectError = new Set([466]);

export default function notifyByError(error: unknown): void {
  if (!error) return;
  const errorCode = (error as ErrorResponse).status;

  if (errorCodesFileProtectError.has(errorCode)) {
    notificationsService.notify(
      'Ошибка скачивания документа со штампами ЭП',
      'Файл защищён от изменений. Отображение штампа невозможно.',
      NotificationsTypesEnum.Error
    );
  } else {
    notificationsService.notify(
      'Ошибка скачивания документа со штампами ЭП',
      'При выгрузке файла документа возникла ошибка, повторите попытку позже.',
      NotificationsTypesEnum.Error
    );
  }
}
