import { StatusUnitEnum } from '@sp-api/documents-api';
import NameValueInfo from 'modules/Documents/components/NameValueInfo';
import PackageSignStatus from 'modules/Documents/components/PackageSignStatus';
import SigningTimer from 'modules/Documents/components/SigningTimer';
import { DATE_TIME_FORMAT } from 'modules/Documents/constants';
import isAllowedStatus from 'modules/Documents/utils/isAllowedStatusChangeDate';
import { formatLocalDate } from 'services/dateUtils';
import { Col, Row } from 'styled-bootstrap-grid';
import type { ReactNode } from 'react';
import type { StatusSigningEnum } from '@sp-api/documents-api';
import { StyledCol, StyledFileProperty } from './DocumentProperties.styled';

type AllowedStatusUnit = Exclude<StatusUnitEnum, StatusUnitEnum.Draft>;

const showTimerStatuses = new Set([StatusUnitEnum.Waiting]);

const statusTextMap: Record<AllowedStatusUnit, string> = {
  [StatusUnitEnum.Waiting]: 'Срок подписания до',
  [StatusUnitEnum.Error]: 'Дата ошибки',
  [StatusUnitEnum.Rejected]: 'Дата отклонения',
  [StatusUnitEnum.Signed]: 'Дата подписания',
  [StatusUnitEnum.RejectedByOwner]: 'Дата отзыва',
  [StatusUnitEnum.Expired]: 'Срок подписания',
};

const dateSelectorMap: Record<
  AllowedStatusUnit,
  (dateMap: {
    signDueDate?: string;
    stateUpdateStatus?: string;
  }) => string | undefined
> = {
  [StatusUnitEnum.Waiting]: (dateMap) => dateMap.signDueDate,
  [StatusUnitEnum.Error]: (dateMap) => dateMap.stateUpdateStatus,
  [StatusUnitEnum.Rejected]: (dateMap) => dateMap.stateUpdateStatus,
  [StatusUnitEnum.Signed]: (dateMap) => dateMap.stateUpdateStatus,
  [StatusUnitEnum.RejectedByOwner]: (dateMap) => dateMap.stateUpdateStatus,
  [StatusUnitEnum.Expired]: (dateMap) => dateMap.signDueDate,
};

export type DocumentPropertiesProps = {
  status: StatusUnitEnum;
  sendTime: string;
  authorName: string;
  slotStatusRight?: ReactNode;
  signDueDate?: string;
  userSigningStatus?: StatusSigningEnum;
  publishDate?: string;
  stateUpdateStatus?: string;
};

const hiddenStatuses = new Set<StatusUnitEnum>([
  StatusUnitEnum.RejectedByOwner,
  StatusUnitEnum.Expired,
  StatusUnitEnum.Draft,
]);

const DocumentProperties = ({
  status,
  authorName,
  sendTime,
  slotStatusRight,
  signDueDate,
  userSigningStatus,
  stateUpdateStatus,
}: DocumentPropertiesProps): JSX.Element => {
  const showChangeDate = isAllowedStatus<AllowedStatusUnit>(
    status,
    hiddenStatuses
  );

  const sendTimeFormatted = formatLocalDate(
    sendTime,
    DATE_TIME_FORMAT,
    '(Мск)'
  );

  const changeDate = showChangeDate
    ? formatLocalDate(
        dateSelectorMap[status]({
          signDueDate,
          stateUpdateStatus,
        }),
        DATE_TIME_FORMAT,
        '(Мск)'
      )
    : null;

  return (
    <>
      <Row alignItems='center'>
        <Col col='auto'>
          <PackageSignStatus
            status={status}
            userSigningStatus={userSigningStatus}
          />
        </Col>

        {slotStatusRight}
      </Row>

      <Row>
        <StyledCol col={12} md={6}>
          <StyledFileProperty>
            <NameValueInfo hasNoColon name='Автор' value={authorName} />
          </StyledFileProperty>
        </StyledCol>

        {showChangeDate && changeDate && (
          <StyledCol col={12} md={6} data-testid='doc-props-change-date'>
            <StyledFileProperty>
              <NameValueInfo
                hasNoColon
                name={statusTextMap[status]}
                value={changeDate}
              />
            </StyledFileProperty>
          </StyledCol>
        )}

        <StyledCol col={12} md={6}>
          {sendTimeFormatted && (
            <StyledFileProperty>
              <NameValueInfo<string>
                hasNoColon
                name='Дата отправки'
                value={sendTimeFormatted}
              />
            </StyledFileProperty>
          )}
        </StyledCol>

        {showTimerStatuses.has(status) && signDueDate && (
          <StyledCol col={12} md={6}>
            <StyledFileProperty>
              <NameValueInfo
                hasNoColon
                name='Осталось'
                value={<SigningTimer dueDate={signDueDate} />}
              />
            </StyledFileProperty>
          </StyledCol>
        )}
      </Row>
    </>
  );
};

export default DocumentProperties;
