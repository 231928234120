import { useDocumentApiClient } from 'modules/Api';
import { useQuery } from 'react-query';
import type { QueryKey, UseQueryResult, UseQueryOptions } from 'react-query';
import type { PackageFullData } from '@sp-api/documents-api';
import type { ErrorResponse } from 'modules/App/types';
import DocumentsStateKeysEnum from '../constants/DocumentsStateKeysEnum';

type PackageCardOptions = {
  packageId: EntityId;
  ownerClientId: EntityId;
};

export function getPackageCardQueryKey({
  ownerClientId,
  packageId,
}: PackageCardOptions): QueryKey {
  return [
    DocumentsStateKeysEnum.Card,
    {
      clientId: String(ownerClientId),
      packageId: String(packageId),
    },
  ];
}

export default function usePackageCard(
  options: PackageCardOptions,
  queryOptions: UseQueryOptions<PackageFullData, ErrorResponse>
): UseQueryResult<PackageFullData, ErrorResponse> {
  const { ownerClientId, packageId } = options;
  const documentApi = useDocumentApiClient();

  return useQuery(
    getPackageCardQueryKey(options),
    async () => {
      const { data } = await documentApi.getPackageDetails(
        ownerClientId,
        packageId
      );

      return data;
    },
    queryOptions
  );
}
