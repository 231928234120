import styled from 'styled-components';
import Header from '@signplatform-ui/header';
import InputField from '@signplatform-ui/input-field';

export const ModalTitle = styled(Header)`
  margin: 10px 0 16px 0;
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;

  button + button {
    margin-left: 16px;
  }
`;

export const SuccessCopiedMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.status.success};

  span {
    margin-left: 8px;
  }

  path {
    fill: ${({ theme }) => theme.color.status.success};
  }
`;

export const InputHref = styled(InputField)`
  input {
    background-color: ${({ theme }) => theme.color.background.tertiary};
  }
`;
