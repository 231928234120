import styled from 'styled-components';
import SigningStatus from 'modules/Documents/components/SigningStatus';

export const DocumentSignerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 28px;
  background-color: ${({ theme }) => theme.color.background.secondary};
  border-radius: 8px;

  & + & {
    margin-top: 16px;
  }
`;

export const SignerNameWrapper = styled.div`
  display: flex;
  align-items: center;
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const StyledDocumentSignStatus = styled(SigningStatus)`
  display: inline-flex;
`;
