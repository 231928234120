import { Configuration, SpLogicCheckSignApi } from '@sp-api/check-sign-api';
import { apiService } from 'modules/Api/components/ApiProvider/services';
import { useConfiguration } from 'modules/Shared/hooks';

const useApi = apiService.createUseApi(SpLogicCheckSignApi, Configuration);

const useCheckSignApi = (): SpLogicCheckSignApi => {
  const [{ checkSignContextUrl }] = useConfiguration();
  return useApi(checkSignContextUrl);
};

export default useCheckSignApi;
