import { createContext } from 'react';
import type { PackageFullData } from '@sp-api/documents-api';
import type { IPackageAdapter } from 'modules/Documents/classes';

export interface IPackageCardContext {
  readonly packageAdapted: IPackageAdapter;
  readonly packageServer: PackageFullData;

  onChangePackageName: (newName: string) => void;

  onRefetchPackage: () => Promise<void>;

  readonly packageName: string;
}

const PackageCardContext = createContext<IPackageCardContext>(
  null as unknown as IPackageCardContext
);

export default PackageCardContext;
