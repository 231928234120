import { DateTime } from 'luxon';

export const TARGET_TIME_ZONE = 'UTC+3';

export function formatDate(
  dateStr: string | undefined,
  format = 'dd.MM.yyyy'
): string {
  if (!dateStr) return '';

  return DateTime.fromISO(dateStr).setLocale('ru').toFormat(format);
}

export function formatLocalDate(
  dateStr: string | undefined,
  format = 'FF',
  postFix?: string
): string {
  if (!dateStr) return '';

  return `${DateTime.fromISO(dateStr, { zone: 'UTC' })
    .setZone(TARGET_TIME_ZONE)
    .setLocale('ru')
    .toFormat(format)}${postFix ? ` ${postFix}` : ''}`;
}
