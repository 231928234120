import {
  FILE_NAME_COMMON_ERROR,
  DocumentRejectReason,
} from 'modules/Documents/constants';
import type { FileErrorData } from 'modules/UI/types';

const mapDefaultError = (errorText: string): FileErrorData => {
  return {
    errorBottom: errorText,
    hasError: true,
  };
};

export default function getErrorDataByRejectReason(
  rejectReason: DocumentRejectReason | undefined,
  hasErrorFromBefore?: boolean
): FileErrorData {
  switch (rejectReason) {
    default:
    case undefined:
    case DocumentRejectReason.FileIsOk:
      return {
        hasError: hasErrorFromBefore,
      };

    case DocumentRejectReason.FileWasNotLoaded:
      return mapDefaultError(
        'Файл не был загружен, попробуйте повторить загрузку позже'
      );

    case DocumentRejectReason.FileNameIsEmpty:
      return mapDefaultError('Название документа не может быть пустым');

    case DocumentRejectReason.FileNameHasForbiddenSymbols:
      return mapDefaultError(FILE_NAME_COMMON_ERROR);

    case DocumentRejectReason.FileNameIsForbidden:
      return mapDefaultError(
        'Наименование документа содержит некорректное название'
      );

    case DocumentRejectReason.FileIsEmpty:
      return mapDefaultError(
        'Файл не был загружен, отсутствует содержимое файла'
      );

    case DocumentRejectReason.FileIsTooLarge:
      return mapDefaultError('Файл не был загружен, превышает 20 МБ');

    case DocumentRejectReason.FileExtIsForbidden:
      return mapDefaultError('Файл не был загружен, неправильный формат');

    case DocumentRejectReason.FileNameAlreadyUsed:
      return mapDefaultError('Наименование документов не должно повторяться');

    case DocumentRejectReason.PackageIsTooLarge:
      if (hasErrorFromBefore) return { hasError: true };

      return {
        hasError: true,
        errorTop:
          'Несколько файлов не было загружено, так как превышен общий объем загружаемых документов',
        nextFilesHasError: true,
      };
  }
}
