import { memo, useState } from 'react';
import type { CertificateInstruction } from './constants';
import { StyledRow } from './CertificateInstructions.styled';
import Tile from './components/Tile';
import { instructionItems } from './constants';

type CertificateInstructionsProps = {
  className?: string;
  items?: CertificateInstruction[];
};

const CertificateInstructions = ({
  className,
  items = instructionItems,
}: CertificateInstructionsProps): JSX.Element => {
  const [activeItem, setActiveItem] = useState('download');

  return (
    <StyledRow className={className}>
      {items.map(
        ({
          id,
          header,
          description,
          activeDescription,
          image,
          activeHeader,
        }) => (
          <Tile
            key={id}
            id={id}
            image={image}
            header={header}
            description={description}
            activeHeader={activeHeader}
            activeDescription={activeDescription}
            isActive={activeItem === id}
            onClick={setActiveItem}
          />
        )
      )}
    </StyledRow>
  );
};

export default memo(CertificateInstructions);
