import { DateTime } from 'luxon';
import { pluralize } from 'services/commonUtils';
import getDiff from './getDiff';

const daysVariants: [string, string, string] = ['день', 'дня', 'дней'];
const hoursVariants: [string, string, string] = ['час', 'часа', 'часов'];
const minutesVariants: [string, string, string] = ['минута', 'минуты', 'минут'];

const getRemainingTime = (dueDate: string): string => {
  const startTime = DateTime.fromISO(dueDate);
  const endTime = DateTime.now();
  const diff = startTime.diff(endTime, ['days', 'hours', 'minutes', 'seconds']);
  const { days, hours, minutes, seconds } = getDiff(dueDate);

  if (diff.toMillis() <= 0) {
    return '0 часов, 0 минут';
  }

  if (diff.toMillis() <= 60 * 1000) {
    return `${Math.trunc(diff.toMillis() === 60 * 1000 ? 60 : seconds)} сек.`;
  }

  if (days && days >= 1) {
    return `${Math.trunc(days)} ${pluralize(days, daysVariants)}, ${Math.trunc(
      hours
    )} ${pluralize(hours, hoursVariants)}`;
  }

  return `${Math.trunc(hours)} ${pluralize(hours, hoursVariants)}, ${Math.trunc(
    minutes
  )} ${pluralize(minutes, minutesVariants)}`;
};

export default getRemainingTime;
