import styled, { css } from 'styled-components';
import { ModalTitle, ModalContent } from 'modules/UI/components/Modal';
import { FontSizesEnum } from '@signplatform-ui/typographics';
import { media } from 'modules/StyledUtility/utils';
import flex from 'modules/StyledUtility/Flex';
import Card from '@signplatform-ui/card';

export const ButtonsWrapper = styled.div`
  margin-top: 24px;

  button + button {
    margin-left: 16px;
  }

  ${media.tablet(css`
    button {
      max-width: calc(50% - 8px);
    }
  `)}

  ${media.mobileXS(css`
    ${flex.flowCol};

    button {
      width: 100%;
      max-width: unset;
    }

    button + button {
      margin: 16px 0 0 0;
    }
  `)}
`;
export const StyledDocsCard = styled.div`
  margin: 16px 0 24px;
`;

export const StyledBigCard = styled(Card)`
  margin: 16px 0 24px;
  padding: 20px 24px;

  ${media.mobile(css`
    margin: 12px 0 20px;
    padding: 20px;
  `)}
`;

export const StyledOl = styled.ol`
  margin: 0;
  padding-left: 20px;

  li {
    margin-bottom: 8px;
  }
`;

export const StyledP = styled.p`
  margin: 0 0 20px;
`;

export const StyledModalTitle = styled(ModalTitle)`
  font-size: ${FontSizesEnum.H6};
`;

export const StyledModalContent = styled(ModalContent)`
  font-size: ${FontSizesEnum.AdditionalM};
`;

export const StyledCaption = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;

    path {
      fill: ${({ theme }) => theme.color.text.tertiary};
    }
  }

  margin: 16px 0 0;
  color: ${({ theme }) => theme.color.text.tertiary};
`;
