import { makeEnumerablePrototype } from 'modules/Common/services';
import type {
  ClientOwnerData,
  CounterpartyClientPerson,
  CounterpartyPersonBase,
  SignerSignStatusUni,
} from 'modules/Documents/types';

import SignMixinEmpty from './SignMixinEmpty';

type ISigningAdapterSearchPerson = CounterpartyClientPerson &
  PartialToUndefined<CounterpartyPersonBase>;

// TODO remove excessive getters due to properties
@makeEnumerablePrototype
export default class SigningAdapterOwnerPerson
  extends SignMixinEmpty<SignerSignStatusUni>
  implements ISigningAdapterSearchPerson
{
  participantId = this._data.participantId;

  constructor(private _data: NonNullable<ClientOwnerData['innerPersonsData']>) {
    super();
  }

  get snils(): string | undefined {
    return this._data.snils;
  }

  get lastName(): string | undefined {
    return this._data.lastName;
  }

  get firstName(): string | undefined {
    return this._data.firstName;
  }

  get middleName(): string | undefined {
    return this._data.middleName;
  }

  get inn(): string | undefined {
    return undefined;
  }

  get idNum(): string | undefined {
    if (!this._data.dulNumber) return undefined;
    return `${this._data.dulSeries || ''} ${this._data.dulNumber}`.trim();
  }

  get email(): string | undefined {
    return undefined;
  }

  get phoneNumber(): string | undefined {
    return undefined;
  }

  get inviteId(): EntityId | undefined {
    return undefined;
  }

  get clientId(): ISigningAdapterSearchPerson['clientId'] {
    return this._data.clientId;
  }

  get __fePerson(): boolean {
    return true;
  }
}
