import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import type { NavLinkProps } from 'react-router-dom';

export const primaryStyles = css`
  background: ${({ theme }) => theme.color.basic.primary};
  color: ${({ theme }) => theme.color.text.staticWhite};

  svg {
    path {
      fill: ${({ theme }) => theme.color.text.staticWhite};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.color.text.staticWhite};
    background: ${({ theme }) => theme.color.basic.hover};
  }

  &:focus,
  &:active {
    color: ${({ theme }) => theme.color.text.staticWhite};
    background: ${({ theme }) => theme.color.basic.press};
  }
`;

export const defaultStyles = css`
  color: ${({ theme }) => theme.color.text.primary};

  &:hover {
    color: ${({ theme }) => theme.color.basic.primary};

    svg {
      path {
        fill: ${({ theme }) => theme.color.basic.primary};
      }
    }
  }

  &.active {
    background: ${({ theme }) => theme.color.background.tertiary};
    color: ${({ theme }) => theme.color.text.secondary};

    svg {
      path {
        fill: ${({ theme }) => theme.color.text.secondary};
      }
    }

    &:hover {
      background: ${({ theme }) => theme.color.background.tertiaryHover};

      svg {
        path {
          fill: ${({ theme }) => theme.color.text.secondary};
        }
      }
    }
  }
`;

export const StyledSideNavItem = styled.li`
  display: flex;
  align-items: center;
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  user-select: none;
  &:not(:first-child) {
    margin-top: 18px;
  }

  svg {
    cursor: pointer;
  }
`;

export const StyledLink = styled(NavLink)<NavLinkProps>`
  display: flex;
  text-decoration: none;
  padding: 8px 9px;
  border-radius: 8px;
  align-items: center;
  width: 100%;
  height: 40px;

  ${defaultStyles}

  svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

export const StyledNavItemText = styled.div`
  flex: 1;
`;

export const StyledSubSideNav = styled.ul<{
  expanded?: boolean;
}>`
  display: ${(props) => (props.expanded ? 'block' : 'none')};
  user-select: none;
`;
