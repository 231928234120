import Card from '@signplatform-ui/card';
import type { ReactNode } from 'react';
import { Placeholder, Content, Title } from './NotFoundPlaceholder.styled';

type NotFoundPlaceholderProps = {
  children?: ReactNode;
  slotCaption?: ReactNode;
  slotContent?: ReactNode;
  slotTitle?: ReactNode;
};

export default function NotFoundPlaceholder({
  children,
  slotCaption,
  slotContent,
  slotTitle,
}: NotFoundPlaceholderProps): JSX.Element {
  return (
    <Card>
      {slotTitle && <Title>{slotTitle}</Title>}

      {slotCaption && <Placeholder>{slotCaption}</Placeholder>}

      {slotContent && <Content>{slotContent}</Content>}

      {children}
    </Card>
  );
}
