import type { StorageKeyValue } from './types';
import { ppAppStorageVersion, StorageKey } from './constants';

function storageResetData(): void {
  try {
    localStorage.clear();
    localStorage.setItem(StorageKey.storageVersion, ppAppStorageVersion);
  } catch {
    return;
  }
}

function storageCheckVersion() {
  try {
    const version = localStorage.getItem(StorageKey.storageVersion);
    if (version !== ppAppStorageVersion) storageResetData();
  } catch {
    return;
  }
}

export function storageReadData(): StorageKeyValue {
  try {
    storageCheckVersion();

    return {
      loggedInRedirect: localStorage.getItem(StorageKey.loggedInRedirect),
    };
  } catch {
    return {};
  }
}

const ignoredKeys = new Set<StorageKey>([StorageKey.storageVersion]);

export function storageWriteData(storageData: StorageKeyValue = {}): boolean {
  try {
    storageCheckVersion();

    Object.keys(storageData).forEach((key) => {
      const assertedKey = key as keyof StorageKeyValue;
      const storageKey = StorageKey[assertedKey];
      const value = storageData[assertedKey];

      if (ignoredKeys.has(storageKey)) return;

      if (value == null) {
        localStorage.removeItem(storageKey);
      } else {
        localStorage.setItem(storageKey, value);
      }
    });

    return true;
  } catch {
    return false;
  }
}
