import { useQuery } from 'react-query';
import { useCallback, useRef, useState } from 'react';
import { useCheckSignApiClient } from 'modules/Api/hooks';
import type { LoadStates } from '@sp-api/check-sign-api';
import type { QueryKey } from 'react-query';
import DocumentsStateKeysEnum from '../constants/DocumentsStateKeysEnum';

type PackageStatusQueryKeyOptions = {
  packageId: EntityId;
  ownerClientId: EntityId;
};

export function getPackageStatusQueryKey({
  ownerClientId,
  packageId,
}: PackageStatusQueryKeyOptions): QueryKey {
  return [
    DocumentsStateKeysEnum.Status,
    {
      clientId: ownerClientId,
      packageId,
    },
  ];
}

const DELAY_ERROR = 1000;
const DELAY_OK = 3000;
const MAX_REQUESTS_COUNT = 5;

export type UsePackageStatusReturn = {
  onStartPolling: () => void;
};

type PackageStatusOptions = PackageStatusQueryKeyOptions & {
  onEndPolling: (data: LoadStates, maxRequestsReached?: boolean) => void;
};

type PollingData = {
  timesRequested: number;
};

export default function usePackageDocsStatus(
  options: PackageStatusOptions
): UsePackageStatusReturn {
  const pollingData = useRef<PollingData>({
    timesRequested: 0,
  });
  const [isPolling, setIsPolling] = useState(false);
  const checkSignApi = useCheckSignApiClient();
  const queryKey = getPackageStatusQueryKey(options);

  const handleStopPolling = useCallback(
    (data: LoadStates, maxRequestsReached: boolean) => {
      setIsPolling(false);
      options.onEndPolling(data, maxRequestsReached);
    },
    [options]
  );

  useQuery(
    queryKey,
    async (): Promise<LoadStates | undefined> => {
      pollingData.current.timesRequested++;
      let data: LoadStates;

      try {
        ({ data } = await checkSignApi.getPackageFilesUploadingStatus(
          options.packageId
        ));
      } catch (e) {
        if (pollingData.current.timesRequested === MAX_REQUESTS_COUNT) {
          handleStopPolling({}, true);
        }

        return undefined;
      }

      if (pollingData.current.timesRequested === MAX_REQUESTS_COUNT) {
        handleStopPolling(data, true);
      } else if (data.loadStates?.length === 0) {
        handleStopPolling(data, false);
      } else {
        const needPolling = !!data.loadStates?.find((x) => !x.state);
        if (!needPolling) handleStopPolling(data, false);
      }

      return data;
    },
    {
      enabled: isPolling,
      refetchInterval(data) {
        if (!isPolling) return false;
        if (!data) return DELAY_ERROR;
        return DELAY_OK;
      },
    }
  );

  const handleStartPolling = useCallback(() => {
    if (isPolling) return;

    setIsPolling(true);
    pollingData.current.timesRequested = 0;
  }, [isPolling]);

  return {
    onStartPolling: handleStartPolling,
  };
}
