import { Chips } from '@vtb/ui-kit3';
import styled, { css } from 'styled-components';
import { media } from 'modules/StyledUtility';
import flex from 'modules/StyledUtility/Flex';

const signItemMargin = 8;

export const SigningsContainer = styled.div`
  margin-top: ${24 - signItemMargin}px;
  margin-left: -${signItemMargin}px;
  margin-right: -${signItemMargin}px;

  ${media.mobileXS(css`
    ${flex.flowCol};
  `)}
`;

export const StyledSigning = styled(Chips).attrs({
  'data-testid': 'counterparties-signing',
})`
  margin-left: ${signItemMargin}px;
  margin-right: ${signItemMargin}px;
  margin-top: ${signItemMargin}px;
  max-width: 450px;

  ${media.mobileXS(css`
    max-width: unset;
  `)}

  > div:first-child {
    flex: 1 1 auto;

    > div:first-child {
      flex: 1 1 auto;
    }
  }
`;
