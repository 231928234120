import styled, { css } from 'styled-components';
import { media } from 'modules/StyledUtility';
import Header from '@signplatform-ui/header';
import { typography } from '@signplatform-ui/typographics';
import { Col } from 'styled-bootstrap-grid';
import backgroundSvg from './images/background.svg';
import mobileBackgroundSvg from './images/background_mobile.svg';
import { ReactComponent as LogoImg } from './images/logo.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  &:before {
    background: left top / cover #030412 no-repeat url(${backgroundSvg});
    content: '';
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;

    ${media.mobile(css`
      background: left top / cover #030412 no-repeat url(${mobileBackgroundSvg});
    `)}
  }

  &:after {
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.5) 4.73%,
      rgba(0, 0, 0, 0) 98.44%
    );
    content: '';
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: #fff;
  overflow-y: auto;
`;

export const MainContent = styled.div<{ contentCentered?: boolean }>`
  display: flex;
  align-items: ${({ contentCentered }) =>
    contentCentered ? 'center' : 'stretch'};
  flex-grow: 1;

  ${media.mobile(css`
    align-items: stretch;
  `)}
`;

export const StyledHeader = styled(Header)`
  color: ${({ theme }) => theme.color.text.staticWhite};
  margin-bottom: 44px;
  font-size: 48px;
  line-height: 56px;

  ${media.mobile(css`
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 32px;
  `)}
`;

export const StyledTextInfo = styled.div`
  ${typography['Additional/L']}
  color: ${({ theme }) => theme.color.text.staticWhite};
  margin-top: 44px;
  margin-bottom: 24px;
  max-width: 400px;

  ${media.mobile(css`
    margin-top: 32px;
  `)}
`;

export const LogoCol = styled(Col)`
  ${media.mobileXS(css`
    padding-top: 68px;
  `)}
`;

export const StyledLogoImg = styled(LogoImg)`
  ${media.mobile(css`
    width: 173px;
  `)}
`;
