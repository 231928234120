import Spinner, { SpinnerDimensionsEnum } from '@signplatform-ui/spinner';
import { useEffect } from 'react';
import { SpinnerWrapper } from './PageLoading.styled';

type PageLoadingProps = {
  isGlobal?: boolean;
};

const PageLoading = ({ isGlobal }: PageLoadingProps): JSX.Element => {
  useEffect(() => {
    if (!isGlobal) return undefined;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SpinnerWrapper $isGlobal={isGlobal}>
      <Spinner dimension={SpinnerDimensionsEnum.L} />
    </SpinnerWrapper>
  );
};

export default PageLoading;
