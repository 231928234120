import { getCounterpartyData } from 'modules/Documents/components/PackageWizard/utils';
import React from 'react';
import type { SigningsFormValues } from 'modules/Documents/components/PackageWizard/types';
import { SigningsContainer, StyledSigning } from './DocumentSignings.styled';

type DocumentSigningsProps = {
  onChange: (value: SigningsFormValues) => void;
  value: SigningsFormValues;
  clientIdOwner: EntityId;
};

// если на входе ключ типа, то результат = элемент массива этого типа, иначе never
type ToRemoveValue<T extends keyof SigningsFormValues> =
  SigningsFormValues[T] extends Array<infer R> ? R : never;

const DocumentSignings = ({
  value,
  onChange,
  clientIdOwner,
}: DocumentSigningsProps): JSX.Element => {
  const removeItem = <T extends keyof SigningsFormValues>(
    type: T,
    toRemoveValue: ToRemoveValue<T>
  ) => {
    const newValue = { ...value } as ShallowMutable<typeof value>;

    // filtering by object reference
    // eslint-disable-next-line @typescript-eslint/ban-types
    newValue[type] = (newValue[type] as object[]).filter(
      (x) => x !== toRemoveValue
    ) as SigningsFormValues[T];

    onChange(newValue);
  };

  return (
    <SigningsContainer>
      {value.clientOrgs.map((signer) => {
        const data = getCounterpartyData(signer);
        if (signer.clientId === clientIdOwner) return null;

        return (
          <StyledSigning
            appearance='filled'
            key={data.perSiblingId}
            onClose={() => removeItem('clientOrgs', signer)}
          >
            {data.caption}
          </StyledSigning>
        );
      })}

      {value.clientPersons.map((signer) => {
        const data = getCounterpartyData(signer);
        if (signer.clientId === clientIdOwner) return null;

        return (
          <StyledSigning
            appearance='filled'
            key={data.perSiblingId}
            onClose={() => removeItem('clientPersons', signer)}
          >
            {data.caption}
          </StyledSigning>
        );
      })}

      {value.counterparties.map((counterparty) => {
        const data = getCounterpartyData(counterparty);

        return (
          <StyledSigning
            appearance='outlined'
            key={data.perSiblingId}
            onClose={() => removeItem('counterparties', counterparty)}
          >
            {data.caption}
          </StyledSigning>
        );
      })}

      {value.persons.map((person) => {
        const data = getCounterpartyData(person);

        return (
          <StyledSigning
            appearance='outlined'
            key={data.perSiblingId}
            onClose={() => removeItem('persons', person)}
          >
            {data.caption}
          </StyledSigning>
        );
      })}
    </SigningsContainer>
  );
};

export default DocumentSignings;
