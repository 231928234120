import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import { TextTypesEnum } from '@signplatform-ui/text';
import { StyledText } from 'modules/Documents/components/SignButton/SignButton.styled';
import Modal, {
  ModalButtonPanel,
  ModalContent,
  ModalTitle,
} from 'modules/UI/components/Modal';
import {
  StyledDescription,
  StyledDescriptionContainer,
} from './CertificateNotFoundErrorModal.styled';

export type PackageSigningErrorModalProps = {
  onClose?: () => Promise<void> | void;
};

const GetUKEPErrorModal = ({
  onClose,
}: PackageSigningErrorModalProps): JSX.Element => {
  return (
    <Modal onClose={onClose}>
      <ModalTitle>Выпустите УКЭП в Госключе</ModalTitle>
      <ModalContent>
        <StyledDescriptionContainer>
          <StyledDescription>
            <StyledText type={TextTypesEnum.SBold}>
              Вам направлен запрос на выпуск УКЭП в Госключ. Убедитесь, что
              процедура выпуска УКЭП успешно завершена. После этого документы на
              подписание УКЭП придут автоматически
              <br />
              <br />
              Если на ваше устройство не пришёл запрос выпуска УКЭП, обратитесь
              в поддержку.
            </StyledText>
          </StyledDescription>
        </StyledDescriptionContainer>
      </ModalContent>

      <ModalButtonPanel>
        <Button
          size={ButtonSizesEnum.Medium}
          color={ButtonColorsEnum.Primary}
          onClick={onClose}
        >
          Закрыть
        </Button>
      </ModalButtonPanel>
    </Modal>
  );
};

export default GetUKEPErrorModal;
