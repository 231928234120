import { makeEnumerablePrototype } from 'modules/Common/services';
import type { ClientPerson } from '@sp-api/counterparties-api';
import type {
  CounterpartyClientPerson,
  CounterpartyPersonBase,
  SignerSignStatusUni,
} from 'modules/Documents/types';
import SignMixinEmpty from './SignMixinEmpty';

type ISigningAdapterSearchPerson = CounterpartyClientPerson &
  PartialToUndefined<Omit<CounterpartyPersonBase, 'participantId'>>;

// TODO remove excessive getters due to properties
@makeEnumerablePrototype
export default class SigningAdapterSearchPerson
  extends SignMixinEmpty<SignerSignStatusUni>
  implements ISigningAdapterSearchPerson
{
  constructor(private _data: ClientPerson) {
    super();
  }

  get snils(): string | undefined {
    return this._data.snils;
  }

  get lastName(): string | undefined {
    return this._data.lastName;
  }

  get firstName(): string | undefined {
    return this._data.firstName;
  }

  get middleName(): string | undefined {
    return this._data.middleName;
  }

  get inn(): string | undefined {
    return this._data.inn;
  }

  get idNum(): string | undefined {
    if (!this._data.dulNumber) return undefined;
    return `${this._data.dulSeries || ''} ${this._data.dulNumber}`.trim();
  }

  get email(): string | undefined {
    return this._data.email;
  }

  get phoneNumber(): string | undefined {
    return this._data.phoneNumber;
  }

  get inviteId(): EntityId | undefined {
    return undefined;
  }

  get clientId(): EntityId {
    return this._data.clientId;
  }

  get __fePerson(): boolean {
    return true;
  }
}
