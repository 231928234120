import type {
  FoundOrInvitedCounterparty,
  CounterpartyClient,
} from 'modules/Documents/components/PackageWizard/types';
import { assertNotUsedIdSigner } from './types';

export default function isCounterpartyClient(
  counterpartyData: DeepROStrict<FoundOrInvitedCounterparty>
): counterpartyData is CounterpartyClient {
  assertNotUsedIdSigner(counterpartyData);

  return !!counterpartyData.clientId;
}
