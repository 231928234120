import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import Text from '@signplatform-ui/text';
import Modal, {
  ModalButtonPanel,
  ModalTitle,
} from 'modules/UI/components/Modal';
import { usePackageContext } from 'modules/Documents/contexts';
import { MAX_LENGTH, MIN_LENGTH } from 'modules/Documents/constants/document';
import { useCallback, useState } from 'react';
import type { ChangeEvent } from 'react';
import { StyledInput } from './RenamePackageModal.styled';

type RenamePackageModalProps = {
  onClose: () => void;
};

const RenamePackageModalUI = ({
  onClose,
}: RenamePackageModalProps): JSX.Element => {
  const { packageName, onChangePackageName } = usePackageContext();

  const [name, setName] = useState(packageName);

  const changeDocumentTitle = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setName(value);
    },
    []
  );

  const onRename = useCallback(async () => {
    onChangePackageName(name);
    onClose();
  }, [name, onChangePackageName, onClose]);

  const titleIsValid = name.length >= MIN_LENGTH && name.length <= MAX_LENGTH;

  return (
    <Modal onClose={onClose}>
      <div>
        <ModalTitle>Переименование комплекта документов</ModalTitle>
        <Text>
          Укажите новое название, которое хотите присвоить комплекту документов
        </Text>
        <StyledInput
          value={name}
          onChange={changeDocumentTitle}
          label='Название'
          status={!titleIsValid ? 'error' : undefined}
          extraText={
            !titleIsValid
              ? `Длина заголовка пакета должна быть от ${MIN_LENGTH} до ${MAX_LENGTH} символов`
              : undefined
          }
        />
      </div>
      <ModalButtonPanel>
        <Button
          onClick={onRename}
          disabled={!titleIsValid}
          size={ButtonSizesEnum.Medium}
        >
          Переименовать
        </Button>
        <Button
          onClick={onClose}
          color={ButtonColorsEnum.Secondary}
          size={ButtonSizesEnum.Medium}
        >
          Отменить
        </Button>
      </ModalButtonPanel>
    </Modal>
  );
};

export default RenamePackageModalUI;
