import type { FoundOrInvitedCounterparty } from 'modules/Documents/components/PackageWizard/types';
import type { CpTypeTrap } from './types';
import isCounterpartyOrg from './isCounterpartyOrg';
import isCounterpartyPerson from './isCounterpartyPerson';

// функция находит идентификатор сущности среди ее сиблингов
// если clientId нет, пытается найти по другим данным
export default function getCounterpartyIdentifier<
  T extends DeepROStrict<FoundOrInvitedCounterparty>
>(data: CpTypeTrap<T>): string {
  if (data.clientId) return String(data.clientId);
  if (data.email) return `__email:${data.email}`;

  if (isCounterpartyOrg(data)) {
    if (data.name) return `__name:${data.name}`;
    if (data.ogrn) return `__orgn:${data.ogrn}`;
    if (data.organizationInn) return `__orgInn:${data.organizationInn}`;
    if (data.organizationKio) return `__orgKio:${data.organizationKio}`;
    if (data.organizationKpp) return `__orgKpp:${data.organizationKpp}`;
  }

  if (isCounterpartyPerson(data)) {
    if (data.snils) return `__snils:${data.snils}`;
    if (data.idNum) return `__dul:${data.idNum}`;
  }

  return '__errorCantGetId';
}
