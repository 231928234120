import { typography } from '@signplatform-ui/typographics';
import flex from 'modules/StyledUtility/Flex';
import styled from 'styled-components';
import QrCodeGoskey from '../QrCodeGoskey';

export const StyledQR = styled(QrCodeGoskey)`
  margin-right: 20px;
`;

export const StyledDescription = styled.div`
  ${typography['Additional/S']}
  padding: 0 16px;
`;

export const StyledDescriptionContainer = styled.div`
  ${flex.start}
`;
