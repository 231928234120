import styled, { css } from 'styled-components';
import SigningTimer from 'modules/Documents/components/SigningTimer';
import { media } from 'modules/StyledUtility';

export const StyledContainer = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.color.background.secondary};
  color: ${({ theme }) => theme.color.text.secondary};
  display: inline-flex;
  align-items: center;
  padding: 8px 12px 8px 8px;

  ${media.mobile(css`
    flex-direction: column;
    width: 100%;
  `)}

  svg {
    flex-shrink: 0;
    margin-right: 12px;
  }
`;

export const StyledCaption = styled.span`
  margin-right: 5px;
  font-size: 14px;
  line-height: 20px;
`;

export const StyledTimer = styled(SigningTimer)`
  font-weight: 500;
`;
