import getDiff from './getDiff';

const TIMER_TICK = 60 * 1000;

/**
 * Return timer updating interval depends on due date
 * @param dueDate
 */
const getInterval = (dueDate: string): number => {
  const diff = getDiff(dueDate);
  const { days = 0, hours = 0, minutes = 0, seconds = 0 } = diff;

  if (days > 0 || hours > 0 || minutes >= 2) {
    return TIMER_TICK;
  }

  if (minutes > 0 && seconds > 0) {
    return diff.toMillis() - 60 * 1000;
  }

  return 1000;
};

export default getInterval;
