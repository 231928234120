const generateGuid = (
  pattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
): string => {
  let d = new Date().getTime();
  return pattern.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
  });
};

export default generateGuid;
