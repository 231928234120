import { HeaderSizesEnum } from '@signplatform-ui/header';
import { TextTypesEnum } from '@signplatform-ui/text';
import useWatchStickyHeader from 'modules/Documents/hooks/useWatchStickyHeader';
import {
  Breadcrumb,
  StyledStickyContainer,
} from 'modules/Layouts/DefaultLayout';
import { useTheme } from 'styled-components';
import AppRoutesPaths from 'modules/Router/routes/paths';
import type { PackageFullData } from 'modules/Documents/types';
import PackageWizardActions from '../PackageWizardActions';
import {
  ButtonsWrapper,
  DocumentHeader,
  StyledUniqueNumber,
} from './PackageWizardHeader.styled';
import isPackageNameIdLike from './utils/isPackageNameIdLike';

export type PackageWizardHeaderProps = {
  ownerClientId: EntityId;
  name: string;
  packageId: EntityId;
  buttons: PackageFullData['buttons'];
  onPublish: () => Promise<void>;
  isPublishDisabled: boolean;
  uniqueNumber: string | undefined;
};

const PackageWizardHeader = ({
  name,
  packageId,
  ownerClientId,
  buttons,
  onPublish,
  isPublishDisabled,
  uniqueNumber,
}: PackageWizardHeaderProps): JSX.Element | null => {
  const { color } = useTheme();
  const { stickyRef, buttonsRef } = useWatchStickyHeader();

  return (
    <>
      <StyledStickyContainer ref={stickyRef}>
        <Breadcrumb
          toFallback={AppRoutesPaths.DocumentsList}
          toOverride={AppRoutesPaths.DocumentsList}
        />

        <DocumentHeader type={HeaderSizesEnum.H5} data-testid='document-header'>
          {isPackageNameIdLike(name) ? `Комплект документов №${name}` : name}
        </DocumentHeader>
      </StyledStickyContainer>

      {uniqueNumber && (
        <StyledUniqueNumber type={TextTypesEnum.S} color={color.text.secondary}>
          {'ID: '}
          {uniqueNumber}-0
        </StyledUniqueNumber>
      )}

      <ButtonsWrapper ref={buttonsRef}>
        <PackageWizardActions
          packageId={packageId}
          title={name}
          ownerClientId={ownerClientId}
          buttons={buttons}
          onPublish={onPublish}
          isPublishDisabled={isPublishDisabled}
        />
      </ButtonsWrapper>
    </>
  );
};

export default PackageWizardHeader;
