import { useLoadDocumentApiClient } from 'modules/Api';
import { useMutation } from 'react-query';
import { getFilenameFromContentDisposition } from 'services/fileUtils';
import type { UseMutationResult } from 'react-query';
import type { ErrorResponse } from 'modules/App/types';
import type { AxiosResponse } from 'axios';

type DocumentDownloadParams = {
  packageId: EntityId;
  clientOwnerId: EntityId;
  documentId: EntityId;
};

type DocumentResponseHeaders = {
  'content-disposition': string;
};

export default function useDownloadDocument(): UseMutationResult<
  { file: Blob; fileName: string },
  ErrorResponse,
  DocumentDownloadParams
> {
  const loadDocumentApi = useLoadDocumentApiClient();

  return useMutation<
    { file: Blob; fileName: string },
    ErrorResponse,
    DocumentDownloadParams
  >(async ({ clientOwnerId, documentId, packageId }) => {
    const { data, headers } = (await loadDocumentApi.getDocumentById(
      packageId,
      documentId,
      clientOwnerId,
      {
        responseType: 'blob',
      }
    )) as unknown as AxiosResponse<Blob>;

    return {
      file: data,
      fileName: getFilenameFromContentDisposition(
        (headers as DocumentResponseHeaders)['content-disposition']
      ),
    };
  });
}
