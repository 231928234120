export default function pluralize(
  number: number,
  titles: [string, string, string]
): string {
  const normalizedNumber = Math.trunc(number);
  const titleValues = [2, 0, 1, 1, 1, 2];
  const titleNumber =
    normalizedNumber % 100 > 4 && normalizedNumber % 100 < 20
      ? 2
      : titleValues[Math.min(normalizedNumber % 10, 5)];
  return titles[titleNumber];
}
