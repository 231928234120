import { FontSizesEnum } from '@signplatform-ui/typographics';
import styled from 'styled-components';

export const NotFoundTitle = styled.div`
  font-size: ${FontSizesEnum.AdditionalS};
  font-weight: 500;
  color: ${({ theme }) => theme.color.text.primary};
  margin-bottom: 4px;
`;

export const NotFoundCaption = styled.div`
  font-size: ${FontSizesEnum.AdditionalS};
`;
