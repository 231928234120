import styled from 'styled-components';
import Button from '@signplatform-ui/button';

const TOOLTIP_PADDING = '4px 8px';
const TOOLTIP_BORDER_RADIUS = '4px';

export const StyledTooltipContent = styled.div`
  display: none;
  word-break: break-word;
  overflow-wrap: break-word;
  width: 206px;
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 8px;
  background: ${({ theme }) => theme.color.background.inversion};
  color: ${({ theme }) => theme.color.text.staticWhite};
  z-index: ${({ theme }) => theme.zIndex.tooltip};
  border-radius: ${TOOLTIP_BORDER_RADIUS};
  ${(props) => props.theme.shadow.ClickableDefault}
  padding: ${TOOLTIP_PADDING};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  background-color: ${({ theme }) => theme.color.text.primary};
  color: ${({ theme }) => theme.color.text.staticWhite};
  white-space: normal;
  text-align: left;
`;

export const StyledButton = styled(Button)`
  position: relative;

  &:hover {
    ${StyledTooltipContent} {
      display: block;
    }
  }
`;
