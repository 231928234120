import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import { TextTypesEnum } from '@signplatform-ui/text';
import { StyledText } from 'modules/Documents/components/SignButton/SignButton.styled';
import Modal, {
  ModalButtonPanel,
  ModalContent,
  ModalTitle,
} from 'modules/UI/components/Modal';
import {
  StyledDescription,
  StyledDescriptionContainer,
  StyledQR,
} from './CertificateNotFoundErrorModal.styled';

export type PackageSigningErrorModalProps = {
  onClose?: () => Promise<void> | void;
};

const CertificateNotFoundErrorModal = ({
  onClose,
}: PackageSigningErrorModalProps): JSX.Element => {
  return (
    <Modal onClose={onClose}>
      <ModalTitle>Сертификат УКЭП не найден в Госключе</ModalTitle>

      <ModalContent>
        <StyledDescriptionContainer>
          <StyledQR />
          <StyledDescription>
            <StyledText type={TextTypesEnum.SBold}>
              Убедитесь, что у вас установлено мобильное приложение Госключ и
              обратитесь в поддержку для выпуска УКЭП
            </StyledText>
          </StyledDescription>
        </StyledDescriptionContainer>
      </ModalContent>

      <ModalButtonPanel>
        <Button
          size={ButtonSizesEnum.Medium}
          color={ButtonColorsEnum.Primary}
          onClick={onClose}
        >
          Закрыть
        </Button>
      </ModalButtonPanel>
    </Modal>
  );
};

export default CertificateNotFoundErrorModal;
