import Text, { TextTypesEnum } from '@signplatform-ui/text';
import { StatusUnitEnum } from '@sp-api/documents-api';
import { DATE_TIME_FORMAT } from 'modules/Documents/constants';
import { isAllowedStatusChangeDate } from 'modules/Documents/utils';
import { formatLocalDate } from 'services/dateUtils';
import { useTheme } from 'styled-components';

export type DateMap = {
  createDate?: string;
  sendDate?: string;
  stateUpdateDate?: string;
  signDueDate?: string;
};

type DateSelector = (dateMap: DateMap) => string | undefined;

const hiddenStatuses = new Set<StatusUnitEnum>([
  StatusUnitEnum.RejectedByOwner,
  StatusUnitEnum.Expired,
]);

const changeStatusTextMap: Record<StatusUnitEnum, string> = {
  [StatusUnitEnum.Draft]: 'Дата создания',
  [StatusUnitEnum.Waiting]: 'Срок подписания',
  [StatusUnitEnum.Error]: 'Дата ошибки',
  [StatusUnitEnum.Rejected]: 'Дата отклонения',
  [StatusUnitEnum.Signed]: 'Дата подписания',
  [StatusUnitEnum.RejectedByOwner]: 'Дата отзыва',
  [StatusUnitEnum.Expired]: 'Срок подписания',
};

const dateSelectorMap: Record<StatusUnitEnum, DateSelector> = {
  [StatusUnitEnum.Draft]: (dateMap) => dateMap.createDate,
  [StatusUnitEnum.Waiting]: (dateMap) => dateMap.signDueDate,
  [StatusUnitEnum.Error]: (dateMap) => dateMap.stateUpdateDate,
  [StatusUnitEnum.Rejected]: (dateMap) => dateMap.stateUpdateDate,
  [StatusUnitEnum.Signed]: (dateMap) => dateMap.stateUpdateDate,
  [StatusUnitEnum.RejectedByOwner]: (dateMap) => dateMap.stateUpdateDate,
  [StatusUnitEnum.Expired]: (dateMap) => dateMap.signDueDate,
};

export type ChangeStatusDateProps = {
  dateMap: DateMap;
  status: StatusUnitEnum;
  className?: string;
};

const ChangeStatusDate = ({
  dateMap,
  status,
  className,
}: ChangeStatusDateProps): JSX.Element | null => {
  const theme = useTheme();

  if (!isAllowedStatusChangeDate<StatusUnitEnum>(status, hiddenStatuses)) {
    return null;
  }

  const statusText = changeStatusTextMap[status];
  const date = dateSelectorMap[status](dateMap);

  return (
    <Text
      type={TextTypesEnum.S}
      color={theme.color.text.secondary}
      className={className}
    >
      {statusText}: {formatLocalDate(date, DATE_TIME_FORMAT, '(Мск)')}
    </Text>
  );
};

export default ChangeStatusDate;
