import { useMutation } from 'react-query';
import type { UseMutationResult } from 'react-query';
import type { PackageFullData, DocumentFullData } from '@sp-api/documents-api';
import type { ErrorResponse } from 'modules/App/types';
import type { FileRequest } from '@sp-api/load-document-api';
import useUploadDocument from './useUploadDocument';

export interface MinimalGetFilesContentData {
  packageData: PackageFullData;
}

export type GetFilesContentCallback<T extends MinimalGetFilesContentData> =
  GenericCallback<[T], Promise<FileRequest[]>>;

// generic hook to use different file content receiving mechanism provided by generic callback
// expected cb to use function getPreparedFilesToUploadByMetadata, but not required
export default function useUploadFilesByPackageData<
  T extends MinimalGetFilesContentData
>(
  getFilesContent: GetFilesContentCallback<T>
): UseMutationResult<PackageFullData, ErrorResponse, T> {
  const { mutateAsync: uploadDocumentMutateAsync } = useUploadDocument();

  return useMutation<PackageFullData, ErrorResponse, T>(async (args) => {
    const fileContents = await getFilesContent(args);
    if (!fileContents.length) return args.packageData;

    await uploadDocumentMutateAsync({
      packageId: args.packageData.packageId,
      files: fileContents,
    });

    // TODO BE fix mutation to return new metadata
    return args.packageData;
  });
}

export type FindFileContentResult =
  | FileFileContentData
  | 'dontUpload'
  | 'notFound';

export type FileFileContentData = {
  content: Promise<string>;
  extensionWithDot: string;
};

export type FindClientFileContextByFile = (
  data: DocumentFullData
) => FindFileContentResult;

export function getPreparedFilesToUploadByMetadata(
  metadata: PackageFullData,
  findClientFileContentByFile: FindClientFileContextByFile
): Promise<FileRequest[]> {
  const promisesArr = metadata.documentFullData
    // we need documentId because else we will upload file and couldn't link it with document
    .filter((docFileData) => docFileData.documentId && docFileData.fileUUID)
    .map(async (singleDocumentData) => {
      const { name, documentId } = singleDocumentData;

      const foundClientFileData =
        findClientFileContentByFile(singleDocumentData);

      if (foundClientFileData === 'notFound') {
        return Promise.reject(new Error('File not found'));
      }

      if (foundClientFileData === 'dontUpload') {
        return undefined;
      }

      const content = await foundClientFileData.content;
      const fileNameWithExt = foundClientFileData.extensionWithDot
        ? `${name}${foundClientFileData.extensionWithDot}`
        : name;

      return {
        documentId: documentId!,
        fileName: fileNameWithExt,
        content,
      };
    })
    .filter(Boolean) as Promise<FileRequest & { documentId: number }>[];

  if (!promisesArr.length) return Promise.resolve([]);

  return Promise.all(promisesArr);
}
