import { Configuration, SpLogicReferencesApi } from '@sp-api/references-api';
import { apiService } from 'modules/Api/components/ApiProvider/services';
import { useConfiguration } from 'modules/Shared/hooks';

const useApi = apiService.createUseApi(SpLogicReferencesApi, Configuration);

const useReferencesApi = (): SpLogicReferencesApi => {
  const [{ referencesContextUrl }] = useConfiguration();
  return useApi(referencesContextUrl);
};

export default useReferencesApi;
