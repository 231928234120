import { useForm } from 'react-hook-form';
import formSchema, {
  MAX_SIGNING_DAY_PERIOD,
} from 'modules/Documents/components/PackageWizard/formSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { ClientServerFile } from 'modules/UI/components/DocumentsPackEditor';
import { formatLocalDate, TARGET_TIME_ZONE } from 'services/dateUtils';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from 'modules/PowAttorney/constants/PowAttorneyEnum';
import { debounce } from 'lodash';
import type { PackageWizardFormValues } from 'modules/Documents/components/PackageWizard/ownTypes';
import type { UseFormReturn } from 'react-hook-form';
import type { IPackageAdapter } from 'modules/Documents/classes';

export type UsePackageWizardFormInput = {
  packageAdapted: IPackageAdapter;
};

export type UsePackageWizardFormOutput = {
  formData: UseFormReturn<PackageWizardFormValues>;
  isDirty: boolean;
};

export default function usePackageWizardForm({
  packageAdapted,
}: UsePackageWizardFormInput): UsePackageWizardFormOutput {
  const formData = useForm<PackageWizardFormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      clientPersons: [],
      clientOrgs: [],
      counterparties: [],
      docs: [],
      iAmNotSigner: false,
      iAmOnlySigner: false,
      persons: [],
      signDueDate: {
        date: DateTime.now()
          .setZone(TARGET_TIME_ZONE)
          .plus({ day: MAX_SIGNING_DAY_PERIOD })
          .toFormat(DATE_FORMAT),
        time: '23:59:59',
      },
    },
  });

  const { formState, reset, setValue, watch, trigger } = formData;

  const formValues = watch();
  const { docs: clientDocs } = formValues;
  const isDirty = !!Object.values(formState.dirtyFields).filter(Boolean).length;

  useEffect(() => {
    const { clientPersons, clientOrgs, counterparties, persons } =
      packageAdapted.signings;

    const { docs } = packageAdapted.docsServerAdapted;
    const { signDueDate, isSingleSigner, isSigner } = packageAdapted.source;

    // only update server files if form is dirty
    if (isDirty) {
      setValue('docs', ClientServerFile.updateServerFiles(clientDocs, docs));
      return;
    }

    const localTime = formatLocalDate(signDueDate, 'HH:mm:ss');

    const signDueDateToForm = packageAdapted.source.signDueDate
      ? {
          date: formatLocalDate(signDueDate, DATE_FORMAT),
          time:
            localTime === '23:59:59'
              ? localTime
              : localTime.replace(/:\d{2}:\d{2}/, ':00:00'), // set minute and seconds to 00:00
        }
      : formValues.signDueDate;

    reset(
      {
        clientOrgs,
        clientPersons,
        counterparties,
        docs,
        iAmNotSigner: isSingleSigner ? false : !isSigner,
        iAmOnlySigner: isSingleSigner,
        persons,
        signDueDate: signDueDateToForm,
      },
      {
        keepDefaultValues: false,
        keepDirty: false,
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageAdapted]);

  useEffect(() => {
    const callback = () => {
      if (formState.isSubmitted) void trigger();
    };
    const subscription = watch(debounce(callback, 0));

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageAdapted, formState]);

  return {
    formData,
    isDirty,
  };
}
