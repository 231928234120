import { useState, useCallback } from 'react';
import type { IPackageDocsContext } from 'modules/Documents/contexts';
import type { IFileInstance } from 'modules/UI/components/DocumentsPackEditor/types';

type DocsUINameType = IPackageDocsContext['docsUINameById'];
type FileInstanceData = Pick<
  IFileInstance,
  'clientServerId' | 'nameWithoutExtension'
>;

export type UseDocsUINameInput = {
  onGetDocs: () => FileInstanceData[];
  onDirtyDocs: () => void;
};

export type UseDocsUINameReturn = {
  onSetDocName: (docId: string, name: string) => void;
  onGetDocName: (fileOrId: EntityId | FileInstanceData | string) => string;
  docsUIName: DocsUINameType;
};

export default function useDocsUIName({
  onGetDocs,
  onDirtyDocs,
}: UseDocsUINameInput): UseDocsUINameReturn {
  const [docsUIName, setDocsUIName] = useState<ShallowMutable<DocsUINameType>>(
    () => ({})
  );

  const handleSetDocName = useCallback(
    (docId: string, name: string) => {
      if (!docId || !name) return;
      setDocsUIName({ ...docsUIName, [docId]: name });
      onDirtyDocs();
    },
    [docsUIName, onDirtyDocs]
  );

  const handleGetDocName = useCallback(
    (fileOrId: EntityId | FileInstanceData | string) => {
      const id = String(
        typeof fileOrId === 'object' ? fileOrId.clientServerId : fileOrId
      );

      const file =
        typeof fileOrId === 'object'
          ? fileOrId
          : onGetDocs().find((x) => String(x.clientServerId) === id);

      if (docsUIName[id]) return docsUIName[id];
      return file?.nameWithoutExtension || '';
    },
    [docsUIName, onGetDocs]
  );

  return {
    docsUIName,
    onGetDocName: handleGetDocName,
    onSetDocName: handleSetDocName,
  };
}
