import { useDocumentApiClient } from 'modules/Api';
import { useMutation } from 'react-query';
import { useInvalidateQuery } from 'modules/Common/hooks';
import type { ErrorResponse } from 'modules/App/types';
import type { QueryKey, UseMutationResult } from 'react-query';

export type RevokeSignPackageParams = {
  packageId: EntityId;
  revokeReason: string | undefined;
};

export default function useRevokeSignPackage(
  invalidateQuery?: QueryKey
): UseMutationResult<void, ErrorResponse, RevokeSignPackageParams> {
  const documentApi = useDocumentApiClient();
  const handleInvalidate = useInvalidateQuery(invalidateQuery);

  return useMutation<void, ErrorResponse, RevokeSignPackageParams>(
    async ({ packageId, revokeReason = '' }) => {
      await documentApi.revokePackage(packageId, {
        revokeReason,
      });
    },
    {
      onSuccess: handleInvalidate,
    }
  );
}
