import { useEffect } from 'react';
import useAppPayload from 'modules/Shared/hooks/useAppPayload';
import type { IPackageAdapter } from 'modules/Documents/classes';
import type { IFileInstance } from 'modules/UI/components/DocumentsPackEditor/types';
import type { PackageCreateLocalStateFiles } from 'modules/Shared/components/AppPayloadProvider';

export type UseAppPayloadFilesInput = {
  packageAdapted: Pick<IPackageAdapter, 'packageId'>;
  setClientDocs: (files: IFileInstance[]) => void;
  onGetDocs: () => IFileInstance[];
};

export default function useAppPayloadFiles({
  packageAdapted,
  onGetDocs,
  setClientDocs,
}: UseAppPayloadFilesInput): PackageCreateLocalStateFiles {
  const { packageDetailsFiles } = useAppPayload();
  const filesData = packageDetailsFiles.value.get(packageAdapted.packageId);

  useEffect(() => {
    if (filesData?.filesWithErrors?.length) {
      setClientDocs([...onGetDocs(), ...filesData.filesWithErrors]);
      packageDetailsFiles.onChange(packageAdapted.packageId, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return filesData || { fileErrors: new Map(), filesWithErrors: [] };
}
