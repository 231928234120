import styled from 'styled-components';
import DropdownButton from '@signplatform-ui/dropdownbutton';

// eslint-disable-next-line import/prefer-default-export
export const StyledDropdownButton = styled(DropdownButton)`
  &,
  button:hover,
  button:focus {
    svg path {
      ${({ theme, disabled }) =>
        disabled ? undefined : `fill: ${theme.color.text.secondary}`};
    }
  }
`;
