import { PageLoading } from 'modules/UI';
import Text, { TextTypesEnum } from '@signplatform-ui/text';
import { documentUserReady } from 'services/documentStatus/documentUserReady';
import NotFoundPlaceholder from 'modules/Shared/components/NotFoundPlaceholder';
import CreatePackage from '../CreatePackage';
import {
  usePackages,
  useUserDocumentStatusDetailsByInterval,
} from '../../hooks';
import PackageListCard from './components/PackageListCard';
import {
  NotificationContent,
  NotificationDescription,
  NotificationStyled,
  StyledContainer,
  TimeSolidStyled,
} from './PackagesList.styled';

const PackagesList = (): JSX.Element => {
  const { isLoading, isError, data } = usePackages();
  const isEmpty = !data?.length;
  const status = useUserDocumentStatusDetailsByInterval(20);
  const isShowUpdateDocumentStatusInfo = status && !documentUserReady(status);

  return (
    <StyledContainer>
      {isLoading && <PageLoading />}

      {isError && <div>Не удалось получить список документов</div>}

      {!isLoading &&
        !isEmpty &&
        data.map((item) => (
          <PackageListCard key={item.packageId} item={item} />
        ))}

      {!isLoading && isShowUpdateDocumentStatusInfo && isEmpty && (
        <NotificationStyled>
          <NotificationContent>
            <TimeSolidStyled />
            <div>
              <Text type={TextTypesEnum.SBold}>Обновление информации</Text>
              <NotificationDescription type={TextTypesEnum.S}>
                Если ранее Вам были направлены документы на подписание, их
                получение может занять некоторое время
              </NotificationDescription>
            </div>
          </NotificationContent>
        </NotificationStyled>
      )}

      {!isLoading && !isError && isEmpty && (
        <NotFoundPlaceholder
          slotCaption={`У вас нет ни одного документа${
            process.env.REACT_APP_ADD_NEW_DOC === 'true'
              ? ', вы можете создать новый'
              : ''
          }`}
          slotContent={
            process.env.REACT_APP_ADD_NEW_DOC === 'true' ? (
              <CreatePackage />
            ) : null
          }
        />
      )}
    </StyledContainer>
  );
};
export default PackagesList;
