import { memo, useCallback, useState } from 'react';
import type { ReactNode } from 'react';
import { ImgPlaceholder, Img, Wrap } from './LazyImg.styled';

type LazyImgProps = {
  srcPlaceholder?: string;
  src?: string;
  srcX2?: string;
  alt: string;
  children?: ReactNode;
  className?: string;
};

const LazyImg = ({
  srcPlaceholder,
  src,
  alt,
  srcX2,
  children,
  className,
}: LazyImgProps) => {
  const [originWasLoaded, setOriginWasLoaded] = useState(false);

  const onLoad = useCallback(() => {
    setOriginWasLoaded(true);
  }, []);

  return (
    <Wrap className={className}>
      <ImgPlaceholder
        src={srcPlaceholder}
        visible={!originWasLoaded}
        data-testid='img-placeholder'
      />
      <picture>
        {children}
        <Img
          data-testid='img'
          onLoad={onLoad}
          src={src}
          srcSet={srcX2 ? `${srcX2} 2x` : ''}
          alt={alt}
          visible={!originWasLoaded}
          loading='lazy'
        />
      </picture>
    </Wrap>
  );
};

export default memo(LazyImg);
