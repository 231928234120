import {
  CloseSolid,
  DocOkSolid,
  ErrorTriangleSolid,
  FolderSolid,
  TimeSolid,
  UndoSolid,
} from '@signplatform-ui/icons';
import Text, { TextTypesEnum } from '@signplatform-ui/text';
import { StatusSigningEnum } from '@sp-api/documents-api';
import statusMap from 'modules/Documents/constants/packageStatusMap';
import { PackageSignStatusEnum } from 'modules/Documents/types';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import type { PackageSignStatusUni } from 'modules/Documents/types';
import type { FunctionComponent, PropsWithChildren } from 'react';
import type { DefaultTheme } from 'styled-components';
import type { StatusUnitEnum } from '@sp-api/documents-api';
import { StatusStyled } from './PackageSignStatus.styled';

const selfSigningStatuses = new Set<StatusSigningEnum>([
  StatusSigningEnum.Waiting,
  StatusSigningEnum.SignProcess,
  StatusSigningEnum.Error,
  StatusSigningEnum.QesIssueProcess,
]);

export type DocumentSignStatusProps = PropsWithChildren<{
  status: StatusUnitEnum;
  hideText?: boolean;
  className?: string;
  userSigningStatus?: StatusSigningEnum;
}>;

const iconByStatus: Record<PackageSignStatusUni, FunctionComponent> = {
  [PackageSignStatusEnum.Waiting]: TimeSolid,
  [PackageSignStatusEnum.Signed]: DocOkSolid,
  [PackageSignStatusEnum.Rejected]: CloseSolid,
  [PackageSignStatusEnum.Error]: ErrorTriangleSolid,
  [PackageSignStatusEnum.Draft]: FolderSolid,
  [PackageSignStatusEnum.RejectedByOwner]: UndoSolid,
  [PackageSignStatusEnum.Expired]: ErrorTriangleSolid,
};

const getColorByStatusMap = (
  theme: DefaultTheme
): Record<PackageSignStatusUni, string> => ({
  [PackageSignStatusEnum.Waiting]: theme.color.special.blue,
  [PackageSignStatusEnum.Signed]: theme.color.status.success, // TODO: color is mismatched with designs!
  [PackageSignStatusEnum.Rejected]: theme.color.status.danger,
  [PackageSignStatusEnum.Error]: theme.color.status.danger,
  [PackageSignStatusEnum.Draft]: theme.color.text.secondary,
  [PackageSignStatusEnum.RejectedByOwner]: theme.color.text.secondary,
  [PackageSignStatusEnum.Expired]: theme.color.status.danger,
});

const PackageSignStatus = ({
  status,
  hideText,
  className,
  children,
  userSigningStatus,
}: DocumentSignStatusProps): JSX.Element => {
  const theme = useTheme();
  const colorMap = useMemo(
    () => getColorByStatusMap(theme) || theme.color.text.tertiary,
    [theme]
  );

  const Icon = iconByStatus[status] || CloseSolid;
  const colorValue = colorMap[status] || colorMap[PackageSignStatusEnum.Draft];
  let statusText = statusMap[status] || status;

  if (
    userSigningStatus &&
    selfSigningStatuses.has(userSigningStatus) &&
    status === PackageSignStatusEnum.Waiting
  ) {
    statusText = `${statusText} у меня`;
  }

  return (
    <StatusStyled
      data-testid='document-status'
      color={colorValue}
      className={className}
      status={status}
    >
      <Icon data-testid='document-status-icon' />

      {!hideText && (
        <Text
          as='span'
          type={TextTypesEnum.Caption}
          data-testid='document-status-text'
          color={theme.color.text.primary}
        >
          {statusText}
        </Text>
      )}

      {children}
    </StatusStyled>
  );
};

export default PackageSignStatus;
