import { useCounterpartiesApiClient } from 'modules/Api';
import { DocumentsStateKeysEnum } from 'modules/Documents/constants';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import type { Dispatch, SetStateAction } from 'react';
import type { CounterpartyRequestField } from 'modules/Documents/types';
import type { ClientSearch } from '@sp-api/counterparties-api';
import type { UseQueryResult } from 'react-query';
import type { ErrorResponse } from 'modules/App/types';

export type CounterpartyRequestParams = Partial<
  Record<CounterpartyRequestField, string>
>;

function useFindUser(
  params: CounterpartyRequestParams
): UseQueryResult<ClientSearch, ErrorResponse> {
  const counterpartiesApiClient = useCounterpartiesApiClient();

  return useQuery<ClientSearch, ErrorResponse>(
    [DocumentsStateKeysEnum.Counterparties, params],
    async () => {
      const { inn, snils, email, idNum, phone } = params;

      const { data } = await counterpartiesApiClient.getCounterpartiesByFilter(
        snils,
        inn,
        idNum,
        email,
        phone
      );

      return data;
    },
    // structuralSharing is off because we want a useEffect to run every search
    // even when response not changed, we can change other form fields not included in the request
    { enabled: false, retry: false, structuralSharing: false }
  );
}

export default function useFindUserAuto(): [
  UseQueryResult<ClientSearch, ErrorResponse>,
  Dispatch<SetStateAction<CounterpartyRequestParams | null>>,
  CounterpartyRequestParams | null
] {
  const [requestParams, doRequestWithParams] =
    useState<CounterpartyRequestParams | null>(null);

  const queryData = useFindUser(requestParams || {});

  useEffect(() => {
    if (requestParams) void queryData.refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams]);

  return [queryData, doRequestWithParams, requestParams];
}
