import {
  StyledValue,
  StyledLabel,
} from 'modules/Documents/components/NameValueInfo';
import { StyledBox } from './FileNameSizeInfo.styled';

type FileInfoProps = {
  name?: string;
  ext?: string;
  size?: string;
  isDisabled?: boolean;
};

const FileNameExtSizeInfo = ({
  name,
  ext,
  size,
  isDisabled,
}: FileInfoProps): JSX.Element | null => {
  const readableExt = (ext || '').replace('.', '').toUpperCase();

  return (
    <StyledBox>
      {name && (
        <StyledValue as='div' $disabled={isDisabled} $ellipsis>
          {name}
        </StyledValue>
      )}

      <StyledLabel
        as='div'
        $disabled={isDisabled}
        $ellipsis
        data-testid='package-document-ext-and-size'
      >
        {readableExt}
        {readableExt && size && '・'}
        {size}
      </StyledLabel>
    </StyledBox>
  );
};
export default FileNameExtSizeInfo;
