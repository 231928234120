import { makeEnumerablePrototype } from 'modules/Common/services';
import type {
  InnerCounterpartyData,
  CounterpartyClientOrg,
  CounterpartyOrg,
  CounterpartyOrgBase,
  SignerSignStatusUni,
} from 'modules/Documents/types';

import SignMixinEmpty from './SignMixinEmpty';

type ISigningAdapterOwnerOrg = CounterpartyClientOrg &
  PartialToUndefined<CounterpartyOrgBase>;

// TODO remove excessive getters due to properties
@makeEnumerablePrototype
export default class SigningAdapterOwnerOrg
  extends SignMixinEmpty<SignerSignStatusUni>
  implements ISigningAdapterOwnerOrg
{
  constructor(private _data: NonNullable<InnerCounterpartyData>) {
    super();
  }

  get name(): string | undefined {
    return this._data.name;
  }

  get ogrn(): string | undefined {
    return this._data.ogrn;
  }

  get organizationInn(): string | undefined {
    return this._data.organizationInn;
  }

  get organizationKpp(): string | undefined {
    return this._data.organizationKpp;
  }

  get organizationKio(): string | undefined {
    return undefined;
  }

  get orgnip(): string | undefined {
    return this._data.ogrnIp;
  }

  get email(): string | undefined {
    return undefined;
  }

  get type(): CounterpartyOrg['type'] | undefined {
    return this._data.type;
  }

  get clientId(): CounterpartyClientOrg['clientId'] {
    return this._data.clientId;
  }

  get inviteId(): EntityId | undefined {
    return undefined;
  }

  get __feOrg(): boolean {
    return true;
  }
}
