import { createContext } from 'react';
import type { SpLogicReferencesApi } from '@sp-api/references-api';
import type { RegistrationApi } from '@signplatform/registration-api';
import type { SpLogicCrudlDocumentApi } from '@sp-api/documents-api';
import type { SpLogicLoadDocumentApi } from '@sp-api/load-document-api';
import type { SpLogicSignGateApi } from '@sp-api/sign-gate-api';
import type { SpUserProfileApi } from '@sp-api/user-api';
import type { SpLogicCounterpartiesApi } from '@sp-api/counterparties-api';
import type { SpLogicCheckSignApi } from '@sp-api/check-sign-api';
import type { PowattorneyServiceApi } from '@pa-api/pa-logic-crudl-document';
import type { LoadPowattorneyServiceApi } from '@pa-api/pa-logic-load-document';
import type { GendocServiceApi } from '@pa-api/pa-gendoc-document';
import type { ReferencesServiceApi as AttorneyReferencesApi } from '@pa-api/pa-logic-references';
import type { ExchangeServiceApi } from '@pa-api/pa-logic-exc-gate';

type ApiContextProps = {
  userApi: SpUserProfileApi;
  authApi: RegistrationApi;
  documentApi: SpLogicCrudlDocumentApi;
  loadDocumentApi: SpLogicLoadDocumentApi;
  signGateApi: SpLogicSignGateApi;
  counterpartiesApi: SpLogicCounterpartiesApi;
  powAttorneyApi: PowattorneyServiceApi;
  powAttorneyLoadDocumentApi: LoadPowattorneyServiceApi;
  genDocApi: GendocServiceApi;
  referencesApi: SpLogicReferencesApi;
  logicReferencesApi: AttorneyReferencesApi;
  excGateApi: ExchangeServiceApi;
  checkSignApi: SpLogicCheckSignApi;
};

const ApiContext = createContext<ApiContextProps>({} as ApiContextProps);

export default ApiContext;
