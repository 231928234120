import MaskedInput from 'modules/UI/components/form/MaskedInput';
import type { MaskedInputProps } from 'modules/UI/components/form/MaskedInput/MaskedInput';
import type { ChangeEventHandler, FocusEventHandler } from 'react';

type PhoneInputProps = {
  name: string;
  label?: string;
  placeholder?: string;
  status?: 'error' | 'success';
  extraText?: string;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  value?: string;
  unmasked?: boolean;
  disabled?: boolean;
};

// https://imask.js.org/guide.html#masked-pattern
const PHONE_FORMAT_MASK = '{+}0 {(}000{)} 000-00-00';
const PHONE_LABEL = 'Телефон';
const PHONE_PLACEHOLDER = 'Введите номер телефона';

const maskOptions: MaskedInputProps['maskOptions'] = {
  mask: PHONE_FORMAT_MASK,
};

const PhoneInput = ({
  name,
  label = PHONE_LABEL,
  placeholder = PHONE_PLACEHOLDER,
  status,
  extraText,
  onChange,
  className,
  value = '',
  ...rest
}: PhoneInputProps): JSX.Element => {
  return (
    <MaskedInput
      className={className}
      displayClearIcon={!rest.disabled && !!value?.length}
      extraText={extraText}
      label={label}
      maskOptions={maskOptions}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      status={status}
      value={value}
      {...rest}
    />
  );
};

export default PhoneInput;
