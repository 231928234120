import useDocumentApi from 'modules/Api/components/ApiProvider/hooks/useDocumentApi';
import { useMutation, useQueryClient } from 'react-query';
import type { UseMutationResult } from 'react-query';
import type { ErrorResponse } from 'modules/App/types';

type PackageRemoveParams = {
  packageId: EntityId;
};

export default function useRemovePackage(
  invalidateQuery?: string
): UseMutationResult<void, ErrorResponse, PackageRemoveParams> {
  const documentApi = useDocumentApi();
  const queryClient = useQueryClient();

  return useMutation<void, ErrorResponse, PackageRemoveParams>(
    async ({ packageId }) => {
      const { data } = await documentApi.deletePackage(packageId);

      return data;
    },
    {
      onSuccess: () =>
        invalidateQuery
          ? queryClient.invalidateQueries(invalidateQuery)
          : undefined,
    }
  );
}
