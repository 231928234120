import { Configuration, GendocServiceApi } from '@pa-api/pa-gendoc-document';
import { apiService } from 'modules/Api/components/ApiProvider/services';
import { useConfiguration } from 'modules/Shared/hooks';

const useApi = apiService.createUseApi(GendocServiceApi, Configuration);

const useGenDocApi = (): GendocServiceApi => {
  const [{ genDocContextUrl }] = useConfiguration();
  return useApi(genDocContextUrl);
};

export default useGenDocApi;
