import { useDocumentApiClient } from 'modules/Api';
import { useMutation } from 'react-query';
import { useInvalidateQuery } from 'modules/Common/hooks';
import type { ErrorResponse } from 'modules/App/types';
import type { QueryKey, UseMutationResult } from 'react-query';

export type RejectSignPackageParams = {
  ownerClientId: EntityId;
  packageId: EntityId;
  signerClientId: EntityId;
  comment: string | undefined;
};

export default function useRejectSignPackage(
  invalidateQuery?: QueryKey
): UseMutationResult<void, ErrorResponse, RejectSignPackageParams> {
  const documentApi = useDocumentApiClient();
  const handleInvalidate = useInvalidateQuery(invalidateQuery);

  return useMutation<void, ErrorResponse, RejectSignPackageParams>(
    async ({ packageId, ownerClientId, signerClientId, comment = '' }) => {
      await documentApi.rejectPackageSigning(
        packageId,
        ownerClientId,
        signerClientId,
        {
          comment,
        }
      );
    },
    {
      onSuccess: handleInvalidate,
    }
  );
}
