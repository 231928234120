import type { FoundOrInvitedCounterparty } from 'modules/Documents/components/PackageWizard/types';
import isCounterpartyOrg from './isCounterpartyOrg';
import isCounterpartyPerson from './isCounterpartyPerson';

const jsonCache: WeakMap<
  DeepROStrict<FoundOrInvitedCounterparty>,
  string
> = new WeakMap();

// функция пытается однозначно отсортировать массив подписантов при любых данных
export default function sortCounterpartiesPersistently<
  T extends DeepROStrict<FoundOrInvitedCounterparty>
>(a: T, b: T): number {
  if (a.clientId && !b.clientId) return 1;
  if (b.clientId && !a.clientId) return -1;

  if (a.email && b.email && a.email !== b.email) {
    return a.email < b.email ? 1 : -1;
  }

  const isAPerson = isCounterpartyPerson(a);
  const isBPerson = isCounterpartyPerson(b);

  if (isAPerson && !isBPerson) return 1;
  if (isBPerson && !isAPerson) return -1;
  if (isAPerson && isBPerson) {
    if (a.snils && b.snils && a.snils !== b.snils) {
      return a.snils < b.snils ? 1 : -1;
    }

    return (a.idNum || '') < (b.idNum || '') ? 1 : -1;
  }

  // оба - не персоны
  const isAORg = isCounterpartyOrg(a);
  const isBOrg = isCounterpartyOrg(b);

  if (isAORg && !isBOrg) return 1;
  if (isBOrg && !isBOrg) return -1;
  if (isAORg && isBOrg) {
    if (a.name && b.name && a.name !== b.name) return a.name < b.name ? 1 : -1;

    return (a.organizationInn || '') < (b.organizationInn || '') ? 1 : -1;
  }

  // оба - не персоны и не орги. Уже делать нечего, кроме json парсинга, тк не знаем что за фрукты
  const aJsonString = jsonCache.get(a) || jsonCache.set(a, JSON.stringify(a));
  const bJsonString = jsonCache.get(b) || jsonCache.set(b, JSON.stringify(b));

  return aJsonString < bJsonString ? 1 : -1;
}
