import InputField from '@signplatform-ui/input-field';
import { media } from 'modules/StyledUtility';
import styled, { css } from 'styled-components';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.mobile(css`
    width: 100%;
  `)}
  button + button {
    margin-left: 8px;

    ${media.mobile(css<{ withoutDropDown?: boolean }>`
      margin-left: 0;
    `)}
  }
`;

export const ModalActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button + button {
    margin-left: 8px;
  }
`;

export const StyledInput = styled(InputField)`
  margin-bottom: 16px;
`;

export const CardButtonsWrapper = styled.div<{ withoutDropDown?: boolean }>`
  margin-left: 16px;
  display: ${({ withoutDropDown }) => (!withoutDropDown ? 'block' : 'none')};

  ${media.mobile(css<{ withoutDropDown?: boolean }>`
    width: 100%;
    margin-left: 0;
    display: ${({ withoutDropDown }) => (withoutDropDown ? 'block' : 'none')};

    button {
      width: 100%;
      margin-top: 16px;
      margin-left: 0;
    }
  `)}
`;
