import { Configuration, SpLogicCrudlDocumentApi } from '@sp-api/documents-api';
import { apiService } from 'modules/Api/components/ApiProvider/services';
import { useConfiguration } from 'modules/Shared/hooks';

const useApi = apiService.createUseApi(SpLogicCrudlDocumentApi, Configuration);

const useDocumentApi = (): SpLogicCrudlDocumentApi => {
  const [{ documentsContextUrl }] = useConfiguration();
  return useApi(documentsContextUrl);
};

export default useDocumentApi;
