import PublicLayout from 'modules/Layouts/PublicLayout/PublicLayout';
import { Container, Row } from 'styled-bootstrap-grid';
import { ColCentered } from 'modules/Auth/components/Login/Login.styled';
import type { ReactNode } from 'react';
import type { PropsPublicLayout } from './PublicLayout';
import { StyledLogoImg, LogoCol } from './PublicLayout.styled';

type PublicLayoutWithLogoProps = PropsPublicLayout & {
  slotContent?: ReactNode;
};

export default function PublicLayoutWithLogo({
  children,
  slotContent,
  ...rest
}: PublicLayoutWithLogoProps): JSX.Element {
  return (
    <PublicLayout contentCentered {...rest}>
      <Container>
        <Row>
          <LogoCol col={12} md={6}>
            <StyledLogoImg />
          </LogoCol>

          <ColCentered col={12} md={6}>
            {slotContent}
          </ColCentered>
        </Row>
      </Container>

      {children}
    </PublicLayout>
  );
}
