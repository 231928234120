import Text, { TextTypesEnum } from '@signplatform-ui/text';
import { useTheme } from 'styled-components';
import useRemainingTime from './hooks/useRemainingTime';

type SigningTimerProps = {
  dueDate: string;
  className?: string;
};

const SigningTimer = ({
  dueDate,
  className,
}: SigningTimerProps): JSX.Element => {
  const theme = useTheme();
  const remainingTime = useRemainingTime(dueDate);

  return (
    <Text
      type={TextTypesEnum.S}
      color={theme.color.text.secondary}
      className={className}
    >
      {remainingTime}
    </Text>
  );
};

export default SigningTimer;
