import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import Modal, {
  ModalButtonPanel,
  ModalTitle,
} from 'modules/UI/components/Modal';
import { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import type { BaseSyntheticEvent } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import type { QueryKey } from 'react-query';
import { StyledInput, StyledText } from './RenameDocumentModal.styled';

type RenameDocumentModalProps = {
  onClose: () => void;
  onConfirm: (e: BaseSyntheticEvent | undefined, name: string) => void;
  invalidateQuery?: QueryKey;
  form: UseFormReturn<{ title: string }>;
};

const RenameDocumentModal = ({
  onClose,
  onConfirm,
  form: { control, handleSubmit, reset },
}: RenameDocumentModalProps): JSX.Element => {
  const handleCancelClick = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const handleRenameClick = useCallback(
    ({ title }: { title: string }, event?: BaseSyntheticEvent) => {
      onConfirm(event, title);
    },
    [onConfirm]
  );

  return (
    <Modal onClose={handleCancelClick}>
      <form>
        <div>
          <ModalTitle>Переименование документа</ModalTitle>
          <StyledText>
            Укажите новое название, которое хотите присвоить документу
          </StyledText>

          <Controller
            name='title'
            control={control}
            render={({ field, fieldState }) => {
              return (
                <StyledInput
                  value={field.value}
                  label='Название'
                  onChange={field.onChange}
                  status={fieldState.error?.message ? 'error' : undefined}
                  extraText={fieldState.error?.message}
                />
              );
            }}
          />
        </div>

        <ModalButtonPanel>
          <Button
            type='submit'
            onClick={handleSubmit(handleRenameClick)}
            size={ButtonSizesEnum.Medium}
          >
            Сохранить
          </Button>

          <Button
            onClick={handleCancelClick}
            color={ButtonColorsEnum.Secondary}
            size={ButtonSizesEnum.Medium}
          >
            Отменить
          </Button>
        </ModalButtonPanel>
      </form>
    </Modal>
  );
};

export default memo(RenameDocumentModal);
