import { DateTime } from 'luxon';
import * as yup from 'yup';
import { TARGET_TIME_ZONE } from 'services/dateUtils';
import transformDate from './utils/transformDate';

export const MAX_SIGNING_DAY_PERIOD = 89;
const dueDateSchema = yup.date().transform(transformDate).nullable();

const formSchema = yup
  .object()
  .shape({
    iAmOnlySigner: yup.bool(),
    iAmNotSigner: yup.bool(),
    clientPersons: yup.array().of(yup.object()),
    clientOrgs: yup.array().of(yup.object()),
    persons: yup.array().of(yup.object()),
    counterparties: yup.array().of(yup.object()),
    docs: yup
      .array()
      .of(yup.object())
      .test({
        test(value) {
          return !!value?.length;
        },
        message: 'Добавьте документ для подписания',
      }),
    signDueDate: dueDateSchema
      .required('Не заполнено обязательное поле')
      .test({
        test(value) {
          if (!value) {
            return false;
          }

          const currentDT = DateTime.now().setZone(TARGET_TIME_ZONE);

          return value > currentDT.toJSDate();
        },
        message: 'Указанная дата уже наступила',
      })
      .test({
        test(value) {
          if (!value) {
            return false;
          }

          const maxDT = DateTime.now().setZone(TARGET_TIME_ZONE).plus({
            day: MAX_SIGNING_DAY_PERIOD,
            hour: 23,
            minute: 59,
            second: 59,
          });

          return value <= maxDT.toJSDate();
        },
        message: 'Указанная дата превышает срок хранения документов (90 дней)',
      }),
  })
  .test(
    'hasAnySigner',
    'Добавьте участников подписания или укажите, что вы единственный подписант',
    (value) => {
      const { clientPersons, clientOrgs, persons, counterparties } = value;

      if (
        !value.iAmOnlySigner &&
        !clientPersons?.length &&
        !clientOrgs?.length &&
        !persons?.length &&
        !counterparties?.length
      ) {
        return false;
      }

      return true;
    }
  );

export default formSchema;
