import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import { HeaderSizesEnum } from '@signplatform-ui/header';
import { isEmpty } from 'lodash';
import Modal, { ModalButtonPanel } from 'modules/UI/components/Modal';
import ErrorMessage from 'modules/UI/components/ErrorMessage';
import { memo, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  distributeCounterpartiesByGroups,
  findSameIdCounterparty,
} from 'modules/Documents/components/PackageWizard/utils';
import { useCounterpartiesContextMin } from 'modules/Documents/components/PackageWizard/hooks';
import { TabControl } from '@signplatform-ui/tab-menu';
import type {
  CounterpartiesDataItem,
  CounterpartiesFormValues,
  CounterpartiesSet,
  FindCounterpartyProps,
  SearchFormData,
  SearchFormProps,
  CounterpartySearchField,
} from 'modules/Documents/components/PackageWizard/types';
import {
  StyledAddButton,
  StyledHeader,
  StyledText,
} from './AddCounterpartyModal.styled';
import SearchForm from './components/SearchForm';
import tabs from './tabsList';

const AddCounterpartyModal = ({
  onSetFoundSigners,
}: FindCounterpartyProps): JSX.Element => {
  // набор выбранных (найденных) юзеров. Изменяем только когда находим новый набор
  const [selectedCps, setSelectedCps] = useState<CounterpartiesSet>(new Set());

  const {
    activeTab = tabs[0].id,
    onSetActiveTab,
    value,
    onSwitchModal,
    onCloseModal,
    clientIdOwner,
  } = useCounterpartiesContextMin();

  const [foundSameIdCp, setFoundSameIdCp] =
    useState<CounterpartiesDataItem | null>(null);

  const { getValues, setValue } = useFormContext<CounterpartiesFormValues>();
  const formValues = getValues();

  const onSelectTab = useCallback(
    (tab: CounterpartySearchField) => {
      onSetActiveTab(tab);
      setFoundSameIdCp(null);
    },
    [onSetActiveTab]
  );

  /**
   * handleAddCounterparties start
   */
  const handleAddCounterparties = () => {
    setFoundSameIdCp(null);

    const selfCp = findSameIdCounterparty({
      clientIdOwner,
      selectedCps,
    });

    const sameIdCp = findSameIdCounterparty({
      currentCpsGroups: formValues,
      selectedCps,
    });

    // самый общий случай, нет дублей
    // далее идут частный случаи
    if (!sameIdCp && !selfCp) {
      onSetFoundSigners(
        distributeCounterpartiesByGroups({
          currentCpsGroups: formValues,
          selectedCps,
        })
      );
      return;
    }

    // если дубли и у себя, и у других
    if (sameIdCp && selfCp) {
      // если Я единственный подписант - ошибка про себя
      // в остальных случаях - ошибка про других
      setFoundSameIdCp(formValues.iAmOnlySigner ? selfCp : sameIdCp);
    }

    // если дубль только у себя
    if (selfCp) {
      // общий случай
      if (!formValues.iAmNotSigner) {
        setFoundSameIdCp(selfCp);
        return;
      }

      // если Я - не подписант, и нашли себя, просто снимем галочку в форме
      setValue('iAmNotSigner', false);
      onCloseModal();
      return;
    }

    // если дубли только у других
    if (sameIdCp) {
      // общий случай
      if (!formValues.iAmOnlySigner) {
        setFoundSameIdCp(sameIdCp);
        return;
      }

      // если Я - единственный подписант, то нашли дубли из-за кеша, очистим кеш новыми данными
      onSetFoundSigners(
        distributeCounterpartiesByGroups({
          selectedCps,
        })
      );
    }
  };
  /**
   * handleAddCounterparties end
   */

  const handleSelectCps: SearchFormProps['onSelect'] = useCallback(
    (setStateAction) => {
      setSelectedCps(setStateAction);
      setFoundSameIdCp(null);
    },
    []
  );

  const handleInviteUser = useCallback(
    (formDataWithCache: SearchFormData) => {
      onSwitchModal({ ...value, ...formDataWithCache });
    },
    [onSwitchModal, value]
  );

  useEffect(() => {
    document.querySelector('[role="dialog"]')?.querySelector('input')?.focus();
  }, [activeTab]);

  return (
    <Modal onClose={onCloseModal} data-testid='add-counterparties-modal'>
      <StyledHeader type={HeaderSizesEnum.H5}>Добавить участника</StyledHeader>

      <StyledText>
        Поиск участника осуществляется по данным, указанным им на портале
        Госуслуг
      </StyledText>

      <TabControl<CounterpartySearchField>
        activeId={activeTab}
        onSwitchTab={onSelectTab}
        tabs={tabs}
      />

      <SearchForm
        searchType={activeTab}
        onInviteUser={handleInviteUser}
        onSelect={handleSelectCps}
        selected={selectedCps}
      />

      {foundSameIdCp && (
        <ErrorMessage style={{ marginTop: 10 }}>
          {clientIdOwner && foundSameIdCp.clientId === clientIdOwner
            ? 'Вы уже являетесь участником подписания'
            : 'Пользователь уже является участником подписания'}
        </ErrorMessage>
      )}

      <ModalButtonPanel>
        <StyledAddButton
          size={ButtonSizesEnum.Medium}
          onClick={handleAddCounterparties}
          disabled={isEmpty(selectedCps)}
          data-testid='counterparties-confirm-button'
        >
          Добавить участника
        </StyledAddButton>

        <Button
          size={ButtonSizesEnum.Medium}
          color={ButtonColorsEnum.Secondary}
          onClick={onCloseModal}
          data-testid='counterparties-cancel-button'
        >
          Отменить
        </Button>
      </ModalButtonPanel>
    </Modal>
  );
};

export default memo(AddCounterpartyModal);
