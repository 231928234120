import { ButtonColorsEnum, ButtonSizesEnum } from '@signplatform-ui/button';
import { MenuPositionsEnum } from '@signplatform-ui/dropdownbutton';
import { useModal } from 'modules/Common/hooks';
import { useCallback, useMemo } from 'react';
import {
  spinnerInIconBox,
  RemoveIcon,
} from 'modules/UI/components/DocumentsPackEditor';
import RemoveDocumentConfirmModal from 'modules/Documents/components/RemoveDocumentConfirmModal';
import RenameDocumentModal from 'modules/Documents/components/RenameDocumentModal';
import { StyledDropdownButton } from 'modules/Shared/components/SharedStyled';
import type { UseFormReturn } from 'react-hook-form';
import type { MouseEvent, BaseSyntheticEvent } from 'react';
import type { MenuItemProp } from '@signplatform-ui/menu';
import { StyledMoreHorizontal } from './DocsPackDocumentActions.styled';

export type DocsPackDocumentActionsProps<T> = {
  canOnlyRemove?: boolean;
  fileInstance: T;
  hasDownload: boolean;
  hasError?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  onDownloadFile?: (e: MouseEvent, fileToDownload: T) => Promise<void> | void;
  onRemoveFile?: (e: MouseEvent, fileToRemove: T) => void;
  onRenameFile?: (
    e: BaseSyntheticEvent | undefined,
    fileToRename: T,
    newName: string
  ) => void;
  renameDocumentForm: UseFormReturn<{ title: string }>;
};

const DocsPackDocumentActions = <T,>({
  canOnlyRemove,
  fileInstance,
  hasDownload,
  hasError,
  isLoading,
  isDisabled,
  onDownloadFile,
  onRemoveFile,
  onRenameFile,
  renameDocumentForm,
}: DocsPackDocumentActionsProps<T>): JSX.Element => {
  const renameModal = useModal();
  const removeModal = useModal();

  const handleDownload = useCallback(
    (e: MouseEvent) => onDownloadFile?.(e, fileInstance),
    [fileInstance, onDownloadFile]
  );

  const handleRemove = useCallback(
    (e: MouseEvent) => {
      onRemoveFile?.(e, fileInstance);
      removeModal.onClose();
    },
    [onRemoveFile, fileInstance, removeModal]
  );

  const handleRename = useCallback(
    (e: BaseSyntheticEvent | undefined, newName: string) => {
      onRenameFile?.(e, fileInstance, newName);
      renameModal.onClose();
    },
    [onRenameFile, fileInstance, renameModal]
  );

  const items = useMemo<MenuItemProp[]>(() => {
    const menuItems: (MenuItemProp | boolean)[] = [
      {
        id: 'rename',
        title: 'Переименовать',
        onClick(e) {
          if (isDisabled) return;
          e.preventDefault();
          renameModal.onOpen();
        },
      },
      hasDownload && {
        id: 'download',
        title: 'Скачать',
        onClick(e) {
          if (isDisabled) return;
          e.preventDefault();
          void handleDownload(e);
        },
      },
      {
        id: 'remove',
        title: 'Удалить',
        onClick(e) {
          if (isDisabled) return;
          e.preventDefault();
          removeModal.onOpen();
        },
      },
    ];

    return menuItems.filter(Boolean) as MenuItemProp[];
  }, [hasDownload, isDisabled, renameModal, handleDownload, removeModal]);

  if (isLoading) return <>{spinnerInIconBox}</>;

  return (
    <>
      {canOnlyRemove ? (
        <RemoveIcon
          hasError={hasError}
          isDisabled={isDisabled}
          onClick={removeModal.onOpen}
        />
      ) : (
        <StyledDropdownButton
          color={ButtonColorsEnum.Ghost}
          data-testid='docs-pack-document-actions-dropdown'
          displayAsSquare
          disabled={isDisabled}
          items={items}
          position={MenuPositionsEnum.Right}
          size={ButtonSizesEnum.Medium}
        >
          <StyledMoreHorizontal />
        </StyledDropdownButton>
      )}

      {!isDisabled && renameModal.isOpen && (
        <RenameDocumentModal
          onConfirm={handleRename}
          onClose={renameModal.onClose}
          form={renameDocumentForm}
        />
      )}

      {!isDisabled && removeModal.isOpen && (
        <RemoveDocumentConfirmModal
          onClose={removeModal.onClose}
          onConfirm={handleRemove}
        />
      )}
    </>
  );
};
export default DocsPackDocumentActions;
