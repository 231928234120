import {
  DocsSolid,
  FilePptSolid,
  FilePreview,
  FileXmlSolid,
  JpgSolid,
  WebSolid,
} from '@signplatform-ui/icons';
import type { ComponentType, SVGProps } from 'react';

const getComponentByExtension = (
  ext: string | undefined = ''
): ComponentType<SVGProps<SVGSVGElement>> => {
  const extParsed = (ext.startsWith('.') ? ext.slice(1) : ext).toLowerCase();

  switch (extParsed) {
    default:
      return WebSolid;

    case 'doc':
    case 'docx':
    case 'txt':
      return DocsSolid;

    case 'xls':
    case 'xml':
      return FileXmlSolid;

    case 'pdf':
      return FilePreview;

    case 'ppt':
    case 'pptx':
      return FilePptSolid;

    case 'jpg':
    case 'jpeg':
    case 'tif':
    case 'tiff':
      return JpgSolid;
  }
};

export type FileExtIconProps = SVGProps<SVGSVGElement> & {
  ext: string | undefined;
};

// компонент выводит svg-like иконку по расширению
// если расширение не найдено, выводит иконку по-умолчанию
const FileIconByExt = ({ ext, ...rest }: FileExtIconProps): JSX.Element => {
  const Component = getComponentByExtension(ext);

  return <Component {...rest} />;
};
export default FileIconByExt;
