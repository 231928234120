import { useEffect, useRef } from 'react';
import type {
  FieldPathValue,
  Path,
  UnpackNestedValue,
  UseFormSetValue,
} from 'react-hook-form';

/**
 * Кеширование полей формы и обратная подстановка из кеша при изменении типа поля
 * @param formData значения формы
 * @param fieldKey текущее поле
 * @param setValue callback для восстановления значения из кеша
 * @param initialValue начальное значение кеша
 * @return cache текущий кеш
 */
export default function useFieldSwitchCache<T extends Record<string, string>>(
  formData: T,
  fieldKey: keyof T,
  setValue: UseFormSetValue<T>,
  initialValue: Partial<T> = {}
): Partial<T> {
  // cache object
  const formValuesRef = useRef<Partial<T>>({ ...initialValue });
  // flag to ignore setting invalid cache data
  // can be omitted, but it provides more predictable cache logic
  let formDataWillChange = false;

  type FakeUnpackedValue = UnpackNestedValue<FieldPathValue<T, Path<T>>>;

  // restoring value from cache
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    formDataWillChange = true;

    setValue(
      fieldKey as Path<T>,
      formValuesRef.current[fieldKey] as FakeUnpackedValue
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldKey]);

  // updating cache
  useEffect(() => {
    if (formDataWillChange) return;
    formValuesRef.current[fieldKey] = formData[fieldKey];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return formValuesRef.current;
}
