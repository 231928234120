import { useEffect, useRef, useMemo } from 'react';
import { getPackageCardQueryKey } from 'modules/Documents/hooks';
import usePackageDocsStatus from 'modules/Documents/hooks/usePackageDocsStatus';
import { useQueryClient } from 'react-query';
import type { LoadStates } from '@sp-api/check-sign-api';
import type { IFileInstance } from 'modules/UI/components/DocumentsPackEditor/types';
import type { UseNotLoadedDocsOutput } from 'modules/Documents/components/PackageWizard/hooks/useNotLoadedDocIds';
import type { IPackageAdapter } from 'modules/Documents/classes';

type FileInstanceData = Pick<
  IFileInstance,
  'documentServerId' | 'isWaitingServerFileData'
>;

type PackageAdaptedData = Pick<IPackageAdapter, 'clientIdOwner' | 'packageId'>;

export type UseDocsPollingInput = UseNotLoadedDocsOutput & {
  onGetDocs: () => FileInstanceData[];
  onBeforeStartPolling?: () => Promise<void>;
  packageAdapted: PackageAdaptedData;
};

export default function useDocsStatusPolling({
  onGetDocs,
  notLoadedDocIds,
  packageAdapted,
  onSetNotLoadedDocIds,
  onBeforeStartPolling,
}: UseDocsPollingInput): void {
  const lastPollingPackageDocIds = useRef<string>('');
  const queryClient = useQueryClient();

  const { onStartPolling } = usePackageDocsStatus({
    packageId: packageAdapted.packageId,
    ownerClientId: packageAdapted.clientIdOwner,
    async onEndPolling(data: LoadStates) {
      const newSet = new Set<string>();
      const docs = onGetDocs();

      docs.forEach((clientDoc) => {
        if (!clientDoc.documentServerId) return;

        const found = data.loadStates?.find(
          (x) => x.documentId === clientDoc.documentServerId
        );

        if (!found || !found.state) {
          newSet.add(String(clientDoc.documentServerId));
        }
      });

      await queryClient.refetchQueries(
        getPackageCardQueryKey({
          ownerClientId: packageAdapted.clientIdOwner,
          packageId: packageAdapted.packageId,
        })
      );

      onSetNotLoadedDocIds(newSet);
    },
  });

  const handleStartPolling = useMemo(() => {
    return async () => {
      await onBeforeStartPolling?.();
      onStartPolling();
    };
  }, [onBeforeStartPolling, onStartPolling]);

  useEffect(() => {
    const newNotLoadedDocIds = new Set(notLoadedDocIds);
    const docs = onGetDocs();

    // if some docs was loaded (maybe later, remove not loaded state)
    docs.forEach((x) => {
      if (x.isWaitingServerFileData) return;
      newNotLoadedDocIds.delete(String(x.documentServerId));
    });

    if (newNotLoadedDocIds.size !== notLoadedDocIds.size) {
      onSetNotLoadedDocIds(newNotLoadedDocIds);
    }

    const hasNewNotLoadedDocs = docs.some(
      (x) =>
        x.documentServerId &&
        x.isWaitingServerFileData &&
        !notLoadedDocIds.has(String(x.documentServerId))
    );

    const packageDocsIdsToPolling = docs
      .map((x) => x.documentServerId)
      .sort(undefined)
      .reduce<string[]>((acc, x) => {
        if (x) acc.push(String(x));
        return acc;
      }, [])
      .toString();

    if (
      // is the doc ids different (we did not perform polling for these before)
      lastPollingPackageDocIds.current !== packageDocsIdsToPolling &&
      // do we need perform polling
      hasNewNotLoadedDocs
    ) {
      lastPollingPackageDocIds.current = packageDocsIdsToPolling;
      void handleStartPolling();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageAdapted]);
}
