import styled, { css } from 'styled-components';
import { FontSizesEnum, LineHeightsEnum } from '@signplatform-ui/typographics';

type StyledProps = {
  $disabled?: boolean;
  $ellipsis?: boolean;
};

const cssEllipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledLabel = styled.span<StyledProps>`
  ${({ $ellipsis }) => ($ellipsis ? cssEllipsis : undefined)}

  color: ${({ theme, $disabled }) =>
    $disabled ? theme.color.text.tertiary : theme.color.text.secondary};
  font-size: ${FontSizesEnum.AdditionalS};
  line-height: ${LineHeightsEnum.AdditionalS};
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
`;

export const StyledValue = styled.span<StyledProps>`
  ${({ $ellipsis }) => ($ellipsis ? cssEllipsis : undefined)}

  color: ${({ theme, $disabled }) =>
    $disabled ? theme.color.text.tertiary : theme.color.text.primary};
  font-size: ${FontSizesEnum.AdditionalS};
  line-height: ${LineHeightsEnum.AdditionalS};
`;
