import { useUserApiClient } from 'modules/Api/hooks';
import AuthStateKeysEnum from 'modules/Auth/constants/AuthStateKeysEnum';
import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';
import type { AxiosRequestConfig } from 'axios';
import type { ErrorResponse } from 'modules/App/types';
import type { UserProfile } from '@sp-api/user-api';

export default function useCurrentUser(
  enabled = true,
  options?: AxiosRequestConfig
): UseQueryResult<UserProfile, ErrorResponse> {
  const userApi = useUserApiClient();
  return useQuery<UserProfile, ErrorResponse>(
    AuthStateKeysEnum.CurrentUser,
    async () => {
      const { data } = await userApi.getProfile(options);

      return data;
    },
    {
      enabled,
      retry: false,
    }
  );
}
