import { makeEnumerablePrototype } from 'modules/Common/services';
import type {
  InnerPersonSigningData,
  CounterpartyClientPerson,
  CounterpartyPersonBase,
  SignerSignStatusUni,
} from 'modules/Documents/types';

import SignMixin from './SignMixin';

type ISigningAdapterPersonInner = CounterpartyClientPerson &
  PartialToUndefined<CounterpartyPersonBase>;

@makeEnumerablePrototype
export default class SigningAdapterPersonInner
  extends SignMixin<SignerSignStatusUni>
  implements ISigningAdapterPersonInner
{
  readonly email: ISigningAdapterPersonInner['email'];
  readonly inn: ISigningAdapterPersonInner['inn'];
  readonly phoneNumber: ISigningAdapterPersonInner['phoneNumber'];
  readonly inviteId: ISigningAdapterPersonInner['inviteId'];

  readonly __fePerson;
  readonly __source;
  readonly firstName;
  readonly lastName;
  readonly middleName;
  readonly snils;
  readonly clientId;
  readonly participantId;

  constructor(private _data: InnerPersonSigningData) {
    super(_data);

    const { innerPersonsData } = _data;

    this.__source = _data;

    this.__fePerson = true;
    this.firstName = innerPersonsData.firstName;
    this.lastName = innerPersonsData.lastName;
    this.middleName = innerPersonsData.middleName;
    this.snils = innerPersonsData.snils;

    this.clientId = innerPersonsData.clientId;
    this.participantId = innerPersonsData.participantId;
  }

  get idNum(): string | undefined {
    const { innerPersonsData } = this._data;

    return innerPersonsData.dulSeries
      ? `${innerPersonsData.dulSeries} ${innerPersonsData.dulNumber}`
      : innerPersonsData.dulNumber;
  }
}
