import type { SigningsUni } from 'modules/Documents/types';
import DocumentSigner from './DocumentSigner';

type DocumentSignersProps = {
  signers: SigningsUni[];
};

const DocumentSigners = ({
  signers,
}: DocumentSignersProps): JSX.Element | null => {
  return (
    <>
      {signers.map((sign) => (
        <DocumentSigner key={sign.signingId} sign={sign} />
      ))}
    </>
  );
};

export default DocumentSigners;
