import { Configuration, ExchangeServiceApi } from '@pa-api/pa-logic-exc-gate';
import { apiService } from 'modules/Api/components/ApiProvider/services';
import { useConfiguration } from 'modules/Shared/hooks';

const useApi = apiService.createUseApi(ExchangeServiceApi, Configuration);

const useExcGateApi = (): ExchangeServiceApi => {
  const [{ excGateContextUrl }] = useConfiguration();
  return useApi(excGateContextUrl);
};

export default useExcGateApi;
