import { LinkTypesEnum, LinkSizesEnum } from '@signplatform-ui/link';
import { ArrowLeftOutline } from '@signplatform-ui/icons';
import { useCallback, useContext } from 'react';
import AppRoutesPaths from 'modules/Router/routes/paths';
import { AppRoutesHistoryContext } from 'modules/Shared/components/AppRoutesHistoryProvider';
import { useHistory } from 'react-router-dom';
import { StyledLink } from 'modules/Layouts/DefaultLayout/components/Breadcrumb/Breadcrumb.styled';
import type { PropsWithChildren, MouseEvent } from 'react';
import type { LinkProps } from '@signplatform-ui/link';

/**
 * toFallback: если пришли по прямой ссылке
 * toOverride: если нужно переопределить стандартное поведение
 */
type BreadcrumbProps = PropsWithChildren<
  Pick<LinkProps, 'size' | 'type'> & {
    toFallback?: typeof AppRoutesPaths[keyof typeof AppRoutesPaths];
    toOverride?: typeof AppRoutesPaths[keyof typeof AppRoutesPaths];
  }
>;

export default function Breadcrumb({
  toFallback,
  toOverride,
  children,
  ...rest
}: BreadcrumbProps): JSX.Element {
  const routesHistory = useContext(AppRoutesHistoryContext);
  const history = useHistory();

  const hasPreviousRoute = !!routesHistory[1];
  const directPathToGo =
    toOverride ||
    routesHistory[1] ||
    toFallback ||
    AppRoutesPaths.DocumentsList;

  const handleClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      history.push(routesHistory[1]);
    },
    [history, routesHistory]
  );

  return (
    <StyledLink
      onClick={hasPreviousRoute && !toOverride ? handleClick : undefined}
      to={directPathToGo}
      type={LinkTypesEnum.Secondary}
      size={LinkSizesEnum.S}
      {...rest}
    >
      {children || (
        <>
          <ArrowLeftOutline />
          Назад
        </>
      )}
    </StyledLink>
  );
}
