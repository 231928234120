import {
  useDownloadDocument,
  useDownloadDocWithSignatures,
} from 'modules/Documents/hooks';
import { useCallback } from 'react';
import fileDownload from 'js-file-download';
import { StatusSigningEnum } from '@sp-api/documents-api';
import { PackageSigningsAdapter } from 'modules/Documents/classes';
import type { FileServerData, IFileInstance } from 'modules/UI/types';
import type { DownloadPackageSingleDocProps } from './types';
import notifyByError from './notifyByError';
import DownloadPackageSingleDocVC from './DownloadPackageSingleDocVC';

function checkIfFileSignedBySomeone(fileData: IFileInstance): boolean {
  if (!fileData.hasDocument) return false;

  const signings = new PackageSigningsAdapter(
    (fileData.source as FileServerData).document
  );

  if (signings.owner?.status === StatusSigningEnum.Signed) return true;
  return [...signings.clientOrgs, ...signings.clientPersons].some(
    (x) => x.status === StatusSigningEnum.Signed
  );
}

function checkIfFileCanHaveSignatures(fileData: IFileInstance): boolean {
  return (fileData.fileExtWithDot || '').toLowerCase().includes('pdf');
}

export default function DownloadPackageSingleDoc({
  data,
  VC = DownloadPackageSingleDocVC,
}: DownloadPackageSingleDocProps): JSX.Element | null {
  const { mutateAsync: downloadMutateAsync, isLoading: isDownloading } =
    useDownloadDocument();

  const {
    mutateAsync: downloadWithSignatureMutateAsync,
    isLoading: isDownloadWithSignatureLoading,
  } = useDownloadDocWithSignatures();

  const { documentId, clientIdOwner, packageId } = (
    data.source as FileServerData
  ).document;

  const handleDownload = useCallback(async () => {
    if (isDownloading || !documentId) return;

    const { file, fileName } = await downloadMutateAsync({
      packageId,
      documentId,
      clientOwnerId: clientIdOwner,
    });
    fileDownload(file, fileName);
  }, [
    downloadMutateAsync,
    documentId,
    clientIdOwner,
    isDownloading,
    packageId,
  ]);

  const handleDownloadWithSignature = useCallback(async () => {
    if (isDownloadWithSignatureLoading || !documentId) return;

    try {
      const { file, fileName } = await downloadWithSignatureMutateAsync({
        packageId,
        documentId,
        clientOwnerId: clientIdOwner,
      });
      fileDownload(file, fileName);
    } catch (e) {
      notifyByError(e);
    }
  }, [
    clientIdOwner,
    documentId,
    downloadWithSignatureMutateAsync,
    isDownloadWithSignatureLoading,
    packageId,
  ]);

  if (!data.hasDocument || !('document' in data.source)) return null;
  if (!documentId || !clientIdOwner) return null;

  const hasDownloadWithSignature =
    checkIfFileCanHaveSignatures(data) && checkIfFileSignedBySomeone(data);

  return (
    <VC
      hasDownloadWithSignature={hasDownloadWithSignature}
      isDownloadDisabled={isDownloading}
      isDownloadWithSignatureDisabled={isDownloadWithSignatureLoading}
      onDownload={handleDownload}
      onDownloadWithSignature={handleDownloadWithSignature}
    />
  );
}
