import MaskedInput from 'modules/UI/components/form/MaskedInput';
import type { MaskedInputProps } from 'modules/UI/components/form/MaskedInput/MaskedInput';
import type { ChangeEventHandler, FocusEventHandler } from 'react';

type PersonInnInputProps = {
  name: string;
  label?: string;
  placeholder?: string;
  status?: 'error' | 'success';
  extraText?: string;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  value?: string;
  unmasked?: boolean;
  disabled?: boolean;
};

const INN_FORMAT_MASK = '000000000000';
const INN_LABEL = 'ИНН';
const INN_PLACEHOLDER = 'Введите ИНН';

const maskProps: Pick<MaskedInputProps, 'maskOptions' | 'unmasked'> = {
  maskOptions: {
    mask: INN_FORMAT_MASK,
  },
};

const PersonInnInput = ({
  name,
  label = INN_LABEL,
  placeholder = INN_PLACEHOLDER,
  status,
  extraText,
  onChange,
  className,
  value = '',
  ...rest
}: PersonInnInputProps): JSX.Element => {
  return (
    <MaskedInput
      className={className}
      displayClearIcon={!rest.disabled && !!value?.length}
      extraText={extraText}
      label={label}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      status={status}
      value={value}
      {...maskProps}
      {...rest}
    />
  );
};

export default PersonInnInput;
