import { isUndefined } from 'lodash';
import { useEffect, useCallback, useRef } from 'react';
import { apiService } from 'modules/Api/components/ApiProvider/services';
import useToken from 'modules/Shared/hooks/useToken';
import type { SpServiceResponse } from 'modules/Api/components/ApiProvider/types';
import type {
  AxiosResponse,
  AxiosRequestConfig,
  AxiosError,
  HeadersWithAuth,
} from 'axios';
import useRefreshToken from './useRefreshToken';

const UNKNOWN_ERROR = {
  displayCode: 'UNKNOWN_ERROR',
} as const;

const onTransformResponse = (
  response: AxiosResponse<SpServiceResponse>
): AxiosResponse => {
  // skip transformation for retied response (which is already processed)
  if (isUndefined(response.data.result)) {
    return response;
  }

  const {
    data: { result, success },
  } = response;

  const { responseType } = response.request as AxiosRequestConfig;

  if (!success && responseType !== 'blob') {
    throw Error(
      'Server response failed with falsy "success" value and 2xx response code.'
    );
  }

  return responseType !== 'blob' ? { ...response, data: result } : response;
};

const onTransformReject = (
  error: AxiosError<SpServiceResponse>
): Promise<AxiosError> => {
  // Pass original object for 401 error
  if (error.response?.status === 401) {
    return Promise.reject(error);
  }
  // TODO: Add error logging
  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({
    ...error.response,
    data: error.response?.data.error || UNKNOWN_ERROR,
  });
};

export default function useInterceptors(): void {
  const transformRef = useRef<number | null>(null);
  const authRef = useRef<number | null>(null);
  const { token, setToken } = useToken();
  const { mutateAsync } = useRefreshToken();

  const onAuthReject = useCallback(
    async (
      error: AxiosError<SpServiceResponse>
    ): Promise<AxiosError | AxiosResponse> => {
      if (!error.response || !token) {
        return Promise.reject(error);
      }

      const originalRequest: AxiosRequestConfig & {
        _retry?: boolean;
      } = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const { accessToken, refreshToken } = await mutateAsync({
            clientId: 'avanpost',
            refreshToken: token.refreshToken,
          });

          if (!accessToken || !refreshToken) {
            throw new Error('Refresh token error');
          }

          setToken({ accessToken, refreshToken });

          const headers: HeadersWithAuth = {
            ...(originalRequest.headers as Record<string, unknown>),
            Authorization: `Bearer ${accessToken}`,
          };

          return await apiService.axiosInstance({
            ...originalRequest,
            headers,
          });
        } catch (e) {
          // reset authorization if refresh token request failed
          setToken(null);
        }
      } else if (originalRequest._retry) {
        setToken(null);
      }

      return Promise.reject(error);
    },
    [mutateAsync, setToken, token]
  );

  useEffect(() => {
    if (!token) {
      if (transformRef.current === null && authRef.current === null) {
        transformRef.current = apiService.addInterceptor(
          'response',
          onTransformResponse,
          onTransformReject
        );

        return;
      }

      if (transformRef.current !== null) {
        apiService.ejectInterceptor('response', transformRef.current);
        transformRef.current = null;
      }

      if (authRef.current !== null) {
        apiService.ejectInterceptor('response', authRef.current);
        authRef.current = null;
      }

      return;
    }

    if (transformRef.current === null || authRef.current === null) {
      if (authRef.current === null) {
        authRef.current = apiService.addInterceptor(
          'response',
          undefined,
          onAuthReject
        );
      }

      if (transformRef.current === null) {
        transformRef.current = apiService.addInterceptor(
          'response',
          onTransformResponse,
          onTransformReject
        );
      }

      return;
    }

    apiService.ejectInterceptor('response', authRef.current);
    apiService.ejectInterceptor('response', transformRef.current);

    authRef.current = null;
    transformRef.current = null;

    authRef.current = apiService.addInterceptor(
      'response',
      undefined,
      onAuthReject
    );

    authRef.current = apiService.addInterceptor(
      'response',
      onTransformResponse,
      onTransformReject
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
}
