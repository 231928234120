import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { usePackageContext } from 'modules/Documents/contexts';
import type { PackageWizardFormValues } from '../../types';
import type { ModalOnLeavingDraftProps } from './types';
import ModalOnLeavingDraft from './ModalOnLeavingDraft';

export default function ModalOnLeavingDraftSpec({
  onSaveDraft,
}: Pick<ModalOnLeavingDraftProps, 'onSaveDraft'>): JSX.Element {
  const history = useHistory();

  const {
    formState: { dirtyFields },
  } = useFormContext<PackageWizardFormValues>();

  const { packageAdapted, packageName } = usePackageContext();
  const isDirty = !!Object.values(dirtyFields).filter(Boolean).length;

  const handleNavigate = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const shouldBlockNavigation = useCallback(() => {
    const isNameChanged = packageAdapted.name !== packageName;

    return isNameChanged || isDirty;
  }, [isDirty, packageAdapted, packageName]);

  return (
    <ModalOnLeavingDraft
      navigate={handleNavigate}
      onSaveDraft={onSaveDraft}
      shouldBlockNavigation={shouldBlockNavigation}
    />
  );
}
