import Login from 'modules/Auth/components/Login';
import { useToken, useUser } from 'modules/Shared/hooks';
import { Route, useRouteMatch, Redirect } from 'react-router-dom';
import AppRoutesPaths from 'modules/Router/routes/paths';
import type { RouteProps } from 'react-router-dom';

type PrivateRouteProps = {
  role?: string;
};

const PrivateRoute = ({
  role,
  ...routeProps
}: PrivateRouteProps & RouteProps): JSX.Element | null => {
  const { token } = useToken();
  const { user } = useUser();
  const exitPageMatch = useRouteMatch(AppRoutesPaths.ProfileExit);

  if (!token) {
    if (exitPageMatch) return <Redirect to={AppRoutesPaths.Home} />;
    return <Login />;
  }

  if (!user) {
    // eslint-disable-next-line no-console
    console.error('unexpected user value');
    return null;
  }

  return <Route {...routeProps} />;
};

export default PrivateRoute;
