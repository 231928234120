import { StatusUnitEnum } from '@sp-api/documents-api';
import styled, { css } from 'styled-components';
import Status from '../Status';

const statusesMap: Record<StatusUnitEnum, ReturnType<typeof css>> = {
  [StatusUnitEnum.Signed]: css`
    background-color: ${({ theme }) => theme.color.special.softGreen};
    border: 1px solid ${({ theme }) => theme.color.special.green};
  `,
  [StatusUnitEnum.Waiting]: css`
    background-color: ${({ theme }) => theme.color.special.softLightBlue};
    border: 1px solid ${({ theme }) => theme.color.special.blue};
  `,
  [StatusUnitEnum.Draft]: css`
    background-color: ${({ theme }) => theme.color.background.tertiary};
    border: 1px solid ${({ theme }) => theme.color.basic.tertiary};
  `,
  [StatusUnitEnum.Error]: css`
    background-color: ${({ theme }) => theme.color.special.softRed};
    border: 1px solid ${({ theme }) => theme.color.special.red};
  `,
  [StatusUnitEnum.RejectedByOwner]: css`
    background-color: ${({ theme }) => theme.color.background.tertiary};
    border: 1px solid ${({ theme }) => theme.color.basic.tertiary};
  `,
  [StatusUnitEnum.Rejected]: css`
    background-color: ${({ theme }) => theme.color.special.softRed};
    border: 1px solid ${({ theme }) => theme.color.special.red};
  `,
  [StatusUnitEnum.Expired]: css`
    background-color: ${({ theme }) => theme.color.special.softRed};
    border: 1px solid ${({ theme }) => theme.color.special.red};
  `,
};

// eslint-disable-next-line import/prefer-default-export
export const StatusStyled = styled(Status)<{ status: StatusUnitEnum }>`
  border-radius: 4px;
  padding: 3px 8px;
  ${({ status }) => statusesMap[status]};

  svg {
    margin-right: 4px;
    width: 16px;
    height: auto;
  }
`;
