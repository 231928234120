import { CheckboxField } from '@vtb/ui-kit3';
import { useCallback } from 'react';
import type { FoundCounterparty } from 'modules/Documents/components/PackageWizard/components/DocumentCounterparties/types';
import type { ChangeEvent } from 'react';
import { StyledCounterparty } from './Counterparty.styled';

type CounterpartyProps = {
  checked: boolean;
  data: DeepRO<FoundCounterparty>;
  name: string;
  onChange: (data: DeepRO<FoundCounterparty>, checked: boolean) => void;
};

const Counterparty = ({
  data,
  name,
  checked,
  onChange,
}: CounterpartyProps): JSX.Element => {
  const selectUser = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      onChange(data, target.checked);
    },
    [data, onChange]
  );

  return (
    <StyledCounterparty data-testid='select-counterparty-item'>
      <CheckboxField checked={checked} onChange={selectUser}>
        {name}
      </CheckboxField>
    </StyledCounterparty>
  );
};

export default Counterparty;
