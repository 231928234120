import {
  Configuration,
  PowattorneyServiceApi,
} from '@pa-api/pa-logic-crudl-document';
import { apiService } from 'modules/Api/components/ApiProvider/services';
import { useConfiguration } from 'modules/Shared/hooks';

const useApi = apiService.createUseApi(PowattorneyServiceApi, Configuration);

const usePowAttorneyDocumentApi = (): PowattorneyServiceApi => {
  const [{ attorneyContextUrl }] = useConfiguration();
  return useApi(attorneyContextUrl);
};

export default usePowAttorneyDocumentApi;
