import DocumentsPackEditor from 'modules/UI/components/DocumentsPackEditor/DocumentsPackEditor';
import { memo, useCallback, useContext, useRef } from 'react';
import { useFormContext, useForm } from 'react-hook-form';
import fileDownload from 'js-file-download';
import { useDownloadDocument } from 'modules/Documents/hooks';
import DocumentValidator from 'modules/Documents/classes/DocumentValidator';
import { getErrorDataByRejectReason } from 'modules/Documents/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  usePackageContext,
  useDocsContext,
  PackageDocsContext,
} from 'modules/Documents/contexts';
import type { MouseEvent } from 'react';
import type {
  IFileInstance,
  OnRenderDocActions,
  FileServerData,
  DocumentsPackEditorProps,
} from 'modules/UI/types';
import type { DocsFormValues } from 'modules/Documents/types';
import type { DocsPackDocumentActionsProps } from './components/DocsPackDocumentActions';
import { MAX_FILE_SIZE_BYTES, UPLOAD_ACCEPT } from '../CreatePackage';
import renameDocumentSchema from './renameSchema';
import {
  UploadDescription,
  UploadHeader,
  DocsPackDocumentActions,
} from './components';

type DocumentActionsProps = {
  canHaveLoadingState?: boolean;
  fileInstance: IFileInstance;
  hasError: boolean | undefined;
  ownerClientId: EntityId;
  isDisabled?: boolean;
};

type DocsActions = Required<DocsPackDocumentActionsProps<IFileInstance>>;

export const docsPackSPStaticProps: Partial<DocumentsPackEditorProps> = {
  acceptExt: UPLOAD_ACCEPT,
  slotHeader: <UploadHeader />,
  slotDescription: <UploadDescription />,
  maxSingleSizeBytes: MAX_FILE_SIZE_BYTES,
};

const DocumentsActionsSP = ({
  hasError,
  fileInstance,
  ownerClientId,
  canHaveLoadingState = true,
  isDisabled,
}: DocumentActionsProps): JSX.Element | null => {
  const { mutateAsync: downloadMutateAsync, isLoading: isDownloading } =
    useDownloadDocument();

  const { watch, setValue } = useFormContext<DocsFormValues>();
  const { notLoadedDocIds, setDocName, getDocName } =
    useContext(PackageDocsContext);
  const docs = watch('docs');

  const renameDocumentForm = useForm<{ title: string }>({
    defaultValues: {
      title: getDocName(fileInstance),
    },
    resolver: yupResolver(renameDocumentSchema),
    context: {
      fileInstance,
      allFiles: docs,
    },
  });

  const handleRemoveFile = useCallback<DocsActions['onRemoveFile']>(
    (e: MouseEvent, fileToRemove: IFileInstance) => {
      setValue(
        'docs',
        docs.filter((x) => x !== fileToRemove),
        { shouldDirty: true }
      );
    },
    [docs, setValue]
  );

  const handleDownload = useCallback<DocsActions['onDownloadFile']>(
    async (e, fileInstanceToDownload) => {
      if (isDownloading) return;
      if (!fileInstanceToDownload.hasServerFile) return;

      const { documentId, packageId } = (
        fileInstanceToDownload.source as FileServerData
      ).document;
      if (!documentId) return;

      const { file, fileName } = await downloadMutateAsync({
        packageId,
        documentId,
        clientOwnerId: ownerClientId,
      });

      fileDownload(file, fileName);
    },
    [isDownloading, downloadMutateAsync, ownerClientId]
  );

  const handleRename = useCallback<DocsActions['onRenameFile']>(
    async (e, fileInstanceToRename, newName) => {
      renameDocumentForm.reset();

      const idx = docs.findIndex((x) => x === fileInstanceToRename);
      if (idx === -1) return;

      const correctedName = DocumentValidator.getCorrectedFileName(newName);
      if (!correctedName) return;

      setDocName(String(fileInstanceToRename.clientServerId), correctedName);
      renameDocumentForm.reset({ title: correctedName });
    },
    [docs, renameDocumentForm, setDocName]
  );

  const docNotLoaded = notLoadedDocIds.has(String(fileInstance.clientServerId));

  return (
    <DocsPackDocumentActions<IFileInstance>
      canOnlyRemove={!fileInstance.hasDocument || docNotLoaded}
      fileInstance={fileInstance}
      hasDownload={fileInstance.hasServerFile}
      hasError={hasError}
      isLoading={
        fileInstance.isWaitingServerFileData &&
        canHaveLoadingState &&
        !docNotLoaded
      }
      isDisabled={isDisabled}
      onDownloadFile={handleDownload}
      onRemoveFile={handleRemoveFile}
      onRenameFile={handleRename}
      renameDocumentForm={renameDocumentForm}
    />
  );
};

// DocumentsPackEditor specialization for PackagePage
// can have contexts and other related data, but it's child can not
const DocsPackEditorSP = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  const { packageAdapted } = usePackageContext();
  const { notLoadedDocIds, docsErrorMap, docsUINameById, isDisabled } =
    useDocsContext();
  const { watch, setValue } = useFormContext<DocsFormValues>();
  const docs = watch('docs');

  const handleAddFiles = useCallback(
    (newFiles: IFileInstance[]) => {
      if (isDisabled) return;

      const resultFiles = DocumentValidator.getSortedFilesList(docs, newFiles);
      setValue('docs', resultFiles, { shouldDirty: true });
    },
    [docs, setValue, isDisabled]
  );

  const handleRenderDocActions = useCallback<OnRenderDocActions>(
    (props) => {
      return (
        <DocumentsActionsSP
          isDisabled={props.isDisabled}
          canHaveLoadingState={
            !props.data.documentServerId ||
            !notLoadedDocIds.has(String(props.data.documentServerId))
          }
          fileInstance={props.data}
          hasError={props.hasError}
          ownerClientId={packageAdapted.clientIdOwner}
        />
      );
    },
    [notLoadedDocIds, packageAdapted.clientIdOwner]
  );

  const nextFilesHasError = useRef(false);

  const handleGetFileErrorData: DocumentsPackEditorProps['onGetFileErrorData'] =
    useCallback(
      (fileInstance: IFileInstance, idx: number) => {
        const errorFromMap = docsErrorMap.get(fileInstance);
        if (idx === 0) nextFilesHasError.current = false;

        const result = getErrorDataByRejectReason(
          errorFromMap?.rejectReason,
          nextFilesHasError.current
        );

        if (result.nextFilesHasError) nextFilesHasError.current = true;
        return result;
      },
      [docsErrorMap]
    );

  const handleGetFileName: DocumentsPackEditorProps['onGetFileName'] =
    useCallback(
      (fileInstance) => {
        return docsUINameById[fileInstance.clientServerId];
      },
      [docsUINameById]
    );

  return (
    <DocumentsPackEditor
      {...docsPackSPStaticProps}
      className={className}
      isDisabled={isDisabled}
      onAddFiles={handleAddFiles}
      onGetFileErrorData={handleGetFileErrorData}
      onGetFileName={handleGetFileName}
      onRenderDocActions={handleRenderDocActions}
      value={docs}
    />
  );
};
export default memo(DocsPackEditorSP);
