import {
  MAX_FILE_SIZE_BYTES,
  MAX_PACK_SIZE_BYTES,
  ALLOWED_FILES_EXTENSIONS,
} from 'modules/Documents/components/CreatePackage/constants';
import type { IFileInstance } from 'modules/UI/types';
import { DocumentRejectReason } from '../constants';

export type ErrorData = {
  rejectReason: DocumentRejectReason;
};

export type ErrorMap = WeakMap<IFileInstance, ErrorData>;

const disallowedSymbolsRegexp = /[^ а-яёa-z0-9.,!@№$;^\-_+=()[\]]/gi;
const disallowedFileNamesRegexp = /^(CON|PRN|AUX|NUL)$|^((COM|LPT)[1-9])$/;

export default class DocumentValidator {
  private static getSizeRejectReason(
    file: File | IFileInstance
  ): DocumentRejectReason {
    if (file.size <= 0) return DocumentRejectReason.FileIsEmpty;
    if (file.size > MAX_FILE_SIZE_BYTES) {
      return DocumentRejectReason.FileIsTooLarge;
    }

    return DocumentRejectReason.FileIsOk;
  }

  static getUnfixableFileNameRejectReason(name: string): DocumentRejectReason {
    if (!name) return DocumentRejectReason.FileNameIsEmpty;
    const trimmed = name.trim().slice(0, 195);
    if (trimmed.length < 1) return DocumentRejectReason.FileNameIsEmpty;

    if (disallowedFileNamesRegexp.test(trimmed)) {
      return DocumentRejectReason.FileNameIsForbidden;
    }

    return DocumentRejectReason.FileIsOk;
  }

  static getCorrectedFileName(name: string | undefined): string | null {
    if (!name) return null;
    const rejectReason = this.getUnfixableFileNameRejectReason(name);
    if (rejectReason) return null;

    const trimmed = name.trim().slice(0, 195);

    let replaced = trimmed.replace(disallowedSymbolsRegexp, '_');
    if (replaced.startsWith('.')) replaced = `_${replaced.slice(1)}`;

    return replaced;
  }

  static getIsolatedFileRejectReason(
    fileInstance: IFileInstance,
    uiName?: string
  ): DocumentRejectReason {
    if (fileInstance.isWaitingServerFileData || fileInstance.hasDocument) {
      return DocumentRejectReason.FileIsOk;
    }

    const rejectionBySize = this.getSizeRejectReason(fileInstance);
    if (rejectionBySize) return rejectionBySize;

    const nameCheckResult = this.getUnfixableFileNameRejectReason(
      uiName || fileInstance.nameWithoutExtension
    );
    if (nameCheckResult) return nameCheckResult;

    if (!ALLOWED_FILES_EXTENSIONS.includes(fileInstance.fileExtWithDot)) {
      return DocumentRejectReason.FileExtIsForbidden;
    }

    return DocumentRejectReason.FileIsOk;
  }

  static validateFileName<
    T extends Pick<IFileInstance, 'nameWithoutExtension'>
  >(file: T, allFiles: T[], name: string | undefined): DocumentRejectReason {
    if (!name) return DocumentRejectReason.FileNameIsEmpty;

    const isNameUnique = allFiles.every((x) => {
      if (x === file) return true;
      return name !== x.nameWithoutExtension;
    });

    if (!isNameUnique) return DocumentRejectReason.FileNameAlreadyUsed;

    const rejectReason = this.getUnfixableFileNameRejectReason(name);
    if (rejectReason) return rejectReason;

    const fileName = this.getCorrectedFileName(name);
    if (fileName !== name) {
      return DocumentRejectReason.FileNameHasForbiddenSymbols;
    }

    return DocumentRejectReason.FileIsOk;
  }

  static getFilesErrorMap(
    allFiles: IFileInstance[],
    docNames: Readonly<{ [clientServerId: string]: string }> = {},
    notLoadedFileIds: Readonly<Set<string>> = new Set<string>()
  ): ErrorMap {
    const result = new WeakMap<IFileInstance, ErrorData>();
    const validFilenamesUsed: Set<string> = new Set<string>();
    let sizeCounter = 0;

    allFiles.forEach((x) => {
      const rejectReason = this.getIsolatedFileRejectReason(
        x,
        docNames[x.clientServerId]
      );
      const fileNotLoaded = notLoadedFileIds.has(String(x.clientServerId));
      const fileName = docNames[x.clientServerId] || x.nameWithoutExtension;

      if (rejectReason) {
        result.set(x, { rejectReason });
        //
      } else if (fileNotLoaded) {
        result.set(x, { rejectReason: DocumentRejectReason.FileWasNotLoaded });
        //
      } else if (!x.hasDocument && validFilenamesUsed.has(fileName)) {
        result.set(x, {
          rejectReason: DocumentRejectReason.FileNameAlreadyUsed,
        });
        //
      } else if (!x.hasDocument && sizeCounter + x.size > MAX_PACK_SIZE_BYTES) {
        result.set(x, { rejectReason: DocumentRejectReason.PackageIsTooLarge });
        //
      } else {
        validFilenamesUsed.add(fileName);
        sizeCounter += x.size;
      }
    });

    return result;
  }

  private static sortByName<T extends { nameWithoutExtension: string }>(
    a: T,
    b: T
  ): number {
    return a.nameWithoutExtension < b.nameWithoutExtension
      ? -1
      : b.nameWithoutExtension < a.nameWithoutExtension
      ? 1
      : 0;
  }

  private static sortBySize = <T extends { size: number }>(
    a: T,
    b: T
  ): number => {
    return a.size < b.size ? -1 : b.size < a.size ? 1 : 0;
  };

  static getSortedFilesList(
    currentFiles: IFileInstance[],
    newFiles: IFileInstance[]
  ): IFileInstance[] {
    const validFiles: IFileInstance[] = [];
    const invalidFiles: IFileInstance[] = [];

    const newFilesParsed = newFiles.slice().sort(this.sortBySize);

    const arr = [...currentFiles, ...newFilesParsed].slice().sort((a, b) => {
      if (a.hasDocument && b.hasDocument) return 0;

      if (!a.hasDocument && !b.hasDocument) {
        return this.sortBySize(a, b) || this.sortByName(a, b);
      }

      return !a.hasDocument ? 1 : !b.hasDocument ? -1 : 0;
    });

    arr.forEach((x) => {
      if (this.getIsolatedFileRejectReason(x)) invalidFiles.push(x);
      else validFiles.push(x);
    });

    return [...validFiles, ...invalidFiles];
  }
}
