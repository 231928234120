import { useDocumentApiClient } from 'modules/Api';
import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';
import type { RemoteUnitParticipantData } from '@sp-api/documents-api';
import type { ErrorResponse } from 'modules/App/types';
import DocumentsStateKeysEnum from '../constants/DocumentsStateKeysEnum';

export default function usePackages(): UseQueryResult<
  RemoteUnitParticipantData[],
  ErrorResponse
> {
  const documentApi = useDocumentApiClient();

  return useQuery<RemoteUnitParticipantData[], ErrorResponse>(
    DocumentsStateKeysEnum.List,
    async () => {
      const { data } = await documentApi.getPackages();
      return data?.remoteUnitParticipants || [];
    }
  );
}
