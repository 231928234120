import styled from 'styled-components';

export const Wrap = styled.div`
  position: relative;
`;

export const ImgPlaceholder = styled.img<{ visible: boolean }>`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: ${({ visible }) => (visible ? 0.5 : 0)};
  z-index: ${({ visible }) => (visible ? 0 : -1)};
  transition: opacity 200ms linear;
`;

export const Img = styled.img<{ visible?: boolean }>`
  display: block;
  left: 0;
  opacity: ${({ visible }) => (visible ? 0 : 1)};
  top: 0;
  transition: opacity 200ms linear;
  width: 100%;
`;
