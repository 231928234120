import { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import { useModal } from 'modules/Common/hooks';
import Modal, {
  ModalButtonPanel,
  ModalContent,
  ModalTitle,
} from 'modules/UI/components/Modal';
import type { Location } from 'history';
import type { ModalOnLeavingDraftProps } from './types';

const ModalOnLeavingDraft = ({
  when = true,
  navigate,
  shouldBlockNavigation,
  onSaveDraft,
}: ModalOnLeavingDraftProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useModal(false);
  const [leavingLocation, setLeavingLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleCheckIfCanNavigate = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      onOpen();
      setLeavingLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleSaveAndNavigate = async () => {
    try {
      await onSaveDraft?.();
      onClose();
      setConfirmedNavigation(true);
    } catch (e) {
      // TODO add notification about error
      onClose();
    }
  };

  const handleNavigateWithoutSave = () => {
    onClose();
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && leavingLocation) {
      navigate(leavingLocation.pathname);
    }
  }, [navigate, confirmedNavigation, leavingLocation]);

  return (
    <>
      <Prompt when={when} message={handleCheckIfCanNavigate} />

      {isOpen && (
        <Modal onClose={onClose}>
          <ModalTitle>Сохранить комплект документов перед выходом?</ModalTitle>

          <ModalContent>
            Вы можете сохранить комплект документов в черновиках и позже
            вернуться к редактированию
          </ModalContent>

          <ModalButtonPanel>
            <Button
              onClick={handleSaveAndNavigate}
              size={ButtonSizesEnum.Medium}
              color={ButtonColorsEnum.Primary}
            >
              Сохранить
            </Button>

            <Button
              onClick={handleNavigateWithoutSave}
              color={ButtonColorsEnum.Secondary}
              size={ButtonSizesEnum.Medium}
            >
              Не сохранять
            </Button>
          </ModalButtonPanel>
        </Modal>
      )}
    </>
  );
};

export default ModalOnLeavingDraft;
