import InputField from '@signplatform-ui/input-field';
import * as React from 'react';
import type {
  ChangeEventHandler,
  FocusEventHandler,
  InputHTMLAttributes,
} from 'react';

type EmailInputProps = InputHTMLAttributes<HTMLInputElement> & {
  name?: string;
  label?: string;
  placeholder?: string;
  status?: 'error' | 'success';
  extraText?: string;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  className?: string;
  disabled?: boolean;
};

const EMAIL_LABEL = 'Email';
const EMAIL_PLACEHOLDER = 'Введите адрес электронной почты';

const EmailInput = ({
  label = EMAIL_LABEL,
  placeholder = EMAIL_PLACEHOLDER,
  extraText,
  status,
  name,
  className,
  onChange,
  onBlur,
  value = '',
  ...rest
}: EmailInputProps): JSX.Element => {
  return (
    <InputField
      className={className}
      displayClearIcon={!rest.disabled && !!value?.length}
      extraText={extraText}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      status={status}
      value={value}
      {...rest}
    />
  );
};

export default EmailInput;
