import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import getRemainingTime from '../utils/getRemainingTime';
import getInterval from '../utils/getInterval';

export default function useRemainingTime(dueDate: string): string {
  const [remainingTime, setRemainingTime] = useState(() =>
    getRemainingTime(dueDate)
  );

  useEffect(() => {
    let refreshTimeout: ReturnType<typeof setTimeout> | null = null;
    let mounted = true;

    const startInterval = (): ReturnType<typeof setTimeout> | null =>
      setTimeout(() => {
        if (mounted) {
          setRemainingTime(getRemainingTime(dueDate));
        }

        if (DateTime.fromISO(dueDate) > DateTime.now()) {
          refreshTimeout = startInterval();
        }
      }, getInterval(dueDate));

    if (DateTime.fromISO(dueDate) > DateTime.now()) {
      refreshTimeout = startInterval();
    }

    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return remainingTime;
}
