import {
  Configuration,
  ReferencesServiceApi as DefaultApi,
} from '@pa-api/pa-logic-references';
import { apiService } from 'modules/Api/components/ApiProvider/services';
import { useConfiguration } from 'modules/Shared/hooks';

const useApi = apiService.createUseApi(DefaultApi, Configuration);

const useLogicReferencesApi = (): DefaultApi => {
  const [{ logicReferencesContextUrl }] = useConfiguration();
  return useApi(logicReferencesContextUrl);
};

export default useLogicReferencesApi;
