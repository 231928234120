import type { FoundOrInvitedCounterparty } from 'modules/Documents/components/PackageWizard/types';
import type { CounterpartyPerson } from '../types';

export default function isCounterpartyPerson(
  counterpartyData: DeepROStrict<FoundOrInvitedCounterparty>
): counterpartyData is CounterpartyPerson {
  const asserted = counterpartyData as DeepRO<CounterpartyPerson>;

  return !!(
    asserted.firstName ||
    asserted.lastName ||
    asserted.middleName ||
    asserted.snils ||
    asserted.idNum
  );
}
