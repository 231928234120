import { yupResolver } from '@hookform/resolvers/yup';
import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import Spinner, { SpinnerDimensionsEnum } from '@signplatform-ui/spinner';
import {
  useFieldSwitchCache,
  useSetFieldErrorByResponse,
} from 'modules/Common/hooks';
import {
  useFindUser,
  useCounterpartiesContextMin,
} from 'modules/Documents/hooks';
import {
  FormEmailInput,
  FormPassportInput,
  FormPersonInnInput,
  FormPhoneInput,
  FormSnilsInput,
} from 'modules/UI/components/form';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SigningAdapterSearchClients } from 'modules/Documents/classes';
import type {
  ControlBySearchField,
  CounterpartyClient,
  SearchFormData,
  SearchFormProps,
} from 'modules/Documents/types';
import tabsList from '../../tabsList';
import CounterpartiesResult from './components/CounterpartiesResult';
import formSchema from './formSchema';
import {
  SpinnerWrapper,
  StyledForm,
  StyledFormGroup,
  StyledInput,
} from './SearchForm.styled';

const componentByType: ControlBySearchField = {
  snils: FormSnilsInput,
  inn: FormPersonInnInput,
  idNum: FormPassportInput,
  email: FormEmailInput,
  phone: FormPhoneInput,
};

const RAW_FIELDS = new Set(['inn', 'phone']);

const SearchForm = ({
  onSelect,
  selected,
  onInviteUser,
  searchType,
}: SearchFormProps): JSX.Element => {
  const { value } = useCounterpartiesContextMin();

  const methods = useForm<SearchFormData>({
    resolver: yupResolver(formSchema),
    shouldUnregister: true,
    context: {
      selectedField: searchType,
    },
  });

  const {
    handleSubmit,
    setError,
    formState: { errors },
    setValue,
    watch,
  } = methods;

  const formData = watch();

  // поиск юзера по вызову колбека с параметрами
  const [{ isLoading, isFetching, data, error, isError }, handleFindUsers] =
    useFindUser();

  // восстановление предыдущего значения поля при обратном переключении вкладки
  const cache = useFieldSwitchCache(formData, searchType, setValue, value);

  // установка ошибки в поле при ответе сервера
  useSetFieldErrorByResponse({ error, isError }, (e) => {
    setError(searchType, e);
  });

  // автовыбор юзера при получении данных
  useEffect(() => {
    if (!data) return;
    const { formValues } = new SigningAdapterSearchClients(data);

    if (formValues.length === 1) {
      // если нашли одного юзера
      onSelect(new Set(formValues));
    } else {
      // если несколько или ни одного
      onSelect(new Set());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // поиск по значению при маунте
  useEffect(() => {
    const initValueToSearch = value[searchType as 'snils'];
    if (initValueToSearch) {
      setValue(searchType, initValueToSearch);
      void handleSubmit(handleFindUsers)();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // трансформация списков в общий набор при получении данных
  const dataSet = useMemo(() => {
    if (!data) return new Set<CounterpartyClient>();
    const { formValues } = new SigningAdapterSearchClients(data);

    return new Set<CounterpartyClient>(formValues);
  }, [data]);

  const handleInviteUser = useCallback(() => {
    onInviteUser({ ...formData, ...cache });
  }, [onInviteUser, formData, cache]);

  const maskedProps = {
    unmasked: RAW_FIELDS.has(searchType),
  };

  const formError = errors[searchType];

  const foundTab = tabsList.find(({ id }) => id === searchType);

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(handleFindUsers)}>
        <StyledFormGroup>
          <StyledInput
            as={componentByType[searchType]}
            data-testid={`find-by-${searchType}-field`}
            error={formError}
            id={foundTab?.id}
            label=''
            name={searchType}
            placeholder={foundTab?.placeholder}
            {...maskedProps}
          />

          <Button
            size={ButtonSizesEnum.Medium}
            color={
              formData[searchType] && !formError
                ? ButtonColorsEnum.Primary
                : ButtonColorsEnum.Secondary
            }
            disabled={isFetching}
            type='submit'
            data-testid='find-counterparties-button'
          >
            Найти
          </Button>
        </StyledFormGroup>

        {isLoading && (
          <SpinnerWrapper>
            <Spinner dimension={SpinnerDimensionsEnum.M} />
          </SpinnerWrapper>
        )}

        {!isLoading && data && (
          <CounterpartiesResult
            data={dataSet}
            onInviteUser={handleInviteUser}
            onSelect={onSelect}
            selected={selected}
          />
        )}
      </StyledForm>
    </FormProvider>
  );
};

export default memo(SearchForm);
