import Button from '@signplatform-ui/button';
import Header from '@signplatform-ui/header';
import { typography } from '@signplatform-ui/typographics';
import styled, { css } from 'styled-components';
import { media } from 'modules/StyledUtility';

export const StyledText = styled.div`
  ${typography['Additional/L']}
`;

export const StyledHeader = styled(Header)`
  margin-bottom: 16px;
`;

export const StyledButton = styled(Button)`
  margin-top: 24px;

  ${media.mobileXS(css`
    width: 100%;
  `)}
`;
