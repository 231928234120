import type { FoundOrInvitedCounterparty } from 'modules/Documents/components/PackageWizard/types';

export type ExcludedCpData = { clientIdSigner?: number };
type TrapType = never;

export type CpTypeTrap<T> = T extends ExcludedCpData ? TrapType : T;

// использовать только при невозможности использовать дженерики, например в гвардах
export const assertNotUsedIdSigner = (
  data: Record<string, unknown>
): never | void => {
  if (
    (data as ExcludedCpData).clientIdSigner &&
    !(data as FoundOrInvitedCounterparty).clientId
  ) {
    throw new Error('assertion error');
  }
};
