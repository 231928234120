import { useUser } from 'modules/Shared/hooks';
import { AppHeaderWrapper } from './AppHeader.styled';
import UserProfile from './components/UserProfile';

const AppHeader = (): JSX.Element => {
  const { user } = useUser();
  return (
    <AppHeaderWrapper>{user && <UserProfile user={user} />}</AppHeaderWrapper>
  );
};

export default AppHeader;
