import { TaskStatusStatusEnum } from '@sp-api/user-api';

export const readyStatuses = [
  TaskStatusStatusEnum.Success,
  TaskStatusStatusEnum.Error,
];

export const documentUserReady = (
  status: TaskStatusStatusEnum | undefined
): boolean => (status ? readyStatuses.includes(status) : false);
