import { useMemo } from 'react';
import useToggler from './useToggler';

export type UseModalResult = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
};

export default function useModal(initialValue = false): UseModalResult {
  const { off, on, toggle, isActive } = useToggler(initialValue);

  return useMemo(
    () => ({ isOpen: isActive, onOpen: on, onClose: off, onToggle: toggle }),
    [isActive, on, off, toggle]
  );
}
