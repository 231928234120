import {
  emailSchema,
  passportSchema,
  requiredStringSchema,
  snilsSchema,
} from 'services/schemaValidation';
import * as yup from 'yup';

const schema = yup.object().shape(
  {
    snils: yup.string().when('idNum', {
      is: (idNum: string) => !idNum,
      then: requiredStringSchema.concat(snilsSchema),
      otherwise: yup.string().length(0),
    }),
    idNum: yup.string().when('snils', {
      is: (snils: string) => !snils,
      then: requiredStringSchema.concat(passportSchema),
      otherwise: yup.string().length(0),
    }),
    email: requiredStringSchema.concat(emailSchema),
  },
  [['snils', 'idNum']]
);

export default schema;
