import { QueryClient, QueryClientProvider } from 'react-query';
import MetaTags from 'react-meta-tags';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import { ThemeProvider } from 'styled-components';
import AppGlobalStyles from 'modules/Shared/AppGlobalStyles';
import { useAppTheme } from 'modules/Shared/hooks';
import ErrorBoundary from 'modules/Shared/components/ErrorBoundary';
import Router from '../Router';
import {
  APPLICATION_DESCRIPTION,
  APPLICATION_TITLE,
} from './constants/AppConstants';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App(): JSX.Element {
  const theme = useAppTheme();

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <GridThemeProvider gridTheme={theme.gridConfig}>
          <>
            <MetaTags>
              <title>{APPLICATION_TITLE}</title>
              <meta name='description' content={APPLICATION_DESCRIPTION} />
            </MetaTags>

            <AppGlobalStyles />

            <QueryClientProvider client={queryClient}>
              <Router />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </>
        </GridThemeProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
