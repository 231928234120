import { DeleteOutline } from '@signplatform-ui/icons';
import styled, { css } from 'styled-components';
import flex from 'modules/StyledUtility/Flex';
import FileIconByExt from 'modules/UI/components/FileIconByExt';
import Button from '@signplatform-ui/button';
import { media } from 'modules/StyledUtility';
import { StyledCard } from '../../DocumentsPackEditor.styled';

export const StyledFileIconByExt = styled(FileIconByExt)<{
  $disabled?: boolean;
}>`
  margin-right: 12px;
  flex: 0 0 32px;
  height: 40px;
  padding: 4px 0;
  align-self: flex-start;

  & path {
    fill: ${({ theme, $disabled }) =>
      $disabled ? theme.color.text.tertiary : undefined};
  }
`;

export const StyledButtonWithIcon = styled(Button)`
  &,
  &:hover,
  &:focus,
  &:active {
    svg,
    svg path {
      ${({ disabled }) => (disabled ? undefined : 'fill: currentColor')};
    }
  }
`;

export const StyledDeleteOutline = styled(DeleteOutline)<{
  $hasError?: boolean;
}>`
  color: ${({ $hasError, theme }) =>
    $hasError ? theme.color.status.danger : theme.color.text.secondary};
`;

export const StyledIconBox = styled.div`
  ${flex.center}
  width: 40px;
  height: 40px;
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }
`;

export const StyledItemCard = styled(StyledCard)`
  ${flex.startCenter};
  min-height: 60px;
`;

export const StyledColDocumentName = styled.div`
  ${flex.flowCol};
  flex: 1 1 auto;
  overflow-wrap: break-word;
  overflow: hidden;
  margin-right: 12px;
`;

export const StyledExtSizeInfo = styled.div`
  min-width: 111px;
  margin-right: 49px;
`;

export const StyledFlexStartCenter = styled.div`
  ${flex.startCenter};
  min-height: 24px;

  ${media.mobile(css`
    flex-flow: column nowrap;
    align-items: flex-start;
  `)}
`;

export const StyledUploadedDate = styled.div`
  ${media.mobile(css`
    margin-top: 8px;
  `)}
`;

export const StyledColFlexEndCenter = styled.div`
  ${flex.endCenter};
  align-self: flex-start;
`;
