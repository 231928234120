import Text, { TextTypesEnum } from '@signplatform-ui/text';
import { Container } from 'styled-bootstrap-grid';
import { useTheme } from 'styled-components';
import AgreementsRoutesEnum from 'modules/Router/routes/paths/AgreementsRoutesEnum';
import React from 'react';
import { FooterColorEnum } from './types';
import {
  CollFooterText,
  FooterContent,
  LinkStyled,
  PhoneNumberLink,
  StyledRow,
} from './Footer.styled';

export type FooterProps = {
  color: FooterColorEnum;
};

const Footer = ({ color }: FooterProps): JSX.Element => {
  const theme = useTheme();

  const textColor =
    color === FooterColorEnum.primary
      ? theme.color.text.secondary
      : theme.color.background.tertiaryHover;

  return (
    <FooterContent $color={color}>
      <Container fluid>
        <StyledRow>
          <CollFooterText sm={6}>
            <LinkStyled
              to={AgreementsRoutesEnum.AgreementsList}
              $color={textColor}
              data-testid='agreements-link'
            >
              <Text as='span' type={TextTypesEnum.S} color={textColor}>
                Правила использования «Платформы подписания»
              </Text>
            </LinkStyled>

            <div>
              <a
                target='_blank'
                href='/docs/Политика обработки персональных данных.pdf'
              >
                <Text as='span' type={TextTypesEnum.S} color={textColor}>
                  Политика обработки персональных данных
                </Text>
              </a>
            </div>
            <Text color={textColor} type={TextTypesEnum.S}>
              АО «Цифровые документы», ИНН 7720852788
            </Text>
          </CollFooterText>
          <CollFooterText sm={6}>
            <PhoneNumberLink
              href='tel:+78005050521'
              $color={textColor}
              data-testid='phone-link'
            >
              8 (800) 505 05 21
            </PhoneNumberLink>
            <Text color={textColor} type={TextTypesEnum.S}>
              Техническая поддержка по <br /> рабочим дням с 9:00 до 18:00 (Мск)
            </Text>
          </CollFooterText>
        </StyledRow>
      </Container>
    </FooterContent>
  );
};

export default Footer;
