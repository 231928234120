import { ButtonColorsEnum, ButtonSizesEnum } from '@signplatform-ui/button';
import DropdownButton, {
  MenuPositionsEnum,
} from '@signplatform-ui/dropdownbutton';
import { MoreHorizontalOutline } from '@signplatform-ui/icons';
import PackageSigningErrorModal from 'modules/Documents/components/PackageSigningErrorModal';
import SignButton from 'modules/Documents/components/SignButton';
import { usePackageContext } from 'modules/Documents/contexts';
import { useSignPackage } from 'modules/Documents/hooks';
import { useMemo } from 'react';
import { useMedia } from 'modules/Shared/hooks';
import ResignButton from 'modules/Documents/components/ResignButton';
import type { MenuItemProp } from '@signplatform-ui/menu';
import DownloadDocsPack, { useDownloadPackAction } from '../DownloadDocsPack';

const PackageCardHeaderActions = ({
  resignThreshold,
  resignTooltip,
  onSignSuccess,
}: {
  resignThreshold?: number;
  resignTooltip?: React.ReactNode;
  onSignSuccess?: () => Promise<void>;
}): JSX.Element | null => {
  const {
    packageAdapted: { packageId, clientIdOwner, buttons },
  } = usePackageContext();
  const { isMobile } = useMedia();
  const { mutateAsync, isLoading, isError, reset } = useSignPackage();
  const { onDownload, downloadRequest } = useDownloadPackAction({
    ownerClientId: clientIdOwner,
    packageId,
  });

  const items: MenuItemProp[] = useMemo(() => {
    const result: MenuItemProp[] = [];

    if (buttons.signButton) {
      result.push({
        id: 'sign',
        title: 'Подписать',
        isDisabled: isLoading,
        onClick: async (e) => {
          e.preventDefault();

          await mutateAsync({
            packageId,
            clientIdOwner,
          });

          await onSignSuccess?.();
        },
      });
    }

    if (buttons.resignButton) {
      result.push({
        id: 'resign',
        title: 'Подписать еще раз',
        isDisabled: isLoading || !!resignThreshold,
        onClick: async (e) => {
          e.preventDefault();

          await mutateAsync({
            packageId,
            clientIdOwner,
          });

          await onSignSuccess?.();
        },
      });
    }

    if (buttons.downloadButton) {
      result.push({
        id: 'download',
        title: 'Скачать',
        isDisabled: downloadRequest.isLoading,
        onClick: (e) => {
          e.preventDefault();
          void onDownload();
        },
      });
    }

    return result;
  }, [
    buttons.signButton,
    buttons.resignButton,
    buttons.downloadButton,
    isLoading,
    mutateAsync,
    packageId,
    clientIdOwner,
    downloadRequest.isLoading,
    onDownload,
    resignThreshold,
    onSignSuccess,
  ]);

  return (
    <>
      {isMobile && !!items.length && (
        <>
          <DropdownButton
            color={ButtonColorsEnum.Ghost}
            size={ButtonSizesEnum.Medium}
            items={items}
            displayAsSquare
            position={MenuPositionsEnum.Right}
            data-testid='document-actions-dropdown'
          >
            <MoreHorizontalOutline />
          </DropdownButton>

          {isError && <PackageSigningErrorModal onClose={reset} />}
        </>
      )}

      {!isMobile && (
        <>
          {buttons.downloadButton && (
            <DownloadDocsPack
              onDownload={onDownload}
              isLoading={downloadRequest.isLoading}
              small={buttons.signButton || buttons.resignButton}
            />
          )}

          {buttons.signButton && (
            <SignButton
              ownerClientId={clientIdOwner}
              packageId={packageId}
              onSignSuccess={onSignSuccess}
              size={ButtonSizesEnum.Medium}
              data-testid='document-sign-button'
            />
          )}
          {buttons.resignButton && (
            <ResignButton
              ownerClientId={clientIdOwner}
              packageId={packageId}
              onSignSuccess={onSignSuccess}
              size={ButtonSizesEnum.Medium}
              color={ButtonColorsEnum.Secondary}
              data-testid='document-resign-button'
              tooltip={resignThreshold ? resignTooltip : undefined}
              disabled={!!resignThreshold}
            >
              Подписать еще раз
            </ResignButton>
          )}
        </>
      )}
    </>
  );
};

export default PackageCardHeaderActions;
