import MaskedInput from 'modules/UI/components/form/MaskedInput';
import type { ChangeEventHandler, FocusEventHandler } from 'react';

type PassportInputProps = {
  name?: string;
  label?: string;
  placeholder?: string;
  status?: 'error' | 'success';
  extraText?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  value?: string;
  unmasked?: boolean;
  disabled?: boolean;
};

const PASSPORT_FORMAT_MASK = '0000 000000';
const PASSPORT_LABEL = 'Документ, удостоверяющий личность';
const PASSPORT_PLACEHOLDER = 'Серия и номер документа';

const maskOptions = {
  mask: PASSPORT_FORMAT_MASK,
};

const PassportInput = ({
  name,
  label = PASSPORT_LABEL,
  placeholder = PASSPORT_PLACEHOLDER,
  status,
  extraText,
  className,
  onChange,
  value = '',
  ...rest
}: PassportInputProps): JSX.Element => {
  return (
    <MaskedInput
      className={className}
      displayClearIcon={!rest.disabled && !!value?.length}
      extraText={extraText}
      label={label}
      maskOptions={maskOptions}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      status={status}
      value={value}
      {...rest}
    />
  );
};

export default PassportInput;
