enum PowAttorneyRoutesEnum {
  PowAttorneyList = '/powattorney',
  PowAttorneyWizard = '/powattorney/wizard',
  PowAttorneyDraft = '/powattorney/wizard/:draftId',
  PowAttorneyDetails = '/powattorney/:attorneyId/view',
  PowAttorneyPreview = '/powattorney/:attorneyId/preview',
  PowAttorneyNotFound = '/powattorney/not-found',
}

export default PowAttorneyRoutesEnum;
