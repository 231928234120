import { memo } from 'react';
import type { ReactNode } from 'react';
import { Main, Wrapper, MainContent } from './PublicLayout.styled';

export type PropsPublicLayout = {
  children?: ReactNode;
  contentCentered?: boolean;
  footer?: JSX.Element;
  header?: JSX.Element;
};

const PublicLayout = ({
  children,
  contentCentered,
  header,
  footer,
}: PropsPublicLayout) => {
  return (
    <Wrapper>
      {header}
      <Main>
        <MainContent contentCentered={contentCentered}>{children}</MainContent>
        {footer}
      </Main>
    </Wrapper>
  );
};

export default memo(PublicLayout);
