import styled from 'styled-components';
import InputField from '@signplatform-ui/input-field';
import { MoreHorizontalOutline } from '@signplatform-ui/icons';
import flex from 'modules/StyledUtility/Flex';

export const ModalActionsWrapper = styled.div`
  ${flex.endCenter}

  button + button {
    margin-left: 8px;
  }
`;

export const StyledInput = styled(InputField)`
  margin-bottom: 16px;
`;

export const StyledText = styled.p`
  margin-bottom: 16px;
  word-break: break-word;
`;

export const StyledMoreHorizontal = styled(MoreHorizontalOutline)``;
