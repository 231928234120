import Button, { ButtonColorsEnum } from '@signplatform-ui/button';
import PackageSigningErrorModal from 'modules/Documents/components/PackageSigningErrorModal';
import useSignPackage from 'modules/Documents/hooks/useSignPackage';
import notifySigningSuccess from 'modules/Documents/utils/notifySigningSuccess';
import type { PropsWithChildren } from 'react';
import type { ButtonProps } from '@signplatform-ui/button';

export type SignButtonProps = PropsWithChildren<
  ButtonProps & {
    onSignSuccess?: () => Promise<void> | void;
    ownerClientId: EntityId;
    packageId: EntityId;
    isSigner?: boolean;
  }
>;

const SignButton = ({
  onSignSuccess,
  ownerClientId,
  packageId,
  children = 'Подписать',
  ...buttonProps
}: SignButtonProps): JSX.Element => {
  const { isLoading, isError, reset, mutateAsync, error } = useSignPackage();

  const onSign = async () => {
    await mutateAsync({ packageId, clientIdOwner: ownerClientId });
    await onSignSuccess?.();
    notifySigningSuccess();
  };

  return (
    <>
      <Button
        color={ButtonColorsEnum.Secondary}
        onClick={onSign}
        disabled={isLoading}
        data-testid='document-sign-button'
        {...buttonProps}
      >
        {children}
      </Button>
      {isError && (
        <PackageSigningErrorModal
          onClose={reset}
          displayCode={error?.data?.displayCode}
        />
      )}
    </>
  );
};

export default SignButton;
