// if we place class instances into react-hook-form, it clones them with "for in" cycle
// therefore we can lose all non-enumerable getters
// when we add getter in class, JS places it in prototype and makes it enumerable: false
// this decorator makes all properties in prototype enumerable
export default function makeEnumerablePrototype<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { new (...args: any[]): any }
>(constructor: T): void {
  const descriptorsMap = Object.getOwnPropertyDescriptors(
    constructor.prototype
  );

  Object.keys(descriptorsMap).forEach((key) => {
    if (key === 'constructor') return;

    Object.defineProperty(constructor.prototype, key, {
      ...descriptorsMap[key],
      enumerable: true,
    });
  });
}
