import { css } from 'styled-components';

// сначала описывается justify-content, затем align-items
// если они совпадают, записывается в одно слово
const flex = {
  center: css`
    align-items: center;
    display: flex;
    justify-content: center;
  `,
  centerStretch: css`
    align-items: stretch;
    display: flex;
    justify-content: center;
  `,
  centerStart: css`
    align-items: flex-start;
    display: flex;
    justify-content: center;
  `,
  betweenCenter: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  endCenter: css`
    align-items: center;
    display: flex;
    justify-content: flex-end;
  `,
  start: css`
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
  `,
  startCenter: css`
    align-items: center;
    display: flex;
    justify-content: flex-start;
  `,
  flowCol: css`
    display: flex;
    flex-flow: column nowrap;
  `,
  flowRow: css`
    display: flex;
    flex-flow: row nowrap;
  `,
};

export const flexWithFlow = {
  colStart: css`
    ${flex.flowCol}
    ${flex.start}
  `,
};

export default flex;
