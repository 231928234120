import Header from '@signplatform-ui/header';
import styled from 'styled-components';

export const StyledHeader = styled(Header)`
  margin-bottom: 16px;
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.color.text.primary};
  margin-bottom: 16px;
`;

export const StyledForm = styled.form`
  padding-top: 24px;
`;

export const StyledInput = styled.input`
  & + & {
    margin-top: 24px;
  }
`;
