import useCurrentUser from 'modules/Auth/hooks/useCurrentUser';
import { usePrevious } from 'modules/Common/hooks';
import AppRoutesPaths from 'modules/Router/routes/paths';
import { PageLoading } from 'modules/UI';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useToken } from 'modules/Shared/hooks';
import type { ReactNode } from 'react';
import type { UserProfile } from '@sp-api/user-api';
import { UserContextExtended } from './UserContext';
import { Wrapper } from './UserProvider.styled';

type UserProviderProps = {
  children: ReactNode;
};

const UserProvider = ({ children }: UserProviderProps): JSX.Element => {
  const [isReady, setReady] = useState(false);
  const [user, setUser] = useState<UserProfile | null>(null);

  const { token, setToken } = useToken();
  const prevToken = usePrevious(token);
  const tokenPathMatch = useRouteMatch(AppRoutesPaths.Token);
  const { data, error, refetch } = useCurrentUser(false);

  useEffect(() => {
    if (!data) {
      return;
    }

    // Profile fetched, set app is initialized
    setUser(data);
    setReady(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!error) {
      return;
    }

    // Reset token and profile if token is not valid
    setToken(null);
    setReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    // If token is empty or route path is the token component path, set app initialized
    if (tokenPathMatch || !token) {
      setReady(true);
      return;
    }

    // Fetch user profile on set new token
    if (!prevToken) {
      setReady(false);
      void refetch();
    } else if (!token) {
      setUser(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (!isReady || (!!token && !user && !tokenPathMatch)) {
    return (
      <Wrapper>
        <PageLoading />
      </Wrapper>
    );
  }

  return (
    <UserContextExtended.Provider value={{ user, setUser }}>
      {children}
    </UserContextExtended.Provider>
  );
};

export default UserProvider;
