import { SignerSignStatusEnum } from 'modules/Documents/types';
import styled, { css } from 'styled-components';
import type {
  StatusInviteEnum,
  StatusSigningEnum,
} from '@sp-api/documents-api';
import Status from '../Status';

const statusesMap: Record<SignerSignStatusEnum, ReturnType<typeof css>> = {
  [SignerSignStatusEnum.Signed]: css`
    background-color: ${({ theme }) => theme.color.special.softGreen};
    border: 1px solid ${({ theme }) => theme.color.status.success};
  `,
  [SignerSignStatusEnum.Inviting]: css`
    background-color: ${({ theme }) => theme.color.special.softBlue};
    border: 1px solid ${({ theme }) => theme.color.special.blue};
  `,
  [SignerSignStatusEnum.Waiting]: css`
    background-color: ${({ theme }) => theme.color.special.softBlue};
    border: 1px solid ${({ theme }) => theme.color.special.blue};
  `,
  [SignerSignStatusEnum.Draft]: css`
    background-color: ${({ theme }) => theme.color.background.tertiary};
    border: 1px solid ${({ theme }) => theme.color.basic.tertiary};
  `,
  [SignerSignStatusEnum.Error]: css`
    background-color: ${({ theme }) => theme.color.special.softRed};
    border: 1px solid ${({ theme }) => theme.color.special.red};
  `,
  [SignerSignStatusEnum.RejectedByOwner]: css`
    background-color: ${({ theme }) => theme.color.special.softRed};
    border: 1px solid ${({ theme }) => theme.color.special.red};
  `,
  [SignerSignStatusEnum.Rejected]: css`
    background-color: ${({ theme }) => theme.color.special.softRed};
    border: 1px solid ${({ theme }) => theme.color.special.red};
  `,
  [SignerSignStatusEnum.SignProcess]: css`
    background-color: ${({ theme }) => theme.color.special.softBlue};
    border: 1px solid ${({ theme }) => theme.color.special.blue};
  `,
  [SignerSignStatusEnum.Expired]: css`
    background-color: ${({ theme }) => theme.color.special.softRed};
    border: 1px solid ${({ theme }) => theme.color.special.red};
  `,
  [SignerSignStatusEnum.QesIssueProcess]: css`
    background-color: ${({ theme }) => theme.color.special.softRed};
    border: 1px solid ${({ theme }) => theme.color.special.red};
  `,
};

// eslint-disable-next-line import/prefer-default-export
export const StatusStyled = styled(Status)<{
  status: StatusInviteEnum | StatusSigningEnum;
}>`
  border-radius: 4px;
  padding: 3px 8px;
  ${({ status }) => statusesMap[status]};

  svg {
    margin-right: 4px;
    width: 16px;
    height: auto;
  }
`;

export const IconStyled = styled.svg<{
  $color: string;
}>`
  width: 24px;
  color: ${({ $color }) => $color};
  margin-right: 8px;
  flex-shrink: 0;

  path {
    fill: currentColor;
  }
`;
