import { makeEnumerablePrototype } from 'modules/Common/services';
import type { SignerBaseWithUniFields } from 'modules/Documents/types';
import type {
  InviteStatusEnumUni,
  SignerSignStatusUni,
  SigningFileData,
} from 'modules/Documents/types';

type ISignMixin = PartialToUndefined<SignerBaseWithUniFields>;

@makeEnumerablePrototype
export default class SignMixin<
  T extends InviteStatusEnumUni | SignerSignStatusUni
> implements ISignMixin
{
  readonly status;
  readonly signingId;
  readonly rejectReason;
  readonly sendDate;
  readonly signingFileData;

  constructor(source: {
    status?: T;
    signingId?: EntityId;
    rejectReason?: string;
    sendDate?: string;
    signingFileData?: SigningFileData;
  }) {
    this.status = source.status;
    this.signingId = source.signingId;
    this.rejectReason = source.rejectReason;
    this.sendDate = source.sendDate;
    this.signingFileData =
      source.signingFileData as PartialToUndefined<SigningFileData>;
  }
}
