import type { CounterpartySearchField } from 'modules/Documents/types';

const tabs: {
  id: CounterpartySearchField;
  content: string;
  placeholder?: string;
  'data-testid'?: string;
  'tab-index'?: number;
}[] = [
  {
    id: 'idNum',
    content: 'Паспорт',
    placeholder: 'Введите серию и номер паспорта',
    'data-testid': 'idNum-tab',
  },
  {
    id: 'phone',
    content: 'Телефон',
    placeholder: 'Введите телефон',
    'data-testid': 'phone-tab',
  },
  {
    id: 'snils',
    content: 'СНИЛС',
    placeholder: 'Введите СНИЛС',
    'data-testid': 'snils-tab',
    'tab-index': -1,
  },
  {
    id: 'inn',
    content: 'ИНН',
    placeholder: 'Введите ИНН',
    'data-testid': 'inn-tab',
  },
];

export default tabs;
