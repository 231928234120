import {
  getFileContent,
  makeEnumerablePrototype,
} from 'modules/Common/services';
import { mimeToExtWithDot } from 'modules/Documents/components/CreatePackage/constants';
import DocumentValidator from 'modules/Documents/classes/DocumentValidator';
import type {
  DocumentFullData,
  FileData as FileDataServer,
} from 'modules/Documents/types';
import type { SigningsAdaptedInput } from 'modules/Documents/classes';
import type {
  FileClientData,
  FileData,
  FileServerData,
  IFileInstance,
} from '../types';

type MapServerData = {
  clientId: EntityId;
  docsUINameById: Readonly<{ [clientServerId: string]: string }>;
  instance: IFileInstance;
  packageId: EntityId;
  signingsData: SigningsAdaptedInput;
};

// Адаптер
// Решаем проблему, когда у нас в разное время может присутствовать разное представление файла:
// только клиентское, клиент+сервер, только серверное
@makeEnumerablePrototype
export default class ClientServerFile implements IFileInstance {
  private static customIdSequencer = 1;

  static getCorrectedFileName(name: string): string | null {
    return DocumentValidator.getCorrectedFileName(name);
  }

  static getNewClientId(): string {
    return `clientSideTempId_${this.customIdSequencer++}`;
  }

  static updateServerFiles(
    oldData: IFileInstance[],
    newData: IFileInstance[]
  ): IFileInstance[] {
    const newById = newData.reduce<{ [serverId: string]: IFileInstance }>(
      (acc, x) => {
        if (x.documentServerId) acc[x.documentServerId] = x;
        return acc;
      },
      {}
    );

    return oldData.map((x) => {
      if (x.documentServerId && newById[x.documentServerId]) {
        return newById[x.documentServerId];
      }

      return x;
    });
  }

  static async getContent(instance: IFileInstance): Promise<string> {
    if (!instance.hasClientFile) return '';
    return getFileContent((instance.source as FileClientData).upload?.file);
  }

  static mapServer({
    clientId,
    docsUINameById,
    instance,
    packageId,
    signingsData,
  }: MapServerData): FileServerData['document'] {
    const name =
      docsUINameById[instance.clientServerId] || instance.nameWithoutExtension;

    if (this.hasDocument(instance.source)) {
      return {
        ...instance.source.document,
        ...signingsData,
        name: name || instance.source.document.name,
      };
    }

    return {
      fileUUID: instance.fileUUID,
      packageId,
      name,
      needSign: true,
      // typeSign: TypeSign.Qes,
      documentFileData: {
        documentId: undefined as unknown as number,
        clientIdOwner: clientId,
      },
      ...signingsData,
    };
  }

  static getNameWithoutExtension(name: string): string {
    if (!name) return 'Безымянный документ';

    const splitted = name.split('.');
    if (splitted.length <= 1) return name;

    return splitted.slice(0, -1).join('.');
  }

  private static hasDocument(data: FileData): data is FileServerData {
    return !!(data as FileServerData).document;
  }

  static getFileDataServer(data: FileData): FileDataServer | undefined {
    if (this.hasDocument(data)) return data.document.documentFileData.fileData;
    return undefined;
  }

  static getFileSize(data: FileData): number {
    if (this.hasDocument(data)) {
      return Number(this.getFileDataServer(data)?.fileSizeByte) || 0;
    }

    return data.upload.file.size;
  }

  static getFileId(data: FileData): string | null | undefined {
    if (this.hasDocument(data)) return data.document.fileUUID;
    return data.upload.fileUUID;
  }

  static getServerCreatedDate(data: FileData): string | undefined {
    if (this.hasDocument(data)) {
      return data.document.createDate
        ? data.document.createDate.valueOf().toString()
        : undefined;
    }

    return undefined;
  }

  static getErrorMessage(data: FileData): string | undefined {
    if (data.errorMessage) return data.errorMessage;
    if (!this.hasDocument(data)) return data.upload.rejectReason;
    return undefined;
  }

  static getFullName(data: FileData): string {
    if (this.hasDocument(data)) {
      return `${data.document.name}.${
        data.document.documentFileData.fileData?.fileExt || ''
      }`;
    }
    return data.upload.file.name;
  }

  static getFileExtWithDot(data: FileData): string | undefined {
    if (this.hasDocument(data)) return this.getFileDataServer(data)?.fileExt;

    return mimeToExtWithDot[data.upload.file.type];
  }

  private _correctedName: string | undefined;
  private _data: FileData;

  constructor(param: FileData | IFileInstance) {
    if ('source' in param) {
      this._data = param.source;
    } else {
      this._data = param;
    }

    const correctedName = ClientServerFile.getCorrectedFileName(
      this.nameWithoutExtension
    );
    if (correctedName && this.nameWithoutExtension !== correctedName) {
      this._correctedName = correctedName;
    }
  }

  get size(): IFileInstance['size'] {
    return ClientServerFile.getFileSize(this.source);
  }

  get fileUUID(): IFileInstance['fileUUID'] {
    return ClientServerFile.getFileId(this.source);
  }

  get clientServerId(): IFileInstance['clientServerId'] {
    return this.fileUUID || this.documentServerId!;
  }

  updateServerData(data: FileServerData): void {
    (this.source as FileServerData).document = data.document;
  }

  get source(): IFileInstance['source'] {
    return this._data;
  }

  get serverCreatedDate(): IFileInstance['serverCreatedDate'] {
    return ClientServerFile.getServerCreatedDate(this.source);
  }

  get documentName(): string | undefined {
    if (!this.document) return undefined;
    return this.document.name;
  }

  get fullName(): string {
    return ClientServerFile.getFullName(this.source);
  }

  get nameWithoutExtension(): string {
    if (this._correctedName !== undefined) return this._correctedName;
    if (this.document) return this.documentName!;
    if (!this.clientFile) return 'Безымянный документ';

    return ClientServerFile.getNameWithoutExtension(
      this.clientFile.upload.file.name
    );
  }

  get fileExtWithDot(): IFileInstance['fileExtWithDot'] {
    return ClientServerFile.getFileExtWithDot(this.source) || '';
  }

  private get document(): DeepRO<DocumentFullData> | undefined {
    if (!this.hasDocument) return undefined;
    return (this.source as FileServerData).document;
  }

  get hasDocument(): IFileInstance['hasDocument'] {
    return ClientServerFile.hasDocument(this.source);
  }

  get documentServerId(): IFileInstance['documentServerId'] {
    return this.document?.documentId;
  }

  get hasServerFile(): IFileInstance['hasServerFile'] {
    return !!this.document?.documentFileData?.fileData?.filePath;
  }

  get hasClientFile(): IFileInstance['hasClientFile'] {
    return !!(this.source as FileClientData).upload?.file;
  }

  get clientFile(): FileClientData | undefined {
    if (!this.hasClientFile) return undefined;
    return this.source as FileClientData;
  }

  get isWaitingServerFileData(): IFileInstance['isWaitingServerFileData'] {
    return !!this.documentServerId && !this.hasServerFile;
  }

  get isNameCorrect(): IFileInstance['isNameCorrect'] {
    return !!ClientServerFile.getCorrectedFileName(this.nameWithoutExtension);
  }

  get serverData(): IFileInstance['serverData'] {
    if (!ClientServerFile.hasDocument(this.source)) return undefined;
    return this.source.document;
  }
}
