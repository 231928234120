import ProfileAvatar from 'modules/UI/components/ProfileAvatar';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledProfileContainer = styled(Link)`
  display: flex;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;
  color: ${({ theme }) => theme.color.text.primary};

  &:hover {
    color: ${({ theme }) => theme.color.text.primary};
  }
`;
export const StyledAvatar = styled(ProfileAvatar)`
  margin-right: 12px;
`;

export const StyledName = styled.div`
  word-break: break-word;
  overflow-wrap: break-word;
`;
