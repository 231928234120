import { lazy } from 'react';
import type { AppRoute } from '../types';
import { RouteTypeEnum } from '../types';
import DocumentsRoutesEnum from './paths/DocumentsRoutesEnum';

const PackagesListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PackagesListPage" */ 'modules/Documents/pages/PackagesListPage'
    )
);

const PackagePage = lazy(
  () =>
    import(
      /* webpackChunkName: "PackagePage" */ 'modules/Documents/pages/PackagePage'
    )
);

export type PackageDetailsUrlParams = {
  clientId: string;
  packageId: string;
};

const routes: AppRoute[] = [
  {
    id: 'documents:list',
    path: DocumentsRoutesEnum.DocumentsList,
    type: RouteTypeEnum.Private,
    exact: true,
    component: PackagesListPage,
  },
  {
    id: 'documents:item',
    path: DocumentsRoutesEnum.PackageDetails,
    type: RouteTypeEnum.Private,
    exact: true,
    component: PackagePage,
  },
];

export default routes;
