import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import {
  StyledModalContent,
  StyledModalTitle,
  StyledOl,
  StyledP,
} from 'modules/Documents/components/PackageCard/PackageCard.styled';
import Modal, { ModalButtonPanel } from 'modules/UI/components/Modal';
import { Col, Row } from 'styled-bootstrap-grid';

type EmptyDocsModalProps = {
  onClose: (isConfirmed?: boolean) => Promise<void> | void;
  onSignPackage: () => Promise<void>;
  isLoading: boolean;
};

const EmptyDocsModalQes = ({
  onClose,
  onSignPackage,
  isLoading,
}: EmptyDocsModalProps): JSX.Element => {
  return (
    <Modal onClose={() => onClose()}>
      <StyledModalTitle>
        Если вы не получили комплект документов в Госключе
      </StyledModalTitle>
      <StyledP></StyledP>
      Вам был направлен запрос на выпуск УКЭП в Госключ. Убедитесь, что
      процедура выпуска УКЭП успешно завершена на вашем устройстве. После этого
      документы на подписание УКЭП придут автоматически
      <br />
      <br />
      <StyledModalContent>
        <Row>
          <Col col={12}>
            <StyledOl>
              <li>
                Если на вашем устройстве уже выпущен УКЭП, то повторно отправьте
                комплект документов на подписание
              </li>
              <li>
                Если на ваше устройство не пришёл запрос выпуска УКЭП,
                обратитесь в поддержку
              </li>
            </StyledOl>
          </Col>
        </Row>
      </StyledModalContent>
      <ModalButtonPanel>
        <Button
          onClick={onSignPackage}
          color={ButtonColorsEnum.Primary}
          size={ButtonSizesEnum.Medium}
          disabled={isLoading}
          data-testid='empty-goskey-modal-sign-button'
        >
          Подписать
        </Button>

        <Button
          size={ButtonSizesEnum.Medium}
          color={ButtonColorsEnum.Secondary}
          disabled={isLoading}
          onClick={() => onClose()}
        >
          Отменить
        </Button>
      </ModalButtonPanel>
    </Modal>
  );
};

export default EmptyDocsModalQes;
