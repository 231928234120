import { makeEnumerablePrototype } from 'modules/Common/services';
import type {
  OuterCounterpartySigningData,
  CounterpartyOrg,
  CounterpartyOrgBase,
  InviteStatusEnumUni,
} from 'modules/Documents/types';

import SignMixin from './SignMixin';

type ISigningAdapterOrgOuter = CounterpartyOrg &
  PartialToUndefined<CounterpartyOrgBase>;

@makeEnumerablePrototype
export default class SigningAdapterOrgOuter
  extends SignMixin<InviteStatusEnumUni>
  implements ISigningAdapterOrgOuter
{
  readonly name: ISigningAdapterOrgOuter['name'];
  readonly organizationInn: ISigningAdapterOrgOuter['organizationInn'];
  readonly organizationKpp: ISigningAdapterOrgOuter['organizationKpp'];
  readonly organizationKio: ISigningAdapterOrgOuter['organizationKio'];
  readonly type: ISigningAdapterOrgOuter['type'];

  readonly __feOrg;
  readonly __source;
  readonly email;
  readonly inviteId;
  readonly ogrn;
  readonly orgnip;

  constructor(private _data: OuterCounterpartySigningData) {
    super(_data);

    const { outerCounterpartyData } = _data;

    this.__source = _data;

    this.__feOrg = true;
    this.email = outerCounterpartyData.email;
    this.inviteId = _data.inviteId;
    this.ogrn = outerCounterpartyData.ogrn;
    this.orgnip = outerCounterpartyData.ogrnIp;
  }
}
