const getFileContent = (
  file: Blob | File,
  keepPrefix?: boolean
): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      const content = keepPrefix
        ? (fileReader.result as string)
        : (fileReader.result as string).split(',').pop()!;
      resolve(content);
    };

    fileReader.onerror = (e) => {
      reject(e);
    };
  });
};

export default getFileContent;
