import { Configuration, SpUserProfileApi } from '@sp-api/user-api';
import { apiService } from 'modules/Api/components/ApiProvider/services';
import { useConfiguration } from 'modules/Shared/hooks';

const useApi = apiService.createUseApi(SpUserProfileApi, Configuration);

const useUserApi = (): SpUserProfileApi => {
  const [{ userContextUrl }] = useConfiguration();
  return useApi(userContextUrl);
};

export default useUserApi;
