import Button, { ButtonSizesEnum } from '@signplatform-ui/button';
import type { ButtonProps } from '@signplatform-ui/button';
import type { PropsWithChildren } from 'react';

type PublishPackageButtonProps = PropsWithChildren<
  ButtonProps & {
    onPublish?: () => Promise<void> | void;
  }
>;

const PublishPackageButton = ({
  onPublish,
  children = 'Отправить',
  ...buttonProps
}: PublishPackageButtonProps): JSX.Element => {
  return (
    <Button
      onClick={onPublish}
      size={ButtonSizesEnum.Medium}
      type='button'
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default PublishPackageButton;
