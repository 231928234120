import type { StatusUnitEnum } from '@sp-api/documents-api';

const isAllowedStatus = <T extends StatusUnitEnum>(
  status: StatusUnitEnum,
  excluded: Set<StatusUnitEnum>
): status is T => {
  return !excluded.has(status);
};

export default isAllowedStatus;
