import Button, { ButtonSizesEnum } from '@signplatform-ui/button';
import Header, { HeaderSizesEnum } from '@signplatform-ui/header';
import { StyledErrorContent, StyledErrorContainer } from './Error.styled';

type ErrorProps = {
  isNotShown?: boolean;
  isRetryDisabled?: boolean;
  onRetry?: () => void;
};

const Error = ({
  isNotShown,
  isRetryDisabled,
  onRetry,
}: ErrorProps): JSX.Element => {
  return (
    <StyledErrorContainer $isNotShown={isNotShown}>
      <Header type={HeaderSizesEnum.H2}>Возникла ошибка</Header>

      <StyledErrorContent>
        Возникла ошибка при работе компонента, вернитесь назад или попробуйте
        снова.
      </StyledErrorContent>

      <Button
        disabled={isNotShown || isRetryDisabled}
        onClick={onRetry}
        size={ButtonSizesEnum.Medium}
      >
        Повторить
      </Button>
    </StyledErrorContainer>
  );
};

export default Error;
