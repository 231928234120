import AppRoutesPaths from 'modules/Router/routes/paths';
import type { MouseEvent } from 'react';

export default function goToAuth(
  e: MouseEvent<HTMLButtonElement>,
  authClientId: string,
  authPrefix: string
): void {
  e.preventDefault();

  const params = new URLSearchParams({
    client_id: authClientId,
    response_type: 'code',
    scope: 'openid permissions sp_profile',
    redirect_uri: `${window.location.origin}${AppRoutesPaths.Token}`,
  });

  window.location.href = `${authPrefix}/authorize?${params}`;
}
