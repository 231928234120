import { useFormContext, Controller } from 'react-hook-form';
import type { FieldError } from 'react-hook-form';
import type { InputHTMLAttributes } from 'react';
import PassportInput from './PassportInput';

type FormPassportInputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: FieldError;
  name: string;
  unmasked?: boolean;
};

function FormPassportInput({
  error,
  name,
  ...restFieldProps
}: FormPassportInputProps): JSX.Element {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <PassportInput
          status={error && 'error'}
          extraText={error?.message}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          {...restFieldProps}
        />
      )}
    />
  );
}

export default FormPassportInput;
