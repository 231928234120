import {
  getCounterpartyCaption,
  getPersonFio,
  getPersonShortName,
} from 'modules/Documents/utils';
import type { ClientOwnerData } from '@sp-api/documents-api';

export function getOwnerName(
  clientOwnerData: DeepRO<ClientOwnerData>,
  truncate = false
): string {
  if (clientOwnerData?.innerPersonsData) {
    return getCounterpartyCaption(clientOwnerData.innerPersonsData, {
      getPersonName: truncate ? getPersonShortName : getPersonFio,
    });
  }

  if (clientOwnerData?.innerCounterpartyData) {
    return getCounterpartyCaption(clientOwnerData.innerCounterpartyData);
  }

  return '';
}
