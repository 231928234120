const forbiddenPrototypes: WeakSet<Record<string, unknown>> = new Set([
  Object.prototype,
  Array.prototype,
]);

// function gets all properties in proto chain in objects and creates plain object
// getters and value descriptors are calculated
// constructor property are ignored
export default function serializeClassInstance<
  R extends string,
  T extends Record<R, unknown>
>(classInstance: T, propsToSkip: Set<string> = new Set()): T {
  if (typeof classInstance !== 'object' || classInstance === null) {
    return classInstance;
  }

  if (Array.isArray(classInstance)) {
    return classInstance.map((arrItem) => {
      return serializeClassInstance(arrItem);
    }) as unknown as T;
  }

  const target: T = {} as T;

  const propertiesSet: Set<string> = new Set<string>();
  let currentProto = classInstance;

  while (!forbiddenPrototypes.has(currentProto) && currentProto !== null) {
    const descriptors = Object.getOwnPropertyDescriptors(currentProto);

    Object.keys(descriptors).forEach((x) => {
      if (x === 'constructor' || propsToSkip.has(x)) return;
      const descriptor = descriptors[x];

      if (descriptor.value || descriptor.get) {
        propertiesSet.add(x);
      }
    });

    currentProto = Object.getPrototypeOf(currentProto);
  }

  propertiesSet.forEach((propertyKey) => {
    target[propertyKey as R] = serializeClassInstance(
      classInstance[propertyKey as R] as Record<string, unknown>
    ) as T[R];
  });

  return target;
}

export function serializeClassInstanceForKeys<
  T extends Record<string, unknown>
>(classInstance: T, propsToSkip: Set<string> = new Set()): T {
  if (typeof classInstance !== 'object') return classInstance;

  return Object.keys(classInstance).reduce((acc, key) => {
    const keyTyped = key as keyof T;

    if (typeof classInstance[keyTyped] !== 'object') {
      acc[keyTyped] = classInstance[keyTyped];
    } else {
      acc[keyTyped] = serializeClassInstance(
        classInstance[keyTyped] as Record<string, unknown>,
        propsToSkip
      ) as T[keyof T];
    }

    return acc;
  }, {} as T);
}
