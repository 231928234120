import { PackageSignStatusEnum } from 'modules/Documents/ownTypes';

const statusMap: Record<PackageSignStatusEnum, string> = {
  [PackageSignStatusEnum.Signed]: 'Подписан',
  [PackageSignStatusEnum.Waiting]: 'На подписании',
  [PackageSignStatusEnum.Error]: 'Ошибка',
  [PackageSignStatusEnum.Draft]: 'Черновик',
  [PackageSignStatusEnum.Rejected]: 'Отклонен',
  [PackageSignStatusEnum.RejectedByOwner]: 'Отозвано автором',
  [PackageSignStatusEnum.Expired]: 'Истек срок подписания',
};

export default statusMap;
