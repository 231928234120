import InputField from '@signplatform-ui/input-field';
import styled from 'styled-components';

export const StyledText = styled.p`
  margin-bottom: 16px;
  word-break: break-word;
`;

export const StyledInput = styled(InputField)`
  margin-top: 16px;
`;
