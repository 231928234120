import { ButtonColorsEnum } from '@signplatform-ui/button';
import { CloseOutline, ExitOutline, MenuOutline } from '@signplatform-ui/icons';
import AppRoutesPaths from 'modules/Router/routes/paths';
import navigationItems from 'modules/UI/components/SideNav/navigationItems';
import { memo, useState } from 'react';
import { SideNavItem } from './components';
import {
  StyledCard,
  StyledCloseButtonWrapper,
  StyledContainer,
  StyledControls,
  StyledControlsLink,
  StyledMenuControl,
  StyledOverlay,
  StyledUserProfile,
  StyledSideNavList,
} from './SideNav.styled';

const SideNav = (): JSX.Element => {
  const [isVisible, setVisible] = useState(false);

  return (
    <>
      <StyledMenuControl
        color={ButtonColorsEnum.Ghost}
        displayAsSquare
        onClick={() => setVisible((prev) => !prev)}
      >
        <MenuOutline />
      </StyledMenuControl>

      {isVisible && <StyledOverlay onClick={() => setVisible(false)} />}

      <StyledCard isVisible={isVisible}>
        <StyledCloseButtonWrapper>
          <StyledMenuControl
            color={ButtonColorsEnum.Ghost}
            displayAsSquare
            onClick={() => setVisible(false)}
          >
            <CloseOutline />
          </StyledMenuControl>
        </StyledCloseButtonWrapper>

        <StyledUserProfile />

        <StyledContainer>
          <StyledSideNavList>
            {navigationItems.map((item) => (
              <SideNavItem item={item} key={item.id} />
            ))}
          </StyledSideNavList>
        </StyledContainer>
        <StyledControls>
          <StyledControlsLink to={AppRoutesPaths.ProfileExit}>
            <ExitOutline /> Выйти
          </StyledControlsLink>
        </StyledControls>
      </StyledCard>
    </>
  );
};

export default memo(SideNav);
