import { StatusSigningEnum } from 'modules/Documents/ownTypes';

const signingStatusMap: Record<StatusSigningEnum, string> = {
  [StatusSigningEnum.Signed]: 'Подписано',
  [StatusSigningEnum.Waiting]: 'Ожидает подписания',
  [StatusSigningEnum.SignProcess]: 'В процессе подписания',
  [StatusSigningEnum.Error]: 'Ошибка',
  [StatusSigningEnum.Rejected]: 'Отклонено',
  [StatusSigningEnum.Draft]: 'Черновик',
  [StatusSigningEnum.Expired]: 'Истек срок подписания',
  [StatusSigningEnum.Inviting]: 'Приглашение',
  [StatusSigningEnum.RejectedByOwner]: 'Отозвано автором',
  [StatusSigningEnum.QesIssueProcess]: 'Ожидает выпуска УКЭП ',
};

export default signingStatusMap;
