import { useFormContext } from 'react-hook-form';
import type {
  CounterpartiesFormValues,
  PackageWizardFormValues,
} from 'modules/Documents/types';
import type {
  UseSaveAndPublishProps,
  UseSaveAndPublishReturnValue,
} from './useSaveAndPublishPackagePure';
import { useDocsContext, usePackageContext } from '../contexts';
import useSaveAndPublishPackagePure from './useSaveAndPublishPackagePure';

export default function useSaveAndPublishPackage({
  packageId,
  isSigner,
}: UseSaveAndPublishProps): UseSaveAndPublishReturnValue {
  const formData = useFormContext<PackageWizardFormValues>();
  const formValues = formData.watch();

  const packageCardContext = usePackageContext();
  const packageDocsContext = useDocsContext();

  return useSaveAndPublishPackagePure({
    packageId,
    packageCardContext,
    onBeforeSave() {
      formData.reset(formValues, { keepDirty: false, keepTouched: false });
    },
    packageDocsContext,
    onGetValues(): CounterpartiesFormValues {
      return formValues;
    },
    isSigner,
  });
}
