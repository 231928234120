import { makeEnumerablePrototype } from 'modules/Common/services';
import type {
  SignerSignStatusUni,
  InviteStatusEnumUni,
  SignerBaseWithUniFields,
} from 'modules/Documents/types';

type ISignMixin = PartialToUndefined<SignerBaseWithUniFields>;

@makeEnumerablePrototype
export default class SignMixinEmpty<
  T extends InviteStatusEnumUni | SignerSignStatusUni | undefined
> implements ISignMixin
{
  readonly rejectReason: ISignMixin['rejectReason'];
  readonly sendDate: ISignMixin['sendDate'];
  readonly signingFileData: ISignMixin['signingFileData'];
  readonly signingId: ISignMixin['signingId'];
  readonly status: T | undefined;
}
