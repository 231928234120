import Link, { LinkSizesEnum } from '@signplatform-ui/link';
import { NotFoundCaption, NotFoundTitle } from './EmptyResult.styled';

type EmptyResultProps = {
  onInviteUser: () => void;
};

const EmptyResult = ({ onInviteUser }: EmptyResultProps): JSX.Element => {
  return (
    <div data-testid='counterparties-result-empty'>
      <NotFoundTitle>
        Пользователь с такими данными в системе не найден.{' '}
      </NotFoundTitle>
      <NotFoundCaption>
        Попробуйте поискать по другим параметрам. Убедитесь, что участник
        является пользователем платформы или{' '}
        <Link
          to='#invite-user'
          onClick={(e) => {
            e.preventDefault();
            onInviteUser();
          }}
          size={LinkSizesEnum.S}
        >
          пригласите участника в Автограф
        </Link>
        .
      </NotFoundCaption>
    </div>
  );
};

export default EmptyResult;
