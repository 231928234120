import Typographics, { FontSizesEnum } from '@signplatform-ui/typographics';
import { memo } from 'react';
import { createGlobalStyle, css } from 'styled-components';

export const elementsGlobalCss = css`
  [role='dialog'] {
    border-radius: 16px;
  }

  body {
    min-width: 300px;
  }

  #root input {
    border-radius: 8px;
  }

  #root button {
    border-radius: 8px;
  }

  [role='dialog'] h5 {
    font-size: ${FontSizesEnum.H6};
    line-height: ${FontSizesEnum.H6};
  }
`;

const GlobalStyles = memo(createGlobalStyle`
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  strong {
    font-weight: 500;
  }

  ${elementsGlobalCss}
`);

function AppGlobalStyles(): JSX.Element {
  return (
    <>
      <GlobalStyles />
      <Typographics />
    </>
  );
}

export default memo(AppGlobalStyles);
