import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import { StatusUnitEnum } from '@sp-api/documents-api';
import { useModal } from 'modules/Common/hooks';
import Modal, {
  ModalButtonPanel,
  ModalTitle,
} from 'modules/UI/components/Modal';
import { getOwnerName } from 'modules/Documents/components/PackageCard/utils';
import { getCounterpartyCaption } from 'modules/Documents/utils';
import type { IPackageAdapter } from 'modules/Documents/classes';

export type RejectReasonModalProps = {
  packageData: Pick<
    IPackageAdapter,
    'clientOwnerData' | 'cpRejectRevokeReason' | 'cpRejectRevokeSign' | 'status'
  >;
};

const RejectReasonModal = ({
  packageData,
}: RejectReasonModalProps): JSX.Element | null => {
  const { isOpen, onOpen, onClose } = useModal();

  const { cpRejectRevokeReason, status, clientOwnerData, cpRejectRevokeSign } =
    packageData;

  if (
    status !== StatusUnitEnum.Rejected &&
    status !== StatusUnitEnum.RejectedByOwner
  ) {
    return null;
  }

  const isRevoke = status === StatusUnitEnum.RejectedByOwner;
  const modalTitle = isRevoke ? 'Причина отзыва' : 'Причина отклонения';

  const fallbackText = isRevoke
    ? 'Автор не указал причину отзыва'
    : 'Автор не указал причину отклонения';

  return (
    <>
      <Button
        size={ButtonSizesEnum.Small}
        color={ButtonColorsEnum.Ghost}
        onClick={onOpen}
        data-testid='reject-reason-modal-open-button'
      >
        Причина
      </Button>

      {isOpen && (
        <Modal onClose={onClose}>
          <ModalTitle data-testid='reject-reason-modal-title'>
            {modalTitle}
          </ModalTitle>
          {'От: '}
          {status === StatusUnitEnum.RejectedByOwner
            ? getOwnerName(clientOwnerData, true)
            : cpRejectRevokeSign && getCounterpartyCaption(cpRejectRevokeSign)}
          <br />
          <br />

          <div data-testid='reject-reason-modal-reason'>
            {cpRejectRevokeReason || fallbackText}
          </div>

          <ModalButtonPanel>
            <Button
              size={ButtonSizesEnum.Medium}
              color={ButtonColorsEnum.Primary}
              onClick={onClose}
              data-testid='reject-reason-modal-ok-button'
            >
              Готово
            </Button>
          </ModalButtonPanel>
        </Modal>
      )}
    </>
  );
};

export default RejectReasonModal;
