import {
  TOAST_AUTO_CLOSE_TIMEOUT,
  TOAST_NOTIFICATIONS_LIMIT,
} from 'modules/Notifications/constants/notifications';
import 'react-toastify/dist/ReactToastify.css';
import StyledToastContainer from './NotificationsContainer.styled';

const NotificationsContainer = (): JSX.Element => (
  <StyledToastContainer
    limit={TOAST_NOTIFICATIONS_LIMIT}
    autoClose={TOAST_AUTO_CLOSE_TIMEOUT}
  />
);

export default NotificationsContainer;
