import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import { useRemovePackage } from 'modules/Documents/hooks';
import AppRoutesPaths from 'modules/Router/routes/paths';
import Modal, {
  ModalButtonPanel,
  ModalTitle,
} from 'modules/UI/components/Modal';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { StyledText } from './RemovePackageModal.styled';

type RemovePackageModalProps = {
  packageId: EntityId;
  onClose: () => void;
  invalidateQuery?: string;
};

const RemovePackageModal = ({
  onClose,
  packageId,
  invalidateQuery,
}: RemovePackageModalProps): JSX.Element => {
  const { mutateAsync, isLoading } = useRemovePackage(invalidateQuery);
  const history = useHistory();

  const onRemove = useCallback(async () => {
    try {
      await mutateAsync({ packageId });
    } finally {
      history.push(AppRoutesPaths.DocumentsList);
      onClose();
    }
  }, [mutateAsync, packageId, history, onClose]);

  return (
    <Modal onClose={onClose}>
      <div>
        <ModalTitle>Удаление комплекта документов</ModalTitle>
        <StyledText>
          Вы уверены что хотите удалить комплект документов?
          <br /> Восстановить комплект документов после удаления будет
          невозможно
        </StyledText>
      </div>
      <ModalButtonPanel>
        <Button
          onClick={onRemove}
          disabled={isLoading}
          size={ButtonSizesEnum.Medium}
        >
          Подтвердить
        </Button>
        <Button
          onClick={onClose}
          color={ButtonColorsEnum.Secondary}
          size={ButtonSizesEnum.Medium}
        >
          Отменить
        </Button>
      </ModalButtonPanel>
    </Modal>
  );
};

export default RemovePackageModal;
