import LazyImg from 'modules/Common/components/LazyImg';
import { memo } from 'react';
import type { ReactNode } from 'react';
import {
  StyledImageWrapper,
  StyledTile,
  StyledTileDescription,
  StyledTileHeader,
} from './Tile.styled';

type TileProps = {
  id: string;
  image: {
    min: string;
    normal: string;
    x2: string;
  };
  header: string | undefined;
  activeHeader: string | undefined;
  description: ReactNode;
  activeDescription: ReactNode;
  isActive?: boolean;
  onClick: (id: string) => void;
};

const Tile = ({
  activeDescription,
  activeHeader,
  description,
  header,
  id,
  image,
  isActive,
  onClick,
}: TileProps): JSX.Element => {
  const headerText = isActive ? activeHeader ?? header : header;
  const descriptionText = isActive
    ? activeDescription ?? description
    : description;

  return (
    <StyledTile
      data-testid={`certificate-instruction-item-${id}`}
      onClick={() => onClick(id)}
      isActive={isActive}
    >
      <StyledImageWrapper>
        <LazyImg
          srcPlaceholder={image.min}
          src={image.normal}
          srcX2={image.x2}
          alt='Скачайте мобильное приложение Госключ на свое устройство'
        />
      </StyledImageWrapper>
      {headerText && (
        <StyledTileHeader
          data-testid={`certificate-instruction-item-title-${id}`}
        >
          {headerText}
        </StyledTileHeader>
      )}
      <StyledTileDescription
        data-testid={`certificate-instruction-item-description-${id}`}
      >
        {descriptionText}
      </StyledTileDescription>
    </StyledTile>
  );
};

export default memo(Tile);
