import * as yup from 'yup';
import { PASSPORT_FIELD_ERROR } from './constants';

export const passportRegex = /^\d{4}\s\d{6}$/;

const passportSchema = yup
  .string()
  .matches(passportRegex, PASSPORT_FIELD_ERROR);

export default passportSchema;
