import flex from 'modules/StyledUtility/Flex';
import styled from 'styled-components';

export const StyledForm = styled.form`
  padding-top: 24px;
  ${flex.flowCol}
  overflow: hidden;
`;

export const StyledInput = styled.input`
  flex-grow: 1;
  margin-right: 16px;
`;

export const StyledFormGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: start;

  & > button {
    margin-top: 8px;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
`;
