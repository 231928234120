import { css } from 'styled-components';
import type {
  CSSObject,
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  SimpleInterpolation,
  ThemedStyledProps,
} from 'styled-components';

const mediaQuery =
  (
    ...query: [
      first: CSSObject | TemplateStringsArray,
      ...interpolations: SimpleInterpolation[]
    ]
  ) =>
  <P extends Record<string, unknown>>(
    rules: FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>>
  ): FlattenSimpleInterpolation =>
    // TODO research
    //  can't use my css style if trying to compose it when using with media
    //  FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>>
    css`
      @media ${css(...query)} {
        ${rules}
      }
    ` as FlattenSimpleInterpolation;

export default mediaQuery;
