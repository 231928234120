import { useDocumentApiClient } from 'modules/Api';
import { useMutation, useQueryClient } from 'react-query';
import type { QueryKey, UseMutationResult } from 'react-query';
import type { ErrorResponse } from 'modules/App/types';
import usePackageCard from './usePackageCard';

type PackageUpdateParams = {
  name: string;
};

type PackageCardParams = {
  packageId: EntityId;
  ownerClientId: EntityId;
};

export default function useRenamePackage(
  { packageId, ownerClientId }: PackageCardParams,
  invalidateQuery?: QueryKey
): UseMutationResult<void, ErrorResponse, PackageUpdateParams> {
  const documentApi = useDocumentApiClient();
  const queryClient = useQueryClient();

  const { refetch: fetchPackageCard } = usePackageCard(
    {
      ownerClientId,
      packageId,
    },
    { enabled: false }
  );

  return useMutation<void, ErrorResponse, PackageUpdateParams>(
    async ({ name }) => {
      const { data: packageData } = await fetchPackageCard();

      if (!packageData) {
        throw new Error('Package data is empty');
      }

      await documentApi.updatePackageMetadata(packageId, {
        ...packageData,
        name,
      });
    },
    {
      onSuccess: () =>
        invalidateQuery
          ? queryClient.invalidateQueries(invalidateQuery)
          : undefined,
    }
  );
}
