import CommonRoutesEnum from './CommonRoutesEnum';
import ProfileRoutesEnum from './ProfileRoutesEnum';
import PowAttorneyRoutesEnum from './PowAttorneyRoutesEnum';
import DocumentsRoutesEnum from './DocumentsRoutesEnum';
import AgreementsRoutesEnum from './AgreementsRoutesEnum';
import NotFoundRoutesEnum from './NotFoundRoutesEnum';

const AppRoutesPaths = {
  ...CommonRoutesEnum,
  ...ProfileRoutesEnum,
  ...DocumentsRoutesEnum,
  ...PowAttorneyRoutesEnum,
  ...AgreementsRoutesEnum,
  ...NotFoundRoutesEnum,
};

export type AppRoutesPathsType = typeof AppRoutesPaths;

export default AppRoutesPaths;
