import { useCallback, useMemo, useState } from 'react';

type UseTogglerResult = {
  isActive: boolean;
  on: () => void;
  off: () => void;
  toggle: () => void;
};

export default function useToggler(defaultValue = false): UseTogglerResult {
  const [isActive, setIsActive] = useState(defaultValue);

  const on = useCallback(() => {
    setIsActive(true);
  }, []);

  const off = useCallback(() => {
    setIsActive(false);
  }, []);

  const toggle = useCallback(() => {
    setIsActive((value) => !value);
  }, []);

  return useMemo(
    () => ({
      on,
      off,
      toggle,
      isActive,
    }),
    [on, off, toggle, isActive]
  );
}
