import {
  storageWriteData,
  storageReadData,
} from 'modules/Shared/services/storageService';
import { useState } from 'react';
import { isEqual } from 'lodash';
import type { StorageKeyValue } from 'modules/Shared/services/storageService';

type UseStorageReturn = {
  storageData: StorageKeyValue;
  storageReadData: () => StorageKeyValue;
  storageWriteData: (storageData: StorageKeyValue) => boolean;
};

export default function useStorage(isWatching = true): UseStorageReturn {
  const [storageData, setStorageData] = useState<StorageKeyValue>(
    storageReadData()
  );

  const handleReadData = (): StorageKeyValue => {
    const data = storageReadData();
    if (!isWatching) return data;

    if (!isEqual(storageData, data)) {
      setStorageData(data);
    }

    return data;
  };

  const handleWriteData = (data: StorageKeyValue): boolean => {
    const result = storageWriteData(data);
    if (!isWatching) return result;

    if (result) handleReadData();
    return result;
  };

  return {
    storageData: storageData,
    storageReadData: handleReadData,
    storageWriteData: handleWriteData,
  };
}
