import type { ReactNode } from 'react';
import { StyledLabel, StyledValue } from './NameValueInfo.styled';

type NameValueInfoProps<T extends ReactNode = undefined> = {
  name?: string;
  value?: T;
  hasNoColon?: boolean;
  isDisabled?: boolean;
};

const NameValueInfo = <T extends ReactNode>({
  name,
  value,
  hasNoColon,
  isDisabled,
}: NameValueInfoProps<T>): JSX.Element => (
  <>
    <StyledLabel $disabled={isDisabled} data-name='label'>
      {name}
      {hasNoColon ? undefined : ':'}
    </StyledLabel>

    <StyledValue $disabled={isDisabled} data-name='value'>
      {value}
    </StyledValue>
  </>
);

export default NameValueInfo;
