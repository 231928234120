import { makeEnumerablePrototype } from 'modules/Common/services';
import type { ClientCounterparty } from '@sp-api/counterparties-api';
import type {
  CounterpartyClientOrg,
  CounterpartyOrg,
  CounterpartyOrgBase,
  SignerSignStatusUni,
} from 'modules/Documents/types';
import SignMixinEmpty from './SignMixinEmpty';

type ISigningAdapterSearchOrg = CounterpartyClientOrg &
  PartialToUndefined<CounterpartyOrgBase>;

// TODO remove excessive getters due to properties
@makeEnumerablePrototype
export default class SigningAdapterSearchOrg
  extends SignMixinEmpty<SignerSignStatusUni>
  implements ISigningAdapterSearchOrg
{
  constructor(private _data: ClientCounterparty) {
    super();
  }

  get name(): string | undefined {
    return this._data.name;
  }

  get ogrn(): string | undefined {
    return this._data.ogrn;
  }

  get organizationInn(): string | undefined {
    return this._data.organizationInn;
  }

  get organizationKpp(): string | undefined {
    return this._data.organizationKpp;
  }

  get organizationKio(): string | undefined {
    return this._data.organizationKio;
  }

  get orgnip(): string | undefined {
    return this._data.ogrnIp;
  }

  get email(): string | undefined {
    return this._data.email;
  }

  get type(): CounterpartyOrg['type'] | undefined {
    return this._data.type;
  }

  get clientId(): number {
    // TODO: remove typecasting
    return this._data.clientId as unknown as number;
  }

  get inviteId(): EntityId | undefined {
    return undefined;
  }

  get __feOrg(): boolean {
    return true;
  }
}
