import { Controller, useFormContext } from 'react-hook-form';
import { Toggle } from '@vtb/ui-kit3';
import type { ControllerProps } from 'react-hook-form/dist/types/controller';
import type { IsOnlySignerFormValues } from 'modules/Documents/components/PackageWizard/ownTypes';
import type { ArgumentsOf } from 'ts-jest';

type FormValues = IsOnlySignerFormValues;
type RenderFirstArg = ArgumentsOf<ControllerProps<FormValues>['render']>[0];

export default function IAmNotSignerToggle(): JSX.Element {
  const { control, setValue } = useFormContext<FormValues>();

  const handleRenderInner = ({ field }: RenderFirstArg) => (
    <Toggle
      checked={field.value || false}
      data-testid='i-am-not-signer-input'
      onChange={(e) => {
        field.onChange(e);
        if (e.target.checked) setValue('iAmOnlySigner', false);
      }}
      value={undefined}
    >
      Участвую в качестве организатора и не подписываю комплект документов
    </Toggle>
  );

  return (
    <Controller
      name='iAmNotSigner'
      control={control}
      render={handleRenderInner}
    />
  );
}
