import { createContext } from 'react';
import type { IMediaContext } from './types';
import { mediaQueriesStr } from '../../gridConfig';

export * from './types';

const selectorsData = {
  mobileXS: window.matchMedia(mediaQueriesStr.mobileXS),
  mobile: window.matchMedia(mediaQueriesStr.mobile),
  tablet: window.matchMedia(mediaQueriesStr.tablet),
};

export const handleGetMediaData = (): Required<IMediaContext> => {
  return {
    isMobileXS: selectorsData.mobileXS.matches,
    isMobile: selectorsData.mobile.matches,
    isTablet: selectorsData.tablet.matches,
  };
};

const MediaContext = createContext<IMediaContext>(handleGetMediaData());
export default MediaContext;
