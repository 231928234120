import Card from '@signplatform-ui/card';
import { hexToRgba } from '@signplatform-ui/colors';
import Menu from '@signplatform-ui/menu';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  cursor: pointer;
  background-color: ${({ theme }) =>
    hexToRgba(theme.color.background.primary, 0.6)!};
  position: relative;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfileNameWrapper = styled.div`
  margin-left: 12px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.text.primary};
`;

export const StyledMenu = styled(Menu)`
  position: absolute;
  margin-top: 8px;
  width: 196px;
  top: 100%;
  right: 0;
  z-index: 1;
  box-shadow: 0 4px 16px rgba(138, 150, 168, 0.4),
    0 0 6px rgba(138, 150, 168, 0.08);
  border-radius: 16px;
`;
