import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import { useModal } from 'modules/Common/hooks';
import type { ButtonProps } from '@signplatform-ui/button';
import RevokeSigningModal from '../RevokeSigningModal';

type RevokePackageSigningButtonProps = {
  packageId: EntityId;
  onSuccess?: () => Promise<void>;
  buttonProps?: ButtonProps;
};

export default function RevokePackageSigningButton({
  packageId,
  buttonProps,
  onSuccess,
}: RevokePackageSigningButtonProps): JSX.Element {
  const { isOpen, onClose, onOpen } = useModal(false);

  return (
    <>
      <Button
        onClick={onOpen}
        size={ButtonSizesEnum.Medium}
        color={ButtonColorsEnum.Secondary}
        data-testid='revoke-package-signing-button'
        {...buttonProps}
      >
        Отозвать
      </Button>

      {isOpen && (
        <RevokeSigningModal
          onClose={onClose}
          onSuccess={onSuccess}
          packageId={packageId}
        />
      )}
    </>
  );
}
