import { ButtonColorsEnum, ButtonSizesEnum } from '@signplatform-ui/button';
import DropdownButton, {
  MenuPositionsEnum,
} from '@signplatform-ui/dropdownbutton';
import { MoreHorizontalOutline } from '@signplatform-ui/icons';
import { useModal } from 'modules/Common/hooks';
import PackagePublishedModal from 'modules/Documents/components/PackagePublishedModal';
import PublishPackageButton from 'modules/Documents/components/PublishPackageButton';
import RejectSigningModal from 'modules/Documents/components/RejectSigningModal';
import RevokeSigningModal from 'modules/Documents/components/RevokeSigningModal';
import RemovePackageModal from 'modules/Documents/components/RemovePackageModal';
import RenamePackageModal from 'modules/Documents/components/RenamePackageModal';
import SignButton from 'modules/Documents/components/SignButton';
import { DocumentsStateKeysEnum } from 'modules/Documents/constants';
import { usePublishPackage } from 'modules/Documents/hooks';
import useDownloadPackage from 'modules/Documents/hooks/useDownloadPackage';
import AppRoutesPaths from 'modules/Router/routes/paths';
import { useUser } from 'modules/Shared/hooks';
import { useQueryClient } from 'react-query';
import { generatePath, useHistory } from 'react-router-dom';
import { hasDownloadDocumentButton } from 'modules/Documents/utils';
import ResignButton from 'modules/Documents/components/ResignButton';
import type { PackageFullData } from '@sp-api/documents-api';
import type { PackageSignStatusUni } from 'modules/Documents/types';
import type { MenuItemProp } from '@signplatform-ui/menu';
import {
  ButtonsWrapper,
  CardButtonsWrapper,
} from './PackageListActions.styled';

type PackageListActionsProps = {
  packageId: EntityId;
  title?: string;
  status: PackageSignStatusUni;
  ownerClientId: EntityId;
  buttons: PackageFullData['buttons'];
  withoutDropDown?: boolean;
  isSigner?: boolean;
  resignThreshold?: number;
  onResignSuccess?: () => Promise<void>;
  resignTooltip?: React.ReactNode;
};

const PackageListActions = ({
  packageId,
  title = '',
  ownerClientId,
  status,
  isSigner,
  buttons: {
    signButton,
    downloadButton,
    renameButton,
    deleteButton,
    rejectButton,
    sendButton,
    revokeButton,
    resignButton,
  },
  withoutDropDown,
  onResignSuccess,
  resignThreshold,
  resignTooltip,
}: PackageListActionsProps): JSX.Element => {
  const renameModalRef = useModal();
  const removeModalRef = useModal();
  const publishedModalRef = useModal();
  const rejectModalRef = useModal();
  const revokeModalRef = useModal();
  const { user } = useUser();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { refetch: downloadPackage } = useDownloadPackage({
    ownerClientId,
    packageId,
  });

  const invalidateListQuery = async () => {
    return queryClient.invalidateQueries(DocumentsStateKeysEnum.List);
  };
  const { mutateAsync, isLoading: isPublishing } = usePublishPackage(!isSigner);
  const onPublish = async () => {
    await mutateAsync({ packageId });
    if (isSigner) {
      publishedModalRef.onOpen();
    } else {
      await invalidateListQuery();
    }
  };

  const items = ((): MenuItemProp[] => {
    const buttonsResult: {
      id: string;
      title: string;
      onClick?: () => void;
    }[] = [];

    if (withoutDropDown) {
      return [];
    }

    if (revokeButton) {
      buttonsResult.push({
        id: 'revoke',
        title: 'Отозвать',
        onClick: revokeModalRef.onOpen,
      });
    }

    if (rejectButton) {
      buttonsResult.push({
        id: 'reject',
        title: 'Отклонить',
        onClick: rejectModalRef.onOpen,
      });
    }

    buttonsResult.push({
      id: 'open',
      title: 'Открыть',
      onClick: () => {
        history.push(
          generatePath(AppRoutesPaths.PackageDetails, {
            clientId: ownerClientId,
            packageId,
          })
        );
      },
    });

    if (renameButton) {
      buttonsResult.push({
        id: 'rename',
        title: 'Переименовать',
        onClick: renameModalRef.onOpen,
      });
    }

    const hasDownloadButton = hasDownloadDocumentButton({
      packageData: {
        buttons: { downloadButton },
        clientIdOwner: ownerClientId,
        status,
      },
      user,
    });

    if (hasDownloadButton) {
      buttonsResult.push({
        id: 'download',
        title: 'Скачать',
        onClick: () => {
          void downloadPackage();
        },
      });
    }

    if (deleteButton) {
      buttonsResult.push({
        id: 'remove',
        title: 'Удалить',
        onClick: removeModalRef.onOpen,
      });
    }

    return buttonsResult;
  })();

  const onClosePublished = async () => {
    publishedModalRef.onClose();
    await invalidateListQuery();
  };

  return (
    <>
      <ButtonsWrapper data-testid='package-actions-wrapper'>
        {!!items.length && (
          <DropdownButton
            color={ButtonColorsEnum.Ghost}
            size={ButtonSizesEnum.Small}
            items={items}
            displayAsSquare
            position={MenuPositionsEnum.Right}
            data-testid='document-actions-dropdown'
          >
            <MoreHorizontalOutline />
          </DropdownButton>
        )}

        <CardButtonsWrapper withoutDropDown={withoutDropDown}>
          {sendButton && (
            <PublishPackageButton
              onClick={onPublish}
              disabled={isPublishing}
              size={
                withoutDropDown ? ButtonSizesEnum.Medium : ButtonSizesEnum.Small
              }
              color={ButtonColorsEnum.Secondary}
              data-testid='package-actions-publish-button'
            >
              Отправить
            </PublishPackageButton>
          )}
          {signButton && (
            <SignButton
              packageId={packageId}
              ownerClientId={ownerClientId}
              onSignSuccess={onResignSuccess}
              data-testid='package-actions-sign-button'
              size={
                withoutDropDown ? ButtonSizesEnum.Medium : ButtonSizesEnum.Small
              }
            />
          )}
          {resignButton && (
            <ResignButton
              packageId={packageId}
              ownerClientId={ownerClientId}
              onSignSuccess={onResignSuccess}
              data-testid='package-actions-resign-button'
              color={ButtonColorsEnum.Secondary}
              size={
                withoutDropDown ? ButtonSizesEnum.Medium : ButtonSizesEnum.Small
              }
              tooltip={resignThreshold ? resignTooltip : undefined}
              disabled={!!resignThreshold}
            />
          )}
        </CardButtonsWrapper>
      </ButtonsWrapper>
      {renameModalRef.isOpen && (
        <RenamePackageModal
          packageId={packageId}
          ownerClientId={ownerClientId}
          onClose={renameModalRef.onClose}
          title={title}
          invalidateQuery={DocumentsStateKeysEnum.List}
        />
      )}
      {removeModalRef.isOpen && (
        <RemovePackageModal
          packageId={packageId}
          onClose={removeModalRef.onClose}
          invalidateQuery={DocumentsStateKeysEnum.List}
        />
      )}
      {publishedModalRef.isOpen && (
        <PackagePublishedModal
          packageId={packageId}
          ownerClientId={ownerClientId}
          onClose={onClosePublished}
        />
      )}
      {rejectModalRef.isOpen && user && (
        <RejectSigningModal
          packageId={packageId}
          ownerClientId={ownerClientId}
          signerClientId={user.clientId}
          onClose={rejectModalRef.onClose}
          onSuccess={invalidateListQuery}
        />
      )}
      {revokeModalRef.isOpen && user && (
        <RevokeSigningModal
          packageId={packageId}
          onClose={revokeModalRef.onClose}
          onSuccess={invalidateListQuery}
        />
      )}
    </>
  );
};

export default PackageListActions;
