import DateField from '@signplatform-ui/date-field';
import { DropDownItem, SelectField } from '@vtb/ui-kit3';
import { DateTime } from 'luxon';
import { Col, Row } from 'styled-bootstrap-grid';
import { MAX_SIGNING_DAY_PERIOD } from 'modules/Documents/components/PackageWizard/formSchema';
import { TARGET_TIME_ZONE } from 'services/dateUtils';
import { DATE_FORMAT } from 'modules/Documents/constants';
import type { DateTimeObj } from 'modules/Documents/components/PackageSigningDueDate/types';
import type { ChangeEvent } from 'react';
import {
  StyledContainer,
  StyledHeader,
  StyledText,
  StyledErrorMessage,
  StyledPackageSigningTimer,
  StyledCol,
} from './PackageSigningDueDate.styled';

export type PackageSigningDueDateProps = {
  signDueDate: DateTimeObj;
  onChange: (value: DateTimeObj) => void;
  className?: string;
  errorMessage?: string;
};

const DATE_END_TIME = '23:59:59';
const DATE_END_LABEL = 'До конца дня';

const hoursList = new Array(24).fill(null).map((el, idx) => ({
  label: idx ? `${idx < 10 ? `0${idx}` : idx}:00` : DATE_END_LABEL,
  value: idx ? `${idx < 10 ? `0${idx}` : idx}:00:00` : DATE_END_TIME,
}));

const renderInputValue = (signDueDate: DateTimeObj) => {
  const [hh, mm] = signDueDate.time.split(':');
  return signDueDate.time === DATE_END_TIME ? DATE_END_LABEL : `${hh}:${mm}`;
};

const PackageSigningDueDate = ({
  className,
  signDueDate,
  onChange,
  errorMessage,
}: PackageSigningDueDateProps): JSX.Element => {
  const onSetDay = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    onChange({
      date: value,
      time: signDueDate.time,
    });
  };

  const onSetTime = (value: string): void => {
    onChange({
      date: signDueDate.date,
      time: value,
    });
  };

  const isTimeDisabled = (time: string): boolean => {
    const [hour, minute] = time.split(':');

    return (
      DateTime.now().setLocale(TARGET_TIME_ZONE) >
        DateTime.fromFormat(signDueDate.date, DATE_FORMAT)
          .setLocale(TARGET_TIME_ZONE)
          .set({
            hour: Number(hour),
            minute: Number(minute),
          }) ||
      DateTime.now()
        .setLocale(TARGET_TIME_ZONE)
        .plus({ day: MAX_SIGNING_DAY_PERIOD }) <=
        DateTime.fromFormat(signDueDate.date, DATE_FORMAT)
          .setLocale(TARGET_TIME_ZONE)
          .set({
            hour: Number(hour),
            minute: Number(minute),
          })
    );
  };

  return (
    <StyledContainer className={className}>
      <Row>
        <Col lg={8}>
          <StyledHeader>Срок подписания комплекта документов</StyledHeader>
          <StyledText role='text'>
            Укажите дату и время по Москве, до которой документы должны быть
            подписаны. По истечении этого срока процесс подписания будет прерван
          </StyledText>
        </Col>
      </Row>
      <Row>
        <StyledCol sm={4}>
          <DateField
            label='Дата'
            value={signDueDate.date}
            id='signDueDate.date'
            data-testid='signDueDate-date-field'
            onChange={onSetDay}
            minDate={DateTime.now().setZone(TARGET_TIME_ZONE).toJSDate()}
            maxDate={DateTime.now()
              .setZone(TARGET_TIME_ZONE)
              .plus({ day: MAX_SIGNING_DAY_PERIOD })
              .toJSDate()}
            status={errorMessage ? 'error' : undefined}
          />
        </StyledCol>
        <StyledCol sm={4}>
          <SelectField
            label='Время'
            onChange={onSetTime}
            value={signDueDate.time}
            data-testid='signDueDate-time-field'
            status={errorMessage ? 'error' : undefined}
            id='signDueDate.time'
            renderInputValue={() => renderInputValue(signDueDate)}
          >
            {hoursList.map(({ value, label }) => (
              <DropDownItem
                role='option'
                value={value}
                key={value}
                disabled={isTimeDisabled(value)}
              >
                {label}
              </DropDownItem>
            ))}
          </SelectField>
        </StyledCol>
        {errorMessage && (
          <Col col={12}>
            <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
          </Col>
        )}
        <Col col={12}>
          <StyledPackageSigningTimer signDueDate={signDueDate} />
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default PackageSigningDueDate;
