import Text, { TextTypesEnum } from '@signplatform-ui/text';
import Tooltip from '@signplatform-ui/tooltip';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import type {
  InnerCounterpartyData,
  InnerPersonsData,
  OuterCounterpartyData,
  OuterPersonsData,
} from '@sp-api/documents-api';
import {
  BadgeStyled,
  ListHeader,
  ListItem,
  ListItemSuffix,
  StyledTooltipContent,
} from '../../PackageListCard.styled';
import {
  endSlice,
  lengthWhichVisibleEllipses,
  VISIBLE_WITHOUT_TOOLTIP_TEXT_ROWS_COUNT,
} from '../../constants';
import { WrapSigners } from './PackageListCardSigners.styled';

type SignerTypes =
  | InnerCounterpartyData
  | InnerPersonsData
  | OuterCounterpartyData
  | OuterPersonsData;

function isInner(
  signer: SignerTypes
): signer is InnerCounterpartyData | InnerPersonsData {
  return Object.prototype.hasOwnProperty.call(signer, 'clientId');
}

function isInnerCounterparty(
  signer: SignerTypes
): signer is InnerCounterpartyData {
  return (
    isInner(signer) && Object.prototype.hasOwnProperty.call(signer, 'name')
  );
}

function isInnerPerson(signer: SignerTypes): signer is InnerPersonsData {
  return isInner(signer) && !isInnerCounterparty(signer);
}

type PackageListCardSignersProps = {
  ownerClientId: number;
  innerPersonsData?: InnerPersonsData[];
  innerCounterpartyData?: InnerCounterpartyData[];
  outerPersonsData?: OuterPersonsData[];
  outerCounterpartyData?: OuterCounterpartyData[];
};

const PackageListCardSigners = ({
  innerPersonsData = [],
  innerCounterpartyData = [],
  outerCounterpartyData = [],
  outerPersonsData = [],
  ownerClientId,
}: PackageListCardSignersProps): JSX.Element => {
  const theme = useTheme();

  const signers = useMemo(
    () => [
      ...[...innerPersonsData, ...innerCounterpartyData].sort(
        ({ clientId: aClientId }, { clientId: bClientId }) =>
          aClientId === ownerClientId ? -1 : bClientId === ownerClientId ? 1 : 0
      ),
      ...outerPersonsData,
      ...outerCounterpartyData,
    ],
    [
      innerPersonsData,
      innerCounterpartyData,
      outerCounterpartyData,
      outerPersonsData,
      ownerClientId,
    ]
  );

  return (
    <WrapSigners>
      <ListHeader>
        <Text color={theme.color.text.secondary}>Участники</Text>
        {!isEmpty(signers) && (
          <BadgeStyled data-testid='signers-count-badge'>
            {signers.length}
          </BadgeStyled>
        )}
      </ListHeader>
      {isEmpty(signers) && <Text type={TextTypesEnum.S}>Не выбрано</Text>}
      {signers
        ?.slice(0, VISIBLE_WITHOUT_TOOLTIP_TEXT_ROWS_COUNT)
        .map((signer, idx) => {
          if (isInnerPerson(signer)) {
            return (
              <ListItem
                key={idx}
                type={TextTypesEnum.S}
                data-testid='signer-item-name'
              >
                {signer.firstName} {signer.middleName} {signer.lastName}{' '}
                {signer.clientId === ownerClientId && (
                  <ListItemSuffix data-testid='signer-author-suffix'>
                    Автор
                  </ListItemSuffix>
                )}
              </ListItem>
            );
          }
          if (isInnerCounterparty(signer)) {
            return (
              <ListItem key={idx} type={TextTypesEnum.S}>
                {signer.name}
                {signer.clientId === ownerClientId && (
                  <ListItemSuffix>Автор</ListItemSuffix>
                )}
              </ListItem>
            );
          }

          return (
            <ListItem key={idx} type={TextTypesEnum.S}>
              {signer.email}
            </ListItem>
          );
        })}
      {signers.length > 2 && (
        <Tooltip
          data-testid='package-signers-tooltip'
          renderContent={() => (
            <StyledTooltipContent>
              {signers
                ?.slice(VISIBLE_WITHOUT_TOOLTIP_TEXT_ROWS_COUNT, endSlice)
                .map((signer, idx) => {
                  if (isInnerPerson(signer)) {
                    return (
                      <div key={idx}>
                        {signer.firstName} {signer.middleName} {signer.lastName}
                      </div>
                    );
                  }

                  if (isInnerCounterparty(signer)) {
                    return <div key={idx}>{signer.name}</div>;
                  }

                  return <div key={idx}>{signer.email}</div>;
                })}
              {signers && signers?.length >= lengthWhichVisibleEllipses ? (
                <div data-testid='signers-tooltip-more'>...</div>
              ) : null}
            </StyledTooltipContent>
          )}
        >
          <Text type={TextTypesEnum.S} color={theme.color.text.secondary}>
            Еще {signers.length - 2}
          </Text>
        </Tooltip>
      )}
    </WrapSigners>
  );
};

export default PackageListCardSigners;
