import {
  emailSchema,
  innSchema,
  passportSchema,
  phoneSchema,
  snilsSchema,
} from 'services/schemaValidation';
import { EMPTY_FIELD_ERROR } from 'services/schemaValidation/constants';
import * as yup from 'yup';
import type { ValidateOptions } from 'yup/lib/types';

const shape = yup.object().shape({
  snils: snilsSchema,
  inn: innSchema,
  idNum: passportSchema,
  email: emailSchema,
  phone: phoneSchema,
});

const validateSchema = async (
  data: Record<string, unknown>,
  options?: ValidateOptions
) => {
  try {
    await shape.validate(data, options);
  } catch (error) {
    if (error instanceof yup.ValidationError) return error;
  }

  return true;
};

const formSchema = yup.object().test({
  name: 'selectedFieldNotEmpty',
  test: async (data, { options: { context }, createError }) => {
    const { selectedField } = context as { selectedField: string };
    const selectedFieldValue = data[selectedField];

    // yup does not provide test order settings, to include schema test inside
    if (!selectedField || selectedFieldValue) {
      return validateSchema(data, { context });
    }

    // return error for selected field if value not provided
    return createError({
      message: EMPTY_FIELD_ERROR,
      path: selectedField,
    });
  },
});

export default formSchema;
