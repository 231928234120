import * as yup from 'yup';
import { EMAIL_FIELD_ERROR } from './constants';

export const emailRegex =
  // mailEn  @server      .subdomains?  .com            mailRuEn    @serverRuEn    .subdomains?      .рф
  /^[\w.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*\.([a-z]+)$|^[а-я\w.-]+@[а-яa-z0-9-]+(\.[а-яa-z0-9-]+)*\.([а-я]+)$/i;

const forbiddenSymbolsStartEnd: { [key: string]: boolean } = {
  '.': true,
  '-': true,
};

const emailSchema = yup
  .string()
  .matches(emailRegex, EMAIL_FIELD_ERROR)
  .test({
    name: 'reqs',
    message: EMAIL_FIELD_ERROR,
    test(value) {
      if (!value) return true;

      const parts = (value || '').split(/@/g);
      if (parts.length !== 2) return false;

      const [name, address] = parts;
      if (name.length < 0 || name.length > 64) return false;
      if (address.length < 0 || address.length > 255) return false;

      if (
        forbiddenSymbolsStartEnd[name[0]] ||
        forbiddenSymbolsStartEnd[name[name.length - 1]] ||
        /[.-]{2,}/.test(name)
      ) {
        return false;
      }

      return true;
    },
  })
  .max(320, EMAIL_FIELD_ERROR);

export default emailSchema;
