import { memo, useMemo } from 'react';
import { DateTime } from 'luxon';
import { TARGET_TIME_ZONE } from 'services/dateUtils';
import { TimeOutline } from '@signplatform-ui/icons';
import { DATE_FORMAT } from 'modules/Documents/constants';
import type { DateTimeObj } from '../../types';
import {
  StyledContainer,
  StyledCaption,
  StyledTimer,
} from './PackageSigningTimer.styled';

export type PackageSigningTimerProps = {
  signDueDate: DateTimeObj;
  className?: string;
};

const PackageSigningTimer = ({
  signDueDate,
  className,
}: PackageSigningTimerProps): JSX.Element | null => {
  const dueDateTime = useMemo(() => {
    const [hour, minute, second] = signDueDate.time.split(':');
    return DateTime.fromFormat(signDueDate.date, DATE_FORMAT, {
      zone: TARGET_TIME_ZONE,
    })
      .set({
        hour: Number(hour),
        minute: Number(minute),
        second: Number(second),
      })
      .toUTC();
  }, [signDueDate.date, signDueDate.time]);

  if (!dueDateTime.isValid) {
    return null;
  }

  const dueDate = dueDateTime.toISO();

  return (
    <StyledContainer className={className}>
      <TimeOutline />
      <StyledCaption>До окончания подписания осталось:</StyledCaption>
      <StyledTimer dueDate={dueDate} key={dueDate} />
    </StyledContainer>
  );
};

export default memo(PackageSigningTimer);
