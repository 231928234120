import { useState, useEffect } from 'react';

export const notLoadedDocIdsByPackageIdCache: Map<
  EntityId,
  Set<string>
> = new Map();

type UseNotLoadedDocsInput = {
  packageId: EntityId;
};

export type UseNotLoadedDocsOutput = {
  notLoadedDocIds: Set<string>;
  onSetNotLoadedDocIds: (ids: Set<string>) => void;
};

export default function useNotLoadedDocIds({
  packageId,
}: UseNotLoadedDocsInput): UseNotLoadedDocsOutput {
  const [notLoadedDocIds, setNotLoadedDocIds] = useState<Set<string>>(
    notLoadedDocIdsByPackageIdCache.get(packageId) || new Set()
  );

  useEffect(() => {
    return () => {
      notLoadedDocIdsByPackageIdCache.set(packageId, notLoadedDocIds);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notLoadedDocIds]);

  return {
    notLoadedDocIds,
    onSetNotLoadedDocIds: setNotLoadedDocIds,
  };
}
