import { useUser } from 'modules/Shared/hooks';
import AppRoutesPaths from 'modules/Router/routes/paths';

import {
  StyledAvatar,
  StyledName,
  StyledProfileContainer,
} from './Profile.styled';

type ProfileProps = {
  className?: string;
};

const Profile = ({ className }: ProfileProps): JSX.Element => {
  const { user } = useUser();

  return (
    <StyledProfileContainer
      to={AppRoutesPaths.ProfileDetails}
      className={className}
    >
      <StyledAvatar
        firstName={user.personalData?.firstName}
        lastName={user.personalData?.lastName}
      />
      <StyledName>
        <div>{user.personalData?.firstName}</div>
        <div>{user.personalData?.lastName}</div>
      </StyledName>
    </StyledProfileContainer>
  );
};

export default Profile;
