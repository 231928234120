import { CardSizesEnum } from '@signplatform-ui/card';
import { ExitOutline, PersonOutline } from '@signplatform-ui/icons';
import AppRoutesPaths from 'modules/Router/routes/paths';
import ProfileAvatar from 'modules/UI/components/ProfileAvatar';
import ProfileAvatarSizesEnum from 'modules/UI/components/ProfileAvatar/ProfileAvatarSizesEnum';
import { memo, useCallback, useState } from 'react';
import type { MenuItemProp } from '@signplatform-ui/menu';
import type { UserProfile } from '@sp-api/user-api';
import {
  ProfileNameWrapper,
  ProfileWrapper,
  StyledCard,
  StyledMenu,
} from './UserProfile.styled';

type UserProfileProps = {
  user: DeepRO<UserProfile>;
};

const items: MenuItemProp[] = [
  {
    id: '1',
    title: (
      <>
        <PersonOutline /> Профиль
      </>
    ),
    to: AppRoutesPaths.ProfileDetails,
    'data-testid': 'profile-menu-details',
  },
  {
    id: '2',
    title: (
      <>
        <ExitOutline /> Выйти
      </>
    ),
    to: AppRoutesPaths.ProfileExit,
    'data-testid': 'profile-menu-exit',
  },
];

const UserProfileComponent = ({
  user: {
    personalData: { firstName = '', lastName = '' },
  },
}: UserProfileProps): JSX.Element => {
  const [isVisible, setVisible] = useState(false);
  const onClose = useCallback(() => {
    setVisible(false);
  }, []);

  const onToggle = useCallback(() => {
    setVisible((visible) => !visible);
  }, []);

  return (
    <StyledCard
      size={CardSizesEnum.Small}
      onClick={onToggle}
      data-testid='header-profile'
    >
      <ProfileWrapper data-testid='header-profile-info'>
        <ProfileAvatar
          size={ProfileAvatarSizesEnum.SM}
          firstName={firstName}
          lastName={lastName}
        />
        <ProfileNameWrapper>
          {[lastName, firstName].filter(Boolean).join(' ')}
        </ProfileNameWrapper>
      </ProfileWrapper>
      {isVisible && (
        <StyledMenu
          items={items}
          onClickOutside={onClose}
          data-testid='header-profile-menu'
        />
      )}
    </StyledCard>
  );
};

export default memo(UserProfileComponent);
