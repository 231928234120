import type { AxiosResponse } from 'axios';

export enum ErrorResponseLocationServiceNameEnum {
  AuthService = 'AUTH_SERVICE',
  CrudlDocument = 'CRUDL_DOCUMENT',
  Idpoint = 'IDPOINT',
  FileService = 'FILE_SERVICE',
  CrudUser = 'CRUD_USER',
}

export enum ErrorResponseTypeEnum {
  FormError = 'FORM_ERROR',
  Unknown = 'UNKNOWN',
  Message = 'MESSAGE',
  ErrorCode = 'ERROR_CODE',
  ValidationError = 'VALIDATION_ERROR',
  UnexpectedError = 'UNEXPECTED_ERROR',
  ServiceUnavailable = 'SERVICE_UNAVAILABLE',
  Unauthorized = 'UNAUTHORIZED',
  PermissionDenied = 'PERMISSION_DENIED',
  BadRequest = 'BAD_REQUEST',
  EntityNotFound = 'ENTITY_NOT_FOUND',
}

export enum ErrorResponseDisplayCodeEnum {
  Unknown = 'UNKNOWN',
  ServiceUnavailable = 'SERVICE_UNAVAILABLE',
  BadRequest = 'BAD_REQUEST',
  NotFound = 'NOT_FOUND',
  LogicError = 'LOGIC_ERROR',
  Forbidden = 'FORBIDDEN',
  FileError = 'FILE_ERROR',
}

export interface ErrorResponseLocation {
  serviceName: ErrorResponseLocationServiceNameEnum;
  nodeId: string;
  errorId: string;
  instant: string;
}

export interface ResponseError {
  location?: ErrorResponseLocation;
  type?: ErrorResponseTypeEnum;
  message?: string;
  displayCode: ErrorResponseDisplayCodeEnum;
  displayMessage: string;
  logMessage?: string;
  details?: Record<string, unknown>;
  stacktrace?: Array<string>;
}

export type ErrorResponse = AxiosResponse<ResponseError> & {
  response?: ResponseError;
};
