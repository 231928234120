import styled, { css } from 'styled-components';
import Text, { TextTypesEnum } from '@signplatform-ui/text';
import ErrorMessage from 'modules/UI/components/ErrorMessage';
import { Col } from 'styled-bootstrap-grid';
import { media } from 'modules/StyledUtility';
import PackageSigningTimer from './components/PackageSigningTimer';

export const StyledContainer = styled.div``;

export const StyledHeader = styled(Text).attrs({ type: TextTypesEnum.LBold })`
  margin-bottom: 16px;
`;

export const StyledText = styled(Text).attrs({ type: TextTypesEnum.L })`
  margin-bottom: 16px;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  margin: 0;
`;

export const StyledPackageSigningTimer = styled(PackageSigningTimer)`
  margin-top: 12px;
`;

export const StyledCol = styled(Col)`
  ${media.mobile(css`
    & + & {
      margin-top: 16px;
    }
  `)}
`;
