import * as yup from 'yup';
import DocumentValidator from 'modules/Documents/classes/DocumentValidator';
import {
  DocumentRejectReason,
  FILE_NAME_COMMON_ERROR,
} from 'modules/Documents/constants';
import { getErrorDataByRejectReason } from 'modules/Documents/utils';
import type { IFileInstance } from 'modules/UI/components/DocumentsPackEditor/types';

type IFileInstanceNeededData = Pick<IFileInstance, 'nameWithoutExtension'>;

export type RenameDocumentSchemaContext = {
  fileInstance: IFileInstanceNeededData;
  allFiles: IFileInstanceNeededData[];
};

const renameDocumentSchema = yup.object().shape({
  title: yup.string().test({
    test(value, { options: { context }, createError }) {
      const { fileInstance, allFiles } = context as RenameDocumentSchemaContext;

      const result = DocumentValidator.validateFileName(
        fileInstance,
        allFiles,
        value
      );

      if (
        result &&
        result !== DocumentRejectReason.FileNameHasForbiddenSymbols
      ) {
        const { errorInside, errorBottom, errorTop } =
          getErrorDataByRejectReason(result, false);

        const error = errorInside || errorBottom || errorTop;
        return createError({ message: error || FILE_NAME_COMMON_ERROR });
      }

      return true;
    },
  }),
});

export default renameDocumentSchema;
