import useDocumentApiClient from 'modules/Api/hooks/useDocumentApiClient';
import { useMutation } from 'react-query';
import notifySigningSuccess from 'modules/Documents/utils/notifySigningSuccess';
import type { ErrorResponse } from 'modules/App/types';
import type { UseMutationResult } from 'react-query';

type PackagePublishParams = {
  packageId: EntityId;
};

export default function usePublishPackage(
  isConcierge?: boolean
): UseMutationResult<void, ErrorResponse, PackagePublishParams> {
  const documentApi = useDocumentApiClient();

  return useMutation<void, ErrorResponse, PackagePublishParams>(
    async ({ packageId }) => {
      await documentApi.publishPackage(packageId);

      if (isConcierge) {
        notifySigningSuccess(true);
      }
    }
  );
}
