import Link from '@signplatform-ui/link';
import { media } from 'modules/StyledUtility';
import { Col, Row } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';
import { FooterColorEnum } from 'modules/UI/components/Footer/types';

export const FooterContent = styled.footer<{ $color: FooterColorEnum }>`
  padding: 20px 64px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme, $color }) =>
    $color === FooterColorEnum.primary
      ? theme.color.background.primary
      : '#000000'};

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;

export const LinkStyled = styled(Link)<{ $color: string }>`
  color: ${({ $color }) => $color};

  &:hover {
    color: ${({ $color }) => $color};
  }

  margin-bottom: 12px;

  ${media.mobile(css`
    margin: 12px 0 0;
  `)}
`;

export const PhoneNumberLink = styled.a<{ $color: string }>`
  width: 100%;
  justify-content: flex-end;
  font-weight: 550;
  font-size: 24px;
  line-height: 32px;
  color: ${({ $color }) => $color};

  &:hover {
    color: ${({ $color }) => $color};
  }

  ${media.mobile(css`
    justify-content: flex-start;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
  `)}
`;

export const CollFooterText = styled(Col)`
  & + & {
    text-align: right;
  }

  ${media.mobile(css`
    & + & {
      margin-bottom: 16px;
      text-align: left;
    }

    text-align: left;
    display: flex;
    flex-direction: column-reverse;
  `)}
`;

export const StyledRow = styled(Row)`
  ${media.mobile(css`
    flex-direction: column-reverse;
  `)}
`;
