import { memo } from 'react';
import type { PropsWithChildren } from 'react';
import { StatusWrapper } from './Status.styled';

type StatusProps = PropsWithChildren<{
  color?: string;
  className?: string;
}>;

const Status = (props: StatusProps) => {
  return <StatusWrapper {...props} />;
};

export default memo(Status);
