import { ButtonColorsEnum, ButtonSizesEnum } from '@signplatform-ui/button';
import { TimeOutline } from '@signplatform-ui/icons';
import { HeaderSizesEnum } from '@signplatform-ui/header';
import {
  StatusSigningEnum,
  StatusUnitEnum,
  TypeSignEnum,
} from '@sp-api/documents-api';
import { CertificateInstructions } from 'modules/Common';
import { StyledHeader } from 'modules/Documents/components/PackageWizard';
import RejectPackageSigningButton from 'modules/Documents/components/RejectPackageSigningButton';
import RevokePackageSigningButton from 'modules/Documents/components/RevokePackageSigningButton';
import SignButton from 'modules/Documents/components/SignButton';
import { usePackageContext } from 'modules/Documents/contexts';
import {
  getPackageCardQueryKey,
  usePackageCard,
} from 'modules/Documents/hooks';
import { useUser } from 'modules/Shared/hooks';
import { DocsPackEditorDocument } from 'modules/UI/components/DocumentsPackEditor';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Col, Row } from 'styled-bootstrap-grid';
import { hasDownloadDocumentButton } from 'modules/Documents/utils';
import ResignButton from 'modules/Documents/components/ResignButton';
import useResignLock from 'modules/Documents/components/ResignButton/hooks/useResignLock';
import getThresholdMessage from 'modules/Documents/utils/getThresholdMessage';
import {
  instructionQESItems,
  instructionItems,
} from 'modules/Common/components/CertificateInstructions/constants';
import { getOwnerName } from './utils';
import {
  DocumentProperties,
  DocumentSigners,
  DownloadPackageSingleDoc,
  GoskeyEmptyDocsNotification,
  PackageCardHeader,
  RejectReasonModal,
} from './components';
import {
  ButtonsWrapper,
  StyledBigCard,
  StyledDocsCard,
  StyledCaption,
} from './PackageCard.styled';

const TIMER_TICK = 60 * 1000;

const PackageCard = (): JSX.Element | null => {
  const { packageAdapted } = usePackageContext();
  const { user } = useUser();
  const queryClient = useQueryClient();

  usePackageCard(
    {
      packageId: packageAdapted.packageId,
      ownerClientId: packageAdapted.clientIdOwner,
    },
    {
      refetchOnMount: false,
      enabled: packageAdapted.status === StatusUnitEnum.Waiting,
      refetchInterval:
        packageAdapted.status === StatusUnitEnum.Waiting ? TIMER_TICK : false,
    }
  );

  const {
    clientIdOwner,
    signings,
    status,
    clientOwnerData,
    sendTime,
    docsServerAdapted,
    buttons,
    packageId,
    source: { signDueDate, stateUpdateStatus },
    docsServer,
  } = packageAdapted;

  const clients = useMemo(() => {
    return [...signings.clientOrgs, ...signings.clientPersons];
  }, [signings.clientOrgs, signings.clientPersons]);

  const ownerWithClients = useMemo(() => {
    return [signings.owner!, ...clients].filter(Boolean);
  }, [clients, signings.owner]);

  const outers = useMemo(() => {
    return [...signings.counterparties, ...signings.persons];
  }, [signings.counterparties, signings.persons]);

  const allSigners = useMemo(() => {
    return [...ownerWithClients, ...outers];
  }, [outers, ownerWithClients]);

  const userSigningStatus = useMemo((): StatusSigningEnum | undefined => {
    return ownerWithClients.find((x) => x.clientId === user.clientId)?.status;
  }, [ownerWithClients, user.clientId]);

  // const currentDocSigner = useMemo(() => {
  //   const selectedSigner = allSigners.find(
  //     (signer) => (signer as any).clientId === user.clientId
  //   );
  //   return selectedSigner;
  // }, [user.clientId, allSigners]);

  const signerDocs = useMemo(() => {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    let res: any[] = [];
    docsServer.forEach((doc) => {
      res = res.concat(doc.innerCounterpartySigningData || []);
      res = res.concat(doc.innerPersonsSigningData || []);
    });
    return res;
  }, [docsServer]);

  const currentDocSigner = signerDocs.find((doc) => {
    const docClientId =
      doc?.innerCounterpartyData?.clientId || doc?.innerPersonsData?.clientId;
    return docClientId === user.clientId;
  });

  const invalidateQuery = async () => {
    return queryClient.invalidateQueries(
      getPackageCardQueryKey({
        ownerClientId: clientIdOwner,
        packageId,
      })
    );
  };

  const isPersonWaiting = ownerWithClients.some(
    (client) =>
      client?.clientId === user.clientId &&
      client?.status === StatusSigningEnum.SignProcess
  );

  const hasDownloadDocs = hasDownloadDocumentButton({
    packageData: packageAdapted,
    user,
  });

  const { threshold, setIsLocked } = useResignLock();

  const tooltipContent = getThresholdMessage(threshold);

  const onLockResign = async () => {
    setIsLocked(true);
    await invalidateQuery();
  };

  return (
    <>
      <PackageCardHeader
        resignThreshold={threshold}
        resignTooltip={tooltipContent}
        onSignSuccess={onLockResign}
      />

      <StyledHeader type={HeaderSizesEnum.H6}>Информация</StyledHeader>

      <StyledBigCard>
        <DocumentProperties
          status={status}
          authorName={getOwnerName(clientOwnerData)}
          sendTime={sendTime}
          signDueDate={signDueDate}
          slotStatusRight={<RejectReasonModal packageData={packageAdapted} />}
          userSigningStatus={userSigningStatus}
          stateUpdateStatus={stateUpdateStatus}
        />
      </StyledBigCard>

      <StyledHeader type={HeaderSizesEnum.H6}>
        Список документов на подписание
      </StyledHeader>

      <StyledDocsCard>
        {docsServerAdapted.docs.map((x) => {
          return (
            <DocsPackEditorDocument
              key={x.clientServerId}
              fileInstance={x}
              onRenderDocActions={
                hasDownloadDocs ? DownloadPackageSingleDoc : undefined
              }
            />
          );
        })}
      </StyledDocsCard>

      <StyledHeader type={HeaderSizesEnum.H6}>
        {allSigners.length === 1 ? 'Подписант' : 'Подписанты'}
      </StyledHeader>

      <StyledBigCard>
        <DocumentSigners signers={allSigners} />
      </StyledBigCard>

      {status === StatusUnitEnum.Waiting &&
        (buttons.signButton ||
          buttons.resignButton ||
          buttons.rejectButton ||
          buttons.revokeButton) && (
          <>
            <StyledHeader type={HeaderSizesEnum.H6}>
              Подписание комплекта документов
            </StyledHeader>

            <StyledBigCard>
              <Row>
                <Col lg={8}>
                  {currentDocSigner.typeSign === TypeSignEnum.Qes && (
                    <div>
                      Установите мобильное приложение{' '}
                      <a
                        href='https://goskey.ru/'
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        Госключ
                      </a>
                      . После нажатия на кнопку "Подписать" выпустите в Госключе
                      сертификат УКЭП и подпишите им документы
                    </div>
                  )}
                  {currentDocSigner.typeSign !== TypeSignEnum.Qes && (
                    <div>
                      Подписание происходит в мобильном приложении{' '}
                      <a
                        href='https://goskey.ru/'
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        Госключ
                      </a>
                      . Убедитесь, что оно установлено на Вашем устройстве
                    </div>
                  )}
                </Col>
              </Row>
              <ButtonsWrapper>
                {buttons.signButton && (
                  <SignButton
                    packageId={packageId}
                    ownerClientId={clientIdOwner}
                    size={ButtonSizesEnum.Medium}
                    color={ButtonColorsEnum.Primary}
                    onSignSuccess={onLockResign}
                  />
                )}

                {buttons.resignButton && (
                  <ResignButton
                    packageId={packageId}
                    ownerClientId={clientIdOwner}
                    size={ButtonSizesEnum.Medium}
                    color={ButtonColorsEnum.Primary}
                    onSignSuccess={onLockResign}
                    tooltip={threshold ? tooltipContent : undefined}
                    disabled={!!threshold}
                  />
                )}

                {buttons.rejectButton && (
                  <RejectPackageSigningButton
                    packageId={packageId}
                    ownerClientId={clientIdOwner}
                    signerClientId={user.clientId}
                    onSuccess={invalidateQuery}
                  />
                )}

                {buttons.revokeButton && (
                  <RevokePackageSigningButton
                    packageId={packageId}
                    onSuccess={invalidateQuery}
                  />
                )}

                {isPersonWaiting && (
                  <GoskeyEmptyDocsNotification
                    packageId={packageId}
                    ownerClientId={clientIdOwner}
                    invalidateQuery={invalidateQuery}
                    signType={currentDocSigner.typeSign}
                  />
                )}
              </ButtonsWrapper>
              {!!threshold && buttons.resignButton && (
                <StyledCaption>
                  <TimeOutline />
                  {tooltipContent}
                </StyledCaption>
              )}
              <CertificateInstructions
                items={
                  currentDocSigner.typeSign === TypeSignEnum.Qes
                    ? instructionQESItems
                    : instructionItems
                }
              />
            </StyledBigCard>
          </>
        )}
    </>
  );
};

export default PackageCard;
