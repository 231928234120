import { useMemo } from 'react';
import DocumentValidator from 'modules/Documents/classes/DocumentValidator';
import type { ErrorMap } from 'modules/Documents/classes/DocumentValidator';
import type { IFileInstance } from 'modules/UI/components/DocumentsPackEditor/types';
import type { IPackageDocsContext } from 'modules/Documents/contexts';

export type UseDocsErrorMapInput = {
  clientDocs: IFileInstance[];
  docsUIName: IPackageDocsContext['docsUINameById'];
  notLoadedDocIds: Set<string>;
};

export type UseDocsErrorMapOutput = {
  docsErrorMap: ErrorMap;
};

export default function useDocsErrorMap({
  clientDocs,
  docsUIName,
  notLoadedDocIds,
}: UseDocsErrorMapInput): UseDocsErrorMapOutput {
  const docsErrorMap = useMemo(() => {
    return DocumentValidator.getFilesErrorMap(
      clientDocs,
      docsUIName,
      notLoadedDocIds
    );
  }, [clientDocs, docsUIName, notLoadedDocIds]);

  return {
    docsErrorMap,
  };
}
