import { useEffect, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

const LOCK_TIME_SECONDS = 30;

export default function useResignLock(): {
  threshold: number;
  setIsLocked: Dispatch<SetStateAction<boolean>>;
} {
  const [threshold, setThreshold] = useState(0);
  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    let ts: ReturnType<typeof setTimeout> | null = null;
    const lockAndCount = (timeout: number) => {
      ts = setTimeout(() => {
        const nextTick = timeout - 1;
        setThreshold(nextTick);

        if (nextTick > 0) {
          lockAndCount(nextTick);
        } else {
          setIsLocked(false);
        }
      }, 1000);
    };

    if (isLocked) {
      setThreshold(LOCK_TIME_SECONDS);
      lockAndCount(LOCK_TIME_SECONDS);
    }

    return () => {
      if (ts) {
        clearTimeout(ts);
      }
    };
  }, [isLocked]);

  return { threshold, setIsLocked };
}
