import { FontSizesEnum } from '@signplatform-ui/typographics';
import styled from 'styled-components';

export const StyledResultTitle = styled.div`
  color: ${({ theme }) => theme.color.text.secondary};
  font-size: ${FontSizesEnum.AdditionalS};
  margin-bottom: 12px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;

  button + button {
    margin-left: 16px;
  }
`;

export const StyledCounterpartiesResult = styled.div`
  padding-top: 16px;
  overflow: auto;
`;

export const StyledFoundCounterparty = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
`;
