import { Configuration, SpLogicSignGateApi } from '@sp-api/sign-gate-api';
import { apiService } from 'modules/Api/components/ApiProvider/services';
import { useConfiguration } from 'modules/Shared/hooks';

const useApi = apiService.createUseApi(SpLogicSignGateApi, Configuration);

const useSignGateApi = (): SpLogicSignGateApi => {
  const [{ signGateContextUrl }] = useConfiguration();
  return useApi(signGateContextUrl);
};

export default useSignGateApi;
