import { yupResolver } from '@hookform/resolvers/yup';
import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import { HeaderSizesEnum } from '@signplatform-ui/header';
import { useUser } from 'modules/Shared/hooks';
import {
  useFieldSwitchCache,
  useSetFieldErrorByResponse,
} from 'modules/Common/hooks';
import { findSameIdCounterparty } from 'modules/Documents/components/PackageWizard/utils';
import useFindUser from 'modules/Documents/hooks/useFindUser';
import Modal, { ModalButtonPanel } from 'modules/UI/components/Modal';
import ErrorMessage from 'modules/UI/components/ErrorMessage';
import {
  FormEmailInput,
  FormPassportInput,
  FormSnilsInput,
} from 'modules/UI/components/form';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import useCounterpartiesContextMin from 'modules/Documents/components/PackageWizard/hooks/useCounterpartiesContextMin';
import { TabControl } from '@signplatform-ui/tab-menu';
import type { InviteNotFoundUserData } from 'modules/Documents/classes';
import type { TabItemConfig } from '@signplatform-ui/tab-menu';
import type {
  CounterpartiesFormValues,
  InviteCounterpartyProps,
  InviteFormData,
} from 'modules/Documents/types';
import formSchema from './formSchema';
import {
  StyledForm,
  StyledHeader,
  StyledInput,
  StyledText,
} from './InviteModal.styled';

export type InviteFieldType = 'idNum' | 'snils';

export const HAS_PASSPORT_TAB = true;

const tabs: TabItemConfig<InviteFieldType>[] = [
  {
    id: 'snils',
    content: 'СНИЛС',
    'data-testid': 'snils-tab',
  },
];

if (HAS_PASSPORT_TAB) {
  tabs.unshift({
    id: 'idNum',
    content: 'Паспорт',
    'data-testid': 'passport-tab',
  });
}

const InviteModal = ({
  onSetInvitedSigner,
}: InviteCounterpartyProps): JSX.Element => {
  const { activeTab, value, onSwitchModal, onSetActiveTab, onCloseModal } =
    useCounterpartiesContextMin();

  const { user } = useUser();

  const counterpartiesForm = useFormContext<CounterpartiesFormValues>();
  const counterpartiesFormValues = counterpartiesForm.getValues();

  const parsedActiveTab = useMemo(() => {
    if (activeTab === 'snils' || (HAS_PASSPORT_TAB && activeTab === 'idNum')) {
      return activeTab;
    }

    return tabs[0].id;
  }, [activeTab]);

  const methods = useForm<InviteFormData & { form?: string }>({
    resolver: yupResolver(formSchema),
    shouldUnregister: true,
    context: {
      user,
    },
  });

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    watch,
    setValue,
  } = methods;

  const formData = watch();

  useEffect(() => {
    const sub = watch(() => {
      if (!errors.form) return;
      clearErrors('form');
    });

    return () => sub.unsubscribe();
  }, [clearErrors, errors.form, watch]);

  // using search request
  const [{ isLoading, data, error, isError }, requestFindUsers] = useFindUser();

  // восстановление предыдущего значения поля при обратном переключении вкладки
  const cache = useFieldSwitchCache(formData, parsedActiveTab, setValue, value);

  // setting error by active tab on response
  useSetFieldErrorByResponse({ error, isError }, (calcError) => {
    setError(parsedActiveTab, calcError);
  });

  // do search by open tab value, not including email
  const handleFindUsers = useCallback(
    (formDataFromSubmit: InviteFormData) => {
      requestFindUsers({
        [parsedActiveTab]: formDataFromSubmit[parsedActiveTab],
      });
    },
    [requestFindUsers, parsedActiveTab]
  );

  // when we get search response, what we do
  useEffect(() => {
    if (!data) return;

    // we dont need invite - we need add modal
    if (data.persons?.length || data.counterparties?.length) {
      const fieldFoundBy = formData.snils ? 'snils' : 'idNum';

      onSetActiveTab(fieldFoundBy);
      onSwitchModal({ ...value, ...formData, ...cache });
      return;
    }

    const person: InviteNotFoundUserData = {
      email: formData.email,
      snils: formData.snils || undefined,
      idNum: formData.idNum || undefined,
    };

    // searching without clientIdOwner, because we are already invited, its our package to edit
    const sameIdCp = findSameIdCounterparty({
      currentCpsGroups: counterpartiesFormValues,
      selectedCps: [person],
    });

    // found already added user in state. Can be really added or just cached
    if (sameIdCp) {
      // cleaning cps cache and adding user
      if (counterpartiesFormValues.iAmOnlySigner) {
        onSetInvitedSigner(person, true);
        return;
      }

      setError('form', {
        message: 'Пользователь уже является участником подписания',
      });
      return;
    }

    // check for email because we won't include email in search request
    if (user?.emails?.find(({ email }) => person.email === email)) {
      setError('form', {
        message: 'Вы уже являетесь участником подписания',
      });
      return;
    }

    onSetInvitedSigner(person);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSelectTab = useCallback(
    (tab: InviteFieldType) => {
      onSetActiveTab(tab);
    },
    [onSetActiveTab]
  );

  useEffect(() => {
    document.querySelector('[role="dialog"]')?.querySelector('input')?.focus();
  }, [parsedActiveTab]);

  const isInviteDisabled =
    isLoading || !formData.email || !formData[parsedActiveTab];

  return (
    <Modal onClose={onCloseModal} data-testid='invite-modal'>
      <StyledHeader type={HeaderSizesEnum.H5}>
        Пригласить участника в Автограф
      </StyledHeader>

      <StyledText>
        Укажите данные для направления и связи приглашения с участником.
        Приглашение будет отправлено после публикации документа
      </StyledText>

      <TabControl<InviteFieldType>
        activeId={parsedActiveTab}
        onSwitchTab={handleSelectTab}
        tabs={tabs}
      />

      <FormProvider {...methods}>
        <StyledForm onSubmit={handleSubmit(handleFindUsers)}>
          {parsedActiveTab === 'snils' ? (
            <StyledInput
              as={FormSnilsInput}
              data-testid='snils-input'
              error={errors.snils}
              id='snils-input'
              name='snils'
            />
          ) : (
            <StyledInput
              as={FormPassportInput}
              data-testid='idNum-input'
              error={errors.idNum}
              id='idNum-input'
              name='idNum'
            />
          )}

          <StyledInput
            as={FormEmailInput}
            name='email'
            id='email-input'
            error={errors.email}
            data-testid='email-input'
          />

          {errors.form && (
            <ErrorMessage style={{ marginTop: 10 }}>
              {errors.form?.message}
            </ErrorMessage>
          )}

          <ModalButtonPanel>
            <Button
              size={ButtonSizesEnum.Medium}
              disabled={isInviteDisabled}
              type='submit'
              data-testid='invite-user-confirm'
            >
              Пригласить участника
            </Button>

            <Button
              color={ButtonColorsEnum.Secondary}
              size={ButtonSizesEnum.Medium}
              onClick={onCloseModal}
              data-testid='invite-user-cancel'
            >
              Отменить
            </Button>
          </ModalButtonPanel>
        </StyledForm>
      </FormProvider>
    </Modal>
  );
};

export default InviteModal;
