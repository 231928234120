import { useState, useEffect, useMemo } from 'react';
import { throttle } from 'lodash';
import type { PropsWithChildren } from 'react';
import type { IMediaContext } from './MediaContext';
import MediaContext, { handleGetMediaData } from './MediaContext';

const MediaProvider = ({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element => {
  const [mediaData, setMediaData] = useState<IMediaContext>(
    handleGetMediaData()
  );

  const handleResize = useMemo(() => {
    return throttle(() => setMediaData(handleGetMediaData()), 200);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <MediaContext.Provider value={mediaData}>{children}</MediaContext.Provider>
  );
};

export default MediaProvider;
