import { lazy } from 'react';
import type { AppRoute } from '../types';
import { RouteTypeEnum } from '../types';
import PowAttorneyRoutesEnum from './paths';

const PowAttorneyPageSearch = lazy(
  () =>
    import(
      /* webpackChunkName: "PowAttorneyPageSearch" */ 'modules/PowAttorney/pages/PowAttorneyPageSearch'
    )
);

const PowAttorneyPageCard = lazy(
  () =>
    import(
      /* webpackChunkName: "PowAttorneyPageCard" */ 'modules/PowAttorney/pages/PowAttorneyPageWizard'
    )
);

const PowAttorneyPagePreview = lazy(
  () =>
    import(
      /* webpackChunkName: "PowAttorneyPagePreview" */ 'modules/PowAttorney/pages/PowAttorneyPagePreview'
    )
);

const PowAttorneyPageNotFound = lazy(
  () =>
    import(
      /* webpackChunkName: "PowAttorneyPageNotFound" */ 'modules/PowAttorney/pages/PowAttorneyPageNotFound'
    )
);

const routes: AppRoute[] = [
  {
    id: 'powattorney:list',
    path: PowAttorneyRoutesEnum.PowAttorneyList,
    type: RouteTypeEnum.Private,
    exact: true,
    component: PowAttorneyPageSearch,
  },
  {
    id: 'powattorney:wizard',
    path: PowAttorneyRoutesEnum.PowAttorneyWizard,
    type: RouteTypeEnum.Private,
    exact: true,
    component: PowAttorneyPageCard,
  },
  {
    id: 'powattorney:draft',
    path: PowAttorneyRoutesEnum.PowAttorneyDraft,
    type: RouteTypeEnum.Private,
    exact: true,
    component: PowAttorneyPageCard,
  },
  {
    id: 'powattorney:preview',
    path: PowAttorneyRoutesEnum.PowAttorneyPreview,
    type: RouteTypeEnum.Private,
    exact: true,
    component: PowAttorneyPagePreview,
  },
  {
    id: 'powattorney:notFound',
    path: PowAttorneyRoutesEnum.PowAttorneyNotFound,
    type: RouteTypeEnum.Private,
    exact: true,
    component: PowAttorneyPageNotFound,
  },
];

export default routes;
