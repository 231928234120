import FileUpload from 'modules/UI/components/FileUpload/FileUpload';
import { Fragment, memo, useCallback } from 'react';
import ErrorMessage from 'modules/UI/components/ErrorMessage';
import type { FileRejection } from 'react-dropzone';
import type { ReactNode } from 'react';
import type { FileUploadProps } from 'modules/UI/components/FileUpload/FileUpload';
import type {
  DocumentsPackEditorProps,
  IFileInstance,
  OnRenderDocActions,
} from './types';
import { ClientServerFileCreator } from './classes';
import { StyledCard, StyledContainer } from './DocumentsPackEditor.styled';
import { DocsPackDocument } from './components';

type DocsPackEditorDocumentProps = {
  children?: ReactNode;
  childrenBefore?: ReactNode;
  fileInstance: IFileInstance;
  fileCardError?: boolean | string | null;
  hasError?: boolean;
  isDisabled?: boolean;
  onRenderDocActions?: OnRenderDocActions;
  onGetFileName?: (fileInstance: IFileInstance) => string | undefined;
};

export const DocsPackEditorDocument = ({
  children,
  childrenBefore,
  fileInstance,
  fileCardError,
  hasError,
  onRenderDocActions,
  onGetFileName,
  isDisabled,
}: DocsPackEditorDocumentProps): JSX.Element => {
  const errorText =
    typeof fileCardError === 'string' ? fileCardError : undefined;

  return (
    <DocsPackDocument
      data={fileInstance}
      errorText={errorText}
      hasError={hasError}
      name={onGetFileName?.(fileInstance)}
    >
      {childrenBefore}
      {onRenderDocActions?.({ hasError, data: fileInstance, isDisabled })}
      {children}
    </DocsPackDocument>
  );
};

const DocumentsPackEditor = ({
  acceptExt,
  children,
  className,
  isDisabled,
  maxSingleSizeBytes,
  onAddFiles,
  onGetFileErrorData,
  onGetFileName,
  onRenderDocActions,
  slotDescription,
  slotHeader,
  value,
}: DocumentsPackEditorProps): JSX.Element => {
  const onDrop: FileUploadProps['onDrop'] = useCallback(
    (acc: File[], rej: FileRejection[]): void => {
      if (isDisabled) return;

      const newFiles = ClientServerFileCreator.getClientFiles(acc, rej);
      onAddFiles?.(newFiles);
    },
    [isDisabled, onAddFiles]
  );

  return (
    <StyledContainer className={className} $isDisabled={isDisabled}>
      <StyledCard>
        {slotHeader}

        <FileUpload
          accept={acceptExt}
          maxSize={maxSingleSizeBytes}
          multiple
          disabled={isDisabled}
          onDrop={onDrop}
          slotDescription={slotDescription}
        />
      </StyledCard>

      {(value || []).slice().map((fileInstance, idx) => {
        const { errorBottom, errorInside, errorTop, hasError } =
          onGetFileErrorData?.(fileInstance, idx) || {};

        return (
          <Fragment key={fileInstance.clientServerId}>
            <ErrorMessage>{errorTop}</ErrorMessage>

            <DocsPackEditorDocument
              fileCardError={errorInside}
              fileInstance={fileInstance}
              hasError={hasError}
              isDisabled={isDisabled}
              onGetFileName={onGetFileName}
              onRenderDocActions={onRenderDocActions}
            />

            <ErrorMessage>{errorBottom}</ErrorMessage>
          </Fragment>
        );
      })}

      {children}
    </StyledContainer>
  );
};
export default memo(DocumentsPackEditor);
