import { noop } from 'lodash';
import Notification, {
  NotificationsTypesEnum,
} from '@signplatform-ui/notification';
import type { MouseEventHandler, ReactNode } from 'react';

export type ToastNotificationProps = {
  title: string;
  details: ReactNode | string;
  type?: NotificationsTypesEnum;
  onClick?: MouseEventHandler<HTMLDivElement>;
  closeToast?: () => void;
};

const ToastNotification = ({
  title,
  details,
  type = NotificationsTypesEnum.Info,
  onClick = noop,
  closeToast,
}: ToastNotificationProps): JSX.Element => {
  return (
    <Notification
      onClose={closeToast}
      onClick={onClick}
      isClosable
      data-testid='toast-notification'
      title={title}
      type={type}
    >
      {details}
    </Notification>
  );
};

export default ToastNotification;
