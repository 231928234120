import {
  AtSolid,
  CalendarSolid,
  CloseSolid,
  DocOkSolid,
  ErrorTriangleSolid,
  FolderSolid,
  LoaderSolid,
  TimeSolid,
  UndoSolid,
  StatusInProgress,
} from '@signplatform-ui/icons';

import Tooltip from '@signplatform-ui/tooltip';
import signingStatusMap from 'modules/Documents/constants/signingStatusMap';
import { SignerSignStatusEnum } from 'modules/Documents/types';
import { useTheme } from 'styled-components';
import type {
  StatusInviteEnum,
  StatusSigningEnum,
} from '@sp-api/documents-api';
import type { SignerSignStatusUni } from 'modules/Documents/types';
import type { FunctionComponent } from 'react';
import type { DefaultTheme } from 'styled-components';
import { IconStyled } from './SigningStatus.styled';

const iconByStatus: Record<
  SignerSignStatusUni,
  FunctionComponent<{ fill?: string }>
> = {
  // TODO: update SignerSignStatusUni statuses to StatusSigningEnum | StatusInviteEnum
  [SignerSignStatusEnum.Draft]: FolderSolid,
  [SignerSignStatusEnum.Rejected]: CloseSolid,
  [SignerSignStatusEnum.RejectedByOwner]: UndoSolid,
  [SignerSignStatusEnum.Inviting]: AtSolid,
  [SignerSignStatusEnum.Waiting]: TimeSolid,
  [SignerSignStatusEnum.SignProcess]: LoaderSolid,
  [SignerSignStatusEnum.Expired]: CalendarSolid,
  [SignerSignStatusEnum.Error]: ErrorTriangleSolid,
  [SignerSignStatusEnum.Signed]: DocOkSolid,
  [SignerSignStatusEnum.QesIssueProcess]: StatusInProgress,
};

// TODO: update SignerSignStatusUni statuses to StatusSigningEnum | StatusInviteEnum
const getColorByStatusMap = (
  theme: DefaultTheme
): Record<SignerSignStatusUni, string> => ({
  [SignerSignStatusEnum.Draft]: theme.color.text.secondary,
  [SignerSignStatusEnum.Rejected]: theme.color.status.danger,
  [SignerSignStatusEnum.RejectedByOwner]: theme.color.status.danger,
  [SignerSignStatusEnum.Inviting]: theme.color.text.secondary,
  [SignerSignStatusEnum.Waiting]: theme.color.basic.primary,
  [SignerSignStatusEnum.SignProcess]: theme.color.basic.primary,
  [SignerSignStatusEnum.Expired]: theme.color.status.danger,
  [SignerSignStatusEnum.Error]: theme.color.status.danger,
  [SignerSignStatusEnum.Signed]: theme.color.status.success,
  //todo: fix color to correct theme
  [SignerSignStatusEnum.QesIssueProcess]: 'white',
});

type SigningStatusProps = {
  status: StatusInviteEnum | StatusSigningEnum;
  className?: string;
};

const SigningStatus = ({
  status,
  className,
}: SigningStatusProps): JSX.Element | null => {
  const theme = useTheme();
  const colorMap = getColorByStatusMap(theme);
  const colorValue = colorMap[status];
  const Icon = iconByStatus[status];

  return (
    <Tooltip
      renderContent={() => signingStatusMap[status]}
      data-testid='signing-status-icon-tooltip'
      className={className}
    >
      <IconStyled
        as={Icon}
        $color={colorValue}
        data-testid='signing-status-icon'
      />
    </Tooltip>
  );
};

export default SigningStatus;
