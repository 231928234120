import { generateGuid } from 'modules/Common/services';
import { NotificationsTypesEnum } from '@signplatform-ui/notification';
import { createElement } from 'react';
import { toast } from 'react-toastify';
import type { ToastOptions } from 'react-toastify';
import type { ReactNode } from 'react';
import ToastNotification from '../components/ToastNotification';

const notificationsComponentClasses = {
  [NotificationsTypesEnum.Info]: 'toast-type-info',
  [NotificationsTypesEnum.Success]: 'toast-type-success',
  [NotificationsTypesEnum.Error]: 'toast-type-error',
  [NotificationsTypesEnum.Warning]: 'toast-type-warning',
};

export class NotificationsService {
  /** Show toast notification * */
  notify(
    title: string,
    details: ReactNode | string,
    notificationType = NotificationsTypesEnum.Info,
    {
      toastId = generateGuid('xxxx-xxxx'),
      onClick,
      type,
      ...options
    } = {} as ToastOptions
  ): void {
    toast(
      createElement(ToastNotification, {
        title,
        details,
        type: notificationType,
        onClick,
      }),
      NotificationsService.__createOptions({
        ...NotificationsService.__classesByType(notificationType),
        ...options,
        toastId,
      })
    );
  }

  private static __classesByType(
    type: NotificationsTypesEnum
  ): Pick<ToastOptions, 'bodyClassName' | 'className' | 'progressClassName'> {
    return {
      className:
        notificationsComponentClasses[type] || NotificationsTypesEnum.Info,
      bodyClassName: '',
      progressClassName: '',
    };
  }

  private static __createOptions(options: ToastOptions) {
    const defaultOptions = {
      closeButton: false,
      hideProgressBar: true,
    };

    return {
      ...defaultOptions,
      ...options,
    };
  }
}

export default new NotificationsService();
