import { useQueryClient } from 'react-query';
import type { QueryKey } from 'react-query';

export default function useInvalidateQuery(
  invalidateQuery?: QueryKey
): () => void {
  const queryClient = useQueryClient();

  return (): void => {
    if (invalidateQuery) {
      void queryClient.invalidateQueries(invalidateQuery);
    }
  };
}
