import fileDownload from 'js-file-download';
import { useLoadDocumentApiClient } from 'modules/Api';
import { DocumentsStateKeysEnum } from 'modules/Documents/constants';
import { useQuery } from 'react-query';
import { getFilenameFromContentDisposition } from 'services/fileUtils';
import type { UseQueryResult } from 'react-query';
import type { ErrorResponse } from 'modules/App/types';

type DownloadPackageParams = {
  ownerClientId: EntityId;
  packageId: EntityId;
};

type PackageResponseHeaders = {
  'content-disposition': string;
};

export default function useDownloadPackage({
  ownerClientId,
  packageId,
}: DownloadPackageParams): UseQueryResult<
  { file: string; fileName: string },
  ErrorResponse
> {
  const loadDocumentApi = useLoadDocumentApiClient();

  return useQuery<{ file: string; fileName: string }, ErrorResponse>(
    DocumentsStateKeysEnum.DownloadPackage,
    async () => {
      const { data, headers } = await loadDocumentApi.getPackageFiles(
        ownerClientId,
        packageId,
        {
          responseType: 'blob',
        }
      );

      const fileName = getFilenameFromContentDisposition(
        (headers as PackageResponseHeaders)['content-disposition']
      );

      fileDownload(data as string, fileName);

      return {
        file: data,
        fileName,
      };
    },
    {
      enabled: false,
    }
  );
}
