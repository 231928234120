import styled from 'styled-components';
import { FontSizesEnum, LineHeightsEnum } from '@signplatform-ui/typographics';

// eslint-disable-next-line import/prefer-default-export
export const StatusWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  color: ${({ color }) => color};
  font-size: ${FontSizesEnum.AdditionalS};
  line-height: ${LineHeightsEnum.AdditionalS};

  svg {
    width: 18px;
    path {
      fill: ${({ color }) => color};
    }
  }
`;
