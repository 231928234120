import Card from '@signplatform-ui/card';
import Header from '@signplatform-ui/header';
import styled, { css } from 'styled-components';
import { typography } from '@signplatform-ui/typographics';
import { media } from 'modules/StyledUtility';
import { DocsPackEditorSP } from 'modules/Documents/components/DocsPackEditorSP';
import ErrorMessage from 'modules/UI/components/ErrorMessage';

export const StyledCard = styled(Card)`
  margin: 16px 0 24px;
  padding: 20px 24px;

  ${media.mobile(css`
    margin: 12px 0 20px;
    padding: 20px;
  `)}
`;

export const StyledErrorMessage = styled(ErrorMessage)<{
  $isAfterCard?: boolean;
}>`
  ${({ $isAfterCard }) => ($isAfterCard ? 'margin: -16px 0 24px' : '')}
`;

export const StyledText = styled.div`
  ${typography['Additional/L']}
  margin-bottom: 24px;
`;

export const StyledPackEditor = styled(DocsPackEditorSP)`
  margin: 16px 0 24px;

  ${media.mobile(css`
    margin: 12px 0 20px;
  `)}
`;

export const StyledHeader = styled(Header)<{
  $margin?: string;
  $justifyContent?: 'space-between';
}>`
  margin: ${({ $margin }) => $margin || '10px 0 16px'};
  display: flex;
  justify-content: ${({ $justifyContent }) => $justifyContent || 'flex-start'};
`;

export const StyledDiv = styled.div`
  display: inline-block;
  flex: 0 0 auto;
  width: 24px;
`;
