import styled from 'styled-components';
import FileUploadButton from 'modules/UI/components/FileUploadButton';
import type { Theme } from '@vtb/ui-kit3';

// eslint-disable-next-line import/prefer-default-export
export const StyledFileUploadButton = styled(FileUploadButton)<{
  theme: Theme;
}>`
  align-items: center;
  display: flex;
  width: 100%;

  &[data-dimension='m'] {
    padding: 8px 9px;
  }
`;
