import type { HTMLAttributes } from 'react';
import StyledErrorMessageContent from './ErrorMessage.styled';

const ErrorMessage = ({
  children,
  ...rest
}: HTMLAttributes<HTMLDivElement>): JSX.Element | null => {
  if (!children) return null;

  return (
    <StyledErrorMessageContent {...rest}>{children}</StyledErrorMessageContent>
  );
};

export default ErrorMessage;
