import styled from 'styled-components';
import Text from '@signplatform-ui/text';
import Notification from '@signplatform-ui/notification';
import { TimeSolid } from '@signplatform-ui/icons';

export const NotificationDescription = styled(Text)`
  margin-top: 4px;
`;

export const NotificationContent = styled.div`
  display: flex;
`;

export const TimeSolidStyled = styled(TimeSolid)`
  margin-right: 15px;

  path {
    fill: ${({ theme }) => theme.color.basic.primary};
  }
`;

export const NotificationStyled = styled(Notification)`
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const StyledContainer = styled.div`
  margin-top: 10px;
`;
