import { ButtonColorsEnum, ButtonSizesEnum } from '@signplatform-ui/button';
import Link from '@signplatform-ui/link';
import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'styled-bootstrap-grid';
import { useCounterpartiesContext } from 'modules/Documents/components/PackageWizard/hooks';
import type { SetValueConfig } from 'react-hook-form';
import type { MouseEvent } from 'react';
import type { CounterpartiesFormValues, SigningsFormValues } from '../../types';
import type { DocumentCounterpartiesProps } from './types';
import { DocumentCounterpartiesContextProvider } from '../../contexts';
import CounterpartiesModals from './components/CounterpartiesModals';
import DocumentSignings from './components/DocumentSignings';
import { StyledButton, StyledText } from './DocumentCounterparties.styled';

const dirtyField: SetValueConfig | undefined = {
  shouldDirty: true,
};

// this also wrapped in context provider when exporting
const DocumentCounterpartiesInner = ({
  documentPackage: { clientIdOwner },
}: DocumentCounterpartiesProps): JSX.Element => {
  const { inviteModal, searchModal } = useCounterpartiesContext();

  const handleShowInviteModal = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    inviteModal.onOpen();
  };

  const { setValue, watch } = useFormContext<CounterpartiesFormValues>();
  const formData = watch();

  const handleSetSingers = (signers: SigningsFormValues): void => {
    setValue('clientPersons', signers.clientPersons, dirtyField);
    setValue('clientOrgs', signers.clientOrgs, dirtyField);
    setValue('counterparties', signers.counterparties, dirtyField);
    setValue('persons', signers.persons, dirtyField);
  };

  const hasSigningsToShow =
    !formData.iAmOnlySigner &&
    (formData.clientOrgs.length ||
      formData.clientPersons.length ||
      formData.counterparties.length ||
      formData.persons.length);

  return (
    <Row>
      <Col>
        <StyledText>
          Укажите участников подписания. Если вы не нашли участника -
          <br />
          <Link
            data-testid='invite-user-link'
            to='#invite-user'
            onClick={handleShowInviteModal}
          >
            пригласите
          </Link>{' '}
          его на платформу подписания Автограф
        </StyledText>
      </Col>

      <Col>
        <StyledButton
          color={ButtonColorsEnum.Secondary}
          data-testid='add-counterparty-button'
          onClick={searchModal.onOpen}
          size={ButtonSizesEnum.Medium}
          type='button'
        >
          Добавить участника
        </StyledButton>
      </Col>

      {!hasSigningsToShow ? null : (
        <Col>
          <DocumentSignings
            clientIdOwner={clientIdOwner}
            onChange={handleSetSingers}
            value={formData}
          />
        </Col>
      )}

      <CounterpartiesModals onSetSigners={handleSetSingers} />
    </Row>
  );
};

const DocumentCounterpartiesWithContext = (
  props: DocumentCounterpartiesProps
): JSX.Element => {
  return (
    <DocumentCounterpartiesContextProvider {...props}>
      <DocumentCounterpartiesInner {...props} />
    </DocumentCounterpartiesContextProvider>
  );
};

export default DocumentCounterpartiesWithContext;
