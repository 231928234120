import { TypeSignEnum } from '@sp-api/documents-api';
import type {
  ClientSigningDataInput,
  ClientSigningData,
  CpSigningDataInput,
  CpSigningData,
} from 'modules/Documents/classes/types';
import type {
  InnerCounterpartyData,
  InnerCounterpartySigningData,
  InnerPersonsData,
  InnerPersonSigningData,
  OuterCounterpartyData,
  OuterCounterpartySigningData,
  OuterPersonsData,
  OuterPersonSigningData,
  CounterpartyClientOrg,
  CounterpartyClientPerson,
  CounterpartyOrg,
  CounterpartyOrgBase,
  CounterpartyPerson,
  CounterpartyPersonBase,
} from 'modules/Documents/types';

// TODO remove all server to client mappers in favor of adapter classes
//  but keep client to server mappers probably
export default abstract class SigningsAdapterMappers {
  private static mapServerOuterOrg(
    source: CounterpartyOrgBase
  ): PartialToUndefined<OuterCounterpartyData> {
    return {
      email: source.email!,
      ogrnIp: source.orgnip,
      ogrn: source.ogrn,
    };
  }

  private static mapServerInnerOrg(
    source: CounterpartyOrgBase
  ): PartialToUndefined<Omit<InnerCounterpartyData, 'clientId'>> {
    return {
      type: source.type,
      ogrnIp: source.orgnip,
      ogrn: source.ogrn,
      organizationInn: source.organizationInn,
      organizationKpp: source.organizationKpp,
      name: source.name,
    };
  }

  private static mapServerOuterPerson(
    source: CounterpartyPersonBase
  ): PartialToUndefined<OuterPersonsData> {
    return {
      snils: source.snils,
      fullDocNumber: source.idNum,
      email: source.email!,
    };
  }

  private static mapServerInnerPerson(
    source: CounterpartyPersonBase
  ): PartialToUndefined<Omit<InnerPersonsData, 'clientId'>> {
    return {
      snils: source.snils!,
      lastName: source.lastName!,
      firstName: source.firstName!,
      middleName: source.middleName,
      dulSeries: source.idNum ? source.idNum.split(' ')[0] : undefined,
      dulNumber: source.idNum ? source.idNum.split(' ')[1] : undefined!,
      participantId: source.participantId,
    };
  }

  protected static mapSigningDataInner(
    signingData: ClientSigningDataInput
  ): PartialToUndefined<ClientSigningData> {
    return {
      sendDate: signingData.sendDate,
      signingId: signingData.signingId,
      status: signingData.status,
      rejectReason: signingData.rejectReason,
      signingFileData: signingData.signingFileData as PartialToUndefined<
        typeof signingData.signingFileData
      >,
    };
  }

  protected static mapSigningDataOuter(
    signingData: CpSigningDataInput
  ): PartialToUndefined<CpSigningData> {
    return {
      sendDate: signingData.sendDate,
      signingId: signingData.signingId,
      status: signingData.status,
      inviteId: signingData.inviteId,
      participantId: signingData.participantId,
    };
  }

  protected static mapToServerSigningInnerOrg(
    data: CounterpartyClientOrg,
    clientIdOwner: EntityId,
    signingData: ClientSigningDataInput = data
  ): InnerCounterpartySigningData {
    if (data.__source && signingData === data) return data.__source;

    const innerCounterpartyData: PartialToUndefined<InnerCounterpartyData> = {
      ...this.mapServerInnerOrg(data),
      clientId: data.clientId,
    };

    return {
      innerCounterpartyData,
      typeSign: TypeSignEnum.Nes,
      clientIdOwner,
      ...this.mapSigningDataInner(signingData),
    };
  }

  protected static mapToServerSigningOuterOrg(
    data: CounterpartyOrg,
    clientIdOwner: EntityId,
    signingData: CpSigningDataInput = data
  ): OuterCounterpartySigningData {
    if (data.__source && signingData === data) return data.__source;

    const outerCounterpartyData: PartialToUndefined<OuterCounterpartyData> =
      this.mapServerOuterOrg(data);

    return {
      outerCounterpartyData,
      clientIdOwner,
      typeSign: TypeSignEnum.Nes,
      ...this.mapSigningDataOuter(signingData),
    };
  }

  protected static mapToServerSigningInnerPerson(
    data: CounterpartyClientPerson,
    clientIdOwner: EntityId,
    signingData: ClientSigningDataInput = data
  ): InnerPersonSigningData {
    if (data.__source && signingData === data) return data.__source;

    const innerPersonsData: PartialToUndefined<InnerPersonsData> = {
      ...this.mapServerInnerPerson(data),
      clientId: data.clientId,
    };

    return {
      typeSign: TypeSignEnum.Nes,
      clientIdOwner,
      innerPersonsData,
      ...this.mapSigningDataInner(signingData),
    };
  }

  protected static mapToServerSigningOuterPerson(
    data: CounterpartyPerson,
    clientIdOwner: EntityId,
    signingData: CpSigningDataInput = data
  ): OuterPersonSigningData {
    if (data.__source && signingData === data) return data.__source;

    const outerPersonsData: PartialToUndefined<OuterPersonsData> =
      this.mapServerOuterPerson(data);

    return {
      typeSign: TypeSignEnum.Nes,
      outerPersonsData,
      clientIdOwner,
      ...this.mapSigningDataOuter(signingData),
    };
  }
}
