import type {
  ClientSigningDataInput,
  CpSigningDataInput,
} from 'modules/Documents/classes';
import type { IFileInstance } from 'modules/UI/types';
import type {
  DocumentFullData,
  OuterPersonSigningData,
  InnerPersonSigningData,
  InnerCounterpartySigningData,
} from '@sp-api/documents-api';
import type { OuterCounterpartySigningData } from '@sp-api/documents-api/api';
import type {
  IPackageSigningsAdapter,
  PreparedSigningsFromFormData,
  SigningsAdaptedInput,
} from './types';
import type {
  CounterpartyClient,
  CounterpartyOrg,
  CounterpartyPerson,
  CounterpartyClientPerson,
  CounterpartyClientOrg,
} from 'modules/Documents/types';
import SigningsAdapterMappers from './SigningAdapterMappers';
import SigningAdapterOrgInner from './SigningAdapterOrgInner';
import SigningAdapterPersonInner from './SigningAdapterPersonInner';
import SigningAdapterOrgOuter from './SigningAdapterOrgOuter';
import SigningAdapterPersonOuter from './SigningAdapterPersonOuter';
import SignMixinEmpty from './SignMixinEmpty';

export default class PackageSigningsAdapter
  extends SigningsAdapterMappers
  implements IPackageSigningsAdapter
{
  static findClientInDoc(
    doc: DeepRO<DocumentFullData>,
    cp: CounterpartyClient
  ): InnerCounterpartySigningData | InnerPersonSigningData | undefined {
    if (!cp.clientId) return undefined;

    return [
      ...(doc.innerCounterpartySigningData || []),
      ...(doc.innerPersonsSigningData || []),
    ].find((x) => {
      const asserted = x as UnionToIntersection<typeof x>;

      return (
        (asserted.innerPersonsData || asserted.innerCounterpartyData)
          .clientId === cp.clientId
      );
    });
  }

  static findCounterpartyInDoc(
    doc: DeepRO<DocumentFullData>,
    cp: CounterpartyOrg | CounterpartyPerson
  ): OuterCounterpartySigningData | OuterPersonSigningData | undefined {
    if (!cp.email) return undefined;

    return [
      ...(doc.outerCounterpartySigningData || []),
      ...(doc.outerPersonsSigningData || []),
    ].find((x) => {
      const asserted = x as UnionToIntersection<typeof x>;

      return (
        (asserted.outerPersonsData || asserted.outerCounterpartyData).email ===
        cp.email
      );
    });
  }

  // maps inner data to server format
  static mapToServer(
    {
      clientPersons,
      clientOrgs,
      persons,
      counterparties,
    }: PreparedSigningsFromFormData,
    document: IFileInstance,
    clientIdOwner: EntityId
  ): SigningsAdaptedInput {
    const innerCounterpartySigningData: DeepMutable<
      SigningsAdaptedInput['innerCounterpartySigningData']
    > = [];
    const innerPersonsSigningData: DeepMutable<
      SigningsAdaptedInput['innerPersonsSigningData']
    > = [];
    const outerCounterpartySigningData: DeepMutable<
      SigningsAdaptedInput['outerCounterpartySigningData']
    > = [];
    const outerPersonsSigningData: DeepMutable<
      SigningsAdaptedInput['outerPersonsSigningData']
    > = [];

    const shouldRemoveSigningData = !document.hasDocument;
    const emptySigningData: PartialToUndefined<
      ClientSigningDataInput & CpSigningDataInput
    > = {
      ...new SignMixinEmpty(),
      inviteId: undefined,
      participantId: undefined,
    };

    clientOrgs.forEach((x) => {
      const signingData: ClientSigningDataInput =
        !shouldRemoveSigningData && document.serverData
          ? { ...this.findClientInDoc(document.serverData, x) }
          : emptySigningData;

      const mapped = PackageSigningsAdapter.mapToServerSigningInnerOrg(
        x as CounterpartyClientOrg,
        clientIdOwner,
        signingData
      );

      innerCounterpartySigningData.push(mapped);
    });

    clientPersons.forEach((x) => {
      const signingData: ClientSigningDataInput =
        !shouldRemoveSigningData && document.serverData
          ? { ...this.findClientInDoc(document.serverData, x) }
          : emptySigningData;

      const mapped = PackageSigningsAdapter.mapToServerSigningInnerPerson(
        x as CounterpartyClientPerson,
        clientIdOwner,
        signingData
      );

      innerPersonsSigningData.push(mapped);
    });

    counterparties.forEach((x) => {
      const foundCp =
        document.serverData &&
        this.findCounterpartyInDoc(document.serverData, x);

      const signingData: CpSigningDataInput =
        !shouldRemoveSigningData && foundCp
          ? foundCp
          : { ...emptySigningData, inviteId: x.inviteId };

      const mapped = PackageSigningsAdapter.mapToServerSigningOuterOrg(
        x,
        clientIdOwner,
        signingData
      );

      outerCounterpartySigningData.push(mapped);
    });

    persons.forEach((x) => {
      const foundCp =
        document.serverData &&
        this.findCounterpartyInDoc(document.serverData, x);

      const signingData: CpSigningDataInput =
        !shouldRemoveSigningData && foundCp
          ? foundCp
          : {
              ...emptySigningData,
              inviteId: x.inviteId,
              participantId: x.participantId,
            };

      const mapped = PackageSigningsAdapter.mapToServerSigningOuterPerson(
        x,
        clientIdOwner,
        signingData
      );

      outerPersonsSigningData.push(mapped);
    });

    return {
      clientIdOwner,
      innerCounterpartySigningData,
      innerPersonsSigningData,
      outerCounterpartySigningData,
      outerPersonsSigningData,
    };
  }

  private _owner!: CounterpartyClient | undefined;
  private _innerPersons!: CounterpartyClient[];
  private _innerOrgs!: CounterpartyClient[];
  private _orgs!: CounterpartyOrg[];
  private _persons!: CounterpartyPerson[];

  constructor(
    private _data: SigningsAdaptedInput = {} as SigningsAdaptedInput
  ) {
    super();
    this.init();
  }

  get clientPersons(): IPackageSigningsAdapter['clientPersons'] {
    return this._innerPersons;
  }

  get clientOrgs(): IPackageSigningsAdapter['clientOrgs'] {
    return this._innerOrgs;
  }

  get counterparties(): IPackageSigningsAdapter['counterparties'] {
    return this._orgs;
  }

  get persons(): IPackageSigningsAdapter['persons'] {
    return this._persons;
  }

  get owner(): CounterpartyClient | undefined {
    return this._owner;
  }

  private reset() {
    this._innerPersons = [];
    this._innerOrgs = [];
    this._persons = [];
    this._orgs = [];
    this._owner = undefined;
  }

  private extractOwnerFilter = (client: CounterpartyClient): boolean => {
    if (client.clientId !== this._data.clientIdOwner) return true;

    this._owner = this._owner || client;
    return false;
  };

  private init() {
    this.reset();

    (this._data.innerPersonsSigningData || []).forEach((x) => {
      this._innerPersons.push(new SigningAdapterPersonInner(x));
    });

    (this._data.innerCounterpartySigningData || []).forEach((x) => {
      this._innerOrgs.push(new SigningAdapterOrgInner(x));
    });

    (this._data.outerPersonsSigningData || []).forEach((x) => {
      this._persons.push(new SigningAdapterPersonOuter(x));
    });

    (this._data.outerCounterpartySigningData || []).forEach((x) => {
      this._orgs.push(new SigningAdapterOrgOuter(x));
    });

    this._owner = undefined;
    // extracting all signers with clientId same as owner - last extracted will go in this.owner
    this._innerOrgs = this._innerOrgs.filter(this.extractOwnerFilter);
    this._innerPersons = this._innerPersons.filter(this.extractOwnerFilter);
  }

  getSendDate(): string | undefined {
    const {
      innerPersonsSigningData = [],
      innerCounterpartySigningData = [],
      outerCounterpartySigningData = [],
      outerPersonsSigningData = [],
    } = this._data;

    return (
      innerPersonsSigningData[0]?.sendDate ||
      innerCounterpartySigningData[0]?.sendDate ||
      outerPersonsSigningData[0]?.sendDate ||
      outerCounterpartySigningData[0]?.sendDate
    );
  }
}
