import { makeEnumerablePrototype } from 'modules/Common/services';
import type { ClientSearch } from '@sp-api/counterparties-api';
import type { CounterpartyClient } from 'modules/Documents/types';
import SigningAdapterSearchPerson from './SigningAdapterSearchPerson';
import SigningAdapterSearchOrg from './SigningAdapterSearchOrg';

@makeEnumerablePrototype
export default class SigningAdapterSearchClients {
  private readonly _formValues: CounterpartyClient[];

  constructor(private _data: ClientSearch) {
    this._formValues = [];

    this._data.persons?.forEach((person) => {
      this._formValues.push(new SigningAdapterSearchPerson(person));
    });

    this._data.counterparties?.forEach((org) => {
      this._formValues.push(new SigningAdapterSearchOrg(org));
    });
  }

  get formValues(): CounterpartyClient[] {
    return this._formValues;
  }
}
