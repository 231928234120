import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import type { PropsWithChildren } from 'react';
import useManualHistory from './useManualHistory';
import AppRoutesHistoryContext from './AppRoutesHistoryContext';

export default function AppRoutesHistoryProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element | null {
  const history = useHistory();

  const [prevPathNames, handleAddPathname] = useManualHistory(
    history.location.pathname
  );

  useEffect(() => {
    return history.listen((newLocation) => {
      handleAddPathname(newLocation.pathname);
    });
  });

  return (
    <AppRoutesHistoryContext.Provider value={prevPathNames}>
      {children}
    </AppRoutesHistoryContext.Provider>
  );
}
