import { ButtonColorsEnum, ButtonSizesEnum } from '@signplatform-ui/button';
import { CardSizesEnum } from '@signplatform-ui/card';
import Spinner, { SpinnerDimensionsEnum } from '@signplatform-ui/spinner';
import NameValueInfo, {
  StyledValue,
} from 'modules/Documents/components/NameValueInfo';
import { formatLocalDate } from 'services/dateUtils';
import { formatFileSize } from 'services/fileUtils';
import { DATE_FORMAT } from 'modules/Documents/constants';
import type { MouseEvent, PropsWithChildren } from 'react';
import type { IFileInstance } from '../../types';
import { FileNameExtSizeInfo } from '../FileNameExtSizeInfo';
import {
  StyledButtonWithIcon,
  StyledColDocumentName,
  StyledColFlexEndCenter,
  StyledDeleteOutline,
  StyledExtSizeInfo,
  StyledFileIconByExt,
  StyledFlexStartCenter,
  StyledIconBox,
  StyledItemCard,
  StyledUploadedDate,
} from './DocsPackDocument.styled';

export const RemoveIcon = ({
  hasError,
  onClick,
  isDisabled,
}: {
  hasError?: boolean;
  onClick?: (e: MouseEvent) => void;
  isDisabled?: boolean;
}): JSX.Element => {
  return (
    <StyledButtonWithIcon
      color={ButtonColorsEnum.Ghost}
      displayAsSquare
      disabled={isDisabled}
      onClick={onClick}
      size={ButtonSizesEnum.Medium}
    >
      <StyledDeleteOutline $hasError={hasError} />
    </StyledButtonWithIcon>
  );
};

export const spinnerInIconBox = (
  <StyledIconBox>
    <Spinner dimension={SpinnerDimensionsEnum.M} style={{ zIndex: 'unset' }} />
  </StyledIconBox>
);

export type DocumentsPackEditorFileItemProps = PropsWithChildren<{
  data: IFileInstance;
  errorText?: string;
  hasError?: boolean;
  name?: string;
}>;

const DocsPackDocument = ({
  children,
  data,
  errorText,
  hasError,
  name,
}: DocumentsPackEditorFileItemProps): JSX.Element | null => {
  const hasNoFile = !data.hasClientFile && !data.hasServerFile;

  const hasUIInsideError = hasError && errorText;

  const hasUIUploadDate =
    !hasError && data.hasServerFile && data.serverCreatedDate;

  const hasUIWillUploadMessage =
    !hasError && data.hasClientFile && !data.hasServerFile;

  return (
    <StyledItemCard size={CardSizesEnum.Small} hasError={hasError}>
      <StyledFileIconByExt width={32} height={32} ext={data.fileExtWithDot} />

      <StyledColDocumentName>
        <StyledValue
          $disabled={hasError}
          as='div'
          data-testid='package-document-title'
        >
          {name || data.nameWithoutExtension}
        </StyledValue>

        <StyledFlexStartCenter>
          {!hasNoFile && (
            <StyledExtSizeInfo>
              <FileNameExtSizeInfo
                ext={data.fileExtWithDot}
                size={hasNoFile ? '' : formatFileSize(data.size)}
                isDisabled={hasError}
              />
            </StyledExtSizeInfo>
          )}

          <StyledUploadedDate>
            {hasUIInsideError && (
              <NameValueInfo isDisabled name={errorText} hasNoColon />
            )}

            {hasUIUploadDate && (
              <NameValueInfo
                name='Загружено'
                value={formatLocalDate(data.serverCreatedDate, DATE_FORMAT)}
              />
            )}

            {hasUIWillUploadMessage && (
              <NameValueInfo
                name='Файл будет загружен при сохранении'
                hasNoColon
              />
            )}
          </StyledUploadedDate>
        </StyledFlexStartCenter>
      </StyledColDocumentName>

      <StyledColFlexEndCenter>{children}</StyledColFlexEndCenter>
    </StyledItemCard>
  );
};
export default DocsPackDocument;
