import mediaQuery from 'modules/StyledUtility/utils/mediaQuery';

export type Breakpoints = 'lg' | 'md' | 'sm' | 'xl' | 'xs' | 'xxl';

export type GridConfig = {
  breakpoints: Record<Breakpoints, number>;
  container: {
    maxWidth: Record<Breakpoints, number>;
    padding?: number;
  };
  gridColumns: number;
  row: {
    padding: number;
  };
  col: {
    padding: number;
  };
};

const gridConfig: GridConfig = {
  breakpoints: {
    xs: 575.98, // mobile portrait: 320px—767px
    sm: 767.98, // mobile landscape: 320px—767px
    md: 1023.98, // tablet: 768px—1023px
    lg: 1198.98, // desktop S: 1024px—1199px
    xl: 1398.98, // desktop M: 1200px—1399px
    xxl: 1400, // desktopL: from 1400px
  },
  container: {
    maxWidth: {
      xs: 540,
      sm: 540,
      md: 720,
      lg: 960,
      xl: 1140,
      xxl: 1140,
    },
    padding: 16,
  },
  gridColumns: 12,
  row: {
    padding: 16,
  },
  col: {
    padding: 16,
  },
};

export const mediaQueriesStr = {
  mobileXS: `only screen and (max-width: ${gridConfig.breakpoints.xs}px)`,
  mobile: `only screen and (max-width: ${gridConfig.breakpoints.sm}px)`,
  tablet: `only screen and (max-width: ${gridConfig.breakpoints.md}px)`,
};

export const media = {
  mobile: mediaQuery`${mediaQueriesStr.mobile}`,
  tablet: mediaQuery`${mediaQueriesStr.tablet}`,
  mobileXS: mediaQuery`${mediaQueriesStr.mobileXS}`,
  lowHeight: mediaQuery`only screen and (max-height: 400px)`,
};

export default gridConfig;
