import styled from 'styled-components';
import LazyImg from '../../../Common/components/LazyImg/LazyImg';
import qrCodeImgNormal from './images/qr-code.png';
import qrCodeImg2x from './images/qr-code_2x.png';
import qrCodeImgMin from './images/qr-code_min.png';

const LazyImgStyled = styled(LazyImg)`
  flex-shrink: 0;

  img {
    border-radius: 7.93043px;
    max-width: 114px;
    max-height: 114px;
  }
`;

export default function QrCodeGoskey({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <LazyImgStyled
      alt='Наведите камеру телефона на QR-код или перейдите в приложение'
      className={className}
      src={qrCodeImgNormal}
      srcPlaceholder={qrCodeImgMin}
      srcX2={qrCodeImg2x}
    />
  );
}
