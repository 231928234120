import Button, {
  ButtonSizesEnum,
  ButtonColorsEnum,
} from '@signplatform-ui/button';
import Modal, {
  ModalButtonPanel,
  ModalContent,
  ModalTitle,
} from 'modules/UI/components/Modal';
import type { MouseEvent } from 'react';

type RemoveDocumentConfirmModalProps = {
  onClose: () => void;
  onConfirm: (e: MouseEvent) => void;
};

export default function RemoveDocumentConfirmModal({
  onClose,
  onConfirm,
}: RemoveDocumentConfirmModalProps): JSX.Element {
  return (
    <Modal onClose={onClose}>
      <ModalTitle>Удаление документа</ModalTitle>

      <ModalContent>
        Вы уверены, что хотите удалить документ? Восстановить документ после
        удаления будет невозможно
      </ModalContent>

      <ModalButtonPanel>
        <Button
          onClick={onConfirm}
          size={ButtonSizesEnum.Medium}
          color={ButtonColorsEnum.Primary}
        >
          Удалить
        </Button>

        <Button
          onClick={onClose}
          size={ButtonSizesEnum.Medium}
          color={ButtonColorsEnum.Secondary}
        >
          Отмена
        </Button>
      </ModalButtonPanel>
    </Modal>
  );
}
