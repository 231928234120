import { useCallback } from 'react';
import useDocsUIName from 'modules/Documents/components/PackageWizard/hooks/useDocsUIName';
import type { UseFormReturn } from 'react-hook-form';
import type { DocsFormValues } from 'modules/Documents/components/PackageWizard/ownTypes';
import type { UseDocsUINameReturn } from 'modules/Documents/components/PackageWizard/hooks/useDocsUIName';

type UseFormData = Pick<
  UseFormReturn<DocsFormValues>,
  'getValues' | 'setValue'
>;

type UseDocsUINameWithFormInput = {
  formData: UseFormData;
};

export default function useDocsUINameWithForm({
  formData: { getValues, setValue },
}: UseDocsUINameWithFormInput): UseDocsUINameReturn {
  const handleGetDocs = useCallback(() => {
    return getValues('docs');
  }, [getValues]);

  const handleDirtyDocs = useCallback(() => {
    setValue('docs', handleGetDocs(), { shouldDirty: true });
  }, [handleGetDocs, setValue]);

  return useDocsUIName({
    onGetDocs: handleGetDocs,
    onDirtyDocs: handleDirtyDocs,
  });
}
