import { createContext } from 'react';
import type { Context } from 'react';
import type { UserProfile } from '@sp-api/user-api';

export type UserContextProps = {
  readonly user: DeepRO<UserProfile>;
};

export type UserContextExtendedProps = {
  user: UserProfile | null;
  setUser: (user: UserProfile | null) => void;
};

const UserContext = createContext<UserContextProps>({} as UserContextProps);

export default UserContext;

export const UserContextExtended =
  UserContext as Context<unknown> as Context<UserContextExtendedProps>;
