import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import { TextTypesEnum } from '@signplatform-ui/text';
import { StyledText } from 'modules/Documents/components/SignButton/SignButton.styled';
import Modal, {
  ModalButtonPanel,
  ModalContent,
  ModalTitle,
} from 'modules/UI/components/Modal';
import CertificateNotFoundErrorModal from '../ResignButton/components/CertificateNotFoundErrorModal';
import GetUKEPErrorModal from '../ResignButton/components/GetUKEPErrorModal';
import {
  StyledDescription,
  StyledDescriptionContainer,
  StyledQR,
} from './PackageSigningErrorModal.styled';

export type PackageSigningErrorModalProps = {
  onClose?: () => Promise<void> | void;
};

const PackageSigningErrorModal = ({
  onClose,
}: PackageSigningErrorModalProps): JSX.Element => {
  return (
    <Modal onClose={onClose}>
      <ModalTitle>Пользователь не найден в Госключе</ModalTitle>

      <ModalContent>
        <StyledDescriptionContainer>
          <StyledQR />
          <StyledDescription>
            <StyledText type={TextTypesEnum.SBold}>
              Подписание документов происходит в мобильном приложении Госключ
            </StyledText>
            <>
              Чтобы установить приложение, наведите камеру на QR-код и скачайте
              мобильное приложение или перейдите на{' '}
            </>
            <a
              href='https://goskey.ru'
              rel='noopener noreferrer'
              target='_blank'
            >
              goskey.ru
            </a>
          </StyledDescription>
        </StyledDescriptionContainer>
      </ModalContent>

      <ModalButtonPanel>
        <Button
          size={ButtonSizesEnum.Medium}
          color={ButtonColorsEnum.Primary}
          onClick={onClose}
        >
          Закрыть
        </Button>
      </ModalButtonPanel>
    </Modal>
  );
};

interface PackageSigningErrorModalPropsDef
  extends PackageSigningErrorModalProps {
  displayCode?: string;
}

export default ({
  onClose,
  displayCode,
}: PackageSigningErrorModalPropsDef): JSX.Element => {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  switch (displayCode as any) {
    case 'IDPOINT_QES_NOT_FOUND':
      return <CertificateNotFoundErrorModal onClose={onClose} />;
    case 'IDPOINT_QES_IN_PROGRESS':
      return <GetUKEPErrorModal onClose={onClose} />;
    default:
      return <PackageSigningErrorModal onClose={onClose} />;
  }
};
