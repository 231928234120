import { StatusUnitEnum } from '@sp-api/documents-api';
import type { UserProfile } from '@sp-api/user-api';
import type { IPackageAdapter } from 'modules/Documents/classes';

type PackageNeededData = Pick<IPackageAdapter, 'clientIdOwner' | 'status'> & {
  buttons: Pick<IPackageAdapter['buttons'], 'downloadButton'>;
};

type HasDownloadDocumentButtonInput = {
  packageData: PackageNeededData;
  user: Pick<UserProfile, 'clientId'>;
};

export default function hasDownloadDocumentButton({
  packageData,
  user,
}: HasDownloadDocumentButtonInput): boolean {
  return (
    packageData.buttons.downloadButton &&
    (packageData.status !== StatusUnitEnum.RejectedByOwner ||
      packageData.clientIdOwner === user.clientId)
  );
}
