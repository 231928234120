import { createContext } from 'react';
import type { Context } from 'react';
import type { UseModalResult } from 'modules/Common/hooks/useModal';
import type { CounterpartyRequestField } from 'modules/Documents/types';

export type ModalsValue = {
  idNum?: string;
  snils?: string;
};

export type TModalContextMinimal = {
  activeTab: CounterpartyRequestField | undefined;
  clientIdOwner: EntityId;
  onCloseModal: () => void;
  onSetActiveTab: (tab: CounterpartyRequestField | undefined) => void;
  onSwitchModal: (value: ModalsValue) => void;
  value: ModalsValue;
};

export type TModalsContext = TModalContextMinimal & {
  inviteModal: UseModalResult;
  searchModal: UseModalResult;
};

export const DocumentCounterpartiesContext =
  createContext<TModalContextMinimal>({} as TModalsContext);

export const DocumentCounterpartiesContextExtended =
  DocumentCounterpartiesContext as Context<unknown> as Context<TModalsContext>;
