import { useEffect } from 'react';
import { getErrorDetails, isValidationError } from 'services/errorUtils';
import type { ErrorOption } from 'react-hook-form/dist/types/errors';
import type { ErrorResponse } from 'modules/App/types';

export type UseSetFieldErrorParams = {
  isError?: boolean;
  error: ErrorResponse | null;
};

export default function useSetFieldErrorByResponse(
  { isError, error }: UseSetFieldErrorParams,
  cb: (error: ErrorOption) => void
): void {
  // при ошибке на сервере, ставим ее в поле
  useEffect(() => {
    if (isError && error && isValidationError(error.data)) {
      cb({
        message: getErrorDetails(error.data),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
}
