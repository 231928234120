const byteUnit = 'Б';
const units = ['КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ', 'ЭБ', 'ЗБ', 'ИБ'];

type FormatFileSizeOptions = {
  // количество знаков. по-умолчанию 1
  dp?: number;
  // разделитель. по-умолчанию ','
  sep?: string;
};

export default function formatFileSize(
  bytes: number,
  options: FormatFileSizeOptions = {}
): string {
  const thresh = 1024;
  const calcOptions: Required<FormatFileSizeOptions> = {
    dp: 1,
    sep: ',',
    ...options,
  };

  if (Math.abs(bytes) < thresh) {
    return `${bytes} ${byteUnit}`;
  }

  let u = -1;
  let tBytes = bytes;
  const r = 10 ** calcOptions.dp;

  do {
    tBytes /= thresh;
    u += 1;
  } while (
    Math.round(Math.abs(tBytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  const sizeStr = tBytes.toFixed(calcOptions.dp).replace('.', calcOptions.sep);

  return `${sizeStr} ${units[u]}`;
}
