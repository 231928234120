import { useMutation, useQueryClient } from 'react-query';
import { useDocumentApiClient } from 'modules/Api';
import type { QueryKey, UseMutationResult } from 'react-query';
import type { ErrorResponse } from 'modules/App/types';
import type { PackageFullData } from '@sp-api/documents-api';

type PackageUpdateMetaParams = {
  packageId: EntityId;
  packageFullData: PackageFullData;
};

export default function useUpdatePackageMeta(
  invalidateQuery?: QueryKey
): UseMutationResult<PackageFullData, ErrorResponse, PackageUpdateMetaParams> {
  const documentApi = useDocumentApiClient();
  const queryClient = useQueryClient();

  return useMutation<PackageFullData, ErrorResponse, PackageUpdateMetaParams>(
    async ({ packageId, packageFullData }) => {
      const { data } = await documentApi.updatePackageMetadata(
        packageId,
        packageFullData
      );

      return data;
    },
    {
      onSuccess: () =>
        invalidateQuery
          ? queryClient.invalidateQueries(invalidateQuery)
          : undefined,
    }
  );
}
