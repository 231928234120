import { useModal } from 'modules/Common/hooks';
import { useMemo, useState } from 'react';
import type { PropsWithChildren } from 'react';
import type { PackageFullData } from '@sp-api/documents-api';
import type { CounterpartyRequestField } from 'modules/Documents/types';
import type {
  ModalsValue,
  TModalsContext,
} from './DocumentCounterpartiesContext';
import { DocumentCounterpartiesContext } from './DocumentCounterpartiesContext';

export type DocumentCounterpartiesContextProviderProps = PropsWithChildren<{
  documentPackage: PackageFullData;
}>;

const DocumentCounterpartiesContextProvider = ({
  children,
  documentPackage: { clientIdOwner },
}: DocumentCounterpartiesContextProviderProps): JSX.Element => {
  const inviteModal = useModal();
  const searchModal = useModal();

  const [crossModalState, setCrossModalState] = useState<ModalsValue>({});

  const [activeTab, setActiveTab] = useState<
    CounterpartyRequestField | undefined
  >();

  const contextValue = useMemo<TModalsContext>(() => {
    return {
      activeTab,
      clientIdOwner,
      inviteModal,
      onCloseModal: () => {
        // reset form values and activeTab
        setCrossModalState({});
        setActiveTab(undefined);
        // close all modals
        searchModal.onClose();
        inviteModal.onClose();
      },
      onSetActiveTab: (tab: CounterpartyRequestField | undefined) => {
        setActiveTab(tab);
      },
      onSwitchModal: (value: ModalsValue) => {
        searchModal.onToggle();
        inviteModal.onToggle();
        setCrossModalState(value);
      },
      searchModal,
      value: crossModalState,
    };
  }, [crossModalState, activeTab, clientIdOwner, searchModal, inviteModal]);

  return (
    <DocumentCounterpartiesContext.Provider value={contextValue}>
      {children}
    </DocumentCounterpartiesContext.Provider>
  );
};

export default DocumentCounterpartiesContextProvider;
