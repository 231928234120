import { ID_POINT_CHANNEL_ID } from 'modules/Documents/constants/signChannels';
import { StatusSigningEnum, StatusUnitEnum } from 'modules/Documents/types';
import { DateTime } from 'luxon';
import { TARGET_TIME_ZONE } from 'services/dateUtils';
import { DATE_FORMAT } from 'modules/Documents/constants';
import { TypeSignEnum } from '@sp-api/documents-api';
import type {
  IPackageCardContext,
  IPackageDocsContext,
} from 'modules/Documents/contexts';
import type { ClientOwnerData } from '@sp-api/documents-api';
import type {
  CounterpartiesFormValues,
  PackageFullData,
  SigningsAndCheckboxFormValues,
} from 'modules/Documents/types';
import type { IFileInstance } from 'modules/UI/types';
import type {
  IPackageAdapter,
  IPackageDocsAdapter,
  IPackageSigningsAdapter,
  PreparedSigningsFromFormData,
} from './types';
import PackageDocsAdapter from './PackageDocsAdapter';
import PackageSigningsAdapter from './PackageSigningsAdapter';
import SigningAdapterOwnerOrg from './SigningAdapterOwnerOrg';
import SigningAdapterOwnerPerson from './SigningAdapterOwnerPerson';

type PackageMapToServerInput = {
  formValues: CounterpartiesFormValues;
  packageCardContext: IPackageCardContext;
  packageDocsContext: IPackageDocsContext;
};

type PackageMapToServerOutput = [
  PackageFullData,
  { [clientServerId: string]: IFileInstance }
];

export type MapFormDataPackageData = {
  source: Pick<PackageFullData, 'clientOwnerData'>;
  signings: Pick<IPackageSigningsAdapter, 'owner'>;
};

export default class PackageAdapter implements IPackageAdapter {
  static mapFormSigningsForDocument = (
    formData: SigningsAndCheckboxFormValues,
    instance: MapFormDataPackageData
  ): PreparedSigningsFromFormData => {
    const { clientOwnerData } = instance.source;
    const ownerIsPerson = !!clientOwnerData.innerPersonsData;

    const ownerDataMapped = instance.signings.owner
      ? instance.signings.owner
      : ownerIsPerson
      ? new SigningAdapterOwnerPerson(clientOwnerData.innerPersonsData!)
      : new SigningAdapterOwnerOrg(clientOwnerData.innerCounterpartyData!);

    if (formData.iAmOnlySigner) {
      return {
        __isFinal: true,
        clientOrgs: !ownerIsPerson ? [ownerDataMapped] : [],
        clientPersons: ownerIsPerson ? [ownerDataMapped] : [],
        counterparties: [],
        persons: [],
      };
    }

    const newClientPersons =
      !ownerIsPerson || formData.iAmNotSigner
        ? formData.clientPersons
        : [ownerDataMapped, ...formData.clientPersons];

    const newClientOrgs =
      ownerIsPerson || formData.iAmNotSigner
        ? formData.clientOrgs
        : [ownerDataMapped, ...formData.clientOrgs];

    return {
      __isFinal: true,
      clientOrgs: newClientOrgs,
      clientPersons: newClientPersons,
      counterparties: formData.counterparties,
      persons: formData.persons,
    };
  };

  static mapToServer({
    formValues,
    packageCardContext: { packageAdapted: instance, packageName },
    packageDocsContext: { notLoadedDocIds, docsErrorMap, docsUINameById },
  }: PackageMapToServerInput): PackageMapToServerOutput {
    const doMapSigningsForDocument = (docData: IFileInstance) => {
      return PackageSigningsAdapter.mapToServer(
        PackageAdapter.mapFormSigningsForDocument(formValues, instance),
        docData,
        instance.clientIdOwner
      );
    };

    const serverDocs = PackageDocsAdapter.mapToServer({
      clientId: instance.clientIdOwner,
      doMapSigningsForDocument,
      docsUINameById,
      instance: { docs: formValues.docs },
      packageId: instance.packageId,
    });

    const result: { [fileUUID: string]: IFileInstance } = {};

    const documentFullData = [...serverDocs.keys()].filter((x) => {
      const fileInstance = serverDocs.get(x);
      if (
        !fileInstance ||
        docsErrorMap.get(fileInstance)?.rejectReason ||
        notLoadedDocIds.has(String(fileInstance.clientServerId))
      ) {
        return false;
      }

      result[fileInstance.clientServerId] = fileInstance;
      return true;
    });

    const [hour, minute, second] = formValues.signDueDate.time.split(':');

    documentFullData.map((doc) => {
      doc.innerPersonsSigningData?.map((innerPerson) => {
        innerPerson.typeSign = TypeSignEnum.Nes;
        return innerPerson;
      });
      doc.innerCounterpartySigningData?.map((innerCounterparty) => {
        innerCounterparty.typeSign = TypeSignEnum.Nes;
        return innerCounterparty;
      });
      return doc;
    });
    return [
      {
        ...instance.source,
        name: packageName,
        documentFullData,
        isSigner: !formValues.iAmNotSigner,
        isSingleSigner: formValues.iAmOnlySigner,
        signDueDate: DateTime.fromFormat(
          formValues.signDueDate.date,
          DATE_FORMAT,
          { zone: TARGET_TIME_ZONE }
        )
          .set({
            hour: Number(hour),
            minute: Number(minute),
            second: Number(second),
          })
          .toUTC()
          .toISO(),
      },
      result,
    ];
  }

  private readonly _signings: IPackageSigningsAdapter;
  private readonly _docsServerAdapted: IPackageDocsAdapter;

  constructor(private _data: PackageFullData) {
    this._signings = new PackageSigningsAdapter(this.docsServer?.[0]);
    this._docsServerAdapted = new PackageDocsAdapter(this.docsServer);
  }

  get signings(): IPackageAdapter['signings'] {
    return this._signings;
  }

  get docsServerAdapted(): IPackageAdapter['docsServerAdapted'] {
    return this._docsServerAdapted;
  }

  get clientIdOwner(): IPackageAdapter['clientIdOwner'] {
    return this._data.clientIdOwner;
  }

  get docsServer(): IPackageAdapter['docsServer'] {
    return this._data.documentFullData || [];
  }

  get createDate(): IPackageAdapter['createDate'] {
    return this._data.createDate
      ? this._data.createDate.valueOf().toString()
      : '';
  }

  get name(): IPackageAdapter['name'] {
    return this._data.name || '';
  }

  get packageId(): IPackageAdapter['packageId'] {
    return this._data.packageId;
  }

  get status(): IPackageAdapter['status'] {
    return this._data.status || StatusUnitEnum.Draft;
  }

  get channelId(): IPackageAdapter['channelId'] {
    return ID_POINT_CHANNEL_ID;
  }

  get source(): PackageFullData {
    return this._data;
  }

  get self(): typeof PackageAdapter {
    return PackageAdapter;
  }

  get clientOwnerData(): DeepRO<ClientOwnerData> {
    return this.source.clientOwnerData;
  }

  get sendTime(): string {
    const { publishDate } = this.source;
    return publishDate || '';
  }

  get buttons(): PackageFullData['buttons'] {
    const { buttons } = this.source;

    return {
      sendButton: buttons.sendButton,
      signButton: buttons.signButton,
      rejectButton: buttons.rejectButton,
      downloadButton: buttons.downloadButton,
      renameButton: buttons.renameButton,
      deleteButton: buttons.deleteButton,
      revokeButton: buttons.revokeButton,
      resignButton: buttons.resignButton,
    };
  }

  get cpRejectRevokeReason(): IPackageAdapter['cpRejectRevokeReason'] {
    if (this.status === StatusUnitEnum.RejectedByOwner) {
      return this.source.revokeReason;
    }

    return this.cpRejectRevokeSign?.rejectReason;
  }

  get cpRejectRevokeSign(): IPackageAdapter['cpRejectRevokeSign'] {
    const isRejected = this.status === StatusUnitEnum.Rejected;
    const isRejectedByOwner = this.status === StatusUnitEnum.RejectedByOwner;

    if (isRejectedByOwner) return this._signings.owner;
    if (!isRejected) return;

    return [
      this._signings.owner,
      ...this._signings.clientPersons,
      ...this._signings.clientOrgs,
    ].find((x) => {
      return x?.status === StatusSigningEnum.Rejected;
    });
  }
}
