import type {
  CounterpartiesDataItem,
  CounterpartiesSet,
  FoundCounterparty,
  FoundOrInvitedCounterparty,
  CounterpartiesData,
} from 'modules/Documents/components/PackageWizard/types';
import type { DistributeFoundCpsParams } from './distributeCounterpartiesByGroups';
import type { CounterpartyClientPerson } from '../types';
import getCounterpartyIdentifier from './getCounterpartyIdentifier';

type FindSameIdValue<T extends FoundOrInvitedCounterparty = FoundCounterparty> =
  {
    clientIdOwner?: EntityId | undefined;
    selectedCps: CounterpartiesData<T> | CounterpartiesSet<T>;
    currentCpsGroups?: DistributeFoundCpsParams['currentCpsGroups'];
  };

export default function findSameIdCounterparty<
  T extends FoundOrInvitedCounterparty = FoundCounterparty
>({
  currentCpsGroups: {
    clientOrgs = [],
    clientPersons = [],
    counterparties: orgs = [],
    persons = [],
  } = {},
  selectedCps,
  clientIdOwner,
}: FindSameIdValue<T>): CounterpartiesDataItem<T> | null {
  const existingIds: { [id: string]: boolean } = clientIdOwner
    ? { [clientIdOwner]: true }
    : {};

  const allSelected: CounterpartiesData<FoundOrInvitedCounterparty> =
    Array.from(selectedCps);

  const allSnilses = new Set<string>();
  const allDuls = new Set<string>();
  const allEmails = new Set<string>();

  [...clientPersons, ...clientOrgs].forEach((x) => {
    existingIds[getCounterpartyIdentifier(x)] = true;
  });

  orgs.forEach((x) => {
    existingIds[getCounterpartyIdentifier(x)] = true;
    if (x.email) allEmails.add(x.email);
  });

  persons.forEach((x) => {
    existingIds[getCounterpartyIdentifier(x)] = true;

    if (x.idNum) allDuls.add(x.idNum);
    if (x.email) allEmails.add(x.email);
    if (x.snils) allSnilses.add(x.snils);
  });

  const result = allSelected.find((selectedCp) => {
    // если есть clientId, просто проверяем по наличию идентификатора
    // так как нам достаточно уникальности идентификатора
    // и совпадение других полей нас не интересует
    if (selectedCp.clientId) {
      return existingIds[getCounterpartyIdentifier(selectedCp)];
    }

    const asserted = selectedCp as CounterpartyClientPerson;

    // если нет, нам нужно проверить по всем полям на уникальность
    // для каждого дока: либо его нет у проверяемого значения, либо его нет в текущих подписантах
    // если док есть у юзера и в подписантах, значит находим юзера как уже добавленного
    return (
      (asserted.email && allEmails.has(asserted.email)) ||
      (asserted.idNum && allDuls.has(asserted.idNum)) ||
      (asserted.snils && allSnilses.has(asserted.snils))
    );
  });

  return (result as CounterpartiesDataItem<T> | undefined) || null;
}
