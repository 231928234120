import styled, { css } from 'styled-components';
import flex from 'modules/StyledUtility/Flex';
import { media } from 'modules/StyledUtility';
import { AttachFileOutline } from '@signplatform-ui/icons';

export const StyledHighlightedText = styled.span`
  color: ${({ theme }) => theme.color.basic.primary};
`;

export const StyledAttachFileOutline = styled(AttachFileOutline)`
  margin-bottom: 16px;
`;

export const StyledDescription = styled.div`
  ${flex.flowCol};
  padding: 24px;
  align-items: center;
  text-align: center;

  ${media.mobileXS(css`
    flex-flow: row nowrap;
    padding: 8px;
    align-items: center;
    justify-content: center;

    ${StyledAttachFileOutline} {
      margin-bottom: 0;

      path {
        fill: ${({ theme }) => theme.color.basic.primary};
      }
    }
  `)}
`;
