import { useRef, useCallback, useState } from 'react';

export type UseSettledPromiseOutput = {
  readonly isSettled: boolean;
  promise: Promise<unknown>;
  handleSetPromise: (promise: Promise<unknown> | null) => void;
};

export default function useSettledPromise(): UseSettledPromiseOutput {
  const promiseObject = useRef<Promise<unknown>>(Promise.resolve());
  const [isSettled, setIsSettled] = useState(true);

  const handleSetPromiseObject = useCallback(
    (newPromise: Promise<unknown> | null) => {
      if (promiseObject.current === newPromise) return;

      if (!newPromise) {
        promiseObject.current = Promise.resolve();
        setIsSettled(true);
      } else {
        promiseObject.current = newPromise;
        setIsSettled(false);

        promiseObject.current.finally(() => {
          if (newPromise !== promiseObject.current) return;
          setIsSettled(true);
        });
      }
    },
    []
  );

  return {
    isSettled,
    promise: promiseObject.current,
    handleSetPromise: handleSetPromiseObject,
  };
}
