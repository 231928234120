import { memo } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';
import type { DropzoneProps } from 'react-dropzone';
import type { ReactNode } from 'react';

type StyledFileUploadBoxProps = {
  $isDragActive: boolean;
  $isDisabled?: boolean;
};

const cssDragActive = css`
  border-color: #4e5969;
  background-color: #e6eeff50;
`;

const StyledFileUploadBox = styled.div<StyledFileUploadBoxProps>`
  border-radius: 8px;
  border: 1px dashed #8a96a8;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'default' : 'pointer')};
  transition: border-color 0.2s linear, background-color 0.2s linear;

  ${({ $isDragActive }) => ($isDragActive ? cssDragActive : undefined)};
`;

export type FileUploadProps = Omit<DropzoneProps, 'children'> & {
  // Несмотря на то, что у нас есть render-prop children, будет удобно иметь также обычный слот
  slotDescription?: ReactNode;
  children?: ReactNode;
};

/**
 * Контрол для загрузки файла(ов)
 * onDrop универсальный обработчик для дропа или загрузки по клику
 */
const FileUpload = (props: FileUploadProps): JSX.Element => {
  const { children, slotDescription, ...rest } = props;
  const { getRootProps, getInputProps, isDragActive } = useDropzone(rest);

  const rootProps = getRootProps();

  return (
    <StyledFileUploadBox
      {...rootProps}
      $isDisabled={rest.disabled}
      $isDragActive={isDragActive}
    >
      <input {...getInputProps()} />

      {slotDescription}

      {children}
    </StyledFileUploadBox>
  );
};
export default memo(FileUpload);
