import { isEmpty } from 'lodash';
import { getCounterpartyData } from 'modules/Documents/components/PackageWizard/utils';
import { memo, useCallback, useMemo } from 'react';
import type {
  SearchFormProps,
  CounterpartiesSet,
  FoundCounterparty,
} from 'modules/Documents/components/PackageWizard/types';
import Counterparty from './components/Counterparty';
import EmptyResult from './components/EmptyResult';
import {
  StyledCounterpartiesResult,
  StyledFoundCounterparty,
  StyledResultTitle,
} from './CounterpartiesResult.styled';

type CounterpartiesResultProps = {
  data: CounterpartiesSet;
  onInviteUser: () => void;
  onSelect: SearchFormProps['onSelect'];
  selected: SearchFormProps['selected'];
};

const CounterpartiesResult = ({
  data,
  onInviteUser,
  selected,
  onSelect,
}: CounterpartiesResultProps): JSX.Element => {
  const onSelectValues = useCallback(
    (selectedData: DeepRO<FoundCounterparty>) => {
      onSelect(new Set([selectedData]));
    },
    [onSelect]
  );

  const adaptedData = useMemo(() => {
    return [...data.values()].slice(0, 10).map(getCounterpartyData);
  }, [data]);

  return (
    <StyledCounterpartiesResult data-testid='add-counterparties-result'>
      <StyledResultTitle>Результаты поиска</StyledResultTitle>

      {isEmpty(adaptedData) && <EmptyResult onInviteUser={onInviteUser} />}

      {adaptedData.length === 1 && (
        <StyledFoundCounterparty data-testid='counterparty-item'>
          {adaptedData[0].caption}
        </StyledFoundCounterparty>
      )}

      {adaptedData.length > 1 &&
        adaptedData.map(({ caption, perSiblingId, source }) => {
          return (
            <Counterparty
              checked={selected.has(source)}
              data={source}
              key={perSiblingId}
              name={caption}
              onChange={onSelectValues}
            />
          );
        })}
    </StyledCounterpartiesResult>
  );
};

export default memo(CounterpartiesResult);
