import { useUserApiClient } from 'modules/Api';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { documentUserReady } from 'services/documentStatus/documentUserReady';
import type { TaskStatusStatusEnum } from '@sp-api/user-api';
import type { UseQueryResult } from 'react-query';
import type { ErrorResponse } from 'modules/App/types';
import DocumentsStateKeysEnum from '../constants/DocumentsStateKeysEnum';

export function useUserDocumentStatusDetails(): UseQueryResult<
  TaskStatusStatusEnum,
  ErrorResponse
> {
  const documentApi = useUserApiClient();

  return useQuery<TaskStatusStatusEnum, ErrorResponse>(
    DocumentsStateKeysEnum.documentLinkingStatus,
    async () => {
      const { data } = await documentApi.getDocumentLinkingStatus();
      return data.status;
    }
  );
}

export function useUserDocumentStatusDetailsByInterval(
  intervalSec: number
): TaskStatusStatusEnum | undefined {
  const [status, setStatus] = useState<TaskStatusStatusEnum>();
  const documentApi = useUserApiClient();

  useEffect(() => {
    let timerId: number;

    const timeout = () => {
      if (timerId) {
        clearTimeout(timerId);
      }

      timerId = window.setTimeout(async () => {
        const { data } = await documentApi.getDocumentLinkingStatus();
        const newStatus = data?.status;
        setStatus(newStatus);

        if (!documentUserReady(newStatus)) {
          timeout();
        }
      }, intervalSec * 1000);
    };

    const checkStatus = async () => {
      const { data } = await documentApi.getDocumentLinkingStatus();
      const newStatus = data?.status;
      setStatus(newStatus);

      if (!documentUserReady(newStatus)) {
        timeout();
      }
    };

    void checkStatus();

    return () => {
      clearTimeout(timerId);
    };
  }, [documentApi, intervalSec]);

  return status;
}
