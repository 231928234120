enum DocumentsStateKeysEnum {
  List = 'remoteUnits:list',
  Card = 'remoteUnits:details',
  Status = 'package:filesStatus',
  Counterparties = 'counterparties',
  DownloadPackage = 'package:download',
  documentLinkingStatus = 'documentLinkingStatus',
}

export default DocumentsStateKeysEnum;
