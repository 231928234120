import { useRef, memo, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePrevious } from 'modules/Common/hooks';
import DocumentsRoutesEnum from 'modules/Router/routes/paths';

const routesToRememberScroll: { [route: string]: boolean } = {
  [DocumentsRoutesEnum.DocumentsList]: true,
};

type ScrollCache = {
  [route: string]: number | undefined;
};

export default memo(function ScrollRestoreMemo(): JSX.Element | null {
  const { pathname } = useLocation();

  // todo use AppRoutesHistory
  const prevPathname = usePrevious(pathname);
  const scrollCache = useRef<ScrollCache>({});

  useLayoutEffect(() => {
    if (prevPathname === pathname) return;

    if (prevPathname && routesToRememberScroll[prevPathname]) {
      scrollCache.current[prevPathname] = window.scrollY;
    }

    const newScrollFromCache = scrollCache.current[pathname];

    window.scrollTo(0, newScrollFromCache || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
});
