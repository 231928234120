import { StatusSigningEnum, TypeSignEnum } from '@sp-api/documents-api';
import Text from '@signplatform-ui/text';
import NameValueInfo from 'modules/Documents/components/NameValueInfo';
import { getCounterpartyData } from 'modules/Documents/components/PackageWizard/utils';
import { DATE_TIME_FORMAT } from 'modules/Documents/constants';
import { formatLocalDate } from 'services/dateUtils';
import type { SigningsUni } from 'modules/Documents/components/PackageWizard/types';
import {
  DocumentSignerWrapper,
  SignerNameWrapper,
  StyledDocumentSignStatus,
} from './DocumentSigner.styled';

export type DocumentSignerProps = {
  sign: SigningsUni;
};
export const typeSignMap = {
  [TypeSignEnum.Qes]: 'УКЭП',
  [TypeSignEnum.Nes]: 'УНЭП',
};

const DocumentSigner = ({ sign }: DocumentSignerProps): JSX.Element => {
  const { status, signingFileData: { signingUpdateDate } = {} } = sign;
  const adaptedData = getCounterpartyData(sign);

  return (
    <DocumentSignerWrapper>
      <SignerNameWrapper>
        {status && <StyledDocumentSignStatus status={status} />}
        <div>
          {adaptedData.caption}
          <Text color='grey'>
            {sign?.__source?.typeSign && typeSignMap[sign?.__source?.typeSign]}
          </Text>
        </div>
      </SignerNameWrapper>
      {status === StatusSigningEnum.Signed && (
        <div>
          <NameValueInfo
            name='Подписан'
            value={formatLocalDate(
              signingUpdateDate,
              DATE_TIME_FORMAT,
              '(Мск)'
            )}
          />
        </div>
      )}
    </DocumentSignerWrapper>
  );
};

export default DocumentSigner;
