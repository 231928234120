import type {
  SigningsFormValues,
  CounterpartiesData,
  CounterpartiesSet,
} from 'modules/Documents/components/PackageWizard/types';
import isCounterpartyClient from './isCounterpartyClient';
import isCounterpartyOrg from './isCounterpartyOrg';
import isCounterpartyPerson from './isCounterpartyPerson';

export type DistributeFoundCpsParams = {
  currentCpsGroups?: Partial<SigningsFormValues>;
  selectedCps: CounterpartiesData | CounterpartiesSet;
};

export default function distributeCounterpartiesByGroups({
  currentCpsGroups: {
    clientOrgs = [],
    clientPersons = [],
    counterparties: orgs = [],
    persons = [],
  } = {},
  selectedCps,
}: DistributeFoundCpsParams): SigningsFormValues {
  const allSelected: CounterpartiesData = Array.from(selectedCps);
  const orgsNew: SigningsFormValues['counterparties'] = [];
  const clientsPersonsNew: SigningsFormValues['clientPersons'] = [];
  const clientOrgsNew: SigningsFormValues['clientOrgs'] = [];
  const personsNew: SigningsFormValues['persons'] = [];

  allSelected.forEach((x) => {
    const isClient = isCounterpartyClient(x);
    const isOrg = isCounterpartyOrg(x);
    const isPerson = isCounterpartyPerson(x);

    if (isClient) {
      if (isOrg) clientOrgsNew.push(x);
      else if (isPerson) clientsPersonsNew.push(x);
    } else if (isOrg) {
      orgsNew.push(x);
    } else if (isPerson) {
      personsNew.push(x);
    }
  });

  return {
    clientPersons: !clientsPersonsNew.length
      ? clientsPersonsNew
      : [...clientPersons, ...clientsPersonsNew],
    clientOrgs: !clientOrgsNew.length
      ? clientOrgs
      : [...clientOrgs, ...clientOrgsNew],
    persons: !personsNew.length ? persons : [...persons, ...personsNew],
    counterparties: !orgsNew.length ? orgs : [...orgs, ...orgsNew],
  };
}
