import { memo, useCallback } from 'react';
import {
  PublicLayoutWithLogo,
  StyledHeader,
  StyledTextInfo,
} from 'modules/Layouts/PublicLayout';
import { HeaderSizesEnum } from '@signplatform-ui/header';
import { ButtonLogin } from 'modules/Auth/components/Login/Login.styled';
import { ButtonColorsEnum, ButtonSizesEnum } from '@signplatform-ui/button';
import { useHistory } from 'react-router-dom';
import AppRoutesPaths from 'modules/Router/routes/paths';
import Footer from 'modules/UI/components/Footer/Footer';
import { FooterColorEnum } from 'modules/UI/components/Footer';
import type { MouseEventHandler } from 'react';

function Page404Content(): JSX.Element {
  const history = useHistory();

  const handleClickButton: MouseEventHandler = useCallback(() => {
    history.push(AppRoutesPaths.Home);
  }, [history]);

  return (
    <div>
      <StyledHeader type={HeaderSizesEnum.H1}>
        Страница
        <br />
        не найдена
      </StyledHeader>

      <ButtonLogin
        color={ButtonColorsEnum.White}
        onClick={handleClickButton}
        size={ButtonSizesEnum.Large}
      >
        {'На главную'}
      </ButtonLogin>

      <StyledTextInfo>
        Проверьте введённый адрес и попробуйте снова.
        <br /> Или найдите необходимую страницу, перейдя на главную
      </StyledTextInfo>
    </div>
  );
}

function Page404WithLayout(): JSX.Element {
  const content = <Page404Content />;
  const footer = <Footer color={FooterColorEnum.secondary} />;

  return <PublicLayoutWithLogo footer={footer} slotContent={content} />;
}

export default memo(Page404WithLayout);
