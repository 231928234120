import { useMedia } from 'modules/Shared/hooks';
import Text, { TextTypesEnum } from '@signplatform-ui/text';
import {
  StyledDescription,
  StyledAttachFileOutline,
  StyledHighlightedText,
} from './UploadDescription.styled';

const UploadDescription = (): JSX.Element => {
  const { isMobileXS } = useMedia();

  return (
    <StyledDescription>
      <StyledAttachFileOutline width={40} />

      {isMobileXS ? (
        'Добавьте файлы'
      ) : (
        <Text type={TextTypesEnum.S}>
          Перетащите в эту область или{' '}
          <StyledHighlightedText>загрузите</StyledHighlightedText> документ
          <br />
          до 20 МБ (PDF, TIFF, XML, TXT), общий объем документов не должен
          превышать 80 МБ
        </Text>
      )}
    </StyledDescription>
  );
};

export default UploadDescription;
