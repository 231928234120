import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import Text from '@signplatform-ui/text';
import { MAX_LENGTH, MIN_LENGTH } from 'modules/Documents/constants/document';
import { useRenamePackage } from 'modules/Documents/hooks';
import Modal, {
  ModalButtonPanel,
  ModalTitle,
} from 'modules/UI/components/Modal';
import { useCallback, useState } from 'react';
import type { ChangeEvent } from 'react';
import type { QueryKey } from 'react-query';
import { StyledInput } from './RenamePackageModal.styled';

type RenamePackageModalProps = {
  packageId: EntityId;
  ownerClientId: EntityId;
  onClose: () => void;
  title: string;
  invalidateQuery?: QueryKey;
};

const RenamePackageModal = ({
  onClose,
  title,
  invalidateQuery,
  packageId,
  ownerClientId,
}: RenamePackageModalProps): JSX.Element => {
  const [name, setName] = useState(title);
  const { isLoading, mutateAsync } = useRenamePackage(
    { packageId, ownerClientId },
    invalidateQuery
  );

  const changeDocumentTitle = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setName(value);
    },
    []
  );

  const cancelRenamePackage = useCallback(() => {
    setName(title);
    onClose();
  }, [title, onClose]);

  const onRename = useCallback(async () => {
    try {
      await mutateAsync({ name });
    } finally {
      onClose();
    }
  }, [mutateAsync, name, onClose]);

  const titleIsValid = name.length >= MIN_LENGTH && name.length <= MAX_LENGTH;

  return (
    <Modal onClose={cancelRenamePackage}>
      <div>
        <ModalTitle>Переименование комплекта документов</ModalTitle>
        <Text>
          Укажите новое название, которое хотите присвоить комплекту документов
        </Text>
        <StyledInput
          value={name}
          onChange={changeDocumentTitle}
          label='Название'
          status={!titleIsValid ? 'error' : undefined}
          extraText={
            !titleIsValid
              ? `Длина заголовка пакета должна быть от ${MIN_LENGTH} до ${MAX_LENGTH} символов`
              : undefined
          }
        />
      </div>
      <ModalButtonPanel>
        <Button
          onClick={onRename}
          disabled={isLoading || !titleIsValid}
          size={ButtonSizesEnum.Medium}
        >
          Переименовать
        </Button>
        <Button
          onClick={cancelRenamePackage}
          color={ButtonColorsEnum.Secondary}
          size={ButtonSizesEnum.Medium}
        >
          Отменить
        </Button>
      </ModalButtonPanel>
    </Modal>
  );
};

export default RenamePackageModal;
