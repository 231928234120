import {
  Configuration,
  SpLogicLoadDocumentApi,
} from '@sp-api/load-document-api';
import { useConfiguration } from 'modules/Shared/hooks';
import { apiService } from 'modules/Api/components/ApiProvider/services';

const useApi = apiService.createUseApi(SpLogicLoadDocumentApi, Configuration);

const useLoadDocumentApi = (): SpLogicLoadDocumentApi => {
  const [{ loadDocumentsContextUrl }] = useConfiguration();
  return useApi(loadDocumentsContextUrl);
};

export default useLoadDocumentApi;
