import notificationsService from 'modules/Notifications/services/NotificationsService';
import { NotificationsTypesEnum } from '@signplatform-ui/notification';

export default function notifySigningSuccess(isConcierge?: boolean): void {
  notificationsService.notify(
    'Комплект документов отправлен на подписание',
    !isConcierge
      ? 'Войдите в мобильное приложение Госключ и завершите подписание'
      : 'Отследить процесс подписания можно на форме просмотра комплекта документов',
    NotificationsTypesEnum.Info
  );
}
