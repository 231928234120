import AppRoutesPaths from 'modules/Router/routes/paths';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoMobileSVG } from './images/logo-mobile.svg';
import { ReactComponent as LogoWhiteSVG } from './images/logo-white.svg';
import { ReactComponent as LogoSVG } from './images/logo.svg';

export enum LogoType {
  Normal = 'normal',
  White = 'white',
}

type LogoProps = {
  type?: LogoType;
  className?: string;
  small?: boolean;
};

const Logo = ({
  className = '',
  type = LogoType.Normal,
  small,
}: LogoProps): JSX.Element => {
  return (
    <Link to={AppRoutesPaths.Home} className={className}>
      {small ? (
        <LogoMobileSVG />
      ) : type === LogoType.Normal ? (
        <LogoSVG />
      ) : (
        <LogoWhiteSVG />
      )}
    </Link>
  );
};

export default memo(Logo);
