import Button from '@signplatform-ui/button';
import { media } from 'modules/StyledUtility';
import styled, { css } from 'styled-components';
import { Col } from 'styled-bootstrap-grid';

export const ColCentered = styled(Col)`
  display: flex;
  align-items: center;

  ${media.tablet(css`
    margin-top: 50px;
    margin-bottom: 66px;
  `)}
`;

export const ButtonLogin = styled(Button)`
  width: auto;

  ${media.mobileXS(css`
    width: 100%;
  `)}
`;

export const LinkStyled = styled.a`
  margin-top: 32px;
  color: ${({ theme }) => theme.color.basic.primary};
`;
