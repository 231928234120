import AddCounterpartyModal from 'modules/Documents/components/PackageWizard/components/AddCounterpartyModal';
import InviteModal from 'modules/Documents/components/PackageWizard/components/InviteModal';
import { useFormContext } from 'react-hook-form';
import { SigningAdapterInvitePerson } from 'modules/Documents/classes';
import { useCounterpartiesContext } from 'modules/Documents/components/PackageWizard/hooks';
import type { SetValueConfig } from 'react-hook-form';
import type { InviteCounterpartyProps } from '../types';
import type {
  CounterpartiesFormValues,
  SigningsFormValues,
} from 'modules/Documents/components/PackageWizard/types';

type InviteSearchModalsProps = {
  onSetSigners: (signers: SigningsFormValues) => void;
};

const dirtyField: SetValueConfig | undefined = {
  shouldDirty: true,
};

const CounterpartiesModals = ({
  onSetSigners,
}: InviteSearchModalsProps): JSX.Element => {
  const { inviteModal, searchModal } = useCounterpartiesContext();

  const { setValue, watch } = useFormContext<CounterpartiesFormValues>();
  const formData = watch();

  const handleSetFoundSigners = (signers: SigningsFormValues): void => {
    setValue('iAmOnlySigner', false, dirtyField);
    onSetSigners(signers);
    searchModal.onClose();
  };

  const handleSetInvitedSigner: InviteCounterpartyProps['onSetInvitedSigner'] =
    (params, clearAllOtherSigners): void => {
      const invitedData = new SigningAdapterInvitePerson(params);
      setValue('iAmOnlySigner', false, dirtyField);

      if (!clearAllOtherSigners) {
        setValue('persons', [...formData.persons, invitedData], dirtyField);
      } else {
        setValue('persons', [invitedData], dirtyField);
        setValue('counterparties', [], dirtyField);
      }

      inviteModal.onClose();
    };

  return (
    <>
      {inviteModal.isOpen && (
        <InviteModal onSetInvitedSigner={handleSetInvitedSigner} />
      )}

      {searchModal.isOpen && (
        <AddCounterpartyModal onSetFoundSigners={handleSetFoundSigners} />
      )}
    </>
  );
};

export default CounterpartiesModals;
