import { useMutation } from 'react-query';
import type { UseMutationResult } from 'react-query';
import type { AuthorizationResult } from '@signplatform/registration-api';
import type { ErrorResponse } from 'modules/App/types';
import useAuthApi from '../useAuthApi';

type RefreshTokenParams = {
  clientId: string;
  refreshToken: string;
};

const useRefreshToken = (): UseMutationResult<
  AuthorizationResult,
  ErrorResponse,
  RefreshTokenParams
> => {
  const authApi = useAuthApi();

  return useMutation(async ({ clientId, refreshToken }) => {
    const { data } = await authApi.refreshAuthenticationRequest(
      clientId,
      refreshToken
    );

    return data;
  });
};

export default useRefreshToken;
