import { useLayoutEffect, useRef } from 'react';
import { useMedia } from 'modules/Shared/hooks';
import type { RefObject } from 'react';

type UseWatchStickyHeaderReturn = {
  stickyRef: RefObject<HTMLDivElement>;
  buttonsRef: RefObject<HTMLDivElement>;
};

export default function useWatchStickyHeader(): UseWatchStickyHeaderReturn {
  const stickyRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<HTMLDivElement>(null);

  useMedia();

  useLayoutEffect(() => {
    if (stickyRef.current && buttonsRef.current) {
      stickyRef.current.style.paddingRight = `${buttonsRef.current?.offsetWidth}px`;
    }
  });

  return {
    stickyRef,
    buttonsRef,
  };
}
