export const DEFAULT_FIELD_ERROR = 'Значение не соответствует формату';
export const IS_REQUIRED_ERROR = 'Поле должно быть заполнено';

export const DOC_SERIES_ERROR = 'Некорректный формат серии';
export const TAX_CODE_ERROR = 'Некорректный формат';
export const DOC_NUMBER_ERROR = 'Некорректный формат номера';
export const ISSUER_CODE_FIELD_ERROR = DEFAULT_FIELD_ERROR;
export const DATE_FIELD_ERROR = 'Некорректный формат даты';
export const SNILS_FIELD_ERROR = 'Некорректный формат СНИЛС';
export const SNILS_FIELD_REQUIRED_ERROR = 'Заполните поле ИНН на Госуслугах';
export const INN_PERSON_FIELD_ERROR = 'Некорректный формат ИНН';
export const FIO_FIELD_ERROR =
  'Нельзя использовать кириллицу и латиницу в одном слове';
export const EMAIL_FIELD_ERROR = 'Некорректный формат адреса электронной почты';
