import { Controller, useFormContext } from 'react-hook-form';
import type { ControllerProps } from 'react-hook-form/dist/types/controller';
import type { IsOnlySignerFormValues } from 'modules/Documents/components/PackageWizard/ownTypes';
import type { ArgumentsOf } from 'ts-jest';
import { StyledFadedToggle } from './IAmOnlySignerToggle.styled';

type FormValues = IsOnlySignerFormValues;
type RenderFirstArg = ArgumentsOf<ControllerProps<FormValues>['render']>[0];

export default function IAmOnlySignerToggle(): JSX.Element {
  const { control, setValue } = useFormContext<FormValues>();

  const handleRenderInner = ({ field }: RenderFirstArg) => (
    <StyledFadedToggle
      checked={field.value || false}
      data-testid='i-am-only-signer-input'
      onChange={(e) => {
        field.onChange(e);
        if (e.target.checked) setValue('iAmNotSigner', false);
      }}
      value={undefined}
      labelPosition='left'
    >
      Я единственный подписант
    </StyledFadedToggle>
  );

  return (
    <Controller
      name='iAmOnlySigner'
      control={control}
      render={handleRenderInner}
    />
  );
}
