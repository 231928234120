import Text from '@signplatform-ui/text';
import { typography } from '@signplatform-ui/typographics';
import { media } from 'modules/StyledUtility';
import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const WrapSigners = styled.div`
  width: 310px;
  flex-shrink: 0;

  ${media.mobile(css`
    width: 100%;
  `)}
`;

export const ListItem = styled(Text)`
  display: flex;
  align-items: center;
`;

export const ListItemSuffix = styled.span`
  ${typography['Caption/XS']}
  color: ${({ theme }) => theme.color.text.secondary};
  margin-left: 8px;
`;
