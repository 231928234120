import axios from 'axios';
import { omit } from 'lodash';
import { useRef } from 'react';
import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import type {
  CommonInterceptor,
  CommonInterceptorManager,
  ConfigurationConstructor,
  InterceptorHandleTypes,
  InterceptorTypes,
} from './types';

type ApiConstructor<T, C> = new (
  Configuration: C,
  basePath: string,
  axios: AxiosInstance
) => T;

export class ApiService {
  createUseApi<T, C>(
    ApiClient: ApiConstructor<T, C>,
    ApiConfiguration: ConfigurationConstructor<C>
  ): (basePath: string) => T {
    const { axiosInstance } = this;
    return function useApiHook(basePath: string): T {
      const clientRef = useRef(
        new ApiClient(new ApiConfiguration({}), basePath, axiosInstance)
      );

      return clientRef.current;
    };
  }

  constructor(private _axiosInstance: AxiosInstance) {}

  get axiosInstance(): AxiosInstance {
    return this._axiosInstance;
  }

  addInterceptor<
    T extends keyof InterceptorTypes,
    X extends Record<string, unknown>
  >(
    type: T,
    onFulfilled?: InterceptorHandleTypes<X>[T],
    onRejected?: (error: AxiosError) => Promise<AxiosError | AxiosResponse>
  ): number {
    return (
      this.axiosInstance.interceptors[type] as CommonInterceptorManager
    ).use(onFulfilled as CommonInterceptor, onRejected);
  }

  ejectInterceptor(type: keyof InterceptorTypes, id: number): void {
    this.axiosInstance.interceptors[type].eject(id);
  }

  setAuthorizationHeader(accessToken?: string): void {
    this.axiosInstance.defaults.headers.common = accessToken
      ? {
          ...this.axiosInstance.defaults.headers.common,
          Authorization: `Bearer ${accessToken}`,
        }
      : omit(this.axiosInstance.defaults.headers.common, 'Authorization');
  }
}

export default new ApiService(axios.create());
