import {
  Configuration,
  LoadPowattorneyServiceApi,
} from '@pa-api/pa-logic-load-document';
import { apiService } from 'modules/Api/components/ApiProvider/services';
import { useConfiguration } from 'modules/Shared/hooks';

const useApi = apiService.createUseApi(
  LoadPowattorneyServiceApi,
  Configuration
);

const usePowAttornyLoadDocumentApi = (): LoadPowattorneyServiceApi => {
  const [{ attorneyLoadDocumentContextUrl }] = useConfiguration();
  return useApi(attorneyLoadDocumentContextUrl);
};

export default usePowAttornyLoadDocumentApi;
