import Button from '@signplatform-ui/button';
import Header from '@signplatform-ui/header';
import styled from 'styled-components';

export const StyledHeader = styled(Header)`
  margin-bottom: 16px;
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.color.text.secondary};
  margin-bottom: 16px;
`;

export const StyledAddButton = styled(Button)`
  min-width: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
