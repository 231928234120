import styled from 'styled-components';
import Button from '@signplatform-ui/button';

// eslint-disable-next-line import/prefer-default-export
export const ButtonStyled = styled(Button)`
  color: ${({ theme }) => theme.color.text.secondary};

  svg {
    fill: currentColor;
  }

  svg path {
    fill: currentColor;
  }
`;
