import { useState } from 'react';
import useUpdatePackage from 'modules/Documents/hooks/useUpdatePackage';
import usePublishPackage from 'modules/Documents/hooks/usePublishPackage';
import { PackageAdapter } from 'modules/Documents/classes';
import { getPackageCardQueryKey } from 'modules/Documents/hooks/usePackageCard';
import { useQueryClient } from 'react-query';
import type {
  IPackageCardContext,
  IPackageDocsContext,
} from 'modules/Documents/contexts';
import type { CounterpartiesFormValues } from 'modules/Documents/components/PackageWizard/types';

export type UseSaveAndPublishProps = {
  packageId: number;
  isSigner?: boolean;
};

type UseSaveAndPublishDefaultProps = UseSaveAndPublishProps & {
  onGetValues: () => CounterpartiesFormValues;
  onBeforeSave?: () => void;
  onSubmit?: (cb: () => void) => void;
  packageCardContext: IPackageCardContext;
  packageDocsContext: IPackageDocsContext;
};

export type UseSaveAndPublishReturnValue = {
  onSave: () => Promise<void>;
  onPublish: () => Promise<void>;
  isPublishing: boolean;
  isUpdatingPackage: boolean;
  isPublished: boolean;
};

export default function useSaveAndPublishPackagePure({
  packageId,
  packageCardContext,
  packageDocsContext,
  onBeforeSave,
  onGetValues,
  onSubmit,
  isSigner,
}: UseSaveAndPublishDefaultProps): UseSaveAndPublishReturnValue {
  const [isPublished, setPublished] = useState(false);

  const { mutateAsync: updatePackageMutation, isLoading: isUpdatingPackage } =
    useUpdatePackage();

  const doSavePackage = async (formValues: CounterpartiesFormValues) => {
    const [packageFullData, filesDict] = PackageAdapter.mapToServer({
      packageDocsContext,
      packageCardContext,
      formValues,
    });

    onBeforeSave?.();

    await updatePackageMutation({
      packageId,
      packageFullData,
      filesDict,
    });
  };

  const handleSavePackage = () => {
    return new Promise<void>((res) => {
      const inner = async () => {
        await doSavePackage(onGetValues());
        res();
      };

      if (onSubmit) {
        onSubmit(inner);
      } else {
        void inner();
      }
    });
  };

  const { mutateAsync: publishPackageMutation, isLoading: isPublishing } =
    usePublishPackage(!isSigner);

  const queryClient = useQueryClient();

  const invalidateQuery = async () => {
    return queryClient.invalidateQueries(
      getPackageCardQueryKey({
        ownerClientId: packageCardContext.packageAdapted.clientIdOwner,
        packageId,
      })
    );
  };

  const onPublishPackage = async () => {
    try {
      await handleSavePackage();
      await publishPackageMutation({ packageId });
      setPublished(true);

      if (!isSigner) {
        await invalidateQuery();
      }
    } catch (e) {
      // TODO: add error processing
    }
  };

  return {
    onSave: handleSavePackage,
    onPublish: onPublishPackage,
    isPublishing,
    isUpdatingPackage,
    isPublished,
  };
}
