import Header, { HeaderSizesEnum } from '@signplatform-ui/header';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ModalTitleStyled = styled(Header).attrs({
  type: HeaderSizesEnum.H6,
})`
  margin-bottom: 16px;
  margin-right: 30px;
`;
