import { useState, useRef } from 'react';

class LimitedDefinedArray<T> extends Array<T> {
  constructor(private initValue: T | undefined, private limit: number) {
    super();
    this.insertValue(initValue);
  }

  private insertValue(value: T | undefined): void {
    if (value === undefined) return;

    this.unshift(value);
    if (this.length > this.limit) this.length = this.limit;
  }

  doAddValue(value: T | undefined): Array<T> {
    this.insertValue(value);
    return this.slice();
  }

  doClear(value: T): Array<T> {
    this.length = 0;
    return this.doAddValue(value);
  }
}

export default function useManualHistory<T>(
  initValue: T | undefined,
  limit = 100
): [T[], (value: T) => void, (value: T) => void] {
  const arr = useRef(new LimitedDefinedArray(initValue, limit));
  const [data, setData] = useState<T[]>(() => arr.current.slice());

  const handleAddNewValue = (value: T) => {
    setData(arr.current.doAddValue(value));
  };

  const handleClear = (value: T) => {
    setData(arr.current.doClear(value));
  };

  return [data, handleAddNewValue, handleClear];
}
