import styled from 'styled-components';
import InputField from '@signplatform-ui/input-field';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button + button {
    margin-left: 8px;
  }
`;

export const ModalActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button + button {
    margin-left: 8px;
  }
`;

export const StyledInput = styled(InputField)`
  margin-bottom: 16px;
`;

export const StyledText = styled.p`
  margin-bottom: 16px;
  word-break: break-word;
`;

export const CardButtonsWrapper = styled.div`
  margin-left: 16px;
`;
