import isCounterpartyOrg from 'modules/Documents/components/PackageWizard/utils/isCounterpartyOrg';
import isCounterpartyPerson from 'modules/Documents/components/PackageWizard/utils/isCounterpartyPerson';
import getPersonShortName from 'modules/Documents/utils/getPersonShortName';
import type { CpTypeTrap } from 'modules/Documents/components/PackageWizard/utils/types';
import type { PersonNameData } from 'modules/Documents/utils/types';
import type { FoundOrInvitedCounterparty } from 'modules/Documents/components/PackageWizard/types';

type GetCpCaptionOptions = {
  getPersonName?: (nameData: PersonNameData) => string;
};

export default function getCounterpartyCaption<
  T extends DeepROStrict<FoundOrInvitedCounterparty>
>(
  data: CpTypeTrap<T>,
  { getPersonName = getPersonShortName }: GetCpCaptionOptions = {}
): string {
  if (isCounterpartyPerson(data)) {
    // отсутствие clientId признак, что клиент приглашен / вне платформы
    if (!data.clientId) return data.email!;

    // отсутствие имени или фамилии - частный случай
    if (!data.firstName || !data.lastName) {
      return data.email || String(data.clientId);
    }

    return getPersonName(data);
  }

  if (isCounterpartyOrg(data)) {
    // отсутствие clientId признак, что организация приглашена / вне платформы
    return data.name || data.email!;
  }

  return data.email!;
}
