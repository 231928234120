import AppRoutesPaths from 'modules/Router/routes/paths';
import { lazy } from 'react';
import type { AppRoute } from '../types';
import { RouteTypeEnum } from '../types';

const AgreementsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AgreementsPage" */ 'modules/UserAgreements/pages/Agreements'
    )
);

const TermsOfUsePage = lazy(
  () =>
    import(
      /* webpackChunkName: "TermsOfUsePage" */ 'modules/UserAgreements/pages/TermsOfUse'
    )
);

const DataProcessingPolicyPage = lazy(
  () =>
    import(
      /* webpackChunkName: "DataProcessingPolicyPage" */ 'modules/UserAgreements/pages/DataProcessingPolicy'
    )
);

const UESUsePage = lazy(
  () =>
    import(
      /* webpackChunkName: "UESUsePage" */ 'modules/UserAgreements/pages/UESUse'
    )
);

const routes: AppRoute[] = [
  {
    id: 'agreements:list',
    path: AppRoutesPaths.AgreementsList,
    exact: true,
    type: RouteTypeEnum.Public,
    component: AgreementsPage,
  },
  {
    id: 'agreements:termsOfUse',
    path: AppRoutesPaths.TermsOfUse,
    exact: true,
    type: RouteTypeEnum.Public,
    component: TermsOfUsePage,
  },
  {
    id: 'agreements:dataProcessingPolicy',
    path: AppRoutesPaths.DataProcessingPolicy,
    exact: true,
    type: RouteTypeEnum.Public,
    component: DataProcessingPolicyPage,
  },
  {
    id: 'agreements:uesAgreement',
    path: AppRoutesPaths.UESAgreement,
    exact: true,
    type: RouteTypeEnum.Public,
    component: UESUsePage,
  },
];

export default routes;
