import Button, { ButtonSizesEnum } from '@signplatform-ui/button';
import { useCallback, useRef } from 'react';
import type { ChangeEvent, PropsWithChildren } from 'react';
import { StyledFileInput } from './FileUploadButton.styled';

type FileUploadProps = {
  className?: string;
  onSelect: (files: FileList) => void;
  disabled?: boolean;
  accept?: string;
  multiple?: boolean;
};

const FileUploadButton = ({
  children,
  onSelect,
  className = '',
  disabled,
  accept = '*/*',
  multiple = true,
}: PropsWithChildren<FileUploadProps>): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const onShowDialog = useCallback(() => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.click();
  }, []);

  const onSelectFile = useCallback(
    ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
      if (files) {
        onSelect(files);
      }

      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
    [onSelect]
  );

  return (
    <>
      <Button
        size={ButtonSizesEnum.Medium}
        className={className}
        onClick={onShowDialog}
        disabled={disabled}
      >
        {children}
      </Button>
      <StyledFileInput
        type='file'
        ref={inputRef}
        onChange={onSelectFile}
        accept={accept}
        multiple={multiple}
      />
    </>
  );
};

export default FileUploadButton;
