import { useToken } from 'modules/Shared/hooks';
import { useEffect } from 'react';
import type { PropsWithChildren } from 'react';
import { useInterceptors } from './hooks/interceptors';
import { apiService } from './services';
import {
  useExcGateApi,
  useAuthApi,
  useCounterpartiesApi,
  useDocumentApi,
  useLoadDocumentApi,
  useSignGateApi,
  useUserApi,
  useAttorneyApi,
  useAttorneyLoadDocumentApi,
  useGenDocApi,
  useReferencesApi,
  useLogicReferencesApi,
  useCheckSignApi,
} from './hooks';
import ApiContext from './ApiContext';

const ApiProvider = ({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element => {
  const { token } = useToken();
  const userApi = useUserApi();
  const authApi = useAuthApi();
  const documentApi = useDocumentApi();
  const loadDocumentApi = useLoadDocumentApi();
  const signGateApi = useSignGateApi();
  const counterpartiesApi = useCounterpartiesApi();
  const powAttorneyApi = useAttorneyApi();
  const powAttorneyLoadDocumentApi = useAttorneyLoadDocumentApi();
  const genDocApi = useGenDocApi();
  const referencesApi = useReferencesApi();
  const logicReferencesApi = useLogicReferencesApi();
  const excGateApi = useExcGateApi();
  const checkSignApi = useCheckSignApi();

  //Add interceptors
  useInterceptors();

  // Watch accessToken for api client instance
  useEffect(() => {
    apiService.setAuthorizationHeader(token?.accessToken);
  }, [token?.accessToken]);

  return (
    <ApiContext.Provider
      value={{
        userApi,
        authApi,
        documentApi,
        loadDocumentApi,
        signGateApi,
        counterpartiesApi,
        powAttorneyApi,
        powAttorneyLoadDocumentApi,
        genDocApi,
        referencesApi,
        logicReferencesApi,
        excGateApi,
        checkSignApi,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
export default ApiProvider;
