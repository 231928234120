import { HeaderSizesEnum } from '@signplatform-ui/header';
import PackagePublishedModal from 'modules/Documents/components/PackagePublishedModal';
import PackageSigningDueDate from 'modules/Documents/components/PackageSigningDueDate';
import { usePackageContext } from 'modules/Documents/contexts';
import { useSaveAndPublishPackage } from 'modules/Documents/hooks';
import AppRoutesPaths from 'modules/Router/routes/paths';
import { FormProvider, useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import useSignDueDate from 'modules/Documents/components/PackageWizard/hooks/useSignDueDate';
import type { FieldError } from 'react-hook-form';
import type { PackageWizardFormValues } from 'modules/Documents/components/PackageWizard/types';
import IAmOnlySignerToggle from './components/IAmOnlySigner';
import IAmNotSignerToggle from './components/IAmNotSigner';
import {
  DocumentCounterparties,
  ModalOnLeavingDraftSpec,
  PackageWizardHeader,
  PublishPackage,
} from './components';
import { packageWizardFormHoc } from './hocs';
import {
  StyledCard,
  StyledHeader,
  StyledPackEditor,
  StyledErrorMessage,
} from './PackageWizard.styled';

// it has HOC it wrapped with
const PackageWizard = (): JSX.Element | null => {
  const { packageAdapted, packageServer, packageName } = usePackageContext();

  const history = useHistory();
  const formData = useFormContext<PackageWizardFormValues>();
  const { signDueDate, onSetDueDate, errorMessage } = useSignDueDate();

  const {
    formState: { errors },
    getValues,
    handleSubmit,
  } = formData;

  const { packageId, clientIdOwner, buttons } = packageAdapted;

  const goToList = () => {
    history.push(AppRoutesPaths.DocumentsList);
  };

  const isSigner = !getValues('iAmNotSigner');

  const { isUpdatingPackage, isPublishing, isPublished, onPublish, onSave } =
    useSaveAndPublishPackage({
      packageId,
      isSigner,
    });

  const isSaveDisabled = isUpdatingPackage || isPublishing;
  const isPublishDisabled =
    isUpdatingPackage || isPublishing || !buttons.sendButton;

  // react-hook-form stores schema-level error in empty string key ''
  const formRootError = (errors as { '': FieldError })?.['']?.message;
  // react-hook-form wrongly types array value
  const docsError = (errors?.docs as FieldError | undefined)?.message;

  return (
    <FormProvider {...formData}>
      <PackageWizardHeader
        buttons={buttons}
        isPublishDisabled={isPublishDisabled}
        name={packageName}
        onPublish={handleSubmit(onPublish)}
        ownerClientId={clientIdOwner}
        packageId={packageId}
        uniqueNumber={packageAdapted.source.uniqueNumber}
      />

      <StyledHeader type={HeaderSizesEnum.H6}>
        Список документов на подписание
      </StyledHeader>

      <StyledPackEditor />

      <StyledErrorMessage $isAfterCard>{docsError}</StyledErrorMessage>

      <StyledHeader type={HeaderSizesEnum.H6}>
        Мой статус в подписании
      </StyledHeader>

      <StyledCard>
        <IAmNotSignerToggle />
      </StyledCard>

      <StyledHeader $justifyContent='space-between' type={HeaderSizesEnum.H6}>
        <div>Добавление участников</div>

        <IAmOnlySignerToggle />
      </StyledHeader>

      <StyledCard>
        <DocumentCounterparties documentPackage={packageServer} />
      </StyledCard>

      <StyledErrorMessage $isAfterCard>{formRootError}</StyledErrorMessage>

      <StyledHeader type={HeaderSizesEnum.H6}>
        Настройки подписания
      </StyledHeader>

      <StyledCard>
        <PackageSigningDueDate
          signDueDate={signDueDate}
          onChange={onSetDueDate}
          errorMessage={errorMessage}
        />
      </StyledCard>

      <StyledHeader type={HeaderSizesEnum.H6}>
        Отправка комплекта документов
      </StyledHeader>

      <StyledCard>
        <PublishPackage
          isPublishDisabled={isPublishDisabled}
          isSaveDisabled={isSaveDisabled}
          handleSavePackage={handleSubmit(onSave)}
          onPublish={handleSubmit(onPublish)}
        />
      </StyledCard>

      <ModalOnLeavingDraftSpec onSaveDraft={onSave} />

      {isPublished && isSigner && (
        <PackagePublishedModal
          packageId={packageId}
          ownerClientId={clientIdOwner}
          onClose={goToList}
        />
      )}
    </FormProvider>
  );
};

export default packageWizardFormHoc(PackageWizard);
