import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import { useModal } from 'modules/Common/hooks';
import type { ButtonProps } from '@signplatform-ui/button';
import RejectSigningModal from '../RejectSigningModal';

type RejectPackageSigningButtonProps = {
  ownerClientId: EntityId;
  packageId: EntityId;
  signerClientId: EntityId;
  onSuccess?: () => Promise<void>;
  buttonProps?: ButtonProps;
};

export default function RejectPackageSigningButton({
  ownerClientId,
  packageId,
  signerClientId,
  buttonProps,
  onSuccess,
}: RejectPackageSigningButtonProps): JSX.Element {
  const { isOpen, onClose, onOpen } = useModal(false);

  return (
    <>
      <Button
        onClick={onOpen}
        size={ButtonSizesEnum.Medium}
        color={ButtonColorsEnum.Secondary}
        data-testid='reject-package-signing-button'
        {...buttonProps}
      >
        Отклонить
      </Button>

      {isOpen && (
        <RejectSigningModal
          onSuccess={onSuccess}
          onClose={onClose}
          ownerClientId={ownerClientId}
          packageId={packageId}
          signerClientId={signerClientId}
        />
      )}
    </>
  );
}
