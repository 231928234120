import Text, { TextTypesEnum } from '@signplatform-ui/text';
import Tooltip from '@signplatform-ui/tooltip';
import { isEmpty } from 'lodash';
import { useTheme } from 'styled-components';
import {
  endSlice,
  lengthWhichVisibleEllipses,
  VISIBLE_WITHOUT_TOOLTIP_TEXT_ROWS_COUNT,
} from '../../constants';
import {
  BadgeStyled,
  ListHeader,
  ListItem,
} from '../../PackageListCard.styled';
import {
  StyledTooltipContent,
  WrapDocuments,
} from './PackageListCardDocuments.styled';

type PackageListCardDocumentsProps = {
  documents?: { name: string }[];
};

const PackageListCardDocuments = ({
  documents,
}: PackageListCardDocumentsProps): JSX.Element => {
  const theme = useTheme();

  return (
    <WrapDocuments>
      <ListHeader>
        <Text color={theme.color.text.secondary}>Документы</Text>
        {!isEmpty(documents) && (
          <BadgeStyled data-testid='documents-count-badge'>
            {documents!.length}
          </BadgeStyled>
        )}
      </ListHeader>
      {isEmpty(documents) && <Text type={TextTypesEnum.S}>Не выбрано</Text>}
      {documents
        ?.slice(0, VISIBLE_WITHOUT_TOOLTIP_TEXT_ROWS_COUNT)
        .map(({ name }, idx) => (
          <ListItem
            key={idx}
            type={TextTypesEnum.S}
            data-testid='document-item-name'
          >
            {name}
          </ListItem>
        ))}
      {!isEmpty(documents) && documents!.length > 2 && (
        <Tooltip
          data-testid='package-documents-tooltip'
          renderContent={() => (
            <StyledTooltipContent>
              {documents
                ?.slice(VISIBLE_WITHOUT_TOOLTIP_TEXT_ROWS_COUNT, endSlice)
                .map(({ name }, idx) => (
                  <div key={idx}>{name}</div>
                ))}
              {documents && documents?.length >= lengthWhichVisibleEllipses ? (
                <div data-testid='documents-tooltip-more'>...</div>
              ) : null}
            </StyledTooltipContent>
          )}
        >
          <Text type={TextTypesEnum.S} color={theme.color.text.secondary}>
            Еще {documents!.length - 2}
          </Text>
        </Tooltip>
      )}
    </WrapDocuments>
  );
};

export default PackageListCardDocuments;
