import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import storage from 'services/storage';
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from 'modules/App/constants/AppConstants';
import TokenContext from 'modules/Shared/components/TokenProvider/TokenContext';
import type { TokenModel } from 'modules/Shared/components/TokenProvider/TokenContext';
import type { PropsWithChildren } from 'react';

const TokenProvider = ({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element => {
  const client = useQueryClient();
  const [tokenModel, setTokenModel] = useState<TokenModel>(() => {
    const accessToken = storage.getItem(ACCESS_TOKEN_KEY);
    const refreshToken = storage.getItem(REFRESH_TOKEN_KEY);

    return accessToken && refreshToken
      ? {
          accessToken,
          refreshToken,
        }
      : null;
  });

  const setTokenValue = useCallback(
    (token: TokenModel) => {
      setTokenModel(() => {
        if (!token) {
          storage.clear();
          client.clear();
          return null;
        }

        const { accessToken, refreshToken } = token;

        storage.setItem(ACCESS_TOKEN_KEY, accessToken);
        storage.setItem(REFRESH_TOKEN_KEY, refreshToken);

        return {
          accessToken,
          refreshToken,
        };
      });
    },
    [client]
  );

  return (
    <TokenContext.Provider
      value={{ token: tokenModel, setToken: setTokenValue }}
    >
      {children}
    </TokenContext.Provider>
  );
};

export default TokenProvider;
