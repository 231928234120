import { media } from 'modules/StyledUtility';
import { ToastContainer } from 'react-toastify';
import styled, { css } from 'styled-components';

const StyledToastContainer = styled(ToastContainer)`
  & {
    width: 424px;

    ${media.mobile(css`
      width: 100%;
      padding-top: 16px;
    `)}
  }

  .Toastify__toast {
    &-theme--light {
      background: transparent;
      padding: 0;
      border-radius: 0;
      box-shadow: none;
      overflow: visible;
    }

    &-body {
      background: transparent;
      padding: 0;
    }
    ${media.mobile(css`
      margin-left: 16px;
      margin-right: 16px;
    `)}
  }
`;

export default StyledToastContainer;
