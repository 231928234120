import styled from 'styled-components';
import { Row } from 'styled-bootstrap-grid';

export const StyledInfoBox = styled.div`
  max-width: 700px;
`;

export const StyledRow = styled(Row)`
  margin-top: 20px;
`;
