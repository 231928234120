import { ButtonColorsEnum } from '@signplatform-ui/button';
import Text, { TextTypesEnum } from '@signplatform-ui/text';
import { StatusUnitEnum } from '@sp-api/documents-api';
import useToggler from 'modules/Common/hooks/useToggler';
import PackageSignStatus from 'modules/Documents/components/PackageSignStatus';
import SigningTimer from 'modules/Documents/components/SigningTimer';
import AppRoutesPaths from 'modules/Router/routes/paths';
import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { useTheme } from 'styled-components';
import PackageListActions from 'modules/Documents/components/PackageListActions';
import useResignLock from 'modules/Documents/components/ResignButton/hooks/useResignLock';
import getThresholdMessage from 'modules/Documents/utils/getThresholdMessage';
import { DocumentsStateKeysEnum } from 'modules/Documents/constants';
import { useQueryClient } from 'react-query';
import { useUser } from 'modules/Shared/hooks';
import type { RemoteUnitParticipantData } from '@sp-api/documents-api';
import type { Theme } from '@vtb/ui-kit3';
import type { DateMap } from './components';
import {
  DirectionStatus,
  PackageListCardDocuments,
  PackageListCardSigners,
} from './components';
import {
  AccordionStyled,
  AccordionTriggerButton,
  CardActionsWrapper,
  CardTitleRow,
  CardTitleWrapper,
  ChevronDownOutlineStyled,
  ContentWrap,
  StatusLeftGroup,
  StatusWrapper,
  StyledCard,
  StyledChangeStatusDate,
  StyledLink,
  StyledPackageDates,
  StyledTimer,
} from './PackageListCard.styled';

export type CardItemProps = {
  item: RemoteUnitParticipantData;
};

const showTimerStatuses = [StatusUnitEnum.Waiting, StatusUnitEnum.Expired];

const PackageListCard = ({
  item: {
    clientIdOwner,
    name,
    status,
    stateUpdateStatus,
    packageId,
    uniqueNumber,
    createDate,
    innerPersonsData,
    innerCounterpartyData,
    outerPersonsData,
    outerCounterpartyData,
    remoteUnitAbd,
    buttons,
    sendDate,
    signDueDate,
    signingStatus,
  },
}: CardItemProps): JSX.Element => {
  const accordionToggler = useToggler();
  const theme = useTheme() as Theme;
  const { user } = useUser();
  const queryClient = useQueryClient();

  const dateMapForStatus = useMemo<DateMap>(
    () => ({
      createDate,
      sendDate,
      stateUpdateDate: stateUpdateStatus,
      signDueDate,
    }),
    [createDate, sendDate, stateUpdateStatus, signDueDate]
  );

  const invalidateListQuery = async () => {
    return queryClient.invalidateQueries(DocumentsStateKeysEnum.List);
  };

  const isSigner = innerPersonsData?.some(
    ({ clientId }) => user.clientId === clientId
  );

  const { threshold, setIsLocked } = useResignLock();

  const tooltipContent = getThresholdMessage(threshold);

  const onLockResign = async () => {
    setIsLocked(true);
    await invalidateListQuery();
  };

  return (
    <StyledCard>
      <CardTitleWrapper>
        <CardTitleRow>
          <StyledLink
            to={generatePath(AppRoutesPaths.PackageDetails, {
              clientId: clientIdOwner,
              packageId: packageId!,
            })}
            title={name}
            data-testid='document-item-title'
          >
            {name}
          </StyledLink>
          <AccordionTriggerButton
            color={ButtonColorsEnum.Ghost}
            onClick={accordionToggler.toggle}
            data-testid='package-card-details-control'
          >
            <ChevronDownOutlineStyled $up={accordionToggler.isActive} />
          </AccordionTriggerButton>
        </CardTitleRow>

        {buttons && (
          <CardActionsWrapper>
            <PackageListActions
              packageId={packageId!}
              ownerClientId={clientIdOwner}
              title={name}
              status={status}
              buttons={buttons}
              isSigner={isSigner}
              resignThreshold={threshold}
              resignTooltip={tooltipContent}
              onResignSuccess={onLockResign}
            />
          </CardActionsWrapper>
        )}
      </CardTitleWrapper>
      <ContentWrap>
        <Text
          type={TextTypesEnum.S}
          as='span'
          color={theme.color.text.tertiary}
        >
          ID:{' '}
          {status === StatusUnitEnum.Draft ? `${uniqueNumber}-0` : uniqueNumber}
        </Text>
        <StatusWrapper>
          <StatusLeftGroup>
            <PackageSignStatus
              status={status}
              userSigningStatus={signingStatus}
            />
            {status !== StatusUnitEnum.Draft && (
              <DirectionStatus clientIdOwner={clientIdOwner} />
            )}
          </StatusLeftGroup>
          <StyledPackageDates>
            <StyledChangeStatusDate
              status={status}
              dateMap={dateMapForStatus}
            />
            {showTimerStatuses.includes(status) && signDueDate && (
              <StyledTimer>
                <Text type={TextTypesEnum.S} color={theme.color.text.secondary}>
                  Осталось:&nbsp;
                </Text>
                <SigningTimer dueDate={signDueDate} />
              </StyledTimer>
            )}
          </StyledPackageDates>
        </StatusWrapper>
        <AccordionStyled trigger='' open={accordionToggler.isActive}>
          <PackageListCardSigners
            ownerClientId={clientIdOwner}
            innerPersonsData={innerPersonsData}
            innerCounterpartyData={innerCounterpartyData}
            outerPersonsData={outerPersonsData}
            outerCounterpartyData={outerCounterpartyData}
          />
          <PackageListCardDocuments documents={remoteUnitAbd} />
        </AccordionStyled>
        {buttons && (
          <CardActionsWrapper>
            <PackageListActions
              packageId={packageId!}
              ownerClientId={clientIdOwner}
              title={name}
              status={status}
              buttons={buttons}
              isSigner={isSigner}
              withoutDropDown
              resignThreshold={threshold}
              resignTooltip={tooltipContent}
              onResignSuccess={onLockResign}
            />
          </CardActionsWrapper>
        )}
      </ContentWrap>
    </StyledCard>
  );
};

export default PackageListCard;
