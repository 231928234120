import Page404WithLayout from 'modules/Shared/components/Page404';
import type { AppRoute } from '../types';
import { RouteTypeEnum } from '../types';

const notFoundRoute: AppRoute = {
  id: 'notFound:404',
  type: RouteTypeEnum.Public,
  component: Page404WithLayout,
};

export default notFoundRoute;
