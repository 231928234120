import { StyledLabel, StyledProfileAvatar } from './ProfileAvatar.styled';
import ProfileAvatarSizesEnum from './ProfileAvatarSizesEnum';

export type ProfileAvatarProps = {
  size?: ProfileAvatarSizesEnum;
  firstName?: string;
  lastName?: string;
  className?: string;
};

const getInitials = (
  firstName: string,
  lastName: string,
  size: ProfileAvatarSizesEnum
): string => {
  return size === ProfileAvatarSizesEnum.XS
    ? lastName?.[0] || ''
    : [firstName?.[0], lastName?.[0]].filter(Boolean).join('');
};

const ProfileAvatar = ({
  size = ProfileAvatarSizesEnum.MD,
  firstName = '',
  lastName = '',
  className,
}: ProfileAvatarProps): JSX.Element => {
  return (
    <StyledProfileAvatar size={size} className={className}>
      <StyledLabel>{getInitials(firstName, lastName, size)}</StyledLabel>
    </StyledProfileAvatar>
  );
};

export default ProfileAvatar;
