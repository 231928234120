import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const SpinnerWrapper = styled.div<{ $isGlobal?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 160px;

  ${({ $isGlobal }) => {
    if (!$isGlobal) return undefined;

    return css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: #cccccc50 fixed;
    `;
  }}
`;
