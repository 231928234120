import { makeEnumerablePrototype } from 'modules/Common/services';
import type { InviteNotFoundUserData } from './types';
import type {
  CounterpartyPerson,
  CounterpartyPersonBase,
  InviteStatusEnumUni,
} from 'modules/Documents/types';
import SignMixinEmpty from './SignMixinEmpty';

type ISigningAdapterSearchPerson = CounterpartyPerson &
  PartialToUndefined<Omit<CounterpartyPersonBase, 'participantId'>>;

// TODO remove excessive getters due to properties
@makeEnumerablePrototype
export default class SigningAdapterInvitePerson
  extends SignMixinEmpty<InviteStatusEnumUni>
  implements ISigningAdapterSearchPerson
{
  constructor(private _data: InviteNotFoundUserData) {
    super();
  }

  get snils(): string | undefined {
    return this._data.snils;
  }

  get email(): string | undefined {
    return this._data.email;
  }

  get idNum(): string | undefined {
    return this._data.idNum;
  }

  get lastName(): string | undefined {
    return undefined;
  }

  get firstName(): string | undefined {
    return undefined;
  }

  get middleName(): string | undefined {
    return undefined;
  }

  get inn(): string | undefined {
    return undefined;
  }

  get phoneNumber(): string | undefined {
    return undefined;
  }

  get inviteId(): EntityId | undefined {
    return undefined;
  }

  get __fePerson(): boolean {
    return true;
  }
}
