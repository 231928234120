export const DEFAULT_FIELD_ERROR =
  'Введенное значение не соответствует формату';

export const EMPTY_FIELD_ERROR = 'Заполните поле';

export const SNILS_FIELD_ERROR = DEFAULT_FIELD_ERROR;
export const INN_FIELD_ERROR = DEFAULT_FIELD_ERROR;
export const PASSPORT_FIELD_ERROR = DEFAULT_FIELD_ERROR;
export const PHONE_FIELD_ERROR = DEFAULT_FIELD_ERROR;
export const EMAIL_FIELD_ERROR = 'Введенное значение не соответствует формату';

export const IS_REQUIRED_ERROR_MESSAGE = 'Не заполнено обязательное поле';
