import { createContext } from 'react';
import type { ErrorMap } from 'modules/Documents/classes/DocumentValidator';
import type { IFileInstance } from 'modules/UI/components/DocumentsPackEditor/types';

export interface IPackageDocsContext {
  readonly notLoadedDocIds: Readonly<Set<string>>;
  readonly docsUINameById: Readonly<{ [clientServerId: string]: string }>;
  readonly setDocName: (clientServerId: string, name: string) => void;
  readonly getDocName: (doc: EntityId | IFileInstance | string) => string;
  readonly docsErrorMap: Readonly<ErrorMap>;
  readonly isDisabled: boolean;
}

const PackageDocsContext = createContext<IPackageDocsContext>(
  null as unknown as IPackageDocsContext
);

export default PackageDocsContext;
