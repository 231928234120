import Button, {
  ButtonColorsEnum,
  ButtonSizesEnum,
} from '@signplatform-ui/button';
import { ArchiveOutline } from '@signplatform-ui/icons';
import { ButtonWithIcon } from '../../CardHeader.styled';

type DownloadDocumentProps = {
  onDownload: () => void;
  isLoading: boolean;
  small?: boolean;
};

const DownloadDocsPack = ({
  onDownload,
  isLoading,
  small,
}: DownloadDocumentProps): JSX.Element => {
  if (!small) {
    return (
      <Button
        color={ButtonColorsEnum.Secondary}
        disabled={isLoading}
        onClick={onDownload}
        size={ButtonSizesEnum.Medium}
      >
        Скачать комплект
      </Button>
    );
  }

  return (
    <ButtonWithIcon
      color={ButtonColorsEnum.Ghost}
      size={ButtonSizesEnum.Medium}
      disabled={isLoading}
      onClick={onDownload}
      displayAsSquare
      style={{ marginRight: 12 }}
    >
      <ArchiveOutline />
    </ButtonWithIcon>
  );
};

export default DownloadDocsPack;
