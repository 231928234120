import styled, { css } from 'styled-components';
import { media } from 'modules/StyledUtility';

// eslint-disable-next-line import/prefer-default-export
export const ButtonsWrapper = styled.div`
  button + button {
    margin-left: 8px;
  }

  ${media.mobileXS(css`
    button {
      width: 100%;
    }
  `)}
`;
